import { Quaternion, Vector3 } from "@babylonjs/core";

import OptionableMixin from "../model/optionable-mixin";
import OptionUtility from "../utility/option-utility";
import AbstractOptionController from "./abstract-option-controller";
import self from "../index";

const {
    modules: {
        catalogManager,
    },
} = self.app;

export default class BaseplateOptionController extends AbstractOptionController {

    constructor(optionController) {
        super(optionController);

        this.products = catalogManager.products;

        this.oldBaseplatesReferences = {};
        this.oldBaseplatesReferences[`${OptionableMixin.BASEPLATE_OPTION.SQUARED}`] = "901 10 07";
        this.oldBaseplatesReferences[`${OptionableMixin.BASEPLATE_OPTION.BOTH_SIDE}`] = "901 10 08";
        this.oldBaseplatesReferences[`${OptionableMixin.BASEPLATE_OPTION.DOUBLE}`] = "901 10 09";
        this.oldBaseplatesReferences[`${OptionableMixin.BASEPLATE_OPTION.ONE_SIDE}`] = "901 10 15";

        this.baseplatesReferences = {};
        this.baseplatesReferences[`${OptionableMixin.BASEPLATE_OPTION.SQUARED}`] = "901 11 07";
        this.baseplatesReferences[`${OptionableMixin.BASEPLATE_OPTION.BOTH_SIDE}`] = "901 11 08";
        this.baseplatesReferences[`${OptionableMixin.BASEPLATE_OPTION.DOUBLE}`] = "901 11 09";
        this.baseplatesReferences[`${OptionableMixin.BASEPLATE_OPTION.ONE_SIDE}`] = "901 11 15";

        this.initializeEvents(this.baseplatesReferences);
    }

    /**
     * Add a baseplate mesh to the passed entity
     * @param {*} baseplateType
     * @param {*} entity if null take the current entity
     */
    addBaseplate(baseplateType, entity = null) {
        const realEntity = this.optionController.returnRealEntity(entity);
        if (!realEntity) {
            return Promise.resolve(null);
        }

        return this.updateBaseplateMeshes(realEntity, baseplateType);
    }

    removeBaseplateMeshes(entity = null) {
        const entityToRemoveFrom = this.optionController.returnRealEntity(entity);
        if (entityToRemoveFrom.optionsMeshes
            && entityToRemoveFrom.optionsMeshes.baseplates.length) {
            entityToRemoveFrom.optionsMeshes.baseplates.forEach(baseplate => {
                baseplate.dispose();
            });
            entityToRemoveFrom.optionsMeshes.baseplates.splice(0);
        }
    }

    /**
     * Remove the baseplate
     * @param {*} entity if null take the current entity
     */
    removeBaseplate(entity = null) {
        const entityToRemoveFrom = this.optionController.returnRealEntity(entity);

        if (entityToRemoveFrom.optionsMeshes
            && entityToRemoveFrom.getBaseplateOption() > OptionableMixin.BASEPLATE_OPTION.NONE) {
            entityToRemoveFrom.setBaseplateOption(OptionableMixin.BASEPLATE_OPTION.NONE);
            entityToRemoveFrom.swappedOptions.BASEPLATE = false;
            this.removeBaseplateMeshes(entity);
        }
    }

    updateBaseplateMeshes(frame, type) {
        this.removeBaseplateMeshes(frame);
        frame.baseplateOption = type;

        if (frame.baseplateOption) {
            const baseplateRef = this.getBaseplateRef(frame);
            const baseplates = [];
            baseplates.push(this.loadBaseplateFromRef(baseplateRef));

            // Add a second baseplate
            if (type === OptionableMixin.BASEPLATE_OPTION.DOUBLE) {
                baseplates.push(this.loadBaseplateFromRef(baseplateRef));
            }
            frame.optionsMeshes.baseplates = baseplates;
            const angleInfo = OptionUtility.angleToUpward(frame.mesh);
            const boundingBox = frame.getBoundingBox();
            const boundingBoxExtend = boundingBox.extendSize;
            frame.computeOptionsParameters();

            // Check frame orientation
            const dot = Math.abs(Vector3.Dot(frame.mesh.up, Vector3.Up()));

            // Modify the real mesh
            frame.optionsMeshes.baseplates.forEach(baseplate => {
                baseplate.name = "baseplate";
                baseplate.parent = frame.mesh;
                baseplate.ref = baseplateRef;
                baseplate.rotate(Vector3.Left(), -Math.PI / 2);
                baseplate.rotate(Vector3.Up(), -angleInfo.angle);
                // plus one millimeter as askes by beMatrix
                baseplate.translate(Vector3.Forward(), frame.orientedHeight / 2 - 0.001);
                baseplate.computeWorldMatrix(true);
            });
            if (frame.optionsMeshes.baseplates.length > 1) {
                if (dot > 0.99) { // Case the frame is not rotated
                    frame.optionsMeshes.baseplates[0]
                        .translate(Vector3.Right(), boundingBoxExtend.x - 0.124);
                    frame.optionsMeshes.baseplates[1]
                        .translate(Vector3.Left(), boundingBoxExtend.x - 0.124);
                } else if (dot < 0.05) { // Case the frame is rotated 90°
                    frame.optionsMeshes.baseplates[0]
                        .translate(Vector3.Right(), boundingBoxExtend.y - 0.124);
                    frame.optionsMeshes.baseplates[1]
                        .translate(Vector3.Left(), boundingBoxExtend.y - 0.124);
                }
            }

            // Swap side if needed
            if (frame.swappedOptions.BASEPLATE) {
                this.optionController.swapOptionsMeshesSide(
                    this.optionController.optionsFamilies.BASEPLATE,
                    frame
                );
            }
            this.toggleBaseplatesVisibility(frame.visible, frame);
        }
        return Promise.resolve(null);
    }

    /**
     * Return an array with the baseplates reference available for the entity
     * @param {*} entity
     */
    availableBaseplates(entity = null) {
        const finalEntity = this.optionController.returnRealEntity(entity);
        if (!finalEntity) {
            return null;
        }
        if (!this.canHaveBaseplate(finalEntity)) {
            finalEntity.canHaveBaseplatesOption = false;
            return [];
        }
        const availableReferences = [];
        finalEntity.computeOptionsParameters();

        if (finalEntity.orientedWidth >= 0.992) {
            Object.keys(this.baseplatesReferences).forEach(baseplateType => {
                const ref = this.baseplatesReferences[baseplateType];
                availableReferences.push({ ref, type: parseInt(baseplateType, 10), thumbnail: this.products[ref].thumbnailUrl });
            });
        } else if (finalEntity.orientedWidth >= 0.496) {
            const squaredBaseplate = OptionableMixin.BASEPLATE_OPTION.SQUARED;
            const doubleBaseplate = OptionableMixin.BASEPLATE_OPTION.DOUBLE;
            const squaredBPRef = this.baseplatesReferences[squaredBaseplate];
            const doubleBPRef = this.baseplatesReferences[doubleBaseplate];

            availableReferences.push({ ref: squaredBPRef, type: parseInt(squaredBaseplate, 10), thumbnail: this.products[squaredBPRef].thumbnailUrl });
            availableReferences.push({ ref: doubleBPRef, type: parseInt(doubleBaseplate, 10), thumbnail: this.products[doubleBPRef].thumbnailUrl });
        }

        finalEntity.canHaveBaseplatesOption = availableReferences.length > 0;

        return availableReferences;
    }

    /**
     * Rotate the baseplate around the up axis of the world, of 180 degrees
     * @param {*} entity
     */
    swapBaseplateSide(entity = null) {
        const quaternion = Quaternion.RotationAxis(Vector3.Forward(), Math.PI);
        const entityToRotate = this.optionController.returnRealEntity(entity);
        entityToRotate.optionsMeshes.baseplates[0].rotationQuaternion.multiplyInPlace(quaternion);
        entityToRotate.optionsMeshes.baseplates[0].computeWorldMatrix(true);
    }

    /**
     * Is this entity nearly aligned with the ground
     * @param {*} entity
     */
    isCorrectlyOriented(entity = null) {
        const realEntity = this.optionController.returnRealEntity(entity);
        if (!realEntity) {
            return null;
        }
        return OptionUtility.isAlignedWithGround(realEntity.mesh);
    }

    /**
     * Does the current entity already have a baseplate or not
     * @param {*} entity if null take the current entity
     */
    haveBaseplate(entity = null) {
        const realEntity = this.optionController.returnRealEntity(entity);
        if (!realEntity) {
            return null;
        }

        if (realEntity.optionsMeshes
            && realEntity.optionsMeshes.baseplates[0]) {
            return true;
        }
        return false;
    }

    /**
     * Check if the given entity can have a baseplate or not
     * @param {*} entity if null take the current entity
     */
    canHaveBaseplate(entity = null) {
        const realEntity = this.optionController.returnRealEntity(entity);
        if (!realEntity) {
            return false;
        }
        let isColliding = false;
        realEntity.mesh.computeWorldMatrix();
        realEntity.mesh.refreshBoundingInfo();
        if (realEntity.mesh.getBoundingInfo().boundingBox.minimumWorld.y < -0.001) {
            isColliding = false;
        } else if (realEntity.mesh.getBoundingInfo().boundingBox.minimumWorld.y < 0.01) {
            isColliding = true;
        }
        return isColliding;
    }

    /**
     * Callback function used in different events in case we want
     * to update the baseplate information of a frame
     * @param {*} entity if null take the current entity
     */
    updateCanHaveBaseplate(entity = null) {
        const realEntity = this.optionController.returnRealEntity(entity);
        if (realEntity) {
            if (realEntity.forceBaseplate) {
                realEntity.canHaveBaseplatesOption = true;
            } else {
                this.availableBaseplates(realEntity);
            }
            if (!realEntity.canHaveBaseplatesOption) {
                this.removeBaseplate(realEntity);
            }
        }
    }

    /**
     * Whats the current entity baseplate type
     * @param {*} entity if null take the current entity
     */
    baseplateType(entity = null) {
        const realEntity = this.optionController.returnRealEntity(entity);
        if (!realEntity) {
            return null;
        }
        return realEntity.getBaseplateOption();
    }

    /**
     * Returns the baseplate corresponding to the ref arguments
     * @param {*} ref
     */
    loadBaseplateFromRef(ref) {
        const baseplate = this.meshManager.getMeshFromLoadedGeometry(
            ref,
            "eco",
            null,
            {
                category: "PARTS",
                subCategory: "BASE PLATES",
            }
        );

        self.app.modules.meshManager.meshUtility.AddMetadataProperties(
            baseplate,
            {
                isOption: true,
            }
        );

        return baseplate;
    }

    /**
     * Toggle the visibilty of the baseplates if there are any
     * @param {*} isVisible
     * @param {*} entity
     */
    toggleBaseplatesVisibility(isVisible, entity = null) {
        const realEntity = this.optionController.returnRealEntity(entity);

        if (realEntity && this.haveBaseplate(realEntity)) {
            realEntity.optionsVisibility.baseplates = isVisible;
            self.app.modules.geometryUtility.toggleMeshesVisibility(
                realEntity.optionsMeshes.baseplates,
                isVisible && realEntity.visible
            );
        }
    }

    /**
     * Returns the baseplate reference from the entity
     * @param {Object} entity
     */
    getBaseplateRef(entity = null) {
        const realEntity = this.optionController.returnRealEntity(entity);

        if (!realEntity) {
            return null;
        }

        return this.baseplatesReferences[entity.baseplateOption];
    }

    /**
     * Returns the baseplate type from the reference
     * @param {string} ref
     */
    getBaseplateTypeFromRef(ref) {
        let referenceBaseplateType = OptionableMixin.BASEPLATE_OPTION.NONE;

        // Loop through the references array
        Object.keys(this.baseplatesReferences).forEach(baseplateRefAlias => {
            if (this.baseplatesReferences[baseplateRefAlias].includes(ref)
                || this.oldBaseplatesReferences[baseplateRefAlias].includes(ref)) {
                referenceBaseplateType = parseInt(baseplateRefAlias, 10);
            }
        });

        return referenceBaseplateType;
    }

    /**
     * Returns true if the reference is a baseplate reference
     * @param {string} ref
     */
    isBaseplateRef(ref) {
        return Boolean(this.getBaseplateTypeFromRef(ref));
    }

}
