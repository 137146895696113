import cu from "vendors/class-utils";

const Structure = require("@obsidianjs/data-store/src/entity");

const EntityStructure = require("./entity-structure").default;

const self = require("../..").default;

const MotionskinStructure = EntityStructure.$extend({

    __name__: "motionskin",


    __init__(params) {
        this.$super(params);
        this.initOptions();
        this.$data.isMotionskin = true;
        this.$data.materialId = "motionskin-material";
    },

    __include__: [
        self.app.modules.optionManager.OptionableMixin.prototype,
    ],

    getIsMotionskin: cu.getter,


});
Structure.$register(MotionskinStructure);
export default MotionskinStructure;
