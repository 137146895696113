import { Color3 } from "@babylonjs/core";

/**
 * Helper that check for a given mesh if all the given rules are respected
 * Highlight the object in Green if the rules are respected
 * Highlight the object in Red if not
 */
export default class HighlightBehaviorHelper {

    constructor(highlightController, mesh) {
        this.highlightBehaviorRules = [];
        this.highlightController = highlightController;
        this.rulesAreRespected = true;
        if (Array.isArray(mesh)) {
            this.mesh = null;
            this.meshes = mesh;
            return;
        }
        this.mesh = mesh;
        this.meshes = null;
    }

    /**
     * Add a rule function that must returns a Boolean
     * @param {Functin} ruleFunction - The predicate that check if the rules are respected
     * @param  {...any} args - The arguments of the predicate
     */
    addHighlightBehaviorRules(ruleFunction, ...args) {
        const ruleObject = {
            callbackFunction: ruleFunction,
            arguments: args,
        };
        this.highlightBehaviorRules.push(ruleObject);
    }

    /**
     * Check if all the rules are respected
     */
    checkAllHighlightBehaviorRules() {
        return this.highlightBehaviorRules.every(
            ruleObject => ruleObject.callbackFunction.apply(null, ruleObject.arguments)
        );
    }

    /**
     * Check the rules and toggle the highlight depending on the result of the rules
     */
    startHighlightBehavior() {
        const currentRulesRespected = this.checkAllHighlightBehaviorRules();
        if (currentRulesRespected && !this.rulesAreRespected) {
            this.setAttachedMeshesHighlightColor();
        } else if (this.rulesAreRespected && !currentRulesRespected) {
            this.setAttachedMeshesHighlightColor(Color3.Red());
        }
        this.rulesAreRespected = currentRulesRespected;
    }

    /**
     *  Toggle the highlight of attached mesh/meshes to the given color
     * @param {Color3} color
     */
    setAttachedMeshesHighlightColor(color = Color3.Green()) {
        if (this.mesh && !this.meshes) {
            this.highlightController.toggleHighlightMesh(this.mesh, false);
            this.highlightController.toggleHighlightMesh(this.mesh, true, color);
        } else if (!this.mesh && this.meshes && this.meshes.length) {
            this.highlightController.toggleHighlightMeshes(this.meshes, false);
            this.highlightController.toggleHighlightMeshes(this.meshes, true, color);
        }
    }

    /**
     * Remove the mesh from the behavior and remove all the rules
     */
    cleanBehavior() {
        this.mesh = null;
        this.meshes = null;
        this.highlightBehaviorRules.splice(0, this.highlightBehaviorRules.length);
        this.rulesAreRespected = true;
    }

}
