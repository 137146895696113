/**
 * Entity Data shared among several modules
 */
const EntitiesData = {
    /**
     * Different entity types
     */
    ENTITY_TYPE: Object.freeze({
        STRAIGHT: "STRAIGHT",
        CURVED: "CURVED",
        PERFECT: "PERFECT",
        GLASS: "GLASS",
        CORNER: "CORNER",
        COVER: "COVER",
        BACKLED: "BACKLED",
        SIDELED: "SIDELED",
        MOTIONSKIN: "MOTIONSKIN",
        TRACKSTART: "TRACKSTART",
        STRUCTURAL: "STRUCTURAL",
    }),

    /** Returns the frame type from the catalog's subcategory
    * @param {String} subCategory
    * @param {String} ref (optional)
    * @returns {ENTITY_TYPE | undefined}
    */
    entityTypeFromSubCategory(subCategory, ref = "") {
        if (ref.includes("R430")) {
            return undefined;
        }
        if (subCategory.includes("CORNER PROFILES")) {
            return EntitiesData.ENTITY_TYPE.CORNER;
        }
        if (subCategory.includes("PERFECT CORNERS")) {
            return EntitiesData.ENTITY_TYPE.PERFECT;
        }
        if (subCategory.includes("TRACK LIGHT START")) {
            return EntitiesData.ENTITY_TYPE.TRACKSTART;
        }
        if (ref !== "") {
            if (subCategory.includes("SIDELED")) {
                return EntitiesData.ENTITY_TYPE.SIDELED;
            }
            if (subCategory.includes("COVER PROFILES")) {
                if (!ref.startsWith("608") || ref.endsWith("D00")) {
                    return EntitiesData.ENTITY_TYPE.COVER;
                }
                return undefined;

            }
        }
        return EntitiesData.ENTITY_TYPE[Object.keys(EntitiesData.ENTITY_TYPE).find((key) => subCategory.includes(key))];
    },
};
export default EntitiesData;
