const Structure = require("@obsidianjs/data-store/src/entity");

const EntityStructure = require("./entity-structure").default;

const FlooringConnectorStructure = EntityStructure.$extend({

    __name__: "flooring-connector",


    __init__(params) {
        this.$super(params);
    },

});
Structure.$register(FlooringConnectorStructure);
export default FlooringConnectorStructure;
