<template>
    <b-nav-item-dropdown
        right
        no-caret
        class="actions-menu"
    >
        <!-- Change button content to an image -->
        <template slot="button-content">
            <img src="/assets/icons/option.svg">
        </template>
        <template v-if="canSave">
            <b-dropdown-item @click="newVersion()">
                <img src="/assets/icons/new-version.svg">
                {{ _("New version") }}
            </b-dropdown-item>
            <b-dropdown-item @click="saveTemplate()">
                <img src="/assets/icons/new-template.svg">
                {{ _("New template") }}
            </b-dropdown-item>
            <b-dropdown-item @click="newProject()">
                <img src="/assets/icons/new-project.svg">
                {{ _("New project") }}
            </b-dropdown-item>
            <b-dropdown-divider />
        </template>
        <b-dropdown-item-button
            v-if="canImportExport"
            @click="submitInputFile()"
        >
            <img src="/assets/icons/import-xml.svg">
            {{ _("Import XML") }}
            <input
                ref="fileInput"
                type="file"
                accept=".xml"
                @change="importXml"
            >
        </b-dropdown-item-button>
        <b-dropdown-item
            v-if="canImportExport"
            @click="exportXml()"
        >
            <img src="/assets/icons/export-xml.svg">
            {{ _("Export XML") }}
        </b-dropdown-item>
        <b-dropdown-item
            v-if="canExportForge"
            @click="callForgeExport()"
        >
            <img src="/assets/icons/export-xml.svg">
            {{ _("Export to 3D file") }}
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item @click="openSettings()">
            <img src="/assets/icons/settings.svg">
            {{ _("Settings") }}
        </b-dropdown-item>
    </b-nav-item-dropdown>
</template>

<script>
import RightsHelper from "helpers/rights-helper";
import self from "../..";

export default {
    name: "Actions",
    props: {
        config: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        canImportExport() {
            return RightsHelper.isRoleAdminBematrix() && RightsHelper.isMode3dAuthenticated();
        },
        canSave() {
            return RightsHelper.isRoleDesigner() && RightsHelper.isMode3dAuthenticated();
        },
        canExportForge() {
            return (
                (RightsHelper.hasForgeRole() || RightsHelper.isRoleAdminBematrix())
                && RightsHelper.isMode3dAuthenticated()
            );
        },
    },
    methods: {
        newVersion() {
            this.openVersionPopin("new-version");
        },

        /**
         * Shows a popin where the user give its project a version name
         */
        openVersionPopin(onValidateEvent = "save", isRequired = false) {
            self.app.events.emit("open-popin", {
                title: this._("New version"),
                description: `${this._("Please insert the version name")}
                    : <br />
                        <input type="text" id="version-name" required pattern= "^([a-zA-Z0-9.]|\\s)+$">
                    <br />
                        <span class="warning-info">
                            ${this._(
        "Warning : Please give a valid version name"
                                    + ", if you write any special characters like (!,_-^%)"
                                    + " or give an empty version name,the submission will be blocked."
    )}
                        </span>`,
                isRequired,
                onValidate: () => {
                    self.app.events.emit("lockUnload");
                    this.config.versionName = document.getElementById("version-name").value;
                    if (onValidateEvent === "save") {
                        this.$emit("onSaveProject");
                    }
                    self.app.modules.iframeApi.sendEvent(onValidateEvent);
                },
            });
        },

        /**
         * Saves the current project as a template
         */
        saveTemplate() {
            self.app.events.emit("open-popin", {
                title: this._("Save a template"),
                description: `${this._(
                    "Please insert a name for your template:"
                )}<br /><input type="text" id="template-name" required pattern= "^([a-zA-Z0-9.]|\\s)+$">
                <br/>
                        <span class="warning-info">
                            ${this._(
        "Warning : Please give a valid template name"
                                    + ", if you write any special characters like (!,_-^%)"
                                    + " or give an empty version name,the submission will be blocked."
    )}
                        </span>`,
                onValidate: () => {
                    const templateName = document.getElementById("template-name").value;
                    self.app.modules.iframeApi.sendEvent("save-template", templateName);
                },
            });
        },

        /**
         * Create a new project
         */
        newProject() {
            if (window.confirm(this._("Are you sure ? You will lose all unsaved progress."))) {
                self.app.events.emit("lockUnload");
                self.app.modules.iframeApi.sendEvent("new-project");
            }
        },

        /**
         * Open the dialog for importing an xml
         */
        submitInputFile() {
            this.$refs.fileInput.click();
        },

        /**
         * Store the imported xml and send an event to the iframeApi
         */
        importXml(event) {
            const file = event.target.files[0];
            self.app.modules.projectManager.controller.importedXML = new Blob([file], {
                type: file.type,
            });
            self.app.events.emit("open-popin", {
                title: this._("Import an XML file"),
                description: this._("Warning, if you import you import this file you will lose all unsaved progress."),
                isRequired: false,
                onValidate: () => {
                    self.app.modules.iframeApi.sendEvent("import-becad-xml");
                },
            });
        },

        exportXml() {
            const blob = self.app.modules.projectManager.controller.exportBecadXml(true, true);
            const projectName = this.config.projectName;
            self.app.modules.projectManager.controller.saveData(blob, `${projectName}.xml`);
        },
        openSettings() {
            self.app.events.emit("open-popin", {
                title: this._("Change settings"),
                description: `${this._("Choose unit system")} :<br /><select id="unit-name"><option value="eu" ${
                    this.config.unitMode === "eu" ? "selected=\"selected\"" : ""
                }>${this._("Metric")}</option><option value="us" ${
                    this.config.unitMode === "us" ? "selected=\"selected\"" : ""
                }>${this._("Imperial")}</option></select>`,
                isRequired: false,
                onValidate: () => {
                    if (this.config.unitMode !== document.getElementById("unit-name").value) {
                        this.config.unitMode = document.getElementById("unit-name").value;
                        self.app.events.emit("modify-unit");
                    }
                },
            });
        },
        callForgeExport() {
            self.app.events.emit("open-popin", {
                title: this._("Export 3D file"),
                description: `${this._("Choose file format to export")} :<br />
                <span>
                    <input type='checkbox' id="stp-option" name="stp-option" class="file-checkbox"/><label for="stp-option">.stp</label>
                </span>
                <span>
                    <input type='checkbox' id="dwg-option" name="dwg-option" class="file-checkbox" checked/><label for="dwg-option">.dwg</label>
                </span>
                <br />
                Export type: <br />
                <div>
                    <input type='radio' id="export-type-simplified" name="export-type" class="file-checkbox mb-0"/>
                    <label for="export-type-simplified">Structure (Simplified, small file)</label>
                </div>
                <div>
                    <input type='radio' id="export-type-render" name="export-type" class="file-checkbox" checked/>
                    <label for="export-type-render">Structure + panels(for render, small file)</label>
                </div>
                <span>
                    <input type='checkbox' id="include-calculates-parts" name="include-calculates-parts" class="file-checkbox"/>
                    <label for="include-calculates-parts">Include calculated parts</label>
                </span>
                `,
                isRequired: false,
                onValidate: () => {
                    const exportStp = document.getElementById("stp-option").checked;
                    const exportDwg = document.getElementById("dwg-option").checked;
                    let exportType = document.getElementById("export-type-simplified").checked
                        ? "SIMPLE"
                        : "SIMPLE_INFILLS";
                    if (document.getElementById("include-calculates-parts").checked) {
                        exportType += "_INCLUDE_AUTOMATION";
                    }
                    if (!exportStp && !exportDwg) {
                        return;
                    }
                    self.app.modules.iframeApi.sendEvent("calling-forge-export", {
                        exportStp,
                        exportDwg,
                        exportType,
                    });
                },
            });
        },
        _(...args) {
            return self.app.modules.stonejs.gettext(...args);
        },
    },
};
</script>

<style lang="scss">
@import "../../../../style/bematrix";

.actions-menu {
    @extend .top-menu-div;

    height: 100%;
    cursor: pointer;

    img {
        max-height: 15px;
        margin: auto;
    }

    // sry
    > a {
        padding: 0 1.5rem !important;
    }

    .dropdown-menu {
        border-radius: 0;
        margin: 0;
    }

    .dropdown-item {
        font-family: $font-regular;
        font-size: 0.8em;

        input[type="file"] {
            display: none;
            border-color: transparent;
        }

        img {
            height: 18px;
            width: 18px;
        }

        &:focus {
            outline: 0px solid transparent;
        }

        &:hover {
            background-color: $pale-grey-hover;
        }

        &:active {
            background-color: $pale-grey-click;
        }
    }

    &:hover {
        background-color: $pale-grey-hover;
    }

    &:active {
        background-color: $pale-grey-click;
    }
}
</style>
