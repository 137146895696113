import { Quaternion, Vector3 } from "@babylonjs/core";

const OptionUtility = {
    // Check if the frame is aligned with the ground plan
    canHaveOption(mesh) {

        const epsilon = 0.1;
        const forward = mesh.forward;
        forward.y = 0;
        forward.normalize();

        /**
         * @type Quaternion
         */
        const q = mesh.rotationQuaternion;
        const ra = new Vector3(q.x, q.y, q.z);
        const proj = forward.clone().scaleInPlace(Vector3.Dot(ra, forward));
        const twist = new Quaternion(proj.x, proj.y, proj.z, q.w);
        twist.normalize();
        const swing = q.multiply(twist.conjugate());
        if (twist.z < epsilon && swing.x < epsilon) {
            return true;
        }
        return false;
    },

    /**
     * Compute the angle for a given mesh (light or shelve)
     */
    computeLightOrShelveAngle(mesh) {
        const epsilon = 0.1;
        if (Math.abs(mesh.forward.x) < epsilon) {
            // zOriented => we align it with the zAngle
            if (mesh.forward.z < 0) {
                return 0;
            }
            return Math.PI;
        }
        if (Vector3.Dot(mesh.absolutePosition, mesh.forward) > 0) {
            return 0;
        }
        return Math.PI;

    },


    /**
     * Compute the oriented up and right vector and the angle needed
     * @param {*} mesh
     */
    angleToUpward(mesh) {
        const absY = Math.abs(mesh.up.y);
        let isUp = false;
        let angle;
        let upVector;
        let rightVector;
        if (absY > Math.abs(mesh.up.x) && absY > Math.abs(mesh.up.z)) {
            // Up/Top oriented
            isUp = true;
            if (mesh.up.y > 0) {
                angle = 0;
                upVector = mesh.up;
                rightVector = mesh.right;
            } else {
                angle = Math.PI;
                upVector = mesh.up.negate();
                rightVector = mesh.right.negate();
            }

        } else { // Left/Right oriented
            angle = mesh.right.y > 0 ? Math.PI / 2 : -Math.PI / 2;
            if (mesh.right.y > 0) {
                angle = Math.PI / 2;
                upVector = mesh.right;
                rightVector = mesh.up;
            } else {
                angle = -Math.PI / 2;
                upVector = mesh.right.negate();
                rightVector = mesh.up.negate();
            }

        }
        return {
            angle, isUp, upVector, rightVector,
        };
    },


    /**
     * Is the mesh aligned nearly aligned with the ground
     * @param {Mesh} mesh
     */
    isAlignedWithGround(mesh) {
        const dot = Math.abs(Vector3.Dot(mesh.up, Vector3.Up()));
        if (dot > 0.99 || dot < 0.05) {
            return true;
        }
        return false;
    },


};
module.exports = OptionUtility;
