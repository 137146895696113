/**
 * Patch to version 1.31.0
 *
 * This version introduce two new data structures sideled and backled lightboxes replacing
 * the lightbox data structure.
 * The lightbox got broke in two due to the different finishes supported by each sub-category,
 * so we had to differentiate them.
 * There is some tricky things as there is version of the project with both ral and eco
 * finishes on lightboxes and some versions with ral
 */
const RalColors = require("../../../option-manager/utility/ral-colors");

export default {
    /**
     * Main patch function
     * Loop through the serialized data to find old lightbox data structure
     * Once lightbox we add or remove properties depending on the data structure we want
     *
     * Data structure example
     * {
     *      "data-path1": [
     *          serialized-item1,
     *          serialized-item2
     *      ],
     *      "data-path2": [
     *          serialized-item3,
     *          serialized-item4
     *      ],
     *  }
     * @param {ObsidianFile} project
     * @returns {ObsidianFile} the project with data strutures from version 1.31.0
     */
    patch(project) {
        const projectSerializedData = project.project;

        Object.values(projectSerializedData).forEach(
            (path) => {
                path.forEach(
                    (item) => {
                        if (item.__name__ === "frame") {
                            if (item.subCategory.toUpperCase().includes("FLOORING CONNECTORS")) {
                                this.toFlooringConnector(item);
                            }
                        } else if (item.__name__ === "lightbox") {
                            if (item.subCategory.toUpperCase().includes("SIDELED LIGHTBOX")) {
                                this.toSideledLightbox(item);

                            } else if (item.subCategory.toUpperCase().includes("BACKLED LIGHTBOX")) {
                                this.toBackledLightbox(item);
                            }
                        }
                    }
                );
            }
        );

        return project;
    },

    /**
     * Convert the item from lightbox to sideled-lightbox
     */
    toSideledLightbox(frame) {
        frame.__name__ = "sideled-lightbox";

        if (!frame.color) {
            frame.color = RalColors.getDefaultColor();
        }
    },

    /**
     * Convert the item from lightbox to backled-lightbox
     * Remove colorable mixin properties
     */
    toBackledLightbox(frame) {
        const propertiesToRemove = [
            "initColor",
            "setRalMaterial",
            "setEcoMaterial",
            "changeColor",
            "recreateMesh",
            "getColor",
            "setColor",
        ];

        frame.__name__ = "backled-lightbox";

        // In case a ral material was on this item
        frame.materialId = "frames-ano";

        // Backled only support ano
        propertiesToRemove.forEach((property) => {
            if (frame[property]) {
                delete frame[property];
            }
        });
    },

    /**
     * Convert the item from frame to flooring connectors
     * Remove colorable mixin properties
     */
    toFlooringConnector(frame) {
        const propertiesToRemove = [
            "initColor",
            "setRalMaterial",
            "setEcoMaterial",
            "changeColor",
            "recreateMesh",
            "getColor",
            "setColor",
        ];

        frame.__name__ = "flooring-connector";

        // In case a ral material was on this item
        frame.materialId = "frames-ano";

        // Backled only support ano
        propertiesToRemove.forEach((property) => {
            if (frame[property]) {
                delete frame[property];
            }
        });
    },
};
