export default {
    name: "selection-manager",
    requires: [
        "obsidian-engine", "gizmo-manager", "collision-manager", "geometry-utility",
        "history", "data-store", "option-manager", "highlight-manager", "grid-manager",
        "mesh-manager", "entity-manager", "gui-manager", "option-manager", "camera-manager",
        "measurement-manager"],
    load() {
        const Controller = require("./src/controllers/selection-controller").default;
        return new Controller();
    },
    unload() {},
};
