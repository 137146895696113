const MathHelper = {

    /**
     * Convert the @angle from degrees to radians
     * @param {Number} angle angle in degrees
     * @returns {Number} angle in radians
     */
    ToRadians(angle) {
        return angle * Math.PI / 180;
    },

    /**
     * Convert the @angle from radians to degrees
     * @param {Number} angle angle in radians
     * @returns {Number} angle in degrees
     */
    ToDegrees(angle) {
        return angle * 180 / Math.PI;
    },

    clamp(value, min, max) {
        return Math.min(Math.max(value, min), max);
    },
};

export default MathHelper;
