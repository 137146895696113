import JsPDF from "jspdf";

/**
 * Using a function that use internal page count is less error prone that creating one
 * You refer to pages using their number instead of their index
 * i.e This function return one for a newly created pdf
 * @returns {number} the number of the page
 */
JsPDF.API.getCurrentPageNumber = function () { // eslint-disable-line
    return this.internal.getCurrentPageInfo().pageNumber;
};
