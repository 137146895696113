
import { v4 as uuid } from "uuid";

const EventHandlerHelper = {
    RULES_COMPARISON_FUNCTION(a, b) {
        if (a.priorityWeight > b.priorityWeight) {
            return -1;
        } if (a.priorityWeight > b.priorityWeight) {
            return 1;
        } return 0;
    },

    CreateRuleFromCallback(callback, priorityWeight = 0) {
        const id = uuid();
        return {
            priorityWeight,
            callback,
            id,
        };
    },
};


/**
 * A class of a rule manager
 * Its aim is to check some rules (given by callbacks) that can block or not an input event
 * (keyboard/mouse) in case the rule(s) is(are) valid
 */
export class EventHandlerRulesManager {

    constructor() {
        this.rulesList = [];
    }

    /**
     * Add a rule that is checked with a callback
     * @param {Function} callback
     * @param {Number} priorityWeight a priority of the rule. 0 is the lowest (like a z-index)
     * @returns {String} the id of the rule, needed for removal
     */
    addRule(callback, priorityWeight = 0) {
        const rule = EventHandlerHelper.CreateRuleFromCallback(callback, priorityWeight);
        this.rulesList.push(rule);
        this.rulesList.sort(EventHandlerHelper.RULES_COMPARISON_FUNCTION);
        return rule.id;
    }

    getHighestPriorityRule() {
        return this.rulesList[0].priorityWeight;
    }

    removeRule(ruleId) {
        const index = this.rulesList.findIndex(
            rule => (rule.id === ruleId)
        );
        this.rulesList.splice(index, 1);
    }

    /**
     * Check if some mouse move rules are valid
     */
    checkRules(pickResult) {
        let foundCorrectRule = false;
        // We do not use "Some" as we want to check every rules
        // and not just find one that is correct
        this.rulesList.forEach(
            (rule) => { foundCorrectRule = rule.callback(pickResult); }
        );
        return foundCorrectRule;
    }

}

export default EventHandlerHelper;
