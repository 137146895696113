export const debounce = (fn, delay) => {
    let timer = null;
    return (...args) => {
        const context = this;
        clearTimeout(timer);
        timer = setTimeout(() => {
            fn.apply(context, args);
        }, delay);
    };
};

// transform collection of object to collection of unique objects by first key
export const filterCollectionByUniqueFirstKey = (collection) => {
    const uniqueItems = {};
    return collection.filter(item => {
        const key = Object.keys(item)[0];
        if (uniqueItems[key]) return false;
        uniqueItems[key] = true;
        return true;
    });
};
