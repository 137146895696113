const HelperFunctions = {

    /**
     * Add a property to the "vueData" object in @object
     * For conveniency purpose we add a getter and a setter on this property
     * to allow writting this.@propertyName
     * @param {Object} object
     * @param {string} propertyName
     * @param {*} defaultValue = null
     */
    AddVueProperty(object, propertyName, defaultValue = null) {
        if (!object.vueData) {
            object.vueData = {};
        }

        object.vueData[propertyName] = defaultValue;

        Object.defineProperty(object, propertyName, {
            get: () => object.vueData[propertyName],
            set: (val) => { object.vueData[propertyName] = val; },
        });
    },
};

export default HelperFunctions;
