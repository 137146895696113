import {
    Vector3, MeshBuilder, Tools, Camera,
} from "@babylonjs/core";

import fileHelper from "vendors/file-helper";
import { CameraViews } from "./camera-controller";
import { app } from "../.."; // eslint-disable-line
import screenshotHelper from "../helpers/screenshot-helper";

const DEBUG = false;

const {
    events,
    modules:
    {
        obsidianEngine: {
            controller: engineController,
        },
        gridManager,
        materialManager,
        iframeApi,
    },
} = app;

export const SCREENSHOT_PIPELINES = {
    DEFAULT: 0,
    VISUALLY_IMPROVED: 1,
    ORTHOGRAPHIC: 2,
};

export default class ScreenshotController {

    constructor(CameraController) {
        this.cameraController = CameraController;
        this.temporaryDatas = {
            initView: null,
            initialPosition: null,
            initialTarget: null,
        };

        this.initScreenshotApiMethods();
        this.initVueData();
        screenshotHelper.patchScreenshotRenderTargetTexture(Tools);

        events.on("@catalog-manager.catalog-initialized", () => {
            this.scene = engineController.scene;
            this.engine = engineController.engine;

            this.loadGroundMaterials();
        });

        if (DEBUG) {
            window.groundMat = (mat) => {
                this.setGroundMaterial(mat);
            };
        }
    }

    loadSkyboxTextures() {
        this.skyboxes = {};
        this.skyboxes.shanghai = materialManager.loadTexture("envmaps/shanghai_bund.dds");
    }

    loadGroundMaterials() {
        this.floors = {};
        ["white", "marble", "concrete", "fabric_007", "fabric_011", "fabric_015",
            "grass", "rug", "tweed", "wood_007", "wood_020", "wood_floor"].forEach(
            (materialName) => {
                this.floors[materialName] = materialManager.loadMaterial(materialName);
            }
        );
        if (DEBUG) {
            window.floorMaterials = this.floors;
        }
    }

    initVueData() {
        this.vueData = {
            floorName: "",
        };
    }

    resetVueData() {
        this.vueData.floorName = "";
    }

    initScreenshotApiMethods() {
        iframeApi.addApiMethod(("screenshotAllViews"), returnsBlob => this.screenshotAllViews(
            SCREENSHOT_PIPELINES.DEFAULT, returnsBlob
        ));
        iframeApi.addApiMethod(("screenshotCurrentView"), returnsBlob => this.screenshotSpecificView(
            SCREENSHOT_PIPELINES.VISUALLY_IMPROVED, null, returnsBlob
        ));
        iframeApi.addApiMethod(("screenshotTopView"), returnsBlob => this.screenshotSpecificView(
            SCREENSHOT_PIPELINES.DEFAULT, CameraViews.TOP_VIEW, returnsBlob
        ));
        iframeApi.addApiMethod(("screenshotSideView"), returnsBlob => this.screenshotSpecificView(
            SCREENSHOT_PIPELINES.DEFAULT, CameraViews.SIDE_VIEW, returnsBlob
        ));
        iframeApi.addApiMethod(("screenshotFrontView"), returnsBlob => this.screenshotSpecificView(
            SCREENSHOT_PIPELINES.DEFAULT, CameraViews.FRONT_VIEW, returnsBlob
        ));
        iframeApi.addApiMethod(("screenshotEditView"), returnsBlob => this.screenshotSpecificView(
            SCREENSHOT_PIPELINES.DEFAULT, CameraViews.EDIT_VIEW, returnsBlob
        ));
        iframeApi.addApiMethod(("screenshotDefaultView"), returnsBlob => this.screenshotSpecificView(
            CameraViews.DEFAULT_VIEW, returnsBlob
        ));
    }

    setSkyboxTexture() {
        this.skybox = this.scene.createDefaultSkybox(
            this.skyboxes[this.vueData.environmentName],
            true,
            1000
        );
    }

    setGroundMaterial(floorName = "") {
        const gridSize = gridManager.getGridSize();
        this.environmentGround = MeshBuilder.CreateGround("screen-ground", gridSize, this.scene);
        this.environmentGround.position.y = 0.001;
        const floorMaterial = this.floors[floorName || this.vueData.floorName];
        const scale = floorMaterial.metadata ? floorMaterial.metadata.scale : 1;
        ["bumpTexture", "ambientTexture", "albedoTexture", "microSurfaceTexture", "reflectivityTexture"].forEach(
            (textureName) => {
                if (floorMaterial[textureName]) {
                    floorMaterial[textureName].uScale = gridSize.width * scale;
                    floorMaterial[textureName].vScale = gridSize.height * scale;
                }
            }
        );
        return floorMaterial.forceCompilationAsync(this.environmentGround).then(
            () => {
                this.environmentGround.material = floorMaterial;
            }
        );
    }

    /**
     * Entry point for all the requested screenshots
     * Call the screenshot function correcponding to the wanted pipeline
     * @param {SCREENSHOT_PIPELINES} define which pipeline to use we doing the screenshot
     * @param {CameraView} view
     * @param {Function} callback
     * @param {(height:Number , width:Number, precision:Number | Number)} size
     * @param {string} mimeType
     * @returns {Promise(image) || null} returns a promise if there is no callback
     */
    screenshot(screenshotPipeline, view = null,
        size = {
            height: this.engine.getRenderHeight(),
            width: this.engine.getRenderWidth(),
            precision: 1,
        },
        mimeType = "image/png") {

        // If no view is setted we screen the current view
        if (view) {
            this.cameraController.setView(view);
        }

        const currentCamera = this.cameraController.currentCamera;
        switch (screenshotPipeline) {
        case SCREENSHOT_PIPELINES.VISUALLY_IMPROVED:
            return this.visuallyImprovedScreenshot(size, currentCamera, mimeType);
        case SCREENSHOT_PIPELINES.ORTHOGRAPHIC:
            return this.orthographicScreenshot(size, currentCamera, mimeType);
        default:
            return this.defaultScreenshot(size, currentCamera, mimeType);
        }
    }

    /**
     * Basic function for making a screenshot
     * @param {(height:Number , width:Number, precision:Number | Number)} size
     * @param {Camera} camera
     * @param {string} mimeType
     */
    defaultScreenshot(size, camera, mimeType) {
        return Tools.CreateScreenshotUsingRenderTargetAsync(
            this.engine,
            camera,
            size,
            mimeType,
            16,
            false,
            "",
            true,
        );
    }

    /**
     * Main function for making a visually improved screenshot
     * Add envmap, a texture on the floor, dof
     * @param {(height:Number , width:Number, precision:Number | Number)} size
     * @param {Camera} camera
     * @param {string} mimeType
     */
    async visuallyImprovedScreenshot(size, camera, mimeType) {
        // Prologue
        events.emit("start-screenshot-behavior");
        if (this.vueData.floorName !== "default") {
            events.emit("hide-ground");
            await this.setGroundMaterial();
        }
        this.scene.render();
        return Tools.CreateScreenshotUsingRenderTargetAsync(
            this.engine,
            camera,
            size,
            mimeType,
            16,
            false,
            "",
            true
        )
            .then(
                (dataUrl) => {
                    if (this.vueData.floorName !== "default") {
                        this.environmentGround.dispose();
                        events.emit("show-ground");
                    }
                    events.emit("end-screenshot-behavior");

                    // Reset vueData
                    this.resetVueData();
                    return dataUrl;
                },
            );
    }

    /**
     * Main function for making a screenshot that preserve scales
     * Use an orthographic camera for those screenshots
     * @param {(height:Number , width:Number, precision:Number | Number)} size
     * @param {Camera} camera
     * @param {string} mimeType
     */
    orthographicScreenshot(size, camera, mimeType) {
        if (!this.cameraController.orthographic) {
            this.cameraController.changeMode();
        }
        this.scene.render();
        return Tools.CreateScreenshotUsingRenderTargetAsync(
            this.engine,
            camera,
            size,
            mimeType,
            16,
            false,
            "",
            true,
        )
            .then(
                (dataUrl) => {
                    if (this.cameraController.orthographic) {
                        this.cameraController.changeMode();
                    }
                    return dataUrl;
                }
            );
    }

    /**
     * Take a screenshot then returns an object containing the screenshot
     * @param {*} view
     * @param {Boolean} returnsBlob false will returns a b64 object, true returns a blob
     */
    screenshotSpecificView(screenshotPipeline, view = null, returnsBlob = false) {
        if (typeof returnsBlob === "function") {
            returnsBlob = false; // eslint-disable-line no-param-reassign
        }
        const viewKey = view ? view.name : "CURRENT_VIEW";

        return this.screenshot(screenshotPipeline, view)
            .then((image) => {
                const tempScreenObject = {};
                const returnData = returnsBlob
                    ? fileHelper.dataURItoBlob(image) : image;
                tempScreenObject[viewKey] = returnData;
                return tempScreenObject;
            });
    }

    /**
     * Take a screenshot to make a thumbnail for a stand template
     */
    screenshotThumbnail() {
        this.replaceCamera();

        return this.screenshot(
            SCREENSHOT_PIPELINES.DEFAULT,
            CameraViews.DEFAULT_VIEW,
        )
            .then((dataUrl) => {
                this.loadTemporaryData();
                return fileHelper.dataURItoBlob(dataUrl);
            });
    }

    /**
     * Take a screenshot of a given meshList
     * @param { Array<AbstractMesh> } meshList - the list of mesh to screenshot
     * @param { Vector3 } target - where the camera should target
     * @param { Object } resolution - screenshot resolution  {width, height}
     * @param { Function } callback - called when the camera is positioned to take the shot.
     */
    screenshotMeshList(meshList, target, resolution = null, callback = () => {}) {
        const canvas = this.engine.getRenderingCanvas();
        const oldCanvasWidth = canvas.width;
        const oldCanvasHeight = canvas.height;

        // scale the canvas to the wanted resolution
        if (resolution && resolution.width && resolution.height) {
            canvas.width = resolution.width;
            canvas.height = resolution.height;
        }

        const cam = this.cameraController.currentCamera;
        const oldCam = { // save camera position
            position: cam.position.clone(),
            mode: cam.mode,
            minZ: cam.minZ,
            target: cam.target.clone(),
            lowerRadiusLimit: cam.lowerRadiusLimit,
            radius: cam.radius,
        };
        // Put the camera in front of the target
        const sceneCenterPoint = this.scene.pick(canvas.width / 2, canvas.height / 2).pickedPoint;
        const targetToCenter = target.subtract(sceneCenterPoint);
        cam.mode = Camera.PERSPECTIVE_CAMERA;
        cam.position.addInPlace(targetToCenter);
        cam.setTarget(target);
        cam.lowerRadiusLimit = 0.0001;
        cam.minZ = 0.001;

        // test increasing radiuses to get all the meshes fully on screen
        let increment = 0.1;
        const log = 1.05;
        const startRadius = 0.1;
        const maxRadius = 50;
        for (let radius = startRadius; radius < maxRadius; radius += increment) {
            cam.radius = radius;
            increment *= log;
            cam.computeWorldMatrix(true);
            this.scene.updateTransformMatrix();
            if (this.cameraController.isMeshListInFrustum(meshList)) {
                break;
            }
        }

        // callback, screenshot event, ground hiding
        callback(meshList);
        events.emit("start-screenshot-behavior");
        events.emit("hide-ground");

        // take the shot and restore previous parameters
        return this.screenshot(
            SCREENSHOT_PIPELINES.DEFAULT,
        )
            .then((dataUrl) => {
                events.emit("show-ground");
                events.emit("end-screenshot-behavior");
                Object.assign(cam, oldCam);
                cam.computeWorldMatrix(true);
                canvas.width = oldCanvasWidth;
                canvas.height = oldCanvasHeight;
                return fileHelper.dataURItoBlob(dataUrl);
            });
    }

    /**
     * Called when we want to screenshot all the default views
     * @param {Boolean} returnsBlob false will returns b64 objects, true returns blobs
     */
    screenshotAllViews(screenshotPipeline, returnsBlob = false) {
        if (typeof returnsBlob === "function") {
            returnsBlob = false; // eslint-disable-line no-param-reassign
        }
        let screenshotObject = {};
        this.replaceCamera();
        Object.keys(CameraViews).forEach(
            (viewKey) => {
                screenshotObject = Object.assign(
                    screenshotObject,
                    this.screenshotSpecificView(
                        screenshotPipeline,
                        CameraViews[viewKey],
                        returnsBlob
                    )
                );
            }
        );
        this.loadTemporaryData();
        return screenshotObject;
    }

    /**
     * Used to replace the camera to the original view for screenshot and stores the current view
     * in temporaryDatas object
     */
    replaceCamera() {
        this.temporaryDatas.initView = {
            alpha: this.cameraController.currentCamera.alpha,
            beta: this.cameraController.currentCamera.beta,
            radius: this.cameraController.currentCamera.radius,
        };
        this.temporaryDatas.initialPosition = this.cameraController.currentCamera.position.clone();
        if (this.cameraController.currentCamera.target) {
            this.temporaryDatas.initialTarget = this.cameraController.currentCamera.target.clone();
        }
        this.cameraController.currentCamera.position = this.cameraController.initialPosition;
        this.cameraController.currentCamera.setTarget(Vector3.Zero());
        this.cameraController.currentCamera.computeWorldMatrix(true);
    }

    /**
     * Replace the camera to the temporaryDatas view and position
     */
    loadTemporaryData() {
        this.cameraController.setView(this.temporaryDatas.initView);
        this.cameraController.currentCamera.position = this.temporaryDatas.initialPosition;
        if (this.cameraController.currentCamera.target) {
            this.cameraController.currentCamera.setTarget(this.temporaryDatas.initialTarget);
        }
        this.cameraController.currentCamera.computeWorldMatrix(true);
        this.temporaryDatas = {
            initView: null,
            initialPosition: null,
            initialTarget: null,
        };
    }

}
