export default {
    name: "snapping",
    requires: ["data-store", "option-manager", "mesh-manager"],
    load() {
        const SnapController = require("./src/controller/snap-controller").default;
        return {
            Controller: new SnapController(),
            SnappableMixin: require("./src/model/snappable-mixin"),
        };
    },
    unload() { },
};
