<template>
    <div id="left-menu-container">
        <div id="left-menu-container-top">
            <ul
                id="left-menu-tab"
            >
                <li
                    :class="{ active: currentTab === 'catalog' }"
                    @click="changeTab('catalog')"
                >
                    <span class="tab-title">{{ _("Catalog") }}</span>
                    <div
                        v-if="currentTab === 'catalog'"
                        class="selection-square"
                    />
                </li>
                <li
                    :class="{ active: currentTab === 'templates' }"
                    @click="changeTab('templates')"
                >
                    <span class="tab-title">{{ _("Templates") }}</span>
                    <div
                        v-if="currentTab === 'templates'"
                        class="selection-square"
                    />
                </li>
            </ul>
            <div
                id="left-menu-tab-content"
            >
                <templates
                    v-if="currentTab === 'templates'"
                    :templates="templates"
                    :grouped-objects="groupedObjects"
                    :config="config"
                />
                <catalog
                    v-if="currentTab === 'catalog'"
                    :catalog="catalog"
                    :config="config"
                />
            </div>
        </div>

        <tools />
    </div>
</template>
<script>
import RightsHelper from "helpers/rights-helper";
import templates from "./catalog/templates.vue";
import catalog from "./catalog/catalog.vue";
import tools from "./tools.vue";
import self from "../../index";

export default {
    name: "LeftMenu",
    components: { templates, catalog, tools },
    props: {
        catalog: {
            type: Array,
            default: () => [],
        },
        config: {
            type: Object,
            default: () => ({}),
        },
        templates: {
            type: Array,
            default: () => [],
        },
        groupedObjects: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            currentTab: "catalog",
            isPublic: RightsHelper.isModePublic(),
        };
    },
    methods: {
        changeTab(tab) {
            this.currentTab = tab;
        },
        _(...args) {
            return self.app.modules.stonejs.gettext(...args);
        },
    },
};
</script>
