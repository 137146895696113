var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left-container"},[_c('div',{staticClass:"preview-container"},[_c('div',{staticClass:"exit-bulding-plan-container"},[_c('div',{staticClass:"exit-bulding-plan-button",on:{"click":_vm.exitPdfMode}},[_vm._m(0),_vm._v("\n                "+_vm._s(_vm._("Return to 3d project"))+"\n            ")])]),_vm._v(" "),_c('draggable',{staticClass:"preview-images-list",attrs:{"draggable":".preview-draggable"},on:{"end":_vm.onDragEnd},model:{value:(_vm.buildingPlanData.pdfPages),callback:function ($$v) {_vm.$set(_vm.buildingPlanData, "pdfPages", $$v)},expression:"buildingPlanData.pdfPages"}},[_vm._l((_vm.buildingPlanData.pdfPages),function(page,pageIndex){return [(page.images.SCENE && page.images.HTML)?_c('div',{key:pageIndex,staticClass:"preview-layer-container preview-draggable",class:{
                        'editing-page': _vm.isEditingPage(pageIndex + 1)
                    },on:{"click":function($event){return _vm.selectPage(pageIndex+ 1)}}},[_c('img',{staticClass:"preview-layer",class:{
                            'page-selected': _vm.isPageSelected(pageIndex + 1),
                        },attrs:{"src":page.images.HTML.base64}}),_vm._v(" "),_c('img',{staticClass:"preview-layer",class:{
                            'page-selected': _vm.isPageSelected(pageIndex + 1),
                        },attrs:{"src":page.images.SCENE.base64}})]):(page.type === _vm.PAGE_TYPES.SCENE && page.images.SCENE)?_c('img',{key:`img-type-scene-${pageIndex}`,staticClass:"preview-image preview-draggable",class:{
                        'page-selected': _vm.isPageSelected(pageIndex + 1),
                        'editing-page': _vm.isEditingPage(pageIndex + 1)
                    },attrs:{"src":page.images.SCENE.base64},on:{"click":function($event){return _vm.selectPage(pageIndex + 1)}}}):(page.type === _vm.PAGE_TYPES.HTML && page.images.HTML)?_c('img',{key:`img-type-html-${pageIndex}`,staticClass:"preview-image preview-draggable",class:{
                        'page-selected': _vm.isPageSelected(pageIndex + 1),
                        'editing-page': _vm.isEditingPage(pageIndex + 1)
                    },attrs:{"src":page.images.HTML.base64},on:{"click":function($event){return _vm.selectPage(pageIndex + 1)}}}):_c('div',{key:`img-type-other-${pageIndex}`,staticClass:"preview-image preview-draggable",class:{
                        'page-selected': _vm.isPageSelected(pageIndex + 1),
                        'editing-page': _vm.isEditingPage(pageIndex + 1)
                    },on:{"click":function($event){return _vm.selectPage(pageIndex + 1)}}},[_c('div',{staticClass:"preview-image-placeholder"},[(page.type === _vm.PAGE_TYPES.SCENE)?_c('img',{attrs:{"src":"assets/icons/no-capture-pdf.svg"}}):_c('img',{attrs:{"src":"assets/icons/no-capture-html.svg"}}),_vm._v(" "),_c('br'),_vm._v("\n                        "+_vm._s(_vm._("no capture for the moment"))+"\n                    ")])])]}),_vm._v(" "),_c('div',{staticClass:"button-separator"},[(!_vm.measurementController.measureModeActivated)?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"add-page-button",attrs:{"title":_vm._('Add new page')},on:{"click":function($event){_vm.isAddPageOpen = !_vm.isAddPageOpen},"mouseover":_vm.useHoverAddIcon,"mouseleave":_vm.useNormalAddIcon}},[_c('img',{ref:"add-page-icon",staticClass:"icon-button",attrs:{"src":"assets/icons/plus.svg"}})]):_vm._e(),_vm._v(" "),(_vm.isAddPageOpen)?_c('div',{staticClass:"add-pdf-page"},[_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":_vm._('Project page'),"src":"assets/icons/3d-page.svg"},on:{"mousedown":_vm.useClickScenePageIcon,"mouseup":_vm.useHoverScenePageIcon,"mouseover":_vm.useHoverScenePageIcon,"mouseleave":_vm.useNormalScenePageIcon,"click":_vm.addScenePage}}),_vm._v(" "),_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":_vm._('Empty page'),"src":"assets/icons/2d-page.svg"},on:{"mousedown":_vm.useClickHtmlPageIcon,"mouseup":_vm.useHoverHtmlPageIcon,"mouseover":_vm.useHoverHtmlPageIcon,"mouseleave":_vm.useNormalHtmlPageIcon,"click":_vm.addHtmlPage}})]):_vm._e()])],2),_vm._v(" "),_c('div',{staticClass:"preview-interactions-container"},[(_vm.buildingPlanData.selectedPageNumber
                    && !_vm.measurementController.measureModeActivated)?_c('div',{staticClass:"preview-interaction",on:{"click":_vm.removePage}},[_vm._m(1),_vm._v(" "),_c('span',{staticClass:"preview-interactions-text"},[_vm._v("\n                    "+_vm._s(_vm._("Remove page"))+"\n                ")])]):_vm._e()])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"edition-button-icon-container"},[_c('img',{staticClass:"icon",attrs:{"src":"assets/icons/3d-page.svg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":"assets/icons/close.svg"}})])
}]

export { render, staticRenderFns }