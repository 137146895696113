export default {
    name: "camera-manager",
    requires: ["obsidian-engine", "iframe-api", "material-manager", "grid-manager"],
    load() {
        const Controller = require("./src/controller/camera-controller").default;
        const CameraViews = require("./src/controller/camera-controller").CameraViews;
        const MainController = new Controller();
        const Screenshot = require("./src/controller/screenshot-controller").default;
        const ScreenshotHelper = require("./src/helpers/screenshot-helper").default;
        const SCREENSHOT_PIPELINES = require("./src/controller/screenshot-controller").SCREENSHOT_PIPELINES;
        return {
            controller: MainController,
            CameraViews,
            ScreenshotController: new Screenshot(MainController),
            ScreenshotHelper,
            SCREENSHOT_PIPELINES,
        };

    },
    unload() {},
};
