export default {
    name: "entity-manager",
    requires: [
        "data-store",
        "snapping",
        "option-manager",
        "catalog-manager",
        "geometry-utility",
        "mesh-manager",
        "measurement-manager",
    ],
    load() {
        const Controller = require("./src/controllers/entity-controller").default;
        const EntityStructure = require("./src/model/entity-structure").default;
        const FrameStructure = require("./src/model/frame-structure").default;
        const CoverStructure = require("./src/model/cover-structure").default;
        const CornerStructure = require("./src/model/corner-structure").default;
        const StructuralStructure = require("./src/model/structural-structure").default;
        const ConnectorStructure = require("./src/model/connector-structure").default;
        const LedskinStructure = require("./src/model/ledskin-structure").default;
        const SideledLightboxStructure = require("./src/model/sideled-lightbox-structure").default;
        const BackledLightboxStructure = require("./src/model/backled-lightbox-structure").default;
        const FlooringConnectorStructure = require("./src/model/flooring-connector-structure")
            .default;
        const MotionskinStructure = require("./src/model/motionskin-structure").default;
        const DoorStructure = require("./src/model/door-structure").default;
        const TracklightStructure = require("./src/model/tracklight-structure").default;
        const PopinStructure = require("./src/model/popin-structure").default;
        const BeTrussB30Structure = require("./src/model/betruss-b30-structure").default;
        const BeTrussSquareStructure = require("./src/model/betruss-square-structure").default;
        const Helper = require("./src/helpers/entity-helper").default;
        return {
            Controller,
            entityStructure: EntityStructure,
            frameStructure: FrameStructure,
            connectorStructure: ConnectorStructure,
            coverStructure: CoverStructure,
            cornerStructure: CornerStructure,
            structuralStructure: StructuralStructure,
            ledskinStructure: LedskinStructure,
            sideledLightboxStructure: SideledLightboxStructure,
            backledLightboxStructure: BackledLightboxStructure,
            motionskinStructure: MotionskinStructure,
            flooringConnectorStructure: FlooringConnectorStructure,
            doorStructure: DoorStructure,
            tracklightStructure: TracklightStructure,
            popinStructure: PopinStructure,
            beTrussB30Structure: BeTrussB30Structure,
            beTrussSquareStructure: BeTrussSquareStructure,
            Helper,
            PRODUCT_ENTITIES_NAME: Helper.PRODUCT_ENTITIES_NAME,
        };
    },
    unload() {},
};
