import self from "../..";

const Structure = require("@obsidianjs/data-store/src/entity");

const EntityStructure = require("./entity-structure").default;

const SideledLightboxStructure = EntityStructure.$extend({

    __name__: "sideled-lightbox",


    __init__(params) {
        this.$super(params);
        this.initOptions();
        this.initMeasurement();
    },

    __include__: [
        self.app.modules.optionManager.OptionableMixin.prototype,
        self.app.modules.measurementManager.MeasurableMixin.prototype,
    ],

});
Structure.$register(SideledLightboxStructure);
export default SideledLightboxStructure;
