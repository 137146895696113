import { Engine, Scene } from "@babylonjs/core";

// Uncomment to activate debugLayer
// import "@babylonjs/core/Debug/debugLayer";
// import "@babylonjs/inspector";

import EventHandler from "../event-handler";

import self from "../..";

const TWEEN = require("@tweenjs/tween.js");

const {
    app: {
        events,
    },
} = self;

/**
 * Contains main function used to interact with object in the scene
 */
export default class EngineController {

    constructor() {
        this.engine = null;
        this.scene = null;
        this.canvas = null;
        this.ready = false;
        events.on("@bematrix-ui.canvas-ready", () => { this.initEngine(); });
    }

    /** Called once the canvas is initialized by the UI module */
    initEngine() {
        this.initBabylonScene();

        // events
        this.eventHandler = new EventHandler(this.scene, this.canvas);

        // listeners
        this.initListening();

        // 3D engine ready !
        this.ready = true;
        events.emit("engine-ready", this.scene);
    }

    /**
     * Init. babylon engine and scene
     * Emits the engine-ready event afterward
     */
    initBabylonScene() {
        this.canvas = document.getElementById("main-canvas");
        this.canvas.oncontextmenu = () => false;

        this.engine = new Engine(this.canvas, true, { preserveDrawingBuffer: true, stencil: true });
        // Disable mesh manifest check
        // => TODO : once in production, remove this line and actually add manifests for meshes
        // Manifests allow storage of meshes in the local IndexedDB to reload it later
        this.engine.enableOfflineSupport = false;

        this.scene = new Scene(this.engine);

        // Uncomment to activate debugLayer
        // this.scene.debugLayer.show();

        // Render loop
        this.engine.runRenderLoop(() => {
            this.scene.render();

            events.emit("main-scene-rendered");

            events.emit("animate");

            TWEEN.update();

        });
    }

    /**
     * Init event listeners handled by this controller :
     * Selection, duplication, gizmos, drags, windows resize
     */
    initListening() {
        window.addEventListener("resize", () => {
            this.engine.resize();
        });
    }

}
