import config from "defaultConfig";

import VueHelper from "helpers/vue-helper";

import GizmoHelper from "../helpers/gizmo-helper";
import PositionGizmo from "./position-gizmo";
import RotationGizmo from "./rotation-gizmo";
import PlaneGizmo from "./plane-gizmo";
import ScaleGizmo from "./scale-gizmo";
import self from "../../index";

const {
    modules: {
        obsidianEngine: {
            controller: engineController,
        },
        highlightManager,
        entityManager: {
            Helper: EntityHelper,
        },
    },
} = self.app;

export const GIZMO_ENUM = {
    NONE: 0,
    POSITION: 1,
    ROTATION: 2,
    SCALE: 3,
};


export class GizmoManager {

    constructor() {
        if (engineController.ready) {
            this.scene = engineController.scene;
            this.initGizmos();
            this.visible = true;
            this.initGizmosVueData();
        } else {
            self.app.events.on("@obsidian-engine.engine-ready", (scene) => {
                this.scene = scene;
                this.initGizmos();
                this.visible = true;
                this.initGizmosVueData();
            });
        }
    }

    /**
     * Create and initialize gizmos and internals
     */
    initGizmos() {
        // Position gizmos
        this.positionGizmo = new PositionGizmo(highlightManager, this.scene);
        this.positionGizmo.snapDistance = config.step;

        // Rotations gizmos
        this.rotationGizmo = new RotationGizmo(highlightManager);
        this.rotationGizmo.snapDistance = config.angleStep45;

        // Plane gizmos
        this.planeGizmo = new PlaneGizmo(highlightManager, this.scene);
        this.planeGizmo.snapDistance = config.step;

        // Scale gizmos
        this.scaleGizmo = new ScaleGizmo();
        this.scaleGizmo.snapDistance = config.step * 2;

        // Bind events to position and plane gizmos
        [
            this.positionGizmo.xGizmo,
            this.positionGizmo.yGizmo,
            this.positionGizmo.zGizmo,
            this.planeGizmo.xGizmo,
            this.planeGizmo.yGizmo,
            this.planeGizmo.zGizmo,
        ].forEach((gizmo) => {
            const dragBe = gizmo.dragBehavior;
            EntityHelper.bindDragBehaviorToAttachedEntity(dragBe);
            GizmoHelper.addGizmoDragEvents(dragBe);
        });

        // Bind drag events to rotation and scale gizmos
        [
            this.rotationGizmo.xGizmo,
            this.rotationGizmo.yGizmo,
            this.rotationGizmo.zGizmo,
            this.scaleGizmo.xGizmo,
            this.scaleGizmo.yGizmo,
            this.scaleGizmo.zGizmo,
            this.scaleGizmo.xMinusGizmo,
            this.scaleGizmo.yMinusGizmo,
            this.scaleGizmo.zMinusGizmo,
        ].forEach((gizmo) => {
            const dragBe = gizmo.dragBehavior;
            GizmoHelper.addGizmoDragEvents(dragBe);
        });
    }

    initGizmosVueData() {
        VueHelper.AddVueProperty(this, "currentGizmo", GIZMO_ENUM.NONE);
    }

    showSpecificGizmo(gizmoType, mesh) {
        switch (gizmoType) {
        case GIZMO_ENUM.POSITION:
            this.showPositionGizmo(mesh);
            this.showPlaneGizmo(mesh);
            break;
        case GIZMO_ENUM.ROTATION:
            this.showRotationGizmo(mesh);
            break;
        case GIZMO_ENUM.SCALE:
            this.showScaleGizmo(mesh);
            break;
        default:
            this.hideGizmos();
            break;
        }
    }

    // Position gizmos
    showPositionGizmo(mesh) {
        this.positionGizmo.attachedMesh = mesh;
        if (mesh.entity) {
            this.positionGizmo.attachedEntity = mesh.entity;
        }
        this.visible = true;
        this.currentGizmo = GIZMO_ENUM.POSITION;
        self.app.events.emit("show-gizmo");
    }

    hidePositionGizmo() {
        [
            this.positionGizmo.xGizmo,
            this.positionGizmo.yGizmo,
            this.positionGizmo.zGizmo,
            this.planeGizmo.xGizmo,
            this.planeGizmo.yGizmo,
            this.planeGizmo.zGizmo,
        ].forEach(
            (gizmo) => {
                if (gizmo.dragBehavior && gizmo.dragBehavior.dragging) {
                    gizmo.dragBehavior.releaseDrag();
                }
            }
        );
        this.positionGizmo.attachedEntity = null;
        this.positionGizmo.attachedMesh = null;
        this.visible = false;
        this.currentGizmo = GIZMO_ENUM.NONE;
    }

    // Plane gizmos
    showPlaneGizmo(mesh) {
        this.planeGizmo.attachedMesh = mesh;
        if (mesh.entity) {
            this.planeGizmo.attachedEntity = mesh.entity;
        }
        this.visible = true;
        this.currentGizmo = GIZMO_ENUM.POSITION;
        self.app.events.emit("show-gizmo");
    }

    hidePlaneGizmo() {
        this.planeGizmo.attachedEntity = null;
        this.planeGizmo.attachedMesh = null;
        this.visible = false;
        this.currentGizmo = GIZMO_ENUM.NONE;
    }

    // Rotation gizmos
    showRotationGizmo(mesh) {
        this.rotationGizmo.attachedMesh = mesh;
        if (mesh.entity) {
            this.rotationGizmo.attachedEntity = mesh.entity;
        }
        this.visible = true;
        this.currentGizmo = GIZMO_ENUM.ROTATION;
        self.app.events.emit("show-gizmo");
    }

    hideRotationGizmo() {
        [
            this.rotationGizmo.xGizmo,
            this.rotationGizmo.yGizmo,
            this.rotationGizmo.zGizmo,
        ].forEach(
            (gizmo) => {
                if (gizmo.dragBehavior && gizmo.dragBehavior.dragging) {
                    gizmo.dragBehavior.releaseDrag();
                }
            }
        );
        this.rotationGizmo.attachedMesh = null;
        this.rotationGizmo.attachedEntity = null;
        this.visible = false;
        this.currentGizmo = GIZMO_ENUM.NONE;
    }

    // Scale Gizmo Functions
    showScaleGizmo(mesh) {
        this.scaleGizmo.attachedMesh = mesh;
        this.scaleGizmo.attachedEntity = mesh.entity;
        this.visible = true;
        this.currentGizmo = GIZMO_ENUM.SCALE;
        self.app.events.emit("show-gizmo");
    }

    hideScaleGizmo() {
        this.scaleGizmo.attachedMesh = null;
        this.scaleGizmo.attachedEntity = null;
        this.visible = false;
        this.currentGizmo = GIZMO_ENUM.NONE;
    }

    hideGizmos() {
        this.hidePositionGizmo();
        this.hideRotationGizmo();
        this.hidePlaneGizmo();
        this.hideScaleGizmo();
        this.currentGizmo = GIZMO_ENUM.NONE;
    }

    isGizmoActive() {
        return [this.positionGizmo, this.rotationGizmo, this.scaleGizmo].some(
            gizmo => (gizmo.attachedEntity || gizmo.attachedMesh)
        );
    }

}
