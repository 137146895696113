/**
 * To use if you want to export several uvMapped models to .obj
 */
module.exports = {

    meshManager: null,

    split() {
        const element = document.createElement("div");
        element.style.display = "block";
        element.style.width = "200px";
        element.style.height = "200px";
        element.style.backgroundColor = "white";
        element.style.position = "absolute";
        element.style.top = "20px";
        element.style.left = "20px";
        element.style.zIndex = "9000";
        element.innerHTML = "<p>Meshes to split to map holes textures</p><input type=\"file\" multiple>";

        const fileInput = element.lastChild;
        document.body.appendChild(element);
        element.onchange = () => {
            for (let i = 0; i < fileInput.files.length; i += 1) {
                const file = fileInput.files[i];
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    this.meshManager.loadGeometry(file.name, reader.result, { exportObj: true });
                });
                reader.readAsDataURL(file);
            }
        };
    },


};
