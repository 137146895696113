<template>
    <div
        id="grid-menu"
        class="top-menu-div"
    >
        <b-nav-item
            :link-classes="'size-column'"
        >
            <label for="width">{{ _("Width") }}</label>
            <br>
            <input
                id="width"
                v-model="updateWidth"
                type="text"
            >
            <span>&nbsp;{{ unit }}</span>
        </b-nav-item>
        <b-nav-item
            :link-classes="'size-column'"
        >
            <label for="height">{{ _("Height") }}</label>
            <br>
            <input
                id="height"
                v-model="updateHeight"
                type="text"
            >
            <span>&nbsp;{{ unit }}</span>
        </b-nav-item>
        <b-nav-item>
            <b-button
                pill
                class="validate"
                @click="updateGridData()"
            >
                {{ _("OK") }}
            </b-button>
        </b-nav-item>
    </div>
</template>

<script>
import self from "../../index";

export default {
    name: "Grid",
    props: {
        gridData: {
            type: Object,
            default: () => ({
                height: 10,
                width: 12,
            }),
        },
        config: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            maxWidth: 99, // Meters
            maxHeight: 99, // Meters
            width: this.gridWidth,
            height: this.gridHeight,
        };
    },
    computed: {
        unit() {
            return this.config.unitMode === "us" ? "in" : "m";
        },
        // Use parse(stringify) to avoid the vue to point directly on the data and modify it
        gridWidth() {
            return JSON.parse(JSON.stringify(this.gridData.width));
        },
        gridHeight() {
            return JSON.parse(JSON.stringify(this.gridData.height));
        },
        updateWidth: {
            get() {
                return this.width - (this.width % 0.5);
            },
            set(val) {
                let width = this.comaToDot(val);
                if (Number.isNaN(Number(width))) {
                    width = 0;
                } else if (this.config.unitMode === "eu" && width > this.maxWidth) {
                    width = this.maxWidth;
                } else if (this.config.unitMode === "us" && width > this.toInches(this.maxWidth)) {
                    width = this.toInches(this.maxWidth);
                }
                this.width = width;
            },
        },
        updateHeight: {
            get() {
                return this.height - (this.height % 0.5);
            },
            set(val) {
                let height = this.comaToDot(val);
                if (Number.isNaN(Number(height))) {
                    height = 0;
                } else if (this.config.unitMode === "eu" && height > this.maxHeight) {
                    height = this.maxHeight;
                } else if (this.config.unitMode === "us" && height > this.toInches(this.maxHeight)) {
                    height = this.toInches(this.maxHeight);
                }
                this.height = height;
            },
        },
    },
    watch: {
        gridWidth(newVal) {
            this.width = newVal;
        },
        gridHeight(newVal) {
            this.height = newVal;
        },
    },
    mounted() {
        this.width = this.gridWidth;
        this.height = this.gridHeight;
        self.app.events.on("modify-unit", () => {
            self.app.modules.gridManager.switchUnit();
        });
    },
    methods: {
        comaToDot(value) {
            let finalValue = value;
            if (Number.isInteger(value)) {
                finalValue = value.toString();
            }
            if (typeof finalValue === "string") {
                if (finalValue.includes(",")) {
                    finalValue = finalValue.replace(",", ".");
                }
                finalValue = Number.parseFloat(finalValue);
            }
            return finalValue;

        },
        toInches(meterValue) {
            return meterValue * this.gridData.toInchesConstant;
        },
        updateGridData() {
            // update vue gridData with the form content
            this.gridData.width = this.width;
            this.gridData.height = this.height;

            // send them to the grid module
            self.app.modules.gridManager.computeDataFromVue(this.width, this.height);
        },
        _(...args) {
            return self.app.modules.stonejs.gettext(...args);
        },
    },
};
</script>

<style lang="scss">
@import "../../../../style/bematrix";

#grid-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-family: $font-medium;
    font-size: 0.75em;
    padding: 0 1rem;

    .size-column {
        width: 100px;
        height: auto;
        display: inline-block;
        padding: 0 0.5rem;

        input {
            width: 70%;
            border-radius: 3px;
            border: solid 1px $bluey-grey;
            padding-left: 0.2rem;
        }

        span {
            position: relative;
            color: $grey-text;
            right: 30%;
        }
    }

    button {
        font-size: 1em;
        height: auto;
        background-color: $green;
        border-color: transparent;
        color: white;

        &:hover {
            background-color: $green-hover;
        }

        &:active {
            background-color: $green-click;
        }
    }
}
</style>
