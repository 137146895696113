export default {
    name: "option-manager",
    requires: [
        "geometry-utility",
        "iframe-api",
        "mesh-manager",
        "material-manager",
        "history",
        "data-store",
        "http-request",
        "highlight-manager",
        "collision-manager",
        "catalog-manager",
        "assets-manager",
    ],
    load() {
        const OptionController = require("./controller/option-controller")
            .default;
        const RalColors = require("./utility/ral-colors");
        return {
            optionController: new OptionController(),
            OptionableMixin: require("./model/optionable-mixin"),
            ColorableMixin: require("./model/colorable-mixin"),
            RalColors,
        };
    },
    unload() {},
};
