import cu from "vendors/class-utils";

const Structure = require("@obsidianjs/data-store/src/entity");

/**
 * A structure that saves the two points of a measurement object
 * Usefull for history and dataStore purpose
 */
const MeasurementStructure = Structure.$extend({
    __name__: "measurement",

    /**
     * @class bematrix.EntityStructure
     */
    __init__(startPoint, endPoint, measurementObject) {
        this.$data.startPoint = startPoint;
        this.$data.endPoint = endPoint;
        this.measurementObject = measurementObject;
        this.$super();
    },

    getStartPoint: cu.getter,
    setStartPoint: cu.setter,

    getEndPoint: cu.getter,
    setEndPoint: cu.setter,
});

Structure.$register(MeasurementStructure);
export default MeasurementStructure;
