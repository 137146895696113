import {
    Vector3, DynamicTexture, SpriteManager, Sprite,
} from "@babylonjs/core";
import { v4 as uuid } from "uuid";
import config from "defaultConfig";

import MeasurementHelper from "../helpers/measurement-helper";


/*
 * Class that uses Babylon meshes to show measurement between two points
 */
export default class MeasurementWorldUi {

    /**
     * Creates the world ui and initialize it
     */
    constructor(scene) {
        this.scene = scene;
        this.entity = null;
        this.id = uuid();
        this.lineObject = null;
        this.startingMesh = null;
        this.endingMesh = null;
        this.startingPoint = null;
        this.endingPoint = null;
    }

    /**
     * Returns all the meshes used to draw a measurement
     */
    getMeshes() {
        return this.lineObject.cones.concat([this.lineObject.lineMesh]);
    }


    /**
     * Destroys the current line object drawn
     */
    destroyLine() {
        if (this.lineObject) {
            this.getMeshes().forEach((mesh) => { mesh.dispose(); });
        }
        [this.startingMesh, this.endingMesh].forEach(
            (mesh) => {
                if (mesh && mesh.name.includes("pointGround")) {
                    mesh.dispose();
                }
            }
        );
    }

    /**
     * Destroys the line and the measurement text wich is a sprite
     */
    destroyMeasurementObject() {
        this.destroyLine();
        if (this.spriteText) {
            this.spriteText.dispose();
            this.spriteText = null;
        }
        this.entity.measurementObject = null;
        this.entity = null;
        this.lineObject = null;
        this.startingMesh = null;
        this.endingMesh = null;
        this.startingPoint = null;
        this.endingPoint = null;
    }

    /**
     * Draw a line between the two points of the measurement object
     * In this part the text is not displayed
     */
    drawLine() {
        this.destroyLine();
        this.lineObject = MeasurementHelper.createLine(this.startingPoint,
            this.endingPoint, this.scene);
    }

    /**
     * Displays the text of the measurement
     * It uses as dynamicTexture and a sprite
     */
    showMeasurement() {
        // Text part
        const dist = Vector3.Distance(this.startingPoint, this.endingPoint);
        let text;
        if (config.unitMode === "us") {
            text = `${Math.round(dist * 39.3701)} in`;
        } else {
            text = `${Math.round(dist * 1000)} mm`;
        }

        // Dimensions for dynamic texture
        const DTHeight = 180;
        const DTWidth = 640;
        const spriteWidth = 1; // sprite width (3D space unit)
        const fontFamily = "Poppins Regular"; // text font

        // Create dynamic texture
        // Please check this to understand how the fitting part works:
        // https://doc.babylonjs.com/how_to/dynamictexture#fit-an-area-to-text
        const textureText = new DynamicTexture("dynamic texture",
            { width: DTWidth, height: DTHeight }, this.scene);
        textureText.hasAlpha = true;
        const ctx = textureText.getContext();
        const size = 14;
        ctx.font = `bold ${size}px ${fontFamily}`;
        ctx.canvas.height = DTHeight;
        ctx.canvas.width = DTWidth;

        const textWidth = ctx.measureText(textureText).width;
        // Calculate ratio and final font size
        const ratio = textWidth / size;
        const fontSize = Math.floor(DTWidth / ratio);

        // styling
        ctx.font = `bold ${fontSize}px ${fontFamily}`;
        const textHeight = ctx.measureText("M").width; // trick to estimate text height
        ctx.textAlign = "center";
        ctx.fillStyle = "black";
        ctx.strokeStyle = "rgba(255,255,255,0.6)";
        ctx.lineWidth = 4;

        // fill and stroke text on the dynamicTexture's canvas
        const y = DTHeight / 2 + (DTHeight - textHeight) / 2;
        ctx.fillText(text, DTWidth / 2, y);
        ctx.strokeText(text, DTWidth / 2, y);
        ctx.fill();
        ctx.stroke();

        // update the dynamic texture
        textureText.update();

        // Sprite manager and sprite creation
        this.textSpriteManager = new SpriteManager(
            `text-manager ${this.id}`,
            "",
            1,
            textureText.getSize(),
            this.scene
        );

        this.textSpriteManager.renderingGroupId = 1; // rendered in front of other meshes
        this.textSpriteManager.isPickable = true;
        this.textSpriteManager.texture = textureText;

        const sprite = new Sprite(`measurementSprite ${this.id}`, this.textSpriteManager);
        const vec2 = this.endingPoint.subtract(this.startingPoint);
        const tmpPosition = this.startingPoint.add(vec2.scale(0.5));

        if (tmpPosition.y < 0.05) { // We check if the texts goes inside the ground;
            tmpPosition.y = 0.07;
        }

        sprite.position = tmpPosition;
        sprite.invertV = 1;
        sprite.height = spriteWidth * DTHeight / DTWidth;
        sprite.width = spriteWidth;
        sprite.isPickable = true;
        sprite.metadata = {};
        // Usefull for measurement selection
        sprite.metadata.measurementObject = this;
        this.spriteText = sprite;
        this.getMeshes().forEach((mesh) => {
            if (!mesh.metadata) {
                mesh.metadata = {};
            }
            mesh.metadata.measurementObject = this;
        });
    }

    /**
     * Sets the initial point given by the position of the given mesh
     * @param {BABYLON.Mesh} mesh
     */
    initLine(mesh) {
        this.startingPoint = mesh.absolutePosition;
        this.startingMesh = mesh;
    }

    /**
     * Sets the final point given by the position of the given mesh and draws the line
     * @param {*} mesh
     */
    endLine(mesh) {
        this.endingPoint = mesh.absolutePosition;
        this.endingMesh = mesh;
        this.drawLine();
    }

    /**
     * Remove the last point of the measurement object and destroys the line
     */
    removeEndLine() {
        this.endingPoint = null;
        this.destroyLine();
    }

    hide() {
        this.getMeshes().forEach((m) => {
            m.setEnabled(false);
        });
        this.spriteText.isVisible = false;
    }

    show() {
        this.getMeshes().forEach((m) => {
            m.setEnabled(true);
        });
        this.spriteText.isVisible = true;
    }

}
