import { TransformNode, Vector3 } from "@babylonjs/core";

import config from "defaultConfig";
import self from "../index";
import AbstractOptionController from "./abstract-option-controller";
import OptionableMixin from "../model/optionable-mixin";

export default class ShelfOptionController extends AbstractOptionController {

    constructor(optionController) {

        super(optionController);
        this.shelfReferences = { };

        this.shelfReferences[`${OptionableMixin.SHELF_OPTION.SIXTYDEG}`] = "901 36 60DEG - ";
        this.shelfReferences[`${OptionableMixin.SHELF_OPTION.LARGE}`] = "901 36 LARGE G - ";
        this.shelfReferences[`${OptionableMixin.SHELF_OPTION.SMALL}`] = "901 36 SMALL G - ";
        this.shelfReferences[`${OptionableMixin.SHELF_OPTION.TUBE}`] = "901 36 TUBE G -";

        this.initializeEvents();

    }

    initializeEvents() {
        super.initializeEvents([]);
        self.app.events.on("update-shelf-number", (entity) => {
            if (entity.mesh) {
                entity.computeOptionsParameters();
                this.updateShelvesMeshes(entity);
            }
        });
    }

    addShelves(type, entity = null) {
        const realEntity = this.optionController.returnRealEntity(entity);
        if (realEntity) {
            realEntity.computeOptionsParameters();
            realEntity.shelfOption = type;

            if (realEntity.shelfNumber === 0) {
                realEntity.shelfNumber = 1;
                return Promise.resolve(null);
            }
            return this.updateShelvesMeshes(realEntity);
        }
        return Promise.resolve(null);
    }

    updateShelvesMeshes(entity) {
        ShelfOptionController.removeShelvesMeshes(entity);
        if (entity.shelfNumber === 0) {
            return Promise.resolve(null);
        }

        const modelRef = this.getShelfRef(entity);
        if (!modelRef) {
            return Promise.resolve(null);
        }
        return this.catalogManager.tryRegisterMeshGeometry(modelRef).then(() => {
            if (entity.destroyed) {
                return;
            }
            const interiorHeight = entity.orientedHeight - 4 * (config.step);
            const nbHoles = interiorHeight / (config.step * 2);
            const interval = Math.ceil(nbHoles / (entity.shelfNumber + 1));
            let margin = config.step;
            if (entity.shelfNumber === 1) {
                margin += config.step;
            }

            // As this is a promise when loading a stand from xml
            // preceding promises are not yet processed so you can
            // create more shelves than needed
            if (entity.optionsMeshes.shelves.length >= entity.maxShelfNumber) {
                return;
            }

            for (let i = 0; i < entity.shelfNumber; i += 1) {
                const mesh = this.loadShelfFromRef(modelRef);
                mesh.name = "shelf";
                mesh.parent = entity.mesh;
                const bb = mesh.getBoundingInfo().boundingBox;
                const center = bb.center;

                // We recenter the model
                mesh.position.x += -center.x;
                mesh.position.y += 0.0075;
                mesh.position.z -= bb.minimum.z - 0.01;

                // correct orientation with a pivot
                const pivot = new TransformNode(`${mesh.name} shelf pivot`);
                pivot.parent = entity.mesh;
                mesh.parent = pivot;
                pivot.rotate(Vector3.Right(), -Math.PI / 2);
                pivot.rotate(Vector3.Up(), entity.angleToUp - Math.PI);

                mesh.position.z -= -entity.orientedHeight / 2
                    + margin + (i + 1) * interval * 2 * config.step;
                mesh.computeWorldMatrix(true);
                entity.optionsMeshes.shelves.push(mesh);
            }

            // Swap side if needed
            if (entity.swappedOptions.SHELF) {
                this.optionController.swapOptionsMeshesSide(
                    this.optionController.optionsFamilies.SHELF,
                    entity
                );
            }
            this.toggleShelvesVisibility(entity.visible, entity);
        });

    }

    getShelfRef(entity) {
        if (entity.shelfOption !== OptionableMixin.SHELF_OPTION.NONE) {
            return `${this.shelfReferences[`${entity.shelfOption}`]}${
                Math.ceil(entity.orientedWidth * 1000)} mm`;
        }
        return null;
    }

    updateMaxShelfNumber(entity = null, computeOptionParams = true) {
        const realEntity = this.optionController.returnRealEntity(entity);
        if (realEntity) {
            ShelfOptionController.computeMaxShelfNumber(realEntity, computeOptionParams);
        }
    }

    /**
    * Returns the baseplate corresponding to the ref arguments
    * @param {*} ref
    * @returns {Mesh}
    */
    loadShelfFromRef(ref) {
        const shelf = this.meshManager.getMeshFromLoadedGeometry(
            ref,
            "eco",
            null,
            {
                category: "PARTS",
                subCategory: "SHELVES BRACKETS",
            }
        );

        self.app.modules.meshManager.meshUtility.AddMetadataProperties(
            shelf,
            {
                isOption: true,
            }
        );

        return shelf;
    }

    removeShelves(entity = null) {
        const realEntity = this.optionController.returnRealEntity(entity);
        if (realEntity) {
            realEntity.shelfOption = OptionableMixin.SHELF_OPTION.NONE;
            realEntity.swappedOptions.SHELF = false;
            ShelfOptionController.removeShelvesMeshes(realEntity);
        }
    }

    toggleShelvesVisibility(isVisible, entity = null) {
        const realEntity = this.optionController.returnRealEntity(entity);
        if (realEntity
            && realEntity.optionsMeshes.shelves.length > 0) {
            realEntity.optionsVisibility.shelves = isVisible;
            self.app.modules.geometryUtility.toggleMeshesVisibility(
                realEntity.optionsMeshes.shelves, isVisible && realEntity.visible
            );
        }
    }


    static computeMaxShelfNumber(entity, computeOptionParams = true) {
        if (computeOptionParams) {
            entity.computeOptionsParameters();
        }
        entity.canHaveShelfOption = true;
        if (entity.orientedHeight < 0.310) {
            entity.maxShelfNumber = 0;
            entity.canHaveShelfOption = false;
        } else if (entity.orientedHeight <= 0.620) {
            entity.maxShelfNumber = 1;
        } else if (entity.orientedHeight <= 1.240) {
            entity.maxShelfNumber = 2;
        } else {
            entity.maxShelfNumber = 3;
        }
        if (entity.shelfNumber > entity.maxShelfNumber) {
            entity.shelfNumber = entity.maxShelfNumber;
        }
    }

    static removeShelvesMeshes(entity) {
        entity.optionsMeshes.shelves.forEach((shelfMesh) => {
            shelfMesh.dispose();
        });
        entity.optionsMeshes.shelves = [];
    }

    /**
     * Returns the shelf type from the reference
     * @param {string} ref
     */
    getShelfTypeFromRef(ref) {
        let referenceShelfType = OptionableMixin.SHELF_OPTION.NONE;

        // Loop through the references array
        Object.keys(this.shelfReferences).forEach((shelfRefAlias) => {
            if (ref.includes(this.shelfReferences[shelfRefAlias])) {
                referenceShelfType = parseInt(shelfRefAlias, 10);
            }
        });

        return referenceShelfType;
    }

    /**
     * Returns true if the reference is a shelf reference
     * @param {string} ref
     */
    isShelfRef(ref) {
        return Boolean(this.getShelfTypeFromRef(ref));
    }

}
