import self from "../index";


export default class AbstractOptionController {

    constructor(optionController) {
        this.selectionController = null;
        this.optionController = optionController;
    }

    /**
    * Load meshes and assign the selectionController when corresponding modules are ready
    */
    initializeEvents(meshRefs, meshesLoadedCallback = () => {}) {
        self.app.events.on("@obsidian-engine.engine-ready", () => {
            this.catalogManager = this.optionController.catalogManager;
            this.meshManager = this.catalogManager.MeshManager;
            this.scene = this.meshManager.scene;
        });

        self.app.events.on("@catalog-manager.catalog-initialized",
            () => {
                const promises = [];
                Object.values(meshRefs).forEach((ref) => {
                    try {
                        promises.push(this.catalogManager.tryRegisterMeshGeometry(ref));
                    } catch (err) {
                        self.app.log.error(`Could not add option ${ref} in catalog:`, err);
                    }
                });
                Promise.all(promises)
                    .then(() => {
                        meshesLoadedCallback();
                    });
            });
    }

}
