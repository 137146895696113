import { Camera } from "@babylonjs/core";
import BuildingPlanHelper from "../helper/building-plan-helper";

import self from "../../index";

const {
    app: {
        modules: {
            cameraManager: {
                controller: cameraController,
                ScreenshotController: screenshotController,
                ScreenshotHelper: screenshotHelper,
                SCREENSHOT_PIPELINES,
            },
            optionManager: {
                optionController,
            },
            selectionManager: selectionController,
            materialManager,
        },
        events: {
            emit,
        },
    },
} = self;

export const IMAGE_MODES = {
    SCENE: 1,
    HTML: 2,
    ALL: 3,
};

export default class BuildingPlanImageController {

    constructor(parentController) {
        this.parentController = parentController;
        this.outputImagesScale = 3;
        this.maxWidth = 3840; // no more than 4K screenshots
        this.boundedOutputImageScale = this.outputImageScale;
        let firstTransparentScreenshot = true;

        this.imageModeCaptureFunction = {
            SCENE: () => {
                const engineWidth = screenshotController.engine.getRenderWidth();
                const engineHeight = screenshotController.engine.getRenderHeight();
                const ratio = engineWidth / engineHeight;
                const width = Math.min(engineWidth * this.outputImagesScale, this.maxWidth);
                const height = width / ratio;
                this.boundedOutputImageScale = width / engineWidth;
                if (firstTransparentScreenshot && materialManager.areFramesTransparent) {
                    // For the first render with transparent materials, prevents first buggy screenshot
                    firstTransparentScreenshot = false;
                    return screenshotController.screenshot(
                        SCREENSHOT_PIPELINES.DEFAULT, null, { width: 1, height: 1 }, "image/jpeg"
                    ).then(() => screenshotController.screenshot(
                        SCREENSHOT_PIPELINES.DEFAULT,
                        null,
                        {
                            width,
                            height,
                        },
                        "image/jpeg",
                    ));
                }
                return screenshotController.screenshot(
                    SCREENSHOT_PIPELINES.DEFAULT,
                    null,
                    {
                        width,
                        height,
                    },
                    "image/jpeg",
                );
            },
            HTML: () => screenshotHelper.getScreenshotFromHtml(
                document.getElementById("pdf-components-container"),
                this.boundedOutputImageScale,
                true
            ),
        };
        this.temporaryMeasurements = [];

    }

    /**
     * Capture the current state of the page and store it into the PDF page image array
     * Image mode allow some control over what the capture will contain
     *
     * /!\ This function doesn't add the image to the JSPDF instance
     *
     * @param { IMAGE_MODES = IMAGE_MODES.ALL } imageMode
     */
    captureImage(imageMode) {
        emit("create-preview", imageMode);

        // Delay function execution to allow vue update
        setTimeout(() => {
            if (imageMode === IMAGE_MODES.NONE) {
                return;
            }

            const page = this.parentController.getPage();
            const imagesPromises = [];
            const imageModesNames = Object.keys(IMAGE_MODES);
            const imageModesValues = Object.values(IMAGE_MODES);

            if (imageMode === IMAGE_MODES.SCENE
                && materialManager.areFramesTransparent && optionController.showInfills
                && !page.scenePageMetadata.connectorSelectionModeEnabled) {
                // We set the frames transparency back
                // because infills shown but transparent are not an available
                // combination in building plan mode when editing page
                materialManager.setFramesTransparency(false);
            }

            const engineWidth = screenshotController.engine.getRenderWidth();
            const engineHeight = screenshotController.engine.getRenderHeight();

            for (let i = 0; i < imageModesNames.length; i += 1) {
                const imageModeName = imageModesNames[i];
                const imageModeFlag = imageModesValues[i];

                if ((imageMode & imageModeFlag) === imageModeFlag) {
                    const imagePromise = this.imageModeCaptureFunction[imageModeName]()
                        .then((image) => {
                            page.images[imageModeName] = {
                                ratio: engineHeight / engineWidth,
                                base64: image,
                            };
                            page.camera = {
                                alpha: cameraController.currentCamera.alpha,
                                beta: cameraController.currentCamera.beta,
                                radius: cameraController.currentCamera.radius,
                                target: {
                                    x: cameraController.currentCamera.target.x,
                                    y: cameraController.currentCamera.target.y,
                                    z: cameraController.currentCamera.target.z,
                                },
                            };
                        });
                    imagesPromises.push(imagePromise);
                }
            }

            Promise.all(imagesPromises)
                .then(() => {
                    if ((imageMode & IMAGE_MODES.SCENE) === IMAGE_MODES.SCENE) {
                        this.parentController.isCameraFixed = true;
                        cameraController.activateCamera(false);
                        cameraController.disableOrthoZoom();

                        page.scenePageMetadata.cameraMode = cameraController.currentCamera.mode;
                        if (cameraController.currentCamera.mode === Camera.ORTHOGRAPHIC_CAMERA) {
                            page.scenePageMetadata.orthoZoomStart = cameraController.orthoZoomStart;
                        }
                        if (materialManager.areFramesTransparent && !optionController.showInfills) {

                            // Activate connector selection mode instead
                            // (infills are explicitly hidden before taking screenshot so
                            // they will be kept hidden after)
                            page.scenePageMetadata.connectorSelectionModeEnabled = true;
                            emit("toggle-connector-selection", true);
                        }
                        page.scenePageMetadata.activeProducts = BuildingPlanHelper
                            .getActiveProductsReferences();
                        page.scenePageMetadata.productsVisibility = BuildingPlanHelper
                            .getProductVisibility();
                        page.scenePageMetadata.measurements = this.temporaryMeasurements;

                        this.temporaryMeasurements = [];
                        selectionController.unselectAll();
                    }
                    this.parentController.updatePage(page);
                })
                .finally(() => {
                    emit("preview-created", imageMode);
                });
        });
    }

    /**
     * Remove a given image type from the current page
     * @param { IMAGE_MODES = IMAGE_MODES.ALL } imageMode
     */
    removeImage(imageMode) {
        const page = this.parentController.getPage();
        const imageModesNames = Object.keys(IMAGE_MODES);
        const imageModesValues = Object.values(IMAGE_MODES);

        for (let i = 0; i < imageModesNames.length; i += 1) {
            const imageModeName = imageModesNames[i];
            const imageModeFlag = imageModesValues[i];

            if ((imageMode & imageModeFlag) === imageModeFlag) {
                page.images[imageModeName] = null;
            }
        }

        if ((imageMode & IMAGE_MODES.SCENE) === IMAGE_MODES.SCENE) {
            page.scenePageMetadata.activeProducts = null;

            selectionController.unselectAll();
        }

        this.parentController.updatePage(page);
    }

    /**
     * Returns the current page images
     * @param {Number} pageNumber optional
     * @returns { images: (HTML, SCENE), editing }
     */
    getPageImages(pageNumber) {
        const page = this.parentController.getPage(pageNumber);

        return Object.values(page.images).filter(image => Boolean(image));
    }

    /**
     * Does the page have atleast one image saved
     * @param {Number} pageNumber optional
     * @returns {Boolean}
     */
    pageHasImages(pageNumber) {
        return this.getPageImages(pageNumber).length > 0;
    }

}
