import { v4 as uuid } from "uuid";
import config from "defaultConfig";
import RightsHelper from "helpers/rights-helper";

import VueHelper from "helpers/vue-helper";
import self from "../..";

import MeasurableEntityHelper from "../helpers/measurable-entity-helper";
import MeasurementStructure from "../models/measurement-structure";
import MeasurementWorldUi from "../world-ui/measurement-world-ui";
import MeasurementHelper, { MEASUREMENT_SPHERE_TYPE } from "../helpers/measurement-helper";

const {
    events,
    modules: {
        obsidianEngine: {
            controller: engineController,
        },
        materialManager,
        highlightManager,
        dataStore,
        history,
    },
} = self.app;

// Wait for engine to be Ready to initialize it
let mouseEventHandler = null;

export default class MeasurementController {

    constructor() {
        VueHelper.AddVueProperty(this, "measureModeActivated", false);

        this.measurableEntitiesList = [];

        if (engineController.ready) {
            this.initEngineDependantFunction();
        } else {
            events.on("@obsidian-engine.engine-ready", () => {
                this.initEngineDependantFunction();
            });
        }

        // Creates the three different mesh for measurement point but need to wait for material
        // manager to have loaded all needed materials
        if (materialManager.materialLibraryLoaded) {
            MeasurementHelper.initMeasurementSphere();
        } else {
            events.on("@catalog-manager.materials-loaded", () => {
                MeasurementHelper.initMeasurementSphere();
            });
        }
        this.selectedMeasurementEntity = null;
        this.currentMeasurementWorldUi = null;
        this.drawingLine = false;
        this.focusedPoint = null;

        this.initEvents();
    }

    initEvents() {
        events.on("@data-store.entity-added", (entity) => {
            this.onEntityAdded(entity);
        });

        events.on("@data-store.entity-removed", (entity) => {
            this.onEntityRemoved(entity);
        });

        events.on("@entity-manager.mesh-fetched", (entity) => {
            this.onMeshFetched(entity);
        });

        events.on("@obsidian-engine.delete", () => {
            if (RightsHelper.isModeBuildingPlan()) {
                return;
            }
            this.onDelete();
        });

        events.on("@building-plan-manager.measurement-delete", () => {
            this.onDelete();
        });

        // Events that unselect the current measurement world ui
        ["@obsidian-engine.unselect", "@obsidian-engine.undo", "@obsidian-engine.redo",
            "@obsidian-engine.drag-start-mesh"].forEach(
            (unselectEvent) => {
                events.on(unselectEvent, () => {
                    this.unselectcurrentMeasurementWorldUi();
                });
            }
        );

        // Events that deactivate the mode or cancel the current line
        events.on("@obsidian-engine.unselect", () => {
            if (this.drawingLine) {
                this.cancelCurrentLine();
            } else if (this.measureModeActivated) {
                this.toggleMeasureMode();
            }
        });
    }


    /**
     * Intialize properties or event that needs the engine to be ready first
     */
    initEngineDependantFunction() {
        mouseEventHandler = engineController.eventHandler.mouse;

        this.initMeasurementMouseMoveRules();
        this.initMeasurementPickingRules();
    }

    /**
     * Initialise picking interactions by setting rules
     */
    initMeasurementPickingRules() {
        /**
         * Check if a measurement point is picked (ground/ object)
         * @param {pickResult} pickResult
         */
        const pointPickingCallback = (pickResult) => {
            if (!this.measureModeActivated) {
                return false;
            }
            if (this.groundPoint) {
                this.onMeasurementPointClicked(this.groundPoint);
                this.groundPoint = null;
                return true;
            }
            if (pickResult.hit && pickResult.pickedMesh
                && pickResult.pickedMesh.name.includes("measurementPoint")) {
                this.onMeasurementPointClicked(pickResult.pickedMesh);
                return true;
            }
            if (this.focusedPoint) {
                this.onMeasurementPointClicked(this.focusedPoint);
                return true;
            }
            return false;
        };

        /**
         * Check if a measurementWorldUI (sprite, cone, line) is picked and select it
         * @param {pickResult} pickResult
         */
        const measurementUiCallback = (pickResult) => {
            if (this.measureModeActivated) {
                return false;
            }
            const spritePickResult = engineController.scene.pickSprite(engineController
                .scene.pointerX,
            engineController.scene.pointerY);
            const meshPredicate = (pickResult.hit && pickResult.pickedMesh
                && (pickResult.pickedMesh.name.includes("cone")
                    || (pickResult.pickedMesh.name.includes("line"))
                )
            );
            const spritePredicate = (spritePickResult.hit && spritePickResult.pickedSprite
                && (spritePickResult.pickedSprite.name.includes("measurementSprite")));
            if (meshPredicate) {
                this.onMeasurementObjetClicked(pickResult.pickedMesh.metadata.measurementObject);
                return true;
            }
            if (spritePredicate) {
                this.onMeasurementObjetClicked(spritePickResult.pickedSprite
                    .metadata.measurementObject);
                return true;
            }

            return false;
        };

        // Init selection blocking rule
        const checkMeasureModeActivated = () => this.measureModeActivated;


        mouseEventHandler.selectionRulesManager.addRule(checkMeasureModeActivated);
        mouseEventHandler.pickingRulesManager.addRule(pointPickingCallback);
        mouseEventHandler.pickingRulesManager.addRule(measurementUiCallback);
    }

    /**
     * Initialise mouse move interactions by setting rules
     */
    initMeasurementMouseMoveRules() {
        /**
         * Callback that displays ground mmeasurement point or toggle hover material on
         * object measurement point on mouse moving
         * @param {pickResult} pickResult
         */
        const callback = (pickResult) => {
            if (!this.measureModeActivated) {
                return false;
            }
            if (pickResult.hit && pickResult.pickedMesh) {
                let focusMesh;
                // Here we picked a measurementPoint


                if (pickResult.pickedMesh.name.includes("measurementPoint")
                    && !(this.currentMeasurementWorldUi && this.currentMeasurementWorldUi
                        .startingMesh.name
                        === pickResult.pickedMesh.name)) {
                    if (this.focusedPoint
                        && this.focusedPoint.name !== pickResult.pickedMesh.name) {
                    // We unhover the last focused point
                        this.focusedPoint = MeasurementHelper.replaceSphere(
                            this.focusedPoint, MEASUREMENT_SPHERE_TYPE.Basic
                        );
                    }
                    // We are hovering a  measurement point so we set the correct material
                    this.focusedPoint = MeasurementHelper.replaceSphere(
                        pickResult.pickedMesh, MEASUREMENT_SPHERE_TYPE.Hovered
                    );
                    focusMesh = this.focusedPoint;
                    if (this.groundPoint) {
                        this.groundPoint.dispose();
                        this.groundPoint = null;
                    }
                } else if (pickResult.pickedMesh.name.includes("main-ground")
                    || pickResult.pickedMesh.name.includes("offset-plane")) {
                    // Here we picked the ground

                    if (this.focusedPoint) {
                        // We unhover the last focused point
                        this.focusedPoint = MeasurementHelper.replaceSphere(
                            this.focusedPoint, MEASUREMENT_SPHERE_TYPE.Basic
                        );
                    }
                    // Create a ground measurement point
                    const sphere = MeasurementHelper.getNewSphere(`${"pointGround"} ${uuid()}`,
                        MEASUREMENT_SPHERE_TYPE.Hovered);
                    const tmpPos = pickResult.pickedPoint;
                    tmpPos.x = Math.floor(
                        tmpPos.x / config.step
                    );
                    tmpPos.z = Math.floor(
                        tmpPos.z / config.step
                    );
                    tmpPos.scaleInPlace(config.step);
                    sphere.position = tmpPos;
                    if (this.groundPoint) {
                        this.groundPoint.dispose();
                    }
                    this.groundPoint = sphere;
                    focusMesh = sphere;

                } else if (this.drawingLine && this.focusedPoint) {
                    // We picked nothing but we started a measurement drawing
                    this.focusedPoint = MeasurementHelper.replaceSphere(
                        this.focusedPoint, MEASUREMENT_SPHERE_TYPE.Basic
                    );
                    this.focusedPoint = null;
                    this.currentMeasurementWorldUi.removeEndLine();
                }
                if (this.drawingLine && focusMesh) {
                    // We end the drawing of a line

                    focusMesh.computeWorldMatrix();
                    this.currentMeasurementWorldUi.endLine(focusMesh);
                }
            }
            return true;
        };
        mouseEventHandler.mouseMoveRulesManager.addRule(callback);
    }

    onDelete() {
        // Remove the selected measurement if exists
        if (this.selectedMeasurementEntity) {
            this.selectedMeasurementEntity.measurementObject.destroyMeasurementObject();
            dataStore.removeEntity(this.selectedMeasurementEntity);
            if (RightsHelper.isModeBuildingPlan()) {
                history.snapshot();
            }
            events.emit("measurement-destroyed", this.selectedMeasurementEntity);
            this.selectedMeasurementEntity = null;
        }
    }

    onMeasurementObjetClicked(measurableObject) {
        // Select the picked object (and unselect the last one if it exists)
        if (this.selectedMeasurementEntity) {
            this.selectedMeasurementEntity.measurementObject.getMeshes().forEach(
                (mesh) => {
                    highlightManager.toggleHighlightMesh(mesh, false);
                }
            );
        }
        this.selectedMeasurementEntity = measurableObject.entity;
        measurableObject.getMeshes().forEach(
            (mesh) => {
                highlightManager.toggleHighlightMesh(mesh, true);
            }
        );
        events.emit("measurement-selected", this.selectedMeasurementEntity);
    }

    onMeasurementPointClicked(measurableObject) {
        if (!this.drawingLine) {
            // Start the drawing of a line from the clicked point
            this.currentMeasurementWorldUi = new MeasurementWorldUi(
                engineController.scene
            );
            this.currentMeasurementWorldUi.initLine(measurableObject);
            this.drawingLine = true;
            this.currentMeasurementWorldUi.startingMesh = MeasurementHelper.replaceSphere(
                this.currentMeasurementWorldUi.startingMesh, MEASUREMENT_SPHERE_TYPE.Active
            );
            this.focusedPoint = null;
        } else {
            // End the current measurement drawing by setting the line end to the last point clicked
            this.currentMeasurementWorldUi.startingMesh = MeasurementHelper.replaceSphere(
                this.currentMeasurementWorldUi.startingMesh, MEASUREMENT_SPHERE_TYPE.Basic
            );
            this.currentMeasurementWorldUi.endLine(measurableObject);
            this.currentMeasurementWorldUi.showMeasurement();

            // Creates a new measurement entity for store purpose
            const measurementEntity = new MeasurementStructure(
                this.currentMeasurementWorldUi.startingPoint,
                this.currentMeasurementWorldUi.endingPoint,
                this.currentMeasurementWorldUi
            );
            this.currentMeasurementWorldUi.entity = measurementEntity;
            dataStore.addEntity(measurementEntity, "/measurements");
            if (!RightsHelper.isModeBuildingPlan()) {
                // In pdf mode measurements are temporary
                history.snapshot();
            }
            events.emit("measurement-created", measurementEntity);
            [this.currentMeasurementWorldUi.startingMesh, this.currentMeasurementWorldUi.endingMesh]
                .forEach(
                    (point) => {
                        if (point.name.includes("pointGround")) {
                            point.dispose();
                        }
                    }
                );
            this.drawingLine = false;
            this.currentMeasurementWorldUi = null;
        }
    }

    unselectcurrentMeasurementWorldUi() {
        if (this.selectedMeasurementEntity) {
            this.selectedMeasurementEntity.measurementObject.getMeshes().forEach(
                (mesh) => {
                    highlightManager.toggleHighlightMesh(mesh, false);
                }
            );
            this.selectedMeasurementEntity = null;
        }
    }

    onMeshFetched(entity) { // eslint-disable-line
        // Measurable entity part
        if (!entity.isMeasurable) {
            return;
        }

        // Compute the measurement points once the entity has its mesh created
        // Necessary for bounding box purpose
        MeasurableEntityHelper.ComputeObjectMeasurementPoints(entity);
    }

    onEntityAdded(entity) {
        // If entity is a measurable one
        if (entity.isMeasurable) {
            this.measurableEntitiesList.push(entity);
            return;
        }

        // If entity is a measurement entity
        if (entity.__name__.includes("measurement") && !entity.measurementObject) {
            // MeasurementStructure part (arrow)
            const measurementObject = new MeasurementWorldUi(
                engineController.scene
            );
            measurementObject.startingPoint = entity.startPoint;
            measurementObject.endingPoint = entity.endPoint;
            measurementObject.drawLine();
            measurementObject.showMeasurement();
            entity.measurementObject = measurementObject;
            measurementObject.entity = entity;
        }
    }


    onEntityRemoved(entity) {
        if (entity.isMeasurable) {
            const index = this.measurableEntitiesList.indexOf(entity);
            if (index >= 0) {
                this.measurableEntitiesList.splice(index, 1);
            }
        } else if (entity.__name__.includes("measurement") && entity.measurementObject) {
            entity.measurementObject.destroyMeasurementObject();
        }
    }

    toggleMeasureMode() {
        events.emit("loading-measurement-mode");
        // As the operation is heavy and freeze the rendering we delay it a bit to show the loader
        setTimeout(
            () => {
                this.measureModeActivated = !this.measureModeActivated;
                if (!this.measureModeActivated) {
                    events.emit("measurement-mode-closed");
                } else {
                    events.emit("measurement-mode-opened");
                }
                this.measurableEntitiesList.forEach(
                    (entity) => {
                        entity.toggleMeasurementPointsVisibility(this.measureModeActivated);
                    }
                );
                if (this.groundPoint) {
                    this.groundPoint.dispose();
                    this.groundPoint = null;
                }
                this.cancelCurrentLine();
                events.emit("measurement-mode-loaded");
            }, 100
        );

    }

    cancelCurrentLine() {
        if (this.currentMeasurementWorldUi) {
            this.drawingLine = false;
            this.currentMeasurementWorldUi.destroyLine();
            this.currentMeasurementWorldUi = null;
        }
    }

    // /////// VISIBILITY PART ////////////////

    hideAll() {
        this.measurementEntities.forEach((entity) => {
            entity.measurementObject.hide();
        });
    }

    showAll() {
        this.measurementEntities.forEach((entity) => {
            entity.measurementObject.show();
        });
    }

    /*eslint-disable */
    get measurementEntities() {
        return dataStore.listEntities("/measurements");
    }
    /* eslint-enable */


}
