<template>
    <div class="popup">
        <div class="popup-header">
            <h1>{{ title }}</h1>
        </div>
        <div class="popup-body">
            {{ content }}
        </div>
    </div>
</template>

<script>
import self from "../../../index";

export default {
    name: "TextPopup",
    props: {
        title: {
            type: String,
            default: () => "",
        },
        content: {
            type: String,
            default: () => "",
        },
    },
    methods: {
        _(...args) {
            return self.app.modules.stonejs.gettext(...args);
        },
    },
};
</script>

<style lang="scss">
@import "../../../../../style/bematrix.scss";
.popup {
    width: 20%;
    top: 30%;
    margin: 0px auto;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    position: absolute;
    right: 40%;
    z-index: $z-index-poppin;

    .popup-header {
        padding: 10px;
        background: $green;

        h1 {
            font-size: 1.2em;
            text-align: center;
            margin: 0;
            color: #fff;
        }
    }

    .popup-body {
        padding: 40px 20px;
        margin: 0;
        text-align: center;
        vertical-align: middle;
        line-height: 30px;
    }
}
</style>
