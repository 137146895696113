<template>
    <b-navbar
        fixed="bottom"
        class="navbar-pdf-bottom"
        :class="{
            noRightMenu: isExpanded,
        }"
    >
        <!-- Text button -->
        <template v-if="hasPage && canEditAnyPage">
            <b-nav-item
                v-if="isTextFocused"
            >
                <img
                    v-b-tooltip.hover
                    src="assets/icons/remove-text.svg"
                    :title="_('Remove text')"
                    @click="removeText"
                >
            </b-nav-item>
            <b-navbar-nav v-else>
                <b-nav-item
                    :class="{ 'open': textSizePanelOpen }"
                >
                    <template v-if="canEdit2DPage">
                        <img
                            v-if="textSizePanelOpen"
                            src="assets/icons/text-white.svg"
                            @click="textSizePanelOpen = false"
                        >
                        <img
                            v-else
                            v-b-tooltip.hover
                            src="assets/icons/text.svg"
                            :title="_('Add text')"
                            @click="textSizePanelOpen = true"
                        >
                    </template>
                    <template v-else>
                        <img
                            src="assets/icons/text-disabled.svg"
                        >
                    </template>
                </b-nav-item>
                <template v-if="textSizePanelOpen">
                    <b-nav-item
                        :class="`text-size-button ${TEXT_SIZES.LARGE}`"
                        @click="addText(TEXT_SIZES.LARGE)"
                    >
                        <img
                            src="assets/icons/text-large.svg"
                        >
                    </b-nav-item>
                    <b-nav-item
                        :class="`text-size-button ${TEXT_SIZES.MEDIUM}`"
                        @click="addText(TEXT_SIZES.MEDIUM)"
                    >
                        <img
                            src="assets/icons/text-medium.svg"
                        >
                    </b-nav-item>
                    <b-nav-item
                        :class="`text-size-button ${TEXT_SIZES.SMALL}`"
                        @click="addText(TEXT_SIZES.SMALL)"
                    >
                        <img
                            src="assets/icons/text-small.svg"
                        >
                    </b-nav-item>
                </template>
            </b-navbar-nav>
        </template>
        <template
            v-if="hasPage && isScenePage"
        >
            <!-- Screenshot button -->
            <b-nav-item
                v-if="buildingPlanData.currentPage.editing
                    && !measurementController.measureModeActivated"
                class="pdf-bottom-button-container"
            >
                <img
                    v-if="buildingPlanData.isCameraFixed"
                    v-b-tooltip.hover
                    class="alternate-button"
                    :title="_('Re-do screenshot')"
                    src="assets/icons/redo-screenshot-pdf.svg"
                    @click="redoScreenshotSceneImage"
                >
                <img
                    v-else
                    v-b-tooltip.hover
                    :title="_('Screenshot scene')"
                    src="assets/icons/screenshot-pdf.svg"
                    class="screenshot-button"
                    @click="addSceneImage"
                >
            </b-nav-item>
            <b-nav-item
                v-else
                class="pdf-bottom-button-container"
                :class="{ disabled: true }"
            >
                <img
                    v-if="buildingPlanData.isCameraFixed"
                    class="redo-screenshot-button"
                    src="assets/icons/redo-screenshot-pdf-disabled.svg"
                >
                <img
                    v-else
                    class="screenshot-button"
                    src="assets/icons/screenshot-pdf-disabled.svg"
                >
            </b-nav-item>
            <!-- Table button -->
            <b-nav-item
                v-if="buildingPlanData.currentPage.table &&
                    !buildingPlanData.currentPage.table.exists"
                class="pdf-bottom-button-container"
            >
                <img
                    v-if="canEditScenePage"
                    v-b-tooltip.hover
                    :title="_('Add a table')"
                    src="assets/icons/table.svg"
                    @click="addTable"
                >
                <img
                    v-else
                    :class="{ disabled: true }"
                    src="assets/icons/table-disabled.svg"
                >
            </b-nav-item>
            <b-nav-item
                v-else
                class="pdf-bottom-button-container"
            >
                <img
                    v-if="canEditScenePage"
                    v-b-tooltip.hover
                    :title="_('Remove table')"
                    src="assets/icons/remove-table.svg"
                    @click="removeTable"
                >
                <img
                    v-else
                    :class="{ disabled: true }"
                    src="assets/icons/remove-table-disabled.svg"
                >
            </b-nav-item>
            <!-- Annotations button -->
            <b-nav-item
                v-if="mayAddAnnotations"
                class="pdf-bottom-button-container"
            >
                <img
                    v-if="canEditScenePage && canAddAnnotations"
                    v-b-tooltip.hover
                    :title="_('Add annotation')"
                    src="assets/icons/indexed-annotation.svg"
                    @click="addIndexedAnnotations"
                >
                <img
                    v-else
                    :class="{ disabled: true }"
                    src="assets/icons/indexed-annotation-disabled.svg"
                >
            </b-nav-item>
            <b-nav-item
                v-else
                class="pdf-bottom-button-container"
            >
                <img
                    v-if="canEditScenePage && canRemoveAnnotations"
                    v-b-tooltip.hover
                    class="alternate-button"
                    :title="_('Remove annotation')"
                    src="assets/icons/remove-indexed-annotation.svg"
                    @click="removeIndexedAnnotations"
                >
                <img
                    v-else
                    :class="{ disabled: true }"
                    src="assets/icons/indexed-annotation-disabled.svg"
                >
            </b-nav-item>
        </template>
        <template
            v-else-if="hasPage && is2DPage"
        >
            <!-- Upload image button -->
            <b-nav-item
                v-if="isImageFocused"
            >
                <img
                    :title="_('Remove image')"
                    src="assets/icons/remove-image.svg"
                    @click="removeImage"
                >
            </b-nav-item>
            <!-- Using a nav-item here override input default behavior -->
            <div
                v-else
                class="input-image-item"
            >
                <template v-if="canEdit2DPage">
                    <img
                        v-b-tooltip.hover
                        :title="_('Add image')"
                        src="assets/icons/upload-image.svg"
                        @click="openFileManager"
                    >
                    <input
                        ref="imageInput"
                        type="file"
                        accept="image/*"
                        @change="addImage"
                    >
                </template>
                <img
                    v-else
                    src="assets/icons/upload-image-disabled.svg"
                >
            </div>
        </template>
    </b-navbar>
</template>
<script>
import EventBus from "../../eventBus";
import self from "../../../index";

const {
    app: {
        modules: {
            buildingPlanManager: {
                buildingPlanHelper: bpHelper,
                buildingPlanController: bpController,
                buildingPlanImageController: {
                    IMAGE_MODES,
                },
                PAGE_TYPES,
                TEXT_SIZES,
            },
            selectionManager: selectionController,
            stonejs,
            measurementManager: {
                Controller: measurementController,
            },
        },
        log,
    },
} = self;

export default {
    name: "PdfBottomMenu",
    props: {
        buildingPlanData: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            isExpanded: false,
            bpController,
            bpImageController: bpController.imageController,
            selectionController,
            measurementController,
            textSizePanelOpen: false,
            TEXT_SIZES,
        };
    },
    computed: {
        hasPage() {
            return Boolean(bpController.getPage());
        },
        isScenePage() {
            return bpController.getPage().type === PAGE_TYPES.SCENE;
        },
        is2DPage() {
            return bpController.getPage().type === PAGE_TYPES.HTML;
        },
        canEditScenePage() {
            const page = bpController.getPage();
            return this.buildingPlanData.isCameraFixed && page.editing;
        },
        canAddAnnotations() {
            const page = bpController.getPage();
            return page.table.exists && this.selectionController.hasSelection();
        },
        canRemoveAnnotations() {
            return this.selectionController.hasSelection();
        },
        mayAddAnnotations() {
            if (this.selectionController.hasSelection()) {
                const page = bpController.getPage();
                const id = bpHelper.selectionAnnotationId();

                return !page.annotations[id];
            }
            return false;
        },
        canEdit2DPage() {
            const page = bpController.getPage();
            return page.editing;
        },
        isTextFocused() {
            const page = bpController.getPage();
            return Boolean(Object.values(page.texts).find(textObject => textObject.isFocused));
        },
        isImageFocused() {
            const page = bpController.getPage();
            return Boolean(Object.values(page.userImages).find(userImage => userImage.isFocused));
        },
        canEditAnyPage() {
            if (this.isScenePage) {
                return this.canEditScenePage;
            } if (this.is2DPage) {
                return this.canEdit2DPage;
            }
            return false;
        },

    },
    mounted() {
        EventBus.$on("toggle-groups", open => {
            this.isExpanded = !open;
        });
    },
    methods: {
        addSceneImage() {
            if (!bpController.isPageEditable()) {
                log.error("The page you are trying to add an image on is immutable");
                return;
            }
            this.bpImageController.captureImage(IMAGE_MODES.SCENE);
        },
        redoScreenshotSceneImage() {
            bpController.startPageEditing();

            selectionController.unselectAll();
            this.bpController.clearPage();
        },
        addTable() {
            if (!bpController.isPageEditable()) {
                log.error("The page you are trying to add a table on is immutable");
                return;
            }
            bpController.addTable();
            this.bpImageController.captureImage(IMAGE_MODES.HTML);
        },
        removeTable() {
            if (!bpController.isPageEditable()) {
                log.error("The page you are trying to remove the table from is immutable");
                return;
            }
            bpController.removeTable();
            this.bpImageController.captureImage(IMAGE_MODES.HTML);
        },
        addIndexedAnnotations() {
            if (!bpController.isPageEditable()) {
                log.error("The page you are trying to add indexed annotations on is immutable");
                return;
            }
            bpController.addIndexedAnnotations();
        },
        removeIndexedAnnotations() {
            if (!bpController.isPageEditable()) {
                log.error("The page you are trying to remove indexed annotations from is immutable");
                return;
            }
            bpController.removeIndexedAnnotations();
            this.bpImageController.captureImage(IMAGE_MODES.HTML);
        },
        addText(textSize) {
            if (!bpController.isPageEditable()) {
                log.error("The page you are trying to add text on is immutable");
                return;
            }
            bpController.addText(textSize);
            this.bpImageController.captureImage(IMAGE_MODES.HTML);
        },
        removeText() {
            if (!bpController.isPageEditable()) {
                log.error("The page you are trying to remove text from is immutable");
                return;
            }
            bpController.removeText();
            this.bpImageController.captureImage(IMAGE_MODES.HTML);
        },
        addImage(event) {
            if (!bpController.isPageEditable()) {
                log.error("The page you are trying to add an image on is immutable");
                return;

            }
            const file = event.target.files[0];
            const fileReader = new FileReader();
            fileReader.addEventListener("loadend", progressEvent => {
                if (progressEvent.returnValue) {
                    bpController.addImage(fileReader.result);
                    this.bpImageController.captureImage(IMAGE_MODES.HTML);
                } else {
                    log.error("The image cannot be laoded");
                }
            });

            fileReader.readAsDataURL(file);
        },
        removeImage() {
            if (!bpController.isPageEditable()) {
                log.error("The page you are trying to remove an image from is immutable");
                return;
            }

            bpController.removeImage();
            this.bpImageController.captureImage(IMAGE_MODES.HTML);
        },

        openFileManager() {
            this.$refs.imageInput.click();
        },

        _(...args) {
            return stonejs.gettext(...args);
        },
    },
};
</script>
