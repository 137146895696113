import { v4 as uuid } from "uuid";

import self from "../..";

import GroupStructure from "../model/group-structure";

const { events, modules } = self.app;
const {
    dataStore, entityManager, selectionManager, stonejs,
} = modules;

export default class GroupManager {

    constructor() {
        this.createDefaultGroup();
        this.eventsMap = [
            {
                name: "updateVueData",
                callback: () => { this.updateVueData(); },
                eventList: ["product-added-group", "product-removed-group", "cleaned-group",
                    "@data-store.entity-added", "@data-store.entity-removed", "@project-manager.project-loaded"],
            },
            {
                name: "updateAllGroups",
                callback: () => { this.updateAllGroups(); },
                eventList: ["@history.history-go", "@project-manager.project-loaded"],
            },
        ];
        this.vueData = {
            groups: [],
        };
        this.initEvents();
    }

    // TODO Make an event module
    static assignEvent(eventList, callback) {
        eventList.forEach(
            (eventName) => {
                events.on(eventName, callback);
            }
        );
    }

    /**
     * Create the default group
     */
    createDefaultGroup() {
        this.defaultGroup = new GroupStructure();
        this.defaultGroup.default = true;
        this.defaultGroup.name = stonejs.gettext("Default Group");
        dataStore.addEntity(this.defaultGroup, "/groups/default");
    }

    /**
     * Set the default group as the one inside the dataStore
     */
    updateDefaultGroup() {
        this.defaultGroup = dataStore.getEntity("/groups/default")[0];
        this.updateVueData();
    }

    // Create a group
    static createGroup(name = null, lockedName = false) {
        const group = new GroupStructure();
        const index = dataStore.listEntities("/groups").length + 1;
        group.name = `Group ${index}`;
        if (name) {
            group.name = name;
        }
        group.nameLocked = lockedName;
        dataStore.addEntity(group, "/groups");
        return group;
    }

    /**
     * Add a given entity inside a given group
     * @param {Object} entity
     * @param {Object} group
     */
    addEntityToGroup(entity, group = null, useId = true) {
        let oldGroup = null;
        if (entity.group) {
            oldGroup = entity.group;
            entity.group.removeProduct(entity.id, true);
        } else {
            oldGroup = { id: null };
        }
        const newGroup = group || this.defaultGroup;
        if (useId) {
            newGroup.addProduct(entity.id);
        } else {
            newGroup.addProductFromEntity(entity);
        }
        events.emit("entity-added-to-group", newGroup, oldGroup);
    }

    /**
     *  Initialize all events
     */
    initEvents() {
        this.eventsMap.forEach(
            (event) => {
                GroupManager.assignEvent(event.eventList, event.callback);
            }
        );
        events.on("@data-store.entity-removed", (entity) => {
            this.checkRemovedEntity(entity);
        });
        events.on("@data-store.entity-added", (entity) => {
            this.checkAddedEntity(entity);
        });
    }

    /**
     * Ungroup a given group
     * @param {*} group
     */
    ungroup(group) {
        if (group.id === this.defaultGroup.id) {
            // TODO warn message in a popup
            self.app.log.error("You can't ungroup the default group");
            return;
        }
        group.productList.forEach(
            (product) => {
                if (product.mesh.selectMesh) {
                    selectionManager
                        .removeOneMultiSelectMesh(product);
                }
            }
        );
        group.clearProducts();
    }

    /**
     * When an entity is removed from the dataStore, we check if the entity still has a group and
     * if its the case we remove it
     * @param {*} entity - the entity to check
     */
    checkRemovedEntity(entity) {
        if (entityManager.PRODUCT_ENTITIES_NAME.includes(entity.__name__)) {
            if (entity.group) {
                entity.group.removeProduct(entity, true);
                this.updateVueData();
            }
        }
    }

    /**
     * When an entity is added in the dataStore we check if it has a groupId (serialized) but not a
     * group (not serialized). If that's the case we update the infos by setting a group with the
     * entity.groupId to the given entity
     * @param {*} entity - the entity to check
     */
    checkAddedEntity(entity) {
        try {
            if (entityManager.PRODUCT_ENTITIES_NAME.includes(entity.__name__)) {
                if (entity.groupId) {
                    const group = dataStore.getEntity(entity.groupId);
                    if (group) {
                        group.addProduct(entity.id);
                        this.updateVueData();
                    }
                } else {
                    this.defaultGroup.addProduct(entity.id);
                }
            }
        } catch (err) {
            self.app.log.error("Can't add product to a group ", entity.id, err);
        }
    }

    /**
     * Update all the group infos from the dataStore and adapt the serialized info directly to the
     * unserialized infos
     */
    updateAllGroups() {
        this.defaultGroup = dataStore.listEntities("/groups/default")[0];
        const groups = dataStore.listEntities("/groups");
        groups.push(this.defaultGroup);
        groups.forEach(
            (group) => {
                if (!group.nameLocked) {
                    group.nameLocked = true;
                }
                if (group.productList.length !== group.productIDs.length) {
                    // Means that we must update the productList (dynamic parameter)
                    // We clean the product list and then for each productIDs
                    // we bind the real product to the productList array
                    group.updateDynamicParameters();
                }
            }
        );
        this.updateVueData();
    }

    /**
     * Remove a given entity from its group
     * @param {Entity} entity
     */
    removeFromGroup(entity) {
        if (!entity.group || entity.group.id === this.defaultGroup.id) {
            self.app.log.error("entity has no group or its group is default");
            return;
        }
        const group = entity.group;
        group.removeProduct(entity.id);
        events.emit("entity-removed-from-group");
    }

    getDefaultGroup() {
        if (!this.defaultGroup) {
            this.createDefaultGroup();
        }
        return this.defaultGroup;
    }

    /**
     * List all the existant groups
     */
    listGroups() {
        const allGroups = [this.defaultGroup];
        return allGroups.concat(dataStore.listEntities("/groups"));
    }

    /**
     * Get all groups that have the given name
     * @param {String} groupName
     */
    getGroupFromName(groupName) {
        const allGroups = this.listGroups();
        const seekedGroup = allGroups.find(group => group.name === groupName);
        return seekedGroup;
    }

    /**
     * Check if an other group with the same name exists
     * In that case, rename the group in parameter so it gets an
     * unique name.
     * @param  {GroupStructure} group
     */
    checkNamesDuplicates(group) {
        const allGroups = this.listGroups();
        let sameName = null;
        let iter = 0;
        do {
            sameName = allGroups.find(
                g => g !== group && g.name === group.name
            );
            if (sameName) {
                const split = group.name.split("-");
                let nameChanged = false;
                if (split.length) {
                    const index = parseInt(split[split.length - 1], 10);
                    if (!Number.isNaN(index)) {
                        split.pop();
                        group.name = `${split.join("-")}-${index + 1}`;
                        nameChanged = true;
                    }
                }
                if (!nameChanged) {
                    group.name = `${group.name}-2`;
                }
                iter += 1;
                if (iter > 500) { // defensive programming.
                    group.name = `${group.name}-${uuid()}`;
                    sameName = false;
                }
            }
        } while (sameName);
    }

    updateVueData() {
        this.vueData.groups.splice(0);
        this.vueData.groups.push(this.defaultGroup);
        dataStore.listEntities("/groups").forEach(
            (group) => {
                this.vueData.groups.push(group);
            }
        );
    }

    getVueData() {
        return this.vueData;
    }

}
