import { Vector3, Quaternion } from "@babylonjs/core";

import VueHelper from "helpers/vue-helper";

import OptionUtility from "../utility/option-utility";
import OptionableMixin from "../model/optionable-mixin";

import BaseplateController from "./baseplate-option-controller";
import GroundplateController from "./groundplate-option-controller";
import LightOptionController from "./light-option-controller";
import ScreenBracketOptionController from "./screen-bracket-option-controller";
import ShelfOptionController from "./shelf-option-controller";
import InfillOptionController from "./infill-option-controller";

import self from "../index";

const {
    modules: {
        catalogManager,
        history,
        dataStore,
    },
    events,
} = self.app;

export default class OptionController {

    constructor() {
        this.catalogManager = catalogManager;
        this.baseplateController = new BaseplateController(this);
        this.groundplateController = new GroundplateController(this);
        this.lightController = new LightOptionController(this);
        this.shelfController = new ShelfOptionController(this);
        this.infillController = new InfillOptionController(this);
        this.screenBracketController = new ScreenBracketOptionController(this);

        this.optionsFamilies = {
            NONE: 0,
            SCREEN: 1,
            BASEPLATE: 2,
            GROUNDPLATE: 3,
            LIGHT: 4,
            SHELF: 8,
            INFILL: 16,
            ALL: 31,
        };

        this.currentEntity = null;
        this.initializeEvents();
        this.initVueData();

        // Get showInfills state of the loaded project
        ["@project-manager.project-loaded", "@history.history-go"]
            .forEach(event => {
                events.on(event, () => {
                    this.initShowInfills();
                });
            });
    }

    initVueData() {
        this.vueData = {
            optionsFamilies: this.optionsFamilies,
            infillsVisibilityLocked: false,
        };

        VueHelper.AddVueProperty(this, "showInfills", true);
    }

    initShowInfills() {
        const entities = dataStore.listEntities("/products/*");
        // Take only on optionable entities and check infills visibility
        this.showInfills = entities
            .filter(entity => entity.isOptionable === true)
            .every(entity => entity.optionsVisibility.infills === true);
        this.infillsVisibilityLocked = !this.showInfills;
    }

    initializeEvents() {
        events.on("@selection-manager.select-entity", entity => {
            this.currentEntity = entity;
        });

        events.on("@selection-manager.unselect-entity", () => {
            this.currentEntity = null;
        });

        const updateOptionEvents = ["@gizmo-manager.dragging-gizmo", "@obsidian-engine.dragging-mesh",
            "@gizmo-manager.mesh-rotate", "@selection-manager.duplicate",
            "@obsidian-engine.drag-start-mesh", "@obsidian-engine.drag-start-select"];

        updateOptionEvents.forEach(
            event => {
                events.on(event, () => {
                    if (this.currentEntity) {
                        if (this.currentEntity.isOptionable) {
                            this.updateCurrentEntityCanHaveOptions();
                            this.baseplateController.updateCanHaveBaseplate();
                            this.groundplateController.updateCanHaveGroundplate();
                            this.lightController.updateCanHaveLights();
                            this.shelfController.updateMaxShelfNumber();
                            this.lightController.updateMaxLightNumber();
                            this.screenBracketController.updateMaxScreenNumber();
                        }
                    }
                });
            }
        );

        events.on("@snapping.snap", paramsSnapping => {
            // When drag and dropping
            if (!this.currentEntity || !paramsSnapping.snappedEntity.isOptionable) {
                return;
            }
            this.currentEntity.canHaveOptions = this.canHaveOptions();
            // If we snapped on top a of the snapped frame,
            // check if the snapping mesh is obstructing the lights
            this.lightController.updateCanHaveLights(paramsSnapping.snappedEntity,
                [paramsSnapping.snappingEntity]);
            this.shelfController.updateMaxShelfNumber(paramsSnapping.snappedEntity, false);
            this.lightController.updateMaxLightNumber(paramsSnapping.snappedEntity);
            this.screenBracketController.updateMaxScreenNumber(paramsSnapping.snappedEntity);
        });

        events.on("@snapping.ghost", () => {
            this.toggleOptionsVisibility(
                false,
                {
                    entity: this.currentEntity,
                }
            );
        });

        events.on("@snapping.unghost", () => {
            this.toggleOptionsVisibility(
                true,
                {
                    entity: this.currentEntity,
                }
            );
        });

        events.on("@history.history-go", () => {
            const entities = dataStore.listEntities("/products/default");
            entities.forEach(
                entity => {
                    if (entity.isOptionable && entity.mesh) {
                        entity.mesh.getChildren().forEach(
                            childMesh => {
                                if (childMesh.name === "baseplate"
                                    || childMesh.name === "light"
                                    || childMesh.name === "shelf"
                                    || childMesh.name === "screen"
                                    || childMesh.name === "infill") {
                                    childMesh.dispose();
                                }
                            }
                        );
                        if (entity.isOptionable) {
                            this.updateAllOptionMeshes(entity);
                        }
                    }
                }
            );
        });

        // Called when loading an entity that need an option
        events.on("@entity-manager.mesh-fetched", entity => {
            entity.canHaveOptions = this.canHaveOptions(entity);
            if (entity.canHaveOptions) {
                if (entity.baseplateOption) {
                    this.baseplateController.updateCanHaveBaseplate(entity);
                    if (entity.canHaveBaseplatesOption) {
                        entity.asynchronousJobs.push(
                            this.addOption(
                                this.optionsFamilies.BASEPLATE,
                                entity.baseplateOption,
                                entity
                            )
                        );
                    } else {
                        this.removeOption(this.optionsFamilies.BASEPLATE, entity);
                    }
                }
                if (entity.groundplateOption) {
                    this.groundplateController.updateCanHaveGroundplate(entity);
                    if (entity.canHaveGroundplatesOption) {
                        entity.asynchronousJobs.push(
                            this.addOption(
                                this.optionsFamilies.GROUNDPLATE,
                                entity.groundplateOption,
                                entity
                            )
                        );
                    } else {
                        this.removeOption(this.optionsFamilies.GROUNDPLATE, entity);
                    }
                }
                if (entity.lightOption) {
                    this.lightController.updateMaxLightNumber(entity);
                    entity.asynchronousJobs.push(
                        this.addOption(this.optionsFamilies.LIGHT, entity.lightOption, entity)
                    );
                }
                if (entity.shelfOption) {
                    this.shelfController.updateMaxShelfNumber(entity, true);
                    entity.asynchronousJobs.push(
                        this.addOption(this.optionsFamilies.SHELF, entity.shelfOption, entity)
                    );
                }
                if (entity.screenOption) {
                    this.screenBracketController.updateMaxScreenNumber(entity);
                    entity.asynchronousJobs.push(
                        this.addOption(this.optionsFamilies.SCREEN, entity.screenOption, entity)
                    );
                }
            } else {
                entity.baseplateOption = 0;
                entity.lightOption = 0;
                entity.shelfOption = 0;
                entity.screenOption = 0;
            }

            if (entity.infillOption) {
                entity.asynchronousJobs.push(
                    this.addOption(this.optionsFamilies.INFILL, entity.infillOption, entity)
                );
            }
            if (entity.forceBaseplate) {
                entity.forceBaseplate = false;
            }
        });

        events.on("@entity-manager.mesh-disposed", entity => {
            this.removeOption(this.optionsFamilies.ALL, entity);
        });

        events.on("@project-manager.init-frame", entity => {
            this.updateCurrentEntityCanHaveOptions();
            this.shelfController.updateMaxShelfNumber(entity, true);
            this.lightController.updateMaxLightNumber(entity);
            this.screenBracketController.updateMaxScreenNumber(entity);
            entity.canHaveOptions = this.canHaveOptions(entity);
        });
    }

    /**
     * Can this entity have options :
     * Does it stick the ground
     * Is it aligned with the ground
     * @param {*} entity
     */
    canHaveOptions(entity = null) {
        const realEntity = this.returnRealEntity(entity);
        if (!realEntity) {
            return null;
        }
        const mesh = realEntity.mesh;
        return OptionUtility.isAlignedWithGround(mesh);
    }

    /**
     * If entity is null, returns engine.entity, otherwise return null and log an error
     * @param {*} entity
     */
    returnRealEntity(entity) {
        let realEntity = entity;

        if (!realEntity) {
            if (!this.currentEntity
                || !this.currentEntity.optionsMeshes) {
                realEntity = null;
            } else {
                realEntity = this.currentEntity;
            }
        } else if (!realEntity.isOptionable) {
            realEntity = null;
        }
        return realEntity;
    }

    /**
     * Add an option _optionFlag of type _type
     * @param {number} optionFlag
     * @param {*} type
     * @param {*} entity
     */
    addOption(optionFlag, type, entity = null, snapshot = false) {
        const realEntity = this.returnRealEntity(entity);
        try {
            if (realEntity) {
                let prom = null;
                events.emit("update-option");
                if (optionFlag === this.optionsFamilies.BASEPLATE) {
                    prom = this.baseplateController.addBaseplate(type, realEntity);
                } else if (optionFlag === this.optionsFamilies.GROUNDPLATE) {
                    prom = this.groundplateController.addGroundplate(type, realEntity);
                } else if (optionFlag === this.optionsFamilies.LIGHT) {
                    prom = this.lightController.addLights(realEntity);
                } else if (optionFlag === this.optionsFamilies.SHELF) {
                    prom = this.shelfController.addShelves(type, realEntity);
                } else if (optionFlag === this.optionsFamilies.SCREEN) {
                    prom = this.screenBracketController.addScreen(type, realEntity);
                } else if (optionFlag === this.optionsFamilies.INFILL) {
                    prom = this.infillController.addInfills(type, realEntity);
                }
                if (snapshot) {
                    prom.then(history.snapshot());
                }
                if (prom) {
                    return prom.then(() => { events.emit("end-update-option"); });
                }
                events.emit("end-update-option");
            }
        } catch (err) {
            events.emit("end-update-option");
            throw err;
        }
        return Promise.resolve(null);
    }

    /**
     * Add an option _optionFlag of type _type to every entity in given
     * entity list _entitiesList
     * @param {number} optionFlag
     * @param {*} type
     * @param {[entity]} entitiesList
     */
    multiEntityAddOption(optionFlag, type, entitiesList) {
        entitiesList.forEach(
            entity => {
                this.addOption(optionFlag, type, entity, false);
            }
        );
        history.snapshot();
    }

    /**
     * Removes the _optionName option of the selected frame
     * @param {*} optionFlag default to this.optionsFamilies.ALL
     */
    removeOption(optionFlag = this.optionsFamilies.ALL, entity = null, snapshot = false) {
        const realEntity = this.returnRealEntity(entity);

        if (realEntity) {
            if ((optionFlag & this.optionsFamilies.BASEPLATE) === this.optionsFamilies.BASEPLATE) {
                this.baseplateController.removeBaseplate(realEntity);
            }
            if ((optionFlag & this.optionsFamilies.GROUNDPLATE)
                === this.optionsFamilies.GROUNDPLATE) {
                this.groundplateController.removeGroundplate(realEntity);
            }
            if ((optionFlag & this.optionsFamilies.LIGHT) === this.optionsFamilies.LIGHT) {
                this.lightController.removeLight(realEntity);
            }
            if ((optionFlag & this.optionsFamilies.SHELF) === this.optionsFamilies.SHELF) {
                this.shelfController.removeShelves(realEntity);
            }
            if ((optionFlag & this.optionsFamilies.SCREEN) === this.optionsFamilies.SCREEN) {
                this.screenBracketController.removeScreens(realEntity);
            }
            if ((optionFlag & this.optionsFamilies.INFILL) === this.optionsFamilies.INFILL) {
                this.infillController.removeInfill(realEntity);
            }
            if (snapshot) {
                history.snapshot();
            }
        }
    }

    /**
     * Remove an option _optionFlag of type _type to every entity in given
     * entity list _entitiesList
     * @param {number} optionFlag
     * @param {*} type
     * @param {[entity]} entitiesList
     */
    multiEntityRemoveOption(optionFlag, entitiesList) {
        entitiesList.forEach(
            entity => {
                this.removeOption(optionFlag, entity, false);
            }
        );
        history.snapshot();
    }

    /**
     * Swap the side of the given entity's given option
     * @param {Number} optionFlag - The option that will be swapped
     * @param {Entity} entity - The entity checked
     * @param {Boolean} snapshot - If we need a snapshot after the swap
     */
    swapOptionsSide(optionFlag, entity, snapshot = true) {
        const entityToRotate = this.returnRealEntity(entity);
        const optionName = this.getOptionFlagName(optionFlag);
        entityToRotate.swappedOptions[optionName] = !entityToRotate.swappedOptions[optionName];
        this.swapOptionsMeshesSide(optionFlag, entityToRotate);
        if (snapshot) history.snapshot();
    }

    /**
     * Swap the side of the given option on every entity in given
     * entity list _entitiesList
     * @param {number} optionFlag
     * @param {[entity]} entitiesList
     */
    multiEntitySwapOptionsSide(optionFlag, entitiesList) {
        entitiesList.forEach(
            entity => {
                this.swapOptionsSide(optionFlag, entity, false);
            }
        );
        history.snapshot();
    }

    /**
     * Rotate the option around the up axis of the world, of 180 degrees
     * @param {*} optionFlag
     * @param {*} entity
     */
    swapOptionsMeshesSide(optionFlag, entity) {
        const quaternion = Quaternion.RotationAxis(Vector3.Forward(), Math.PI);

        if (optionFlag === this.optionsFamilies.BASEPLATE) {
            entity.optionsMeshes.baseplates[0].rotationQuaternion
                .multiplyInPlace(quaternion);
        }
        if (optionFlag === this.optionsFamilies.LIGHT) {
            entity.optionsMeshes.lights.forEach(lightMesh => {
                lightMesh.rotationQuaternion.multiplyInPlace(quaternion);
            });
        }
        if (optionFlag === this.optionsFamilies.SHELF) {
            entity.optionsMeshes.shelves.forEach(shelf => {
                // As we use a pivot to rotate the shelves we apply the rotation to the shelf parent
                // which is a pivot
                shelf.parent.rotationQuaternion.multiplyInPlace(quaternion);
            });
        }
        if (optionFlag === this.optionsFamilies.SCREEN) {
            entity.optionsMeshes.screens.forEach(screen => {
                screen.rotationQuaternion.multiplyInPlace(quaternion);
            });
        }
        if (optionFlag === this.optionsFamilies.INFILL) {
            this.infillController.swapInfillSide(entity);
        }
    }

    /**
     * Check if the given option can be swapped
     * @param {*} optionFamily - the family of option we want to check
     * @param {*} optionType - the current option type
     */
    isSwappable(optionFamily, optionType) {
        if (optionFamily === this.optionsFamilies.BASEPLATE
            && optionType === OptionableMixin.BASEPLATE_OPTION.ONE_SIDE) {
            return true;
        }
        if (optionFamily === this.optionsFamilies.LIGHT
            && optionType !== OptionableMixin.LIGHT_OPTION.NONE) {
            return true;
        }
        if (optionFamily === this.optionsFamilies.SHELF) {
            return true;
        }
        if (optionFamily === this.optionsFamilies.SCREEN) {
            return true;
        }
        if (optionFamily === this.optionsFamilies.INFILL
            && optionType === OptionableMixin.INFILL_OPTION.ONE_FACE) {
            return true;
        }

        return false;
    }

    /**
     * Check if the current entity can have options, if not remove them all
     */
    updateCurrentEntityCanHaveOptions() {
        if (this.currentEntity) {
            this.currentEntity.canHaveOptions = this.canHaveOptions();
            if (!this.currentEntity.canHaveOptions && this.haveOptions(this.currentEntity)) {
                this.removeOption(this.optionsFamilies.ALL ^ this.optionsFamilies.INFILL);
            }
        }
    }

    /**
     * Called when an option is updated on the UI and take a snapshot
     * @param {*} optionFamily
     */
    onUpdateOptionNumberFromUi(optionFamily) {
        if (this.currentEntity) {
            if ((optionFamily === this.optionsFamilies.LIGHT
                && this.currentEntity.lightOption
                    !== OptionableMixin.LIGHT_OPTION.NONE)
                || (optionFamily === this.optionsFamilies.SHELF
                    && this.currentEntity.shelfOption
                    !== OptionableMixin.SHELF_OPTION.NONE)
                || (optionFamily === this.optionsFamilies.SCREEN
                    && this.currentEntity.screenOption
                    !== OptionableMixin.SCREEN_OPTION.NONE)) {
                history.snapshot();
            }
        }
    }

    /**
     * Return the option flag key name from its corresponding number
     * @param  {Number} optionFlag [
     * @return {String}
     */
    getOptionFlagName(optionFlag) {
        let optionName = null;
        Object.keys(this.optionsFamilies).some(key => {
            if (this.optionsFamilies[key] === optionFlag) {
                optionName = key;
                return true;
            }
            return false;
        });
        return optionName;
    }

    updateAllOptionMeshes(entity = null) {
        entity.computeOptionsParameters();
        let promises = [];
        if (entity.canHaveOptions) {
            promises = [this.baseplateController
                .updateBaseplateMeshes(entity, entity.baseplateOption),
            this.groundplateController
                .updateGroundplateMeshes(entity, entity.groundplateOption),
            this.lightController
                .updateLightMeshes(entity, entity.lightOption),
            this.shelfController
                .updateShelvesMeshes(entity, entity.shelfOption),
            this.screenBracketController
                .updateScreensMeshes(entity, entity.screenOption)];
        }
        promises.push(this.infillController
            .updateInfillMeshes(entity, entity.infillOption));
        return Promise.all(promises);
    }

    /**
     * Return the meshes corresponding to the optionFamily in arguments
     * @param {Number} optionFamily
     * @param {Object} entity
     */
    getOptionMeshes(optionFamily, entity = null) {
        const realEntity = this.returnRealEntity(entity);

        if (realEntity) {
            if (optionFamily === this.optionsFamilies.BASEPLATE) {
                return realEntity.optionsMeshes.baseplates;
            }
            if (optionFamily === this.optionsFamilies.GROUNDPLATE) {
                return realEntity.optionsMeshes.groundplates;
            }
            if (optionFamily === this.optionsFamilies.LIGHT) {
                return realEntity.optionsMeshes.lights;
            }
            if (optionFamily === this.optionsFamilies.SHELF) {
                return realEntity.optionsMeshes.shelves;
            }
            if (optionFamily === this.optionsFamilies.SCREEN) {
                return realEntity.optionsMeshes.screens;
            }
            if (optionFamily === this.optionsFamilies.INFILL) {
                return realEntity.optionsMeshes.infills;
            }
        }

        return null;
    }

    /**
     * Toggle all options marked in @optionFlag visibility's
     * @param {*} isVisible
     * @param {*} optionFlag
     * @param {*} entity
     */
    toggleOptionsVisibility(isVisible, { optionFlag = this.optionsFamilies.ALL, entity = null }) {
        const realEntity = this.returnRealEntity(entity);

        if (realEntity) {
            if ((optionFlag & this.optionsFamilies.BASEPLATE) === this.optionsFamilies.BASEPLATE) {
                this.baseplateController.toggleBaseplatesVisibility(isVisible, realEntity);
            }
            if ((optionFlag & this.optionsFamilies.LIGHT) === this.optionsFamilies.LIGHT) {
                this.lightController.toggleLightsVisibility(isVisible, realEntity);
            }
            if ((optionFlag & this.optionsFamilies.SHELF) === this.optionsFamilies.SHELF) {
                this.shelfController.toggleShelvesVisibility(isVisible, realEntity);
            }
            if ((optionFlag & this.optionsFamilies.SCREEN) === this.optionsFamilies.SCREEN) {
                this.screenBracketController.toggleScreensVisibility(isVisible, realEntity);
            }
            if ((optionFlag & this.optionsFamilies.INFILL) === this.optionsFamilies.INFILL
                && !this.infillsVisibilityLocked) {
                this.infillController.toggleInfillsVisibility(isVisible, realEntity);
            }
        }
    }

    /**
     * Set all options marked by @optionFlag visibility's
     * @param {*} optionVisibility
     * @param {*} optionFlag
     */
    setAllOptionsVisibility(optionVisibility, optionFlag = this.optionsFamilies.ALL) {
        if (optionFlag) {
            if ((optionFlag & this.optionsFamilies.INFILL) === this.optionsFamilies.INFILL) {
                this.infillController.setAllInfillsVisibility(optionVisibility);
            }
        }
    }

    /**
     * Get all the options info of a given entity
     * @param {Entity} entity
     */
    getEntityOptions(entity = null) {
        const realEntity = this.returnRealEntity(entity);
        if (!realEntity) {
            return {};
        }

        return {
            get baseplateOption() { return realEntity.baseplateOption; },
            get groundplateOption() { return realEntity.groundplateOption; },
            get lightOption() { return realEntity.lightOption; },
            get shelfOption() { return realEntity.shelfOption; },
            get screenOption() { return realEntity.screenOption; },
            get infillOption() { return realEntity.infillOption; },
        };
    }

    getEntityOptionsNumber(entity = null) {
        const realEntity = this.returnRealEntity(entity);
        return {
            get screenNumber() { return realEntity.screenNumber; },
            get shelfNumber() { return realEntity.shelfNumber; },
            get lightNumber() { return realEntity.lightNumber; },
        };
    }

    getOptionFamilyFromKey(key) {
        switch (key) {
        case "baseplateOption":
            return this.optionsFamilies.BASEPLATE;
        case "groundplateOption":
            return this.optionsFamilies.GROUNDPLATE;
        case "lightOption":
            return this.optionsFamilies.LIGHT;
        case "shelfOption":
            return this.optionsFamilies.SHELF;
        case "screenOption":
            return this.optionsFamilies.SCREEN;
        case "infillOption":
            return this.optionsFamilies.INFILL;
        default:
            return this.optionsFamilies.NONE;
        }
    }

    /**
     * Returns true if the entity have one ore more options
     * If no value is passed we use the currentEntity of the app
     * @param {*} entity
     */
    haveOptions(entity = null) {
        const realEntity = this.returnRealEntity(entity);
        if (!realEntity) {
            return false;
        }

        if (realEntity.optionsMeshes) {
            if (realEntity.baseplateOption !== OptionableMixin.BASEPLATE_OPTION.NONE) {
                return true;
            } if (realEntity.lightOption !== OptionableMixin.LIGHT_OPTION.NONE) {
                return true;
            } if (realEntity.shelfOption !== OptionableMixin.SHELF_OPTION.NONE) {
                return true;
            } if (realEntity.screenOption !== OptionableMixin.SCREEN_OPTION.NONE) {
                return true;
            } if (realEntity.infillOption !== OptionableMixin.INFILL_OPTION.NONE) {
                return true;
            }
        }

        return false;
    }

    /**
     * Returns the reference of the optionFlag passed in args
     * If there is no option it returns null
     * If no value is passed we use the currentEntity of the app
     * @param {*} optionFlag
     * @param {*} entity
     */
    getOptionReference(optionFlag, entity) {
        const realEntity = this.returnRealEntity(entity);
        if (!realEntity) {
            return null;
        }

        if (optionFlag === this.optionsFamilies.BASEPLATE
            && realEntity.baseplateOption !== OptionableMixin.BASEPLATE_OPTION.NONE) {
            return this.baseplateController.getBaseplateRef(realEntity);
        }

        if (optionFlag === this.optionsFamilies.GROUNDPLATE
            && realEntity.groundplateOption !== OptionableMixin.GROUNDPLATE_OPTION.NONE) {
            return this.groundplateController.getGroundplateRef(realEntity);
        }

        if (optionFlag === this.optionsFamilies.LIGHT
            && realEntity.lightOption !== OptionableMixin.LIGHT_OPTION.NONE) {
            return this.lightController.getLightRef(realEntity.lightOption);
        }

        if (optionFlag === this.optionsFamilies.SHELF
            && realEntity.shelfOption !== OptionableMixin.SHELF_OPTION.NONE) {
            return this.shelfController.getShelfRef(realEntity);
        }

        if (optionFlag === this.optionsFamilies.SCREEN
            && realEntity.screenOption !== OptionableMixin.SCREEN_OPTION.NONE) {
            return this.screenBracketController.getScreenRef(realEntity);
        }

        if (optionFlag === this.optionsFamilies.INFILL
            && realEntity.infillOption !== OptionableMixin.INFILL_OPTION.NONE) {
            return this.infillController.getInfillReference(realEntity);
        }

        return null;
    }

    /**
     * Use the passed reference to check what family this reference is a part of
     * Once the reference option family found we return the option family and option type
     * @param {*} ref option reference
     * @return {*} object containing optionFamily and optionFamily
     */
    getOptionTypeFromReference(ref) {
        if (this.baseplateController.isBaseplateRef(ref)) {
            return {
                optionFamily: this.optionsFamilies.BASEPLATE,
                optionType: this.baseplateController.getBaseplateTypeFromRef(ref),
            };
        }
        if (this.groundplateController.isGroundplateRef(ref)) {
            return {
                optionFamily: this.optionsFamilies.GROUNDPLATE,
                optionType: this.groundplateController.getGroundplateTypeFromRef(ref),
            };
        }
        if (this.shelfController.isShelfRef(ref)) {
            return {
                optionFamily: this.optionsFamilies.SHELF,
                optionType: this.shelfController.getShelfTypeFromRef(ref),
            };
        }
        if (this.screenBracketController.isScreenRef(ref)) {
            return {
                optionFamily: this.optionsFamilies.SCREEN,
                optionType: this.screenBracketController.getScreenTypeFromRef(ref),
            };
        }
        if (this.lightController.isLightRef(ref)) {
            return {
                optionFamily: this.optionsFamilies.LIGHT,
                optionType: 1, // We cannot found the light option type from the reference
            };
        }
        if (this.infillController.infillHelper.isInfillRef(ref)) {
            return {
                optionFamily: this.optionsFamilies.INFILL,
                optionType: 1, // We cannot found the infill option type from the reference
            };
        }
        return {
            optionFamily: this.optionsFamilies.NONE,
            optionType: 0,
        };
    }

}
