<template>
    <b-navbar
        class="color-picker-navbar"
        :class="{
            noRightMenu: isExpanded,
            noLeftMenu: isPublic,
        }"
    >
        <b-navbar-nav>
            <template v-if="ralOnly">
                <compact-picker
                    :value="colorableEntity.color"
                    :palette="palette"
                    @input="updateColor"
                />
            </template>
            <template v-else>
                <b-button
                    class="option-type-button color-mode-buttons"
                    @click="setEcoMaterial"
                >
                    ECO
                </b-button>
                <b-button
                    v-if="!isRalMode"
                    class="option-type-button color-mode-buttons"
                    @click="updateColor(colors)"
                >
                    RAL
                </b-button>
                <compact-picker
                    v-else
                    :value="colorableEntity.color"
                    :palette="palette"
                    @input="updateColor"
                />
            </template>
        </b-navbar-nav>
    </b-navbar>
</template>

<script>
import RightsHelper from "helpers/rights-helper";
import { Compact } from "vue-color";
import EventBus from "../eventBus";

const defaultProps = {
    hex: "#A5A5A5",
};

export default {
    components: {
        "compact-picker": Compact,
    },
    props: {
        palette: {
            type: Array,
            default: () => [],
        },
        colorableEntity: {
            type: Object,
            default: () => ({}),
        },
        config: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            colors: defaultProps,
            isExpanded: false,
            isPublic: RightsHelper.isModePublic(),
            isRalMode: Boolean(this.colorableEntity.color),
            ralOnly: this.colorableEntity.ralOnly,
        };
    },
    mounted() {
        EventBus.$on("toggle-groups", (open) => {
            this.isExpanded = !open;
        });
    },
    methods: {
        updateColor(color) {
            this.colorableEntity.changeColor(color.hex);
            this.isRalMode = true;
        },
        setEcoMaterial() {
            this.colorableEntity.setEcoMaterial();
            this.isRalMode = false;
        },
    },
};
</script>
