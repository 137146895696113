<template>
    <div
        ref="image-container"
        class="user-image-container"
        :class="[
            localUserImageData.isFocused ? 'focused' : '',
        ]"
    >
        <div
            v-if="localUserImageData.isFocused"
            class="resize-handle top-left"
            @mousedown="resizeStart({ directionX: -1, directionY: -1})"
        />
        <div
            v-if="localUserImageData.isFocused"
            class="resize-handle top-right"
            @mousedown="resizeStart({ directionX: 1, directionY: -1})"
        />
        <img
            ref="image"
            class="user-image"
            :class="[
                localUserImageData.isFocused ? 'focused' : '',
                dragging ? 'dragging' : '',
            ]"
            draggable="false"
            :src="userImageData.binaryImageContent"
            @click.capture.stop="bpController.isPageEditable() && focus()"
        >
        <div
            v-if="localUserImageData.isFocused"
            class="resize-handle bottom-left"
            @mousedown="resizeStart({ directionX: -1, directionY: 1})"
            @mouseup="resizeEnd"
        />
        <div
            v-if="localUserImageData.isFocused"
            class="resize-handle bottom-right"
            @mousedown="resizeStart({ directionX: 1, directionY: 1})"
            @mouseup="resizeEnd"
        />
    </div>
</template>
<script>
import self from "../../../index";

import EventBus from "../../eventBus";

const {
    app: {
        modules: {
            buildingPlanManager: {
                buildingPlanController: bpController,
                buildingPlanImageController: {
                    IMAGE_MODES,
                },
            },
        },
        events: {
            on,
            removeListener,
        },
    },
} = self;

export default {
    props: {
        userImageData: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            bpController,
            localUserImageData: this.userImageData,
            dragging: false,
            resizing: false,
            canvasContainer: null,
            startOffsetX: 0,
            startOffsetY: 0,
            draggingVectorX: 0,
            draggingVectorY: 0,

            imageElement: null,
            imageContainerElement: null,
        };
    },
    mounted() {
        this.imageContainerElement = this.$refs["image-container"];
        this.imageContainerElement.style.left = this.userImageData.position.x;
        this.imageContainerElement.style.top = this.userImageData.position.y;

        this.imageElement = this.$refs.image;
        this.imageElement.width = this.userImageData.width;

        this.canvasContainer = document.getElementById("canvas-container");

        EventBus.$on("user-image-focus", this.onOtherUserImageFocus);

        // Wait the image to be rendered
        this.$nextTick(() => {
            this.imageRatio = this.imageElement.naturalWidth / this.imageElement.naturalHeight;
        });
    },
    beforeDestroy() {
        this.blur();

        EventBus.$off("user-image-focus", this.onOtherUserImageFocus);
    },
    methods: {
        onOtherUserImageFocus(targetElement) {
            if (targetElement !== this.$el) {
                this.onClickOutside();
            }
        },
        onClickOutside() {
            this.blur();
            bpController.imageController.captureImage(IMAGE_MODES.HTML);
        },

        addClickOutsideListeners() {
            this.canvasContainer.addEventListener("click", this.onClickOutside);
            on("@obsidian-engine.unselect", this.onClickOutside);
        },
        removeClickOutsideListeners() {
            this.canvasContainer.removeEventListener("click", this.onClickOutside);
            removeListener("@obsidian-engine.unselect", this.onClickOutside);
        },

        focus() {
            if (!this.userImageData.isFocused) {
                this.localUserImageData.isFocused = true;

                EventBus.$emit("user-image-focus", this.$el);

                // Not done on template side because mousedown shadows click event
                this.imageElement.addEventListener("mousedown", this.dragStart);
                this.imageElement.addEventListener("mouseup", this.dragEnd);

                this.addClickOutsideListeners();
            }
        },
        blur() {
            if (this.localUserImageData.isFocused) {
                this.localUserImageData.isFocused = false;

                this.imageElement.removeEventListener("mousedown", this.dragStart);
                this.imageElement.removeEventListener("mouseup", this.dragEnd);

                this.removeClickOutsideListeners();
            }

            if (this.dragging) {
                this.dragEnd();
            }

            if (this.resizing) {
                this.resizeEnd();
            }
        },


        // Dragging
        dragStart(event) {
            this.dragging = true;
            this.userImageData.isFocused = true;

            // Initial offset on the drag handle
            this.startOffsetX = event.offsetX;
            this.startOffsetY = event.offsetY;

            document.body.addEventListener("mousemove", this.drag);
        },
        drag(event) {
            const offsetX = this.canvasContainer.offsetLeft + this.startOffsetX;
            const offsetY = this.canvasContainer.offsetTop + this.startOffsetY;

            // Subtract the canvas offset in the window + the drag handle initial offset from
            // the mouse position in the window
            const positionX = event.clientX - offsetX;
            const positionY = event.clientY - offsetY;

            this.imageContainerElement.style.left = positionX;
            this.imageContainerElement.style.top = positionY;
            this.localUserImageData.position.x = positionX;
            this.localUserImageData.position.y = positionY;
        },
        dragEnd() {
            document.body.removeEventListener("mousemove", this.drag);
            this.dragging = false;

            this.startOffsetX = 0;
            this.startOffsetY = 0;
        },


        // Resizing
        resizeStart({ directionX, directionY }) {
            document.body.addEventListener("mousemove", this.resize);
            document.body.addEventListener("mouseup", this.resizeEnd);

            this.removeClickOutsideListeners();

            this.resizing = true;

            this.draggingVectorX = directionX;
            this.draggingVectorY = directionY;
        },
        // The element resized is the
        resize(event) {
            const heightBeforeResize = this.imageElement.height;
            const xSign = Math.sign(this.draggingVectorX);

            // Resizing on the X axis
            if (this.draggingVectorX < 0) { // Image grow to the left
                const newLeft = Number.parseInt(this.imageContainerElement.style.left, 10)
                     + event.movementX;

                this.imageContainerElement.style.left = `${newLeft}px`;
            }

            this.imageElement.width += xSign * event.movementX;


            // Resizing on the Y axis
            if (this.draggingVectorY < 0) { // Image grow to the top
                const heightDiff = heightBeforeResize - this.imageElement.height;
                const newTop = Number.parseInt(this.imageContainerElement.style.top, 10)
                         + heightDiff;

                this.imageContainerElement.style.top = `${newTop}px`;
            }

            this.localUserImageData.width = this.imageElement.width;
        },
        resizeEnd() {
            document.body.removeEventListener("mousemove", this.resize);
            document.body.removeEventListener("mouseup", this.resizeEnd);

            setTimeout(this.addClickOutsideListeners, 0);

            this.resizing = false;
        },
    },
};
</script>
<style lang="scss">
@import "../../../../../style/bematrix";

.user-image-container {
    position: absolute;
    top: 0;
    left: 0;

    padding: 0.25rem;

    .resize-handle {
        position: absolute;
        width: 16px;
        height: 16px;
        background-color: white;
        border: solid 1px $dark-click;
        margin: -9px;
    }

    .top-left {
        top: 0;
        left: 0;
        cursor: nw-resize;
    }

    .top-right {
        top: 0;
        right: 0;
        cursor: ne-resize;
    }

    .bottom-left {
        bottom: 0;
        left: 0;
        cursor: sw-resize;
    }

    .bottom-right {
        bottom: 0;
        right: 0;
        cursor: se-resize;
    }

    &.focused {
        border: solid 2px $dark-click;
    }

    .user-image {
        &.focused {
            cursor: grab;
        }

        &.dragging {
            cursor: grabbing;
        }
    }
}
</style>
