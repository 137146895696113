import { v4 as uuid } from "uuid";
import self from "../..";

export default class AssetsController {

    constructor() {
        this.importedImages = {};
        this.removedImages = {};
        this.blockImageRemoving = false;
        self.app.events.on("@option-manager.unused-id-found",
            id => {
                if (!this.blockImageRemoving) {
                    this.removeImportedImage(id);
                }
            });
        self.app.events.on("@material-manager.reimport-texture", imageObject => {
            const blobUrl = imageObject.texture.url;
            const xhr = new XMLHttpRequest();
            xhr.open("GET", blobUrl, true);
            xhr.responseType = "blob";
            xhr.onload = () => {
                if (xhr.status === 200) {
                    const myBlob = xhr.response;
                    this.addBlobToImportedImages(imageObject.id, myBlob);
                }
            };
            xhr.send();
        });
        self.app.events.on("@material-manager.check-reloaded-material", (id, url) => {
            if (!this.importedImages[id]) {
                this.reloadImageFromUrl(url, id);
            }
        });
    }

    /**
     * Add a new imported image file inside this.importedImages object
     * @param {File} file - The imported image
     */
    addNewImportedImage(file) {
        const imageId = uuid();
        this.importedImages[imageId] = new Blob([file], { type: file.type });
        delete this.removedImages[imageId];
        const imageUrl = URL.createObjectURL(file);
        const urlObject = {};
        urlObject[imageId] = imageUrl;
        self.app.events.emit("loaded-image", imageId);
        return urlObject;
    }

    addNewImportedImageFromBlob(blob) {
        const imageId = uuid();
        const imageUrl = URL.createObjectURL(blob);
        this.addBlobToImportedImages(imageId, blob);
        const urlObject = {};
        urlObject[imageId] = imageUrl;
        self.app.events.emit("loaded-image", imageId);
        return urlObject;
    }

    /**
     * Add a new imported image blob inside this.importedImages object
     * @param {String} id - the id of the given image
     * @param {Blob} blob - the blob of the given image
     */
    addBlobToImportedImages(id, blob) {
        this.importedImages[id] = blob;
        delete this.removedImages[id];
    }

    /**
     * Empty the importedImages object
     */
    clearAllImportedImages() {
        const keys = Object.keys(this.importedImages);
        keys.forEach(
            key => {
                this.removedImages[key] = true;
                delete this.importedImages[key];
            }
        );
    }

    /**
     * Remove imported image from importedImages object by giving an id
     * @param {String} id - id of the image we want to remove
     */
    removeImportedImage(id) {
        this.removedImages[id] = true;
        if (this.importedImages[id]) {
            delete this.importedImages[id];
        }
    }

    resetRemovedImages() {
        this.removedImages = {};
    }

    async reloadImageFromUrl(url, id) {
        const blob = await fetch(url).then(r => r.blob());
        this.addBlobToImportedImages(id, blob);
    }

    exportImportedImages() {
        return this.importedImages;
    }

    exportRemovedImages() {
        return Object.keys(this.removedImages);
    }

}
