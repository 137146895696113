import self from "../..";

const Structure = require("@obsidianjs/data-store/src/entity");

const EntityStructure = require("./entity-structure").default;

const CoverStructure = EntityStructure.$extend({

    __name__: "cover",

    __init__(params) {
        this.$super(params);
        this.coverThickness = 0.0085;
        this.initSnapping();
    },

    __include__: [
        self.app.modules.snapping.SnappableMixin.prototype,
    ],

});
Structure.$register(CoverStructure);
export default CoverStructure;
