import self from "../..";

const Structure = require("@obsidianjs/data-store/src/entity");

const EntityStructure = require("./entity-structure").default;


const LedskinStructure = EntityStructure.$extend({

    __name__: "ledskin",


    __init__(params) {
        this.$super(params);
        this.initMeasurement();
    },

    __include__: [
        self.app.modules.measurementManager.MeasurableMixin.prototype,
    ],
});
Structure.$register(LedskinStructure);
export default LedskinStructure;
