export default {
    name: "bematrix-ui",
    requires: [
        "camera-manager",
        "grid-manager",
        "catalog-manager",
        "scene-manager",
        "gizmo-manager",
        "project-manager",
        "group-manager",
        "iframe-api",
        "obsidian-engine",
        "option-manager",
        "stonejs",
        "history",
        "init-utility",
        "selection-manager",
        "data-store",
        "material-manager",
        "building-plan-manager",
        "measurement-manager",
        "entity-manager",
        "assets-manager",
    ],
    load() {
        const Controller = require("./src/ui-controller").default;
        return new Controller();
    },
    unload() {},
};
