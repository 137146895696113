import {
    Vector3, MeshBuilder, StandardMaterial, Color3, SphereBuilder,
} from "@babylonjs/core";

import self from "../..";

const {
    modules: {
        obsidianEngine: {
            controller: engineController,
        },
        materialManager,
    },
} = self.app;

export const MEASUREMENT_SPHERE_TYPE = {
    Basic: 0,
    Hovered: 1,
    Active: 2,
};


const MeasurementHelper = {

    measurementPoints: [],

    /**
     * Initialize the cone mesh that will be used at the end of the mesurement arrow
     * @param {BABYLON.Scene} scene
     */
    initConeMesh(scene) {
        this.coneMesh = MeshBuilder.CreateCylinder("cone", {
            diameterTop: 0, diameterBottom: 0.05, height: 0.05, tessellation: 10,
        }, scene);
        const mat = new StandardMaterial("coneMat", scene);
        this.coneMesh.rotate(Vector3.Left(), Math.PI / 2);
        this.coneMesh.bakeCurrentTransformIntoVertices();
        mat.emissiveColor = Color3.Black();
        mat.disableLighting = true;
        this.coneMesh.material = mat;
        this.coneMesh.renderingGroupId = 1;
        this.coneMesh.setEnabled(false);
    },

    /**
     * Draws a 3D line from given pointA to pointB
     * Uses BABYLON Meshes as the lines needs a real world position not like GUI
     * @param {BABYLON.TransformNode} pointA
     * @param {BABYLON.TransformNode} pointB
     * @param {BABYLON.Scene} scene
     */
    createLine(pointA, pointB, scene) {
        // Line part
        const length = Vector3.Distance(pointA, pointB);
        const line = MeshBuilder.CreateDashedLines("line",
            {
                points: [pointA, pointB],
                updatable: true,
                size: 12,
                dashNb: length * 10,
                useVertexAlpha: false,
            }, scene);
        line.color = Color3.Black();
        line.renderingGroupId = 1;

        // Cones part
        if (!this.coneMesh) {
            this.initConeMesh(scene);
        }
        const direction = pointB.subtract(pointA);

        const cone1 = this.coneMesh.clone("cone1");
        cone1.position = pointB.clone();
        cone1.lookAt(pointA);
        cone1.position = cone1.position.add(direction.clone().normalize().scale(-0.025));

        const cone2 = this.coneMesh.clone("cone2");
        cone2.position = pointA.clone();
        cone2.lookAt(pointB);
        cone2.position = cone2.position.add(direction.clone().normalize().scale(0.025));
        return {
            points: [pointA, pointB],
            cones: [cone1, cone2],
            lineMesh: line,
        };
    },

    checkCollisionBetweenNewPointsAndExistingPoints(measurementPointMesh) {
        // As the points all have the same width we only check their position.
        // Only true if the points are in the same position (or nearly)
        return this.measurementPoints.some(
            (pointToCheck) => {
                pointToCheck.computeWorldMatrix(true);
                measurementPointMesh.computeWorldMatrix(true);
                const ptcAbsolutePosition = pointToCheck.getAbsolutePosition();
                const mpAbsolutePosition = measurementPointMesh.getAbsolutePosition();
                return ["x", "y", "z"].every(
                    coordinate => (
                        ptcAbsolutePosition[coordinate]
                                < mpAbsolutePosition[coordinate] + 0.001
                                        && ptcAbsolutePosition[coordinate]
                                            > mpAbsolutePosition[coordinate] - 0.001
                    )
                );
            }
        );
    },

    /**
     * Create three differents spheres that has the three measurement object materials
     */
    initMeasurementSphere() {
        this.basicMeasurementPointMesh = SphereBuilder.CreateSphere("measurementPointBasic",
            { diameter: 0.07, segments: 6 },
            engineController.scene);
        this.basicMeasurementPointMesh.material = materialManager.loadedMaterials[
            "basic-green-material"];

        this.hoveredMeasurementPointMesh = SphereBuilder.CreateSphere("measurementPointHovered",
            { diameter: 0.07, segments: 6 },
            engineController.scene);
        this.hoveredMeasurementPointMesh.material = materialManager.loadedMaterials[
            "hover-green-material"];

        this.activeMeasurementPointMesh = SphereBuilder.CreateSphere("measurementPointActive",
            { diameter: 0.07, segments: 6 },
            engineController.scene);
        this.activeMeasurementPointMesh.material = materialManager.loadedMaterials[
            "active-green-material"];

        this.hoveredMeasurementPointMesh.setEnabled(false);
        this.basicMeasurementPointMesh.setEnabled(false);
        this.activeMeasurementPointMesh.setEnabled(false);
    },

    /**
     * Set sphereMesh its parent and it's world position
     * @param {BABYLON.Mesh} sphere the sphere to place
     * @param {BABYLON.Mesh} parent the parent of the point
     * @param {BABYLON.TransformNode} point the point that has the coordinate informations
     */
    setMeasurementSphereParent(sphere, parent, point) {
        sphere.parent = parent;
        sphere.position = point.position;
        sphere.rotationQuaternion = point.rotationQuaternion;
        if (!sphere.metadata) {
            sphere.metadata = {};
        }
        sphere.computeWorldMatrix(true);
    },

    /**
     * Create an instance of sphere of given sphereType
     * @param {String} name the name of the new sphere
     * @param {MEASUREMENT_SPHERE_TYPE} sphereType the type of sphere we want to create
     */
    getNewSphere(name, sphereType) {
        let sphere;
        if (sphereType === MEASUREMENT_SPHERE_TYPE.Hovered) {
            sphere = this.hoveredMeasurementPointMesh.createInstance(name);
        } else if (sphereType === MEASUREMENT_SPHERE_TYPE.Active) {
            sphere = this.activeMeasurementPointMesh.createInstance(name);
        } else {
            sphere = this.basicMeasurementPointMesh.createInstance(name);
        }
        sphere.setEnabled(true);
        return sphere;
    },

    /**
     * Creates a new sphere that replace a given sphere by taking its coordinate but change
     * the material
     * @param {BABYLON.Mesh} sphereToReplace the sphere to replace
     * @param {MEASUREMENT_SPHERE_TYPE} sphereType the new type of sphere
     */
    replaceSphere(sphereToReplace, sphereType) {
        const newSphere = this.getNewSphere(sphereToReplace.name, sphereType);
        newSphere.parent = sphereToReplace.parent;
        newSphere.position = sphereToReplace.position;
        newSphere.rotationQuaternion = sphereToReplace.rotationQuaternion;
        newSphere.computeWorldMatrix(true);
        if (sphereToReplace.metadata && sphereToReplace.metadata.measurementPoint) {
            const measurementPoint = sphereToReplace.metadata.measurementPoint;
            Object.assign(newSphere, {
                metadata: { measurementPoint },
            });
            measurementPoint.pointMesh = newSphere;
        }
        sphereToReplace.dispose();
        return newSphere;
    },

    /**
     * remove given point from measurmentPoints list
     * @param {BABYLON.Mesh} measurementPointMesh
     */
    removePoint(measurementPointMesh) {
        const index = this.measurementPoints.findIndex(pointToCheck => (pointToCheck.name
            === measurementPointMesh.name));
        this.measurementPoints.splice(index, 1);
    },
};

export default MeasurementHelper;
