<template>
    <div id="loader" />
</template>
<script>
export default {
    name: "Loader",
};
</script>
<style>
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
    /* Center the loader */
    #loader {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 150px;
        height: 150px;
        margin: -75px 0 0 -75px;
        border: 16px solid #757474;
        border-radius: 50%;
        border-top: 16px solid #97BF0D;
        animation: spin 2s linear infinite;
    }
</style>
