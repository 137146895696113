<template>
    <b-nav-item-dropdown
        right
        no-caret
        class="interactions-menu"
        @click="toggleDropdown()"
    >
        <!-- Change button content to an image -->
        <template slot="button-content">
            <img src="/assets/icons/eye-black.svg">
            <span class="custom-text">{{ _("Scene interactions") }}</span>
            <img
                :class="{ open: isOpen }"
                src="/assets/icons/dropdown-black.svg"
            >
        </template>
        <b-dropdown-item @click="setInfillsVisibility(!localOptionsData.showInfills)">
            <span v-if="localOptionsData.showInfills">{{ _("Hide infills") }}</span>
            <span v-else>{{ _("Show infills") }}</span>
        </b-dropdown-item>
        <b-dropdown-item @click="setFramesTransparency(!materialData.areFramesTransparent)">
            <span v-if="!materialData.areFramesTransparent">{{ _("Transparent frames") }}</span>
            <span v-else>{{ _("Normal frames") }}</span>
        </b-dropdown-item>
        <b-dropdown-item
            v-if="canDisplayRemoveConnectorsButton"
            @click="removeConnectors()"
        >
            <span>{{ _("Remove connectors") }}</span>
        </b-dropdown-item>
    </b-nav-item-dropdown>
</template>
<script>
import RightsHelper from "helpers/rights-helper";
import self from "../../index";

const {
    app: {
        modules: {
            optionManager: { optionController },
            materialManager,
        },
    },
} = self;

export default {
    name: "SceneInteractions",
    props: {
        optionsData: {
            type: Object,
            default: () => ({
                optionsFamilies: {},
                showInfills: true,
                infillsVisibilityLocked: false,
            }),
        },
        materialData: {
            type: Object,
            default: () => ({
                areFramesTransparent: false,
            }),
        },
    },
    data() {
        return {
            isOpen: false,
            localOptionsData: this.optionsData,
        };
    },
    computed: {
        canDisplayRemoveConnectorsButton() {
            return !RightsHelper.isModeDemo()
                && !RightsHelper.isModePublic()
                && !RightsHelper.isModeBuildingPlan();
        },
    },
    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },

        setInfillsVisibility(areInfillsVisible) {
            optionController.setAllOptionsVisibility(
                areInfillsVisible,
                optionController.optionsFamilies.INFILL
            );
        },

        removeConnectors() {
            self.app.modules.projectManager.controller.clearPath([
                "/connectors/default",
            ]);
            self.app.modules.history.snapshot();
        },

        setFramesTransparency(areFramesTransparent) {
            materialManager.setFramesTransparency(areFramesTransparent);

            this.setInfillsVisibility(!areFramesTransparent);
        },

        _(...args) {
            return self.app.modules.stonejs.gettext(...args);
        },
    },
};
</script>

<style lang="scss">
@import "../../../../style/bematrix";

.interactions-menu {
    @extend .top-menu-div;

    height: 100%;
    font-family: $font-medium;

    button {
        padding: 1rem;
    }

    .custom-text {
        font-size: 0.85em;
        color: $grey-text;
    }

    img {
        max-width: 20px;

        &.open {
            transform: rotate(180deg);
        }
    }

    span {
        padding: 0 0.5rem;
    }

    // sry
    > a {
        padding: 0 1.5rem !important;
    }

    .dropdown-menu {
        border-radius: 0;
        margin: 0;
    }

    .dropdown-item {
        font-family: $font-regular;
        font-size: 0.8em;

        &:hover {
            background-color: $pale-grey-hover;
        }

        &:active {
            background-color: $pale-grey-click;
            color: $dark;
        }
    }
}
</style>
