import { HighlightLayer, Color3 } from "@babylonjs/core";

import self from "../..";
import HighlightBehaviorHelper from "../helpers/highlight-behavior-helper";

export default class HighlightController {

    constructor() {
        this.highlightLayer = null;
        self.app.events.on("@obsidian-engine.engine-ready", (scene) => {
            this.highlightLayer = new HighlightLayer("hl", scene);
            this.highlightLayer.blurHorizontalSize = 1;
            this.highlightLayer.blurVerticalSize = 1;
            this.highlightLayer.innerGlow = false;
            this.highlightLayer.renderingGroupId = 0;

            // front highlight layer for meshes with a different rendering group id
            this.frontLayer = new HighlightLayer("h2", scene);
            this.frontLayer.blurHorizontalSize = 1;
            this.frontLayer.blurVerticalSize = 1;
            this.frontLayer.innerGlow = false;
            this.frontLayer.renderingGroupId = 1;
        });
    }

    /**
     * Enable/disable highlight a given mesh to a given color
     * @param {Mesh} mesh
     * @param {Boolean} highlightValue true if we enable the highlight, false if we disable
     * @param {Color3} color (Optional, Green by default, useless if
     *                         highlightValue is false) the color of the highlight
     */
    toggleHighlightMesh(mesh, highlightValue, color = Color3.Green()) {
        const layer = mesh.renderingGroupId > 0 ? this.frontLayer : this.highlightLayer;
        if (highlightValue) {
            layer.addMesh(mesh, color);
            mesh.getChildMeshes(true).forEach(
                (childMesh) => {
                    if (!childMesh.sourceMesh) { // means that the mesh is not an Instance
                        layer.addMesh(childMesh, color);
                    }
                }
            );
        } else {
            layer.removeMesh(mesh);
            mesh.getChildMeshes(true).forEach(
                (childMesh) => {
                    if (!childMesh.sourceMesh) {
                        try {
                            layer.removeMesh(childMesh);
                        } catch (err) {
                            self.app.log.error("Error while highlighting childMesh :", err);
                        }
                    }
                }
            );
        }
    }

    /** *
     * Enable/disable highlight to a given mesh LIST to a given color
     * @param {Array<Mesh>} mesh
     * @param {Boolean} highlightValue true if we enable the highlight, false if we disable
     * @param {Color3} color (Optional, Green by default, useless if
     *                         highlightValue is false) the color of the highlight
     */
    toggleHighlightMeshes(meshList, highlightValue, color = Color3.Green()) {
        meshList.forEach(
            (mesh) => {
                this.toggleHighlightMesh(mesh, highlightValue, color);
            }
        );
    }

    createNewHighlightBehavior(mesh) {
        return new HighlightBehaviorHelper(this, mesh);
    }

}
