<template>
    <div>
        <user-image
            v-for="(userImage, uuid) in getPageUserImages"
            :key="uuid"
            :user-image-data="userImage"
        />
    </div>
</template>

<script>
import UserImage from "./user-image.vue";
import self from "../../../index";

const {
    app: {
        modules: {
            buildingPlanManager: {
                buildingPlanController: bpController,
            },
        },
    },
} = self;

export default {
    components: {
        UserImage,
    },
    computed: {
        getPageUserImages() {
            const page = bpController.getPage();
            return page.userImages;
        },
    },
};
</script>
