export default {
    getEntityPath(entity) {
        if (!this.PRODUCT_ENTITIES_NAME.includes(entity.__name__)) {
            return null;
        } if (entity.__name__ === "connector") {
            return "/connectors/default";
        }
        return "/products/default";
    },

    bindDragBehaviorToAttachedEntity(dragBehavior) {
        if (dragBehavior.attachedEntity) {
            if (dragBehavior.attachedEntity.onDrag) {
                dragBehavior.onDragObservable.add(() => {
                    dragBehavior.attachedEntity.onDrag();
                });
            }
            if (dragBehavior.attachedEntity.onDragEnd) {
                dragBehavior.onDragEndObservable.add(
                    () => {
                        dragBehavior.attachedEntity.onDragEnd();
                    }
                );
            }
            if (dragBehavior.attachedEntity.onDragStart) {
                dragBehavior.onDragStartObservable.add(
                    () => {
                        dragBehavior.attachedEntity.onDragStart();
                    }
                );
            }
        } else {
            // Gizmo case which is initialized without entity attached
            // This case check on drag events if attachedEntity exists
            dragBehavior.onDragObservable.add(
                () => {
                    if (dragBehavior.attachedEntity && dragBehavior.attachedEntity.onDrag) {
                        dragBehavior.attachedEntity.onDrag();
                    }
                }
            );
            dragBehavior.onDragEndObservable.add(
                () => {
                    if (dragBehavior.attachedEntity && dragBehavior.attachedEntity.onDragEnd) {
                        dragBehavior.attachedEntity.onDragEnd();
                    }
                }
            );
            dragBehavior.onDragStartObservable.add(
                () => {
                    if (dragBehavior.attachedEntity && dragBehavior.attachedEntity.onDragStart) {
                        dragBehavior.attachedEntity.onDragStart();
                    }
                }
            );
        }
    },

    PRODUCT_ENTITIES_NAME: [
        "connector",
        "entity",
        "frame",
        "door",
        "cover",
        "ledskin",
        "sideled-lightbox",
        "backled-lightbox",
        "flooring-connector",
        "motionskin",
        "corner",
        "structural",
        "tracklight",
        "popin",
        "betruss-b30",
        "betruss-square",
    ],

};
