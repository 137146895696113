<template>
    <div
        class="indexed-annotation-container"
    >
        <canvas
            ref="annotationCanvas"
            class="line-canvas"
            :class="{hasImage: annotationData.image}"
        />
        <div
            ref="annotationCircle"
            :style="imageUrl"
            class="annotation"
            :class="{
                editing: annotationData.editing,
                editable: canBeModified,
                hasImage: annotationData.image}"
            :img="annotationData.image"
            @click="canBeModified && onClick($event)"
        >
            <template v-if="!annotationData.image">
                {{ annotationData.number }}
            </template>
        </div>
    </div>
</template>
<script>
import self from "../../../index";
import EventBus from "../../eventBus";

const {
    modules: {
        buildingPlanManager: {
            buildingPlanController: bpController,
            buildingPlanImageController: {
                IMAGE_MODES,
            },
        },
    },
    events,
} = self.app;

export default {
    name: "BuildingPlanTable",
    props: {
        annotationData: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            bpImageController: bpController.imageController,
            canvasScaleRatio: 4,
            canvas: null,
            canvasContext: null,
            annotation: null,
            annotationX: 0,
            annotationY: 0,
            annotationDataMutable: this.annotationData,
        };
    },
    computed: {
        imageUrl() {
            if (this.annotationData.image) {
                return {
                    backgroundImage: `url(${this.annotationData.image})`,
                };
            }
            return "";
        },
        canBeModified() {
            return bpController.isPageEditable();
        },
    },
    mounted() {
        // TODO: Use only one canvas to draw all the lines ! #performanceImprovement
        // Doing the resize manually instead off using a class because
        // the class style is applied after the mounted
        // So we got a canvas that is to small with a line that can't go to our target position,
        // upscaled.
        this.canvas = this.$refs.annotationCanvas;
        this.annotation = this.$refs.annotationCircle;
        // Set canvas size in CSS
        this.canvas.style.width = this.canvas.parentElement.offsetWidth;
        this.canvas.style.height = this.canvas.parentElement.offsetHeight;

        // Set canvas pixel size
        this.canvas.width = this.canvas.parentElement.offsetWidth * this.canvasScaleRatio;
        this.canvas.height = this.canvas.parentElement.offsetHeight * this.canvasScaleRatio;

        this.context = this.canvas.getContext("2d");
        this.context.scale(this.canvasScaleRatio, this.canvasScaleRatio);

        this.container = document.getElementById("canvas-container");


        // Restore annotation position
        const positionX = this.annotationDataMutable.annotationPosition.x;
        const positionY = this.annotationDataMutable.annotationPosition.y;
        this.setAnnotationPosition(positionX, positionY, false);
        this.drawLine(positionX, positionY);

        events.on("@building-plan-manager.end-page-editing", this.onEndPageEditing);

        // if this annotation has a parent annotation, listen fot its events
        // to reproduce its movement
        if (this.annotationDataMutable.parent) {
            EventBus.$on("annotation-move", this.onParentMove);
            // position saved when clicking
            EventBus.$on("annotation-click", this.onParentClick);
        }

        if (this.annotationData.editing) {
            this.addListeners();
        }
    },

    /**
    * remove events once destroyed
    */
    destroyed() {
        if (this.annotationDataMutable.parent) {
            EventBus.$off("annotation-move", this.onParentMove);
            EventBus.$off("annotation-click", this.onParentClick);
        }
        events.removeListener("@building-plan-manager.end-page-editing", this.onEndPageEditing);
    },
    methods: {
        drawLine(startPositionX, startPositionY) {
            this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.context.beginPath();

            // Line
            this.context.moveTo(startPositionX, startPositionY);
            this.context.lineTo(
                this.annotationData.tipPosition.x,
                this.annotationData.tipPosition.y
            );
            this.context.stroke();
            this.context.closePath();

            // Tip point
            this.context.beginPath();
            this.context.arc(
                this.annotationData.tipPosition.x,
                this.annotationData.tipPosition.y,
                2.5,
                0,
                2 * Math.PI,
            );
            this.context.fill();

            this.context.closePath();
        },
        setAnnotationPosition(startPositionX, startPositionY, propagate = true) {
            if (propagate) {
                // event to update the child annotations
                EventBus.$emit("annotation-move", {
                    uuid: this.annotationDataMutable.uuid,
                    dx: startPositionX - this.annotationX,
                    dy: startPositionY - this.annotationY,
                });
            }
            this.annotation.style.left = startPositionX - this.annotation.offsetWidth / 2;
            this.annotation.style.top = startPositionY - this.annotation.offsetHeight / 2;
            this.annotationX = startPositionX;
            this.annotationY = startPositionY;
        },
        onMoveLocal(event) {
            const positionX = event.target.offsetLeft + event.offsetX;
            const positionY = event.target.offsetTop + event.offsetY;
            this.setAnnotationPosition(positionX, positionY);
            this.drawLine(positionX, positionY);
        },
        onMoveOnCanvas(event) {
            this.setAnnotationPosition(event.offsetX, event.offsetY);
            this.drawLine(event.offsetX, event.offsetY);
        },
        onClick(event) {
            if (this.annotationData.editing) {
                this.removeListeners();

                this.bpImageController.captureImage(IMAGE_MODES.HTML);

                // Center of the circle
                this.annotationDataMutable.annotationPosition.x = event.target.offsetLeft
                    + event.target.offsetWidth / 2;
                this.annotationDataMutable.annotationPosition.y = event.target.offsetTop
                    + event.target.offsetHeight / 2;
                // event to update the child annotations
                EventBus.$emit("annotation-click", { uuid: this.annotationDataMutable.uuid });
            } else {
                this.addListeners();
            }

            this.annotationDataMutable.editing = !this.annotationDataMutable.editing;
        },
        onEndPageEditing() {
            if (this.annotationData.editing) {
                this.removeListeners();
                this.bpImageController.captureImage(IMAGE_MODES.HTML);
                this.annotationDataMutable.editing = false;
            }
        },
        onParentMove({ uuid, dx, dy }) {
            if (this.annotationDataMutable.parent.uuid === uuid) {
                const newPos = { x: this.annotationX + dx, y: this.annotationY + dy };
                this.setAnnotationPosition(newPos.x, newPos.y);
                this.annotationData.tipPosition.x += dx;
                this.annotationData.tipPosition.y += dy;
                this.drawLine(newPos.x, newPos.y);
            }
        },
        onParentClick({ uuid }) {
            if (this.annotationDataMutable.parent.uuid === uuid) {
                this.annotationDataMutable.annotationPosition.x = this.annotationX;
                this.annotationDataMutable.annotationPosition.y = this.annotationY;
            }
        },
        addListeners() {
            this.container.addEventListener("pointermove", this.onMoveLocal);
        },
        removeListeners() {
            this.container.removeEventListener("pointermove", this.onMoveLocal);
        },
    },

};
</script>
<style lang="scss">
@import "../../../../../style/base/variables.scss";

.indexed-annotation-container {
    position: absolute;
    width: 100%;
    height: 100%;

    .annotation {
        position: relative;
        display: inline-flex;
        flex-direction: column;
        padding: 6px;
        min-width: 1em;
        z-index: $z-index-annotation;
        &.hasImage {
            z-index: $z-index-image-annotation;
            width: 200px;
            height: 200px;
            background-size:cover;
        }
        line-height: 1em;
        font-size: 1.5em;
        box-sizing: content-box;
        text-align: center;

        border: solid 2px;
        border-radius: 50%;

        background: white;

        pointer-events: auto;

        &.editing {
            border-style: dashed;
        }


        &.editable {
            cursor: pointer;
        }

        &>img{
            width:300px;
        }
    }

    .line-canvas.hasImage{
        z-index: $z-index-line-image-annotation;
    }
}
</style>
