import {
    Quaternion, Vector4, Vector3, Color4, MeshBuilder,
} from "@babylonjs/core";
import { v4 as uuid } from "uuid";

import config from "defaultConfig";
import EntitiesData from "../../../entities-data";
import AbstractOptionController from "./abstract-option-controller";
import OptionableMixin from "../model/optionable-mixin";
import InfillHelper from "../helper/infill-helper";
import self from "../index";

const {
    assetsManager,
} = self.app.modules;

const INFILLS_THICKNESS = 0.003; // Infills thickness in mm

export default class InfillOptionControler extends AbstractOptionController {

    constructor(optionController) {
        super(optionController);
        this.highlightManager = self.app.modules.highlightManager;
        this.dataStore = self.app.modules.dataStore;

        this.infillsReferences = {};
        this.infillHelper = InfillHelper;

        this.initializeReferences();
        this.initializeEvents([]);

        // Can't be included in AbstractOptionController
        self.app.events.on("@catalog-manager.catalog-initialized", () => {
            this.registerSpecialInfillInOut();
        });
    }

    initializeEvents() {
        super.initializeEvents([]);
        self.app.events.on("@data-store.entity-added", entity => {
            if (entity.isOptionable) {
                this.infillHelper.checkInfillBothSides(entity);
            }
        });
        self.app.events.on("@data-store.entity-removed", entity => {
            const idsToCheck = [];
            if (entity.firstInfillImageId) {
                idsToCheck.push(entity.firstInfillImageId);
            }
            if (entity.secondInfillImageId) {
                idsToCheck.push(entity.secondInfillImageId);
            }
            if (idsToCheck.length) {
                this.checkAvailableInfillIds(idsToCheck);
            }
        });
        self.app.events.on("update-infill-image-id", (newval, oldval) => {
            if (newval !== oldval && oldval) {
                this.checkAvailableInfillIds([oldval]);
            }
        });
    }

    initializeReferences() {
        // Infills not generated proceduraly and that got one reference for both sides of the frame
        // We keep here the reference of the object affected by those special cases infills
        this.specialInfillsFrameReference = ["606 0992 0992 R430"];
        // this.specialInfillsFrameReference = ["606 0992 0992 R430"];

        // FRAMES
        this.infillsReferences["STRAIGHT FRAMES"] = "PANEL FOR b62-b55 FRAME ";
        this.infillsReferences["CURVED FRAMES"] = "PANEL FOR b62 CURVED ";
        this.infillsReferences["PERFECT CORNERS"] = "PANEL FOR b62 PERFECT ";

        // DOORS
        this.infillsReferences["SINGLE DOORS"] = "PANEL FOR b62 DOORFRAME MKII ";
        this.infillsReferences["DOUBLE DOORS"] = "PANEL FOR b62 DOUBLE DOOR H";
        this.infillsReferences["STORAGE DOORS"] = "PANEL FOR b62 ";

        // LIGHTBOXES
        this.infillsReferences["BACKLED LIGHTBOX"] = this.infillsReferences["STRAIGHT FRAMES"];
        this.infillsReferences["SIDELED LIGHTBOX"] = this.infillsReferences["STRAIGHT FRAMES"];

        this.infillsReferences.MOTIONSKIN = this.infillsReferences["STRAIGHT FRAMES"];
    }

    /**
     * Add an infill of the passed infill type to this entity
     * @type {OptionableMixin.INFILL_OPTION}
     * @type {* || null} entity
     */
    addInfills(type, entity = null) {
        const realEntity = this.optionController.returnRealEntity(entity);

        if (realEntity) {
            return this.updateInfillMeshes(realEntity, type);
        }
        return Promise.resolve(null);
    }

    /**
     * Remove and add the infill of the passed type
     * @param {*}
     * @param {OptionableMixin.INFILL_OPTION}
     */
    updateInfillMeshes(entity, type, options = { materialName: "base-infill-material", infillType: 0 }) {
        const tempInfillsMaterialName = entity.optionsMeshes.infills.map(
            infill => infill.material.name
        );
        this.removeInfillMeshes(entity);
        if (!entity.hasInfillsBothSide && type === OptionableMixin.INFILL_OPTION.BOTH_FACES) {
            entity.infillOption = OptionableMixin.INFILL_OPTION.ONE_FACE;
        } else {
            entity.infillOption = type;
        }
        if (entity.infillOption !== OptionableMixin.INFILL_OPTION.NONE) {
            const promises = [];
            const swap = entity.swappedOptions.INFILL;
            let finalInfill1MaterialName = tempInfillsMaterialName[0];
            if (options.infillType !== 2) {
                // console.log("getting my first infill material name");
                finalInfill1MaterialName = options.materialName;
            }
            promises.push(
                this.getInfillMesh(entity, finalInfill1MaterialName, swap)
                    .then(infill => {
                        self.app.modules.geometryUtility.toggleMeshVisibility(
                            infill, false
                        ); // Trick to hide infill while we instanciate it
                        entity.optionsMeshes.infills.push(infill);
                    })
            );
            if (entity.infillOption === OptionableMixin.INFILL_OPTION.BOTH_FACES) {
                let finalInfill2MaterialName = tempInfillsMaterialName[1];
                if (options.infillType !== 1) {
                    finalInfill2MaterialName = options.materialName;
                }
                promises.push(
                    this.getInfillMesh(entity, finalInfill2MaterialName, !swap)
                        .then(infill2 => {
                            self.app.modules.geometryUtility.toggleMeshVisibility(
                                infill2, false
                            ); // Trick to hide infill while we instanciate it
                            entity.optionsMeshes.infills.push(infill2);
                        })
                );
            }

            return Promise.all(promises)
                .then(() => {
                    entity.optionsMeshes.infills.forEach((mesh, index) => {
                        mesh.name = "infill";
                        mesh.parent = entity.mesh;
                        mesh.position = Vector3.Zero();
                        mesh.rotationQuaternion = mesh.rotation.toQuaternion();
                        mesh.computeWorldMatrix(true);

                        // Set pivot to the center of the parent frame
                        if (entity.subCategory.toUpperCase() === "STRAIGHT FRAMES"
                            || entity.subCategory.toUpperCase().includes("LIGHTBOX")
                            || entity.isMotionskin) {

                            // This condition check if the infill must be swapped in the first case
                            const invertSwap = entity.subCategory.toUpperCase().includes("BACKLED")
                                || entity.isMotionskin;

                            const entityType = EntitiesData
                                .entityTypeFromSubCategory(entity.subCategory);
                            const dimensions = this.meshManager.getDimensionsFromRef(entity.ref,
                                entityType);
                            mesh.setPivotPoint(
                                new Vector3(
                                    0,
                                    0,
                                    -dimensions.depth / 2 + INFILLS_THICKNESS / 2
                                )
                            );
                            mesh.position.z = dimensions.depth / 2 - INFILLS_THICKNESS / 2;

                            // Swap the main infill if needed
                            if (invertSwap) {
                                if (!mesh.rotationQuaternion) {
                                    mesh.rotationQuaternion = mesh.rotation.toQuaternion();
                                }
                                const quat = Quaternion.RotationAxis(
                                    Vector3.Up(), Math.PI
                                );
                                mesh.rotationQuaternion.multiplyInPlace(quat);
                                mesh.computeWorldMatrix(true);
                            }

                            if (entity.infillOption === OptionableMixin.INFILL_OPTION.BOTH_FACES
                                && index % 2 === 1) {
                                // As we can't rotate this particular infill we just put it back
                                if (entity.ref.includes("R430")) {
                                    mesh.position.z = -dimensions.depth / 2 + INFILLS_THICKNESS / 2;
                                } else {
                                    // Special case of infill for lightbox 724 ...
                                    if (entity.ref.startsWith("724")) {
                                        mesh.locallyTranslate(new Vector3(0, 0, -0.063));
                                    }
                                    const quat = Quaternion.RotationAxis(
                                        Vector3.Up(),
                                        Math.PI
                                    );
                                    mesh.rotationQuaternion.multiplyInPlace(quat);
                                }
                            }

                            if (entity.swappedOptions.INFILL && entity.ref.includes("R430")) {
                                if (index % 2 === 1) {
                                    mesh.position.z = dimensions.depth / 2 - INFILLS_THICKNESS / 2;
                                } else {
                                    mesh.position.z = -dimensions.depth / 2 + INFILLS_THICKNESS / 2;
                                }
                            }
                        }
                        mesh.computeWorldMatrix(true);
                        self.app.modules.geometryUtility.toggleMeshVisibility(
                            mesh, true
                        );
                    });
                    this.toggleInfillsVisibility(
                        entity.visible
                        && this.optionController.showInfills
                        && entity.optionsVisibility.infills,
                        entity
                    );
                })
                .then(() => {
                    if ((entity.subCategory.toUpperCase() === "STRAIGHT FRAMES"
                    || entity.subCategory.toUpperCase().includes("LIGHTBOX")
                    || entity.isMotionskin) && entity.swappedOptions.INFILL && !entity.ref.includes("R430")) {
                        this.swapInfillSide(entity);
                    }
                    return this.updateInfillImage(entity);
                });

        }
        return Promise.resolve(null);
    }

    removeInfillMeshes(entity = null) {
        const realEntity = this.optionController.returnRealEntity(entity);
        if (realEntity && realEntity.optionsMeshes.infills.length > 0) {
            realEntity.optionsMeshes.infills.forEach(mesh => {
                mesh.dispose();
            });
            realEntity.optionsMeshes.infills = [];
        }
    }

    /**
     * Remove the infills meshes and set the infillOption to NONE
     * @param {* || null}
     */
    removeInfill(entity = null) {
        const realEntity = this.optionController.returnRealEntity(entity);
        if (realEntity) {
            this.removeInfillMeshes(realEntity);
            realEntity.swappedOptions.INFILL = false;
            realEntity.infillImageId = null;
            realEntity.firstInfillImageId = null;
            realEntity.firstInfillColor = null;
            realEntity.secondInfillImageId = null;
            realEntity.secondInfillColor = null;
            realEntity.infillOption = OptionableMixin.INFILL_OPTION.NONE;
        }
    }

    /**
     * Returns the infill reference corresponding to this entity
     * @param {*} entity
     * @return {string}
     */
    getInfillReference(entity) {
        const entityType = EntitiesData.entityTypeFromSubCategory(entity.subCategory);
        const {
            width, height, depth,
        } = this.meshManager.getDimensionsFromRef(entity.ref, entityType);
        const strFixedSizeWidth = width.toFixed(3);
        let strWidth = strFixedSizeWidth.toString().replace(".", "");
        const strFixedSizeHeight = height.toFixed(3);
        const strHeight = strFixedSizeHeight.toString().replace(".", "");
        const strFixedSizeDepth = depth.toFixed(3);
        const strDepth = strFixedSizeDepth.toString().replace(".", "");

        // Straight frames infills references
        if (entity.subCategory.toUpperCase() === "STRAIGHT FRAMES"
            || entity.category.toUpperCase() === "LIGHTBOXES"
            || entity.category.toUpperCase() === "MOTIONSKIN") {
            if (entity.ref.includes("R430")) {
                return `PANEL FOR ${entity.ref}`;
            }

            return `${this.infillsReferences[
                entity.subCategory.toUpperCase()
            ]}${strWidth} x ${strHeight}`;
        }

        // Curved frames infills references
        if (entity.subCategory.toUpperCase() === "CURVED FRAMES") {
            if (parseInt(strWidth, 10) <= 497) {
                strWidth = Number(parseInt(strWidth, 10) - 66);
            }
            if (entity.ref.includes("E45")) {
                return `${this.infillsReferences[
                    entity.subCategory.toUpperCase()
                ]}45° R=${parseInt(strWidth, 10)} H=${strHeight}`;
            }
            return `${this.infillsReferences[
                entity.subCategory.toUpperCase()
            ]}R=${parseInt(strWidth, 10)} H=${strHeight}`;
        }

        // Door frames infills references
        if (entity.subCategory.toUpperCase() === "SINGLE DOORS") {
            return `${this.infillsReferences[
                entity.subCategory.toUpperCase()
            ]}H=${strHeight} mm `;
        }

        if (entity.subCategory.toUpperCase() === "DOUBLE DOORS") {
            return `${this.infillsReferences[
                entity.subCategory.toUpperCase()
            ]}=${strHeight} mm`;
        }

        if (entity.subCategory.toUpperCase() === "STORAGE DOORS") {
            const splittedRef = entity.ref.split(" ");
            let storageDoorType = "UNKNOWN";
            if (splittedRef[splittedRef.length - 1] === "S") {
                storageDoorType = "SINGLE";
            } else if (splittedRef[splittedRef.length - 1] === "D") {
                storageDoorType = "DOUBLE";
            }

            return `${this.infillsReferences[
                entity.subCategory.toUpperCase()
            ]}${storageDoorType} LOCKING STORAGE DOOR =${strWidth} mm`;
        }

        // Perfect corners infills references
        if (entity.subCategory.toUpperCase() === "PERFECT CORNERS") {
            if (entity.ref.startsWith("691") || entity.ref.startsWith("671")) {
                return `${this.infillsReferences[
                    entity.subCategory.toUpperCase()
                ]}U ${parseInt(strDepth, 10)} H=${strHeight}`;
            }
            return `${this.infillsReferences[
                entity.subCategory.toUpperCase()
            ]}CORNER ${parseInt(strWidth, 10)} H=${strHeight}`;
        }

        const err = new Error(`Can't find infill reference for entity : ${entity.ref} with _name_ :
            ${entity.__name__}`);
        self.app.log.error(err);
        throw err;
    }

    /**
     * Set an image or color to the given entities's infill(s)
     */
    setInfill(entityList, options = { id: null, url: null, color: null }, infillType = 0) {
        const isImage = !options.color;
        const promises = [];
        self.app.events.emit("load-infill-image");
        try {
            entityList.forEach(
                entity => {
                    const realEntity = this.optionController.returnRealEntity(entity);
                    if (realEntity && realEntity.infillOption) {
                        if (infillType === 0) {
                            if (isImage) {
                                const sameTexture = realEntity.optionsMeshes.infills.every(
                                    infill => (infill.material
                                        && infill.material.albedoTexture
                                        && (infill.material.albedoTexture.url === options.url)
                                    )
                                );
                                if (sameTexture) return;
                                realEntity.firstInfillImageId = options.id;
                                realEntity.firstInfillColor = null;
                                realEntity.secondInfillImageId = options.id;
                                realEntity.secondInfillColor = null;
                            } else {
                                const sameTexture = realEntity.optionsMeshes.infills.every(
                                    infill => (infill.material
                                        && infill.material.diffuseColor === options.color
                                    )
                                );
                                if (sameTexture) return;
                                realEntity.firstInfillImageId = null;
                                realEntity.firstInfillColor = options.color;
                                realEntity.secondInfillImageId = null;
                                realEntity.secondInfillColor = options.color;
                            }
                        } else if (infillType === 1) {
                            const infill = realEntity.optionsMeshes.infills[0];
                            if (isImage) {
                                const sameTexture = infill.material
                                    && infill.material.albedoTexture
                                    && (infill.material.albedoTexture.url === options.url);
                                if (sameTexture) return;
                                realEntity.firstInfillImageId = options.id;
                                realEntity.firstInfillColor = null;
                            } else {
                                const sameTexture = infill.material
                                    && infill.material.diffuseColor === options.color;
                                if (sameTexture) return;
                                realEntity.firstInfillColor = options.color;
                                realEntity.firstInfillImageId = null;
                            }
                        } else if (infillType === 2) {
                            const infill = realEntity.optionsMeshes.infills[1];
                            if (!infill) return;
                            if (isImage) {
                                const sameTexture = infill.material
                                && infill.material.albedoTexture
                                && (infill.material.albedoTexture.url === options.url);
                                if (sameTexture) return;
                                realEntity.secondInfillImageId = options.id;
                                realEntity.secondInfillColor = null;
                            } else {
                                const sameTexture = infill.material
                                && infill.material.diffuseColor === options.color;
                                if (sameTexture) return;
                                realEntity.secondInfillColor = options.color;
                                realEntity.secondInfillImageId = null;
                            }
                        }

                        const materialName = self.app.modules.materialManager
                            .createInfillMaterial(entity, options);
                        promises.push(
                            this.updateInfillMeshes(entity,
                                entity.infillOption,
                                { materialName, infillType })
                        );
                    }
                }
            );
            self.app.events.emit("infill-image-loaded");
        } catch (err) {
            self.app.log.error("error while setting infill image", err);
            self.app.events.emit("infill-image-loaded");
        }
        return Promise.all(promises);
    }

    /**
     * Remove the image of the infill(s) of the given entity
     * @param {Entity} entity (Optional, current entity by default)
     * @param {Boolean} snapshot (Optional, false by default) if true, takes a snapshot at the end
     * @param {Boolean} cleanId (Optional, false by default), if true,
     *                                                  set the given entity infillImageId to null
     */
    removeInfillImage(entity = null, snapshot = false, cleanId = false) {
        const realEntity = this.optionController.returnRealEntity(entity);
        this.updateInfillMeshes(realEntity, realEntity.infillOption);
        if (cleanId) {
            realEntity.infillImageId = null;
            realEntity.firstInfillImageId = null;
            realEntity.firstInfillColor = null;
            realEntity.secondInfillImageId = null;
            realEntity.secondInfillColor = null;
        }
        self.app.events.emit("infill-image-removed");
        if (snapshot) {
            self.app.modules.history.snapshot();
        }
    }

    /**
     * Check if the given infillImageId is still used by another infill
     * @param {Array<String>} idArray - the infillImageId array checked
     */
    checkAvailableInfillIds(idArray) {
        idArray.forEach(
            id => {
                const idStillUsed = this.dataStore
                    .listEntities("/products/default").some(
                        entity => Boolean(entity.firstInfillImageId
                && entity.firstInfillImageId === id
                || (entity.secondInfillImageId && entity.secondInfillImageId === id))
                    );
                if (!idStillUsed) {
                    self.app.events.emit("unused-id-found", id);
                }
            }
        );
    }

    /**
     * Remove the infill images of all entity in given entityList
     * @param {Array<Entity>} entityList - the list of entity we want to remove the images
     */
    removeEntitiesListInfillsImages(entityList) {
        if (entityList.length) {
            entityList.forEach(
                entity => {
                    this.removeInfillImage(entity, false, true);
                }
            );
        }
        self.app.modules.history.snapshot();
    }

    /**
     *  Set the infill image to a given list of entity from a remote image
     * @param {Object} imageObject
     * @param {Array<Entity>} entityList
     */
    setInfillFromUrlObject(imageObject, entityList, infillType, snapshot = false) {
        const id = Object.keys(imageObject)[0];
        const url = imageObject[id];
        return this.setInfill(entityList, { id, url }, infillType)
            .then(() => {
                if (snapshot) {
                    self.app.modules.history.snapshot();
                }
            });
    }

    /**
     *  Set the infill image to a given list of entity from a remote image
     * @param {Object} imageObject
     * @param {Array<Entity>} entityList
     */
    setInfillImageFromUrlObject(imageObject, entityList, infillType) {
        const id = Object.keys(imageObject)[0];
        const url = imageObject[id];
        this.setInfill(entityList, { id, url }, infillType)
            .then(() => self.app.modules.history.snapshot());
    }

    /**
     *  Set the infill color to a given list of entity
     * @param {Array<Entity>} entityList
     * @param {String} color
     */
    setInfillColor(entityList, color, infillType) {
        this.setInfill(entityList, { color }, infillType)
            .then(() => self.app.modules.history.snapshot());
    }

    /**
     * Update the infill image of the given entity
     * @param {Entity} entity
     * @return {null || Promise} return the promise on image setting
     */
    updateInfillImage(entity) { // TODO: Update color here
        // Infills child meshes can be the only textured meshes
        let infillsWithSubMeshes = entity.optionsMeshes.infills;
        entity.optionsMeshes.infills.forEach(mesh => {
            infillsWithSubMeshes = infillsWithSubMeshes.concat(mesh.getChildMeshes());
        });

        // const infillsGotTexture = infillsWithSubMeshes.some(
        //     infill => (infill.material && infill.material.albedoTexture)
        // );

        let firstInfillSubMeshes = [entity.optionsMeshes.infills[0]];
        firstInfillSubMeshes = firstInfillSubMeshes.concat(
            entity.optionsMeshes.infills[0].getChildMeshes()
        );

        const firstInfillGotTexture = firstInfillSubMeshes.some(
            infill => (infill.material
                && (infill.material.albedoTexture || infill.material.diffuseColor))
        );

        if ((!entity.firstInfillImageId && !entity.firstInfillColor) && firstInfillGotTexture) {
            this.removeInfillImage(entity, false, true, 1);
        }

        let secondInfillGotTexture = false;
        if (entity.optionsMeshes.infills.length === 2) {

            let secondInfillWithSubMeshes = [entity.optionsMeshes.infills[1]];
            secondInfillWithSubMeshes = secondInfillWithSubMeshes.concat(
                entity.optionsMeshes.infills[1].getChildMeshes()
            );

            secondInfillGotTexture = secondInfillWithSubMeshes.some(
                infill => (infill.material
                    && (infill.material.albedoTexture || infill.material.diffuseColor))
            );

            if ((!entity.secondInfillImageId && !entity.secondInfillColor)
                && secondInfillGotTexture) {
                this.removeInfillImage(entity, false, true, 2);
            }
        }

        let promise = null;
        if (entity.firstInfillImageId && !firstInfillGotTexture) {
            promise = this.setInfill([entity], { id: entity.firstInfillImageId, url: null }, 1);
        } else if (entity.firstInfillColor && !firstInfillGotTexture) {
            promise = this.setInfill([entity], { color: entity.firstInfillColor }, 1);
        }
        if (entity.secondInfillImageId && !secondInfillGotTexture) {
            if (promise) {
                promise.then(
                    () => {
                        this.setInfill([entity], { id: entity.secondInfillImageId, url: null }, 2);
                    }
                );
            } else {
                promise = this.setInfill([entity],
                    { id: entity.secondInfillImageId, url: null },
                    2);
            }
        } else if (entity.secondInfillColor && !secondInfillGotTexture) {
            if (promise) {
                promise.then(
                    () => { this.setInfill([entity], { color: entity.secondInfillColor }, 2); }
                );
            } else {
                promise = this.setInfill([entity], { color: entity.secondInfillColor }, 2);
            }
        }
        if (promise) {
            return promise;
        }
        return null;
    }

    /**
     * Create a mesh of the same width and height the entity passed in argument and return it
     * Or for complicated geomtry we just load the object from S3
     * @param {*} entity
     * @param {boolean} isIn are we asking for the IN infill
     */
    getInfillMesh(entity, materialName, isIn = true) {
        let infillMesh = null;
        let ref = this.getInfillReference(entity);
        // R430 is a straight frames panel loaded from S3 as it's a straight frames it only got one
        // reference for both sides
        const isR430 = entity.ref.includes("R430");

        const isSquaredInfill = (entity.subCategory.toUpperCase() === "STRAIGHT FRAMES" && !isR430)
            || entity.subCategory.toUpperCase().includes("LIGHTBOX")
            || entity.subCategory.toUpperCase().includes("MOTIONSKIN");

        if (isSquaredInfill) {
            const { width, height } = this.meshManager.getDimensionsFromRef(entity.ref);
            const faceUV = new Array(6);

            // set all values to zero
            for (let i = 0; i < 6; i += 1) {
                faceUV[i] = new Vector4(0, 0, 0, 0);
            }
            // overwrite wanted face with sprite coordinates
            faceUV[0] = new Vector4(0, 0, 1, 1);

            const faceColors = new Array(6);
            for (let i = 1; i < 6; i += 1) {
                faceColors[i] = new Color4(0, 0, 0, 1);
            }
            infillMesh = MeshBuilder.CreateBox(uuid(), {
                width: width - 0.007,
                height: height - 0.007,
                depth: INFILLS_THICKNESS,
                updatable: true,
                faceUV,
                faceColors,
            }, this.scene);

            self.app.modules.meshManager.meshUtility.AddMetadataProperties(
                infillMesh,
                {
                    isOption: true,
                    ref,
                }
            );

            infillMesh.material = self.app.modules.materialManager.loadMaterial(materialName);
            return Promise.resolve(infillMesh);
        }

        if (entity.subCategory.toUpperCase() === "SINGLE DOORS") {
            ref += isIn ? " Rear [30°]" : " Front [30°]";
        } else if (entity.subCategory.toUpperCase() === "DOUBLE DOORS") {
            ref += isIn ? " Rear [30DEG]" : " Front [30DEG]";
        } else if (entity.subCategory.toUpperCase() === "STORAGE DOORS") {
            ref += " Front";
        } else {
            ref += isIn ? " IN" : " OUT";
        }

        let promise = Promise.resolve(null);
        if (!this.specialInfillsFrameReference.includes(entity.ref)) {
            promise = this.catalogManager.tryRegisterMeshGeometry(ref);
        }

        return promise
            .then(() => {
                const infill = this.meshManager.getMeshFromLoadedGeometry(
                    ref,
                    materialName,
                    null,
                    {
                        category: "INFILLS",
                        subCategory: "PANELS",
                        isInInfill: isIn,
                        isOutInfill: !isIn,
                    }
                );
                self.app.modules.meshManager.meshUtility.AddMetadataProperties(
                    infill,
                    {
                        isOption: true,
                    }
                );
                return infill;
            });
    }

    /**
     * Swap the side of the given entity's infill
     * Use only if entity.infillOption === OptionableMixin.INFILL_OPTION.ONE_FACE
     * @param {Entity} entity - The entity we want to swap infill
     */
    swapInfillSide(entity) {
        const realEntity = this.optionController.returnRealEntity(entity);
        if (realEntity && realEntity.hasInfillsBothSide) {
            if ((realEntity.subCategory.toUpperCase() === "STRAIGHT FRAMES"
                && !realEntity.ref.includes("R430"))
                || realEntity.category.toUpperCase() === "LIGHTBOXES"
                || realEntity.category.toUpperCase() === "MOTIONSKIN") {
                entity.optionsMeshes.infills.forEach(infill => {
                    // Special case of infill for lightbox 724 ...
                    if (entity.ref.startsWith("724") && entity.swappedOptions.INFILL) {
                        infill.locallyTranslate(new Vector3(0, 0, -0.063));
                    }
                    if (!infill.rotationQuaternion) {
                        infill.rotationQuaternion = infill.rotation.toQuaternion();
                    }
                    const quat = Quaternion.RotationAxis(Vector3.Up(), Math.PI);
                    infill.rotationQuaternion.multiplyInPlace(quat);
                    if (entity.ref.startsWith("724") && !entity.swappedOptions.INFILL) {
                        infill.locallyTranslate(new Vector3(0, 0, 0.063));
                    }
                    infill.computeWorldMatrix(true);
                });
            } else {
                this.updateInfillMeshes(entity, entity.infillOption);
            }
        }
    }

    /**
     * Toggle the infills visibility into the scene
     * @param {boolean} isVisible
     * @param {*} entity
     */
    toggleInfillsVisibility(isVisible, entity = null) {
        const realEntity = this.optionController.returnRealEntity(entity);
        if (realEntity.optionsMeshes.infills.length > 0) {
            realEntity.optionsVisibility.infills = isVisible;

            self.app.modules.geometryUtility.toggleMeshesVisibility(
                realEntity.optionsMeshes.infills,
                isVisible && realEntity.visible
            );
        }
    }

    /**
     * Some infills are not procedurally generated and do not have one mesh for each side of the
     * frame to put infills on.
     * For the cases we add to entries to mesh-manager with same ref suffixed with IN or OUT
     * Those have the same geometry has the original unique infill but they are textured on a
     * different side
     * Here we register those two entries and the mesh-manager do the other part of the job
     * /!\ Those entries are intentionally not added to the catalog
     */
    registerSpecialInfillInOut() {
        this.specialInfillsFrameReference.forEach(entityRef => {
            const product = this.catalogManager.products[entityRef];
            const ref = this.getInfillReference(product).trim();
            const infillProduct = this.catalogManager.products[ref];

            // Load the mesh from S3
            this.catalogManager.getProxyMeshFromURL(
                `${config.meshesUrl}/${infillProduct.url}`, ref
            )
                .then(localUrl => {
                    // If the mesh was loaded register the two entries in the mesh-manager
                    if (localUrl) {
                        this.meshManager.loadGeometry(
                            `${ref} IN`,
                            localUrl,
                            {
                                subCategory: infillProduct.subCategory,
                                category: infillProduct.category,
                                description: infillProduct.description,
                                ref,
                                isConnector: infillProduct.isConnector,
                            }
                        );
                        this.meshManager.loadGeometry(
                            `${ref} OUT`,
                            localUrl,
                            {
                                subCategory: infillProduct.subCategory,
                                category: infillProduct.category,
                                description: infillProduct.description,
                                ref,
                                isConnector: infillProduct.isConnector,
                            }
                        );
                    }
                });
        });
    }

    setAllInfillsVisibility(infillsVisibility) {
        this.optionController.showInfills = infillsVisibility;

        if (this.optionController.showInfills) {
            this.optionController.infillsVisibilityLocked = false;
        }
        const entities = self.app.modules.dataStore.listEntities(
            "/products/*"
        );
        entities.forEach(entity => {
            if (entity.isOptionable) {
                this.toggleInfillsVisibility(
                    infillsVisibility,
                    entity,
                );
            }
        });

        if (!this.optionController.showInfills) {
            this.optionController.infillsVisibilityLocked = true;
        }

        self.app.modules.history.snapshot();
    }

    displayOneImageOnManyInfills(entityList, imageBlob) {
        const infillInfos = InfillHelper.generateInfillListInfos(entityList);
        const infillSizeData = InfillHelper.computeHeightAndWidthFromInfillInfos(infillInfos);

        const img = new Image();
        img.onload = () => {

            const promiseArray = [];
            infillInfos.forEach(
                infillInfo => {
                    const promise = new Promise(
                        (resolve, reject) => {
                            try {
                                const canvas = this.infillHelper.createCanvasForInfill(img,
                                    infillInfo,
                                    infillSizeData);
                                canvas.toBlob(
                                    blob => {
                                        const objectUrl = assetsManager.controller
                                            .addNewImportedImageFromBlob(blob);
                                        this.setInfillFromUrlObject(
                                            objectUrl,
                                            [infillInfo.entity],
                                            0
                                        ).then(
                                            () => {
                                                canvas.remove();
                                                resolve();
                                            }
                                        );
                                    }
                                );
                            } catch (err) {
                                self.app.log.error("Can't spread image ", err);
                                reject();
                            }
                        }
                    );
                    promiseArray.push(promise);
                }
            );

            Promise.allSettled(promiseArray).then(
                () => {
                    self.app.events.emit("image-to-spread-loaded");
                    self.app.modules.history.snapshot();
                }
            );

        };
        img.src = URL.createObjectURL(imageBlob);

    }

}
