import self from "../..";
import GroupManager from "./group-controller";
import SelectionHelper from "../helpers/selection-helper";

const { GroupActionEnum } = SelectionHelper;
const { events, modules } = self.app;
const { history, selectionManager } = modules;

export default class GroupSelectionController {

    constructor(groupManager) {
        this.vueData = {
            selectedEntities: [],
            selectedGroups: [],
        };
        this.GroupManager = groupManager;
        events.on("@data-store.entity-removed", (entity) => {
            this.removeSelectedEntity(entity);
        });
        events.on("@selection-manager.select-entity-scene", (entity) => {
            this.selectProductFromScene(entity);
        });
        events.on("@selection-manager.unselect-entity-scene", (entity) => {
            this.unselectProductFromScene(entity);
        });
    }

    /**
     * Group all the selected Entities
     */
    groupSelectedEntities() {
        let noConnectorsObject = this.vueData.selectedEntities.filter(
            entity => !entity.isConnector
        );
        if (noConnectorsObject.length <= 1) {
            self.app.log.error(`too few selected entites to make a group :
                ${noConnectorsObject.length}`);
            return;
        }
        const alreadySetted = noConnectorsObject.some((
            entity => entity.group.id !== this.GroupManager.defaultGroup.id
        ));
        if (alreadySetted) {
            // TODO warn message in a popup
            self.app.log.warn("You tried to set a group to entities which already had one");
            return;
        }
        const newGroup = GroupManager.createGroup();
        noConnectorsObject.forEach(
            (product) => {
                this.GroupManager.addEntityToGroup(product, newGroup);
            }
        );
        while (noConnectorsObject.length > 0) {
            this.unselectProduct(noConnectorsObject[0]);
            noConnectorsObject = this.vueData.selectedEntities.filter(
                entity => !entity.isConnector
            );
        }
        history.snapshot();
    }

    /**
     * Unselect an entity on the UI
     * @param {EntityStructure} entity
     */
    removeSelectedEntity(entity) {
        const indexToRemoveProducts = this.vueData.selectedEntities.indexOf(entity);
        if (indexToRemoveProducts !== -1) {
            this.vueData.selectedEntities.splice(indexToRemoveProducts, 1);
            return;
        }
        const indexToRemoveGroups = this.vueData.selectedGroups.indexOf(entity);
        if (indexToRemoveGroups !== -1) {
            this.vueData.selectedGroups.splice(indexToRemoveGroups, 1);
        }
    }

    /**
     * Select a product on the UI
     * @param {EntityStructure} product
     */
    selectProduct(product) {
        if (!product.visible || product.isConnector) {
            return;
        }
        // We unselect the group of the product if this one is selected
        if (this.isSelectedGroup(product.group)) {
            this.unselectGroup(product.group);
        }
        // Classic selection
        if (!this.vueData.selectedGroups.length) {
            switch (this.vueData.selectedEntities.length) {
            // If we are selecting one mesh
            case 0:
                selectionManager
                    .selectEntity(product.mesh, false);
                break;
            case 1:
                selectionManager.unselectEntity();
                selectionManager
                    .multiSelectMesh(this.vueData.selectedEntities[0]);
                selectionManager.multiSelectMesh(product);
                break;
            default:
                selectionManager.multiSelectMesh(product);
            }
        } else {
            selectionManager.multiSelectMesh(product);
        }
        this.vueData.selectedEntities.push(product);
        this.checkGroupSelection(product);
    }

    /**
     * Select a product on the UI that have been selected on the scene
     * @param {Entity-structure} product
     */
    selectProductFromScene(product) {
        if (product.isConnector) {
            return;
        }
        this.vueData.selectedEntities.push(product);
        this.checkGroupSelection(product);
    }

    /**
     * Each time we select/unselect a product we check if we need to select
     * or unselect its group
     * @param {*} product
     */
    checkGroupSelection(product) {

        const noConnectorProductList = product.group.productList.filter(
            groupProduct => !groupProduct.isConnector
        );
        if (product.group.id === this.GroupManager.defaultGroup.id
            || noConnectorProductList.length === 1) {
            return;
        }
        // If we have selected all the products of a group, we select the group instead
        const filteredProducts = this.vueData.selectedEntities.filter(
            currentProduct => (currentProduct.group.id === product.group.id && !product.isConnector)
        );
        if (!(filteredProducts.length === noConnectorProductList.length)) {
            return;
        }
        this.selectGroup(product.group);
    }

    /**
     * Unselect a product on the UI
     * @param {EntityStructure} product
     */
    unselectProduct(product) {
        // This case should never happen, else something is wrong
        if (!product.visible || product.isConnector) {
            self.app.log.error("You unselected a product that is invisible or that is a connector"
                + " this should not happen");
            return;
        }
        const index = this.vueData.selectedEntities.indexOf(product);
        if (this.vueData.selectedEntities.length === 1 && !this.vueData.selectedGroups.length) {
            selectionManager.unselectEntity();
        } else {
            selectionManager.removeOneMultiSelectMesh(product);
        }
        this.vueData.selectedEntities.splice(index, 1);
        if (this.vueData.selectedEntities.length === 1 && !this.vueData.selectedGroups.length) {
            const lastProduct = this.vueData.selectedEntities[0];
            selectionManager
                .removeOneMultiSelectMesh(lastProduct);
            selectionManager
                .selectEntity(lastProduct.mesh, false);
        }
    }

    /**
     * Unselect a product on the UI that have been selected on the scene
     * @param {Entity-structure} product
     */
    unselectProductFromScene(product) {
        if (product.isConnector) {
            return;
        }
        if (this.isSelectedGroup(product.group) && !product.group.default) {
            const filteredProductList = product.group.productList.filter(
                groupProduct => !groupProduct.isConnector
            );
            filteredProductList.forEach(
                (selectedProduct) => {
                    this.vueData.selectedEntities.push(selectedProduct);
                }
            );
            const index = this.vueData.selectedGroups.indexOf(product.group);
            this.vueData.selectedGroups.splice(index, 1);
        }
        const index = this.vueData.selectedEntities.indexOf(product);
        this.vueData.selectedEntities.splice(index, 1);
    }

    /**
     * Select a group on the UI
     * @param {GroupStructure} group
     */
    selectGroup(group) {
        // Disable default group selection
        if (group.default) {
            group.productList.forEach(
                (product) => {
                    if (!product.visible || product.isConnector) {
                        return;
                    }
                    if (!this.isSelectedProduct(product)) {
                        this.selectProduct(product);
                    }
                }
            );
            return;
        }
        const groupSelectedEntities = this.vueData.selectedEntities.filter(
            product => (product.group.id === group.id && !product.isConnector)
        );

        // If some group's products are selected, we unselect them and select the group
        switch (groupSelectedEntities.length) {
        case 0:
            break;
        case 1:
            selectionManager.unselectEntity();
            this.vueData.selectedEntities.splice(0, 1);
            break;
        default:
            groupSelectedEntities.forEach(
                (product) => {
                    this.unselectProduct(product);
                }
            );
        }

        // If we select a group and one product
        if (this.vueData.selectedEntities.length === 1
            && this.vueData.selectedGroups.length === 0) {
            selectionManager.unselectEntity();
            selectionManager
                .multiSelectMesh(this.vueData.selectedEntities[0]);
        }

        const filteredProductList = group.productList.filter(
            product => !product.isConnector
        );
        const visibleProducts = filteredProductList.filter(
            product => product.visible
        );
        // We disable the possibility to select product which are invisible
        if (visibleProducts.length === filteredProductList.length) {
            filteredProductList.forEach(
                (product) => {
                    selectionManager.multiSelectMesh(product);
                }
            );
            this.vueData.selectedGroups.push(group);
            events.emit("select-group");
        } else {
            visibleProducts.forEach(
                (product) => {
                    this.selectProduct(product);
                }
            );
        }
    }

    /**
     * Unelect a group on the UI
     * @param {GroupStructure} group
     */
    unselectGroup(group) {
        const noConnectorProductList = group.productList.filter(
            groupProduct => !groupProduct.isConnector
        );
        noConnectorProductList.forEach(
            (product) => {
                selectionManager
                    .removeOneMultiSelectMesh(product);
            }
        );
        const index = this.vueData.selectedGroups.indexOf(group);
        this.vueData.selectedGroups.splice(index, 1);
        const filteredSelectedEntities = this.vueData.selectedEntities.filter(
            groupProduct => !groupProduct.isConnector
        );
        if (filteredSelectedEntities.length === 1) {
            const product = filteredSelectedEntities[0];
            selectionManager.removeOneMultiSelectMesh(product);
            selectionManager.selectEntity(product.mesh, false);
        }
        events.emit("unselect-group");
    }

    /**
     * Update group selection when we select/unselect it
     * from the scene
     * @param {*} group
     */
    unselectGroupFromScene(group) {
        const index = this.vueData.selectedGroups.indexOf(group);
        this.vueData.selectedGroups.splice(index, 1);
    }

    /**
     * Ungroup all selected groups
     */
    ungroupAllSelected() {
        this.vueData.selectedGroups.forEach(
            (group) => {
                this.GroupManager.ungroup(group);
            }
        );
        while (this.vueData.selectedGroups.length > 0) {
            this.unselectGroup(this.vueData.selectedGroups[0]);
        }
        events.emit("ungroup");
        history.snapshot();
    }

    /**
     * Merge all selected groups
     */
    fusionSelectedGroups() {
        if (this.vueData.selectedEntities.length > 0) {
            self.app.log.error("you must not have a product selected to merge groups");
            return;
        }
        const newGroup = GroupManager.createGroup();
        while (this.vueData.selectedGroups.length) {
            const group = this.vueData.selectedGroups.pop();
            let filteredGroupProducts = group.productList.filter(
                groupProduct => !groupProduct.isConnector
            );
            while (filteredGroupProducts.length) {
                const product = filteredGroupProducts[0];
                selectionManager
                    .removeOneMultiSelectMesh(product);
                this.GroupManager.addEntityToGroup(product, newGroup);
                filteredGroupProducts = group.productList.filter(
                    groupProduct => !groupProduct.isConnector
                );
            }
        }
        events.emit("merge-groups");
        history.snapshot();
    }


    /**
     * Add selected product to selected group
     */
    addSelectedProductsToSelectedGroup() {
        if (this.vueData.selectedEntities.length === 0
            || this.vueData.selectedGroups.length !== 1) {
            self.app.log.warn("This function should not be called, you have more"
            + "than one group selected or no entites selected");
            return;
        }

        const group = this.vueData.selectedGroups[0];
        while (this.vueData.selectedEntities.length > 0) {
            const product = this.vueData.selectedEntities[0];
            this.unselectProduct(product);
            this.GroupManager.addEntityToGroup(product, group);
        }
        this.unselectGroup(group);
        history.snapshot();
    }

    /**
     * Remove the selected product from the selected Group
     */
    removeProductsFromSelectedGroup() {
        if (this.vueData.selectedEntities.length === 0
            || this.vueData.selectedGroups.length > 0) {
            self.app.log.warn("This function should not be called, you have more"
            + "than one group selected or no entites selected");
            return;
        }
        while (this.vueData.selectedEntities.length > 0) {
            const product = this.vueData.selectedEntities[0];
            this.unselectProduct(product);
            this.GroupManager.removeFromGroup(product);
        }
        history.snapshot();
    }

    /**
     * Returns the action to use on the UI
     * @return {int} id : the id of the Action for the UI
     * @return {String} label : the label of the button that will use the action
     * @return {int} buttonType : the id of the button that will call the action
     */
    getGroupActionStatus() {
        // Only ungrouped selectedEntities
        let returnAction = GroupActionEnum.NONE;
        returnAction.callback = () => {};
        // Check if all selected entities are from default group
        const allSelectedAreDefault = this.vueData.selectedEntities.every(
            entity => entity.group.id === this.GroupManager.defaultGroup.id
        );
        if (this.vueData.selectedEntities.length > 0 && this.vueData.selectedGroups.length === 0) {
            // Check if all selected entities are NOT from default group and have same group
            const allSelectedAreSameGroup = (this.vueData.selectedEntities[0].group.id
                !== this.GroupManager.defaultGroup.id)
                && this.vueData.selectedEntities.every(
                    entity => (entity.group.id === this.vueData.selectedEntities[0].group.id)
                );

            if (allSelectedAreDefault && this.vueData.selectedEntities.length > 1) {
            // GROUP SELECTED ENTITIES
                returnAction = GroupActionEnum.GROUP;
                returnAction.callback = () => { this.groupSelectedEntities(); };

            } else if (allSelectedAreSameGroup && this.vueData.selectedEntities.length >= 1) {
                // REMOVE SELECTED ENTITIES FROM GROUP
                returnAction = GroupActionEnum.LEAVE;
                returnAction.callback = () => { this.removeProductsFromSelectedGroup(); };
            }

        } else if (this.vueData.selectedEntities.length === 0) {
            // 1 group selected and is not default
            const isDefault = this.vueData.selectedGroups.some(group => group.default);
            if (this.vueData.selectedGroups.length === 1 && !isDefault) {
                // UNGROUP SELECTED GROUP
                returnAction = GroupActionEnum.UNGROUP;
                returnAction.callback = () => { this.ungroupAllSelected(); };

                // Only groups selected
            } if (this.vueData.selectedGroups.length > 1 && !isDefault) {
                // MERGE SELECTED GROUPS
                returnAction = GroupActionEnum.FUSION;
                returnAction.callback = () => { this.fusionSelectedGroups(); };
            }

        } else if (this.vueData.selectedEntities.length >= 1
            && this.vueData.selectedGroups.length === 1 && allSelectedAreDefault) {
            // ADD SELECTION TO GROUP
            returnAction = GroupActionEnum.ADD;
            returnAction.callback = () => { this.addSelectedProductsToSelectedGroup(); };
        }
        return returnAction;
    }

    isSelectedGroup(group) {
        return (this.vueData.selectedGroups.indexOf(group) >= 0);
    }

    isSelectedProduct(product) {
        return (this.vueData.selectedEntities.indexOf(product) >= 0);
    }

    getSelectedEntities() {
        return this.vueData.selectedEntities;
    }

    getVueData() {
        return this.vueData;
    }

}
