export default {
    name: "collision-manager",
    requires: ["data-store", "highlight-manager", "mesh-manager"],
    load() {
        const Controller = require("./src/controller/collision-controller").default;
        const ExportedController = new Controller();
        const CollisionHelper = require("./src/helpers/collision-helper").default;
        return {
            Controller: ExportedController,
            CollisionHelper,
        };
    },
    unload() {},
};
