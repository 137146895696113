<template>
    <ul id="category-menu">
        <li
            v-for="templateCategory in templates"
            :key="templateCategory.name"
            class="category-item"
        >
            <template
                v-if="canDisplayTemplateCategory(templateCategory)"
            >
                <label @click="toggleTemplates(templateCategory.category)">
                    <i
                        v-if="currentTemplateCategory !== templateCategory.category"
                        class="fas fa-angle-right"
                    />
                    <i
                        v-else
                        class="fas fa-angle-down"
                    />
                    <span>{{ _(templateCategory.name) }} &nbsp;</span>
                </label>
                <div
                    v-show="currentTemplateCategory === templateCategory.category"
                    class="products-menu template-menu"
                >
                    <product
                        v-for="(product, index) in currentTemplates"
                        :key="index"
                        :infos="product"
                        :name="product.name"
                        :reference="product.name"
                        :is-available="true"
                        :thumbnail-path="product.urlThumbnail"
                        :is-raw-thumbnail-path="true"
                        :custom-click-handling="true"
                        @click="loadTemplate(product.urlBmx, product.infillsImg)"
                    />
                </div>
            </template>
        </li>
        <li
            v-for="groupedObjectCategory in groupedObjects"
            :key="groupedObjectCategory.name"
            class="category-item"
        >
            <template
                v-if="canDisplayGroupedObjectsCategory(groupedObjectCategory)"
            >
                <label @click="toggleGroupedObjects(groupedObjectCategory.category)">
                    <i
                        v-if="currentGroupObjectsCategory !== groupedObjectCategory.category"
                        class="fas fa-angle-right"
                    />
                    <i
                        v-else
                        class="fas fa-angle-down"
                    />
                    <span>{{ _(groupedObjectCategory.name) }} &nbsp;</span>
                </label>
                <div
                    v-show="currentGroupObjectsCategory === groupedObjectCategory.category"
                    class="products-menu template-menu"
                >
                    <product
                        v-for="(product, index) in currentGroupedObjects"
                        :key="index"
                        :infos="product"
                        :name="product.name"
                        :reference="product.name"
                        :is-available="true"
                        :thumbnail-path="product.urlThumbnail"
                        :is-raw-thumbnail-path="true"
                        :custom-click-handling="true"
                        @click="loadGroupProduct(product.urlBgo, product.name)"
                    />
                </div>
            </template>
        </li>
    </ul>
</template>

<script>
import RightsHelper from "helpers/rights-helper";
import self from "../../..";
import product from "./catalog-product.vue";

export default {
    name: "Templates",
    components: {
        product,
    },
    props: {
        templates: {
            type: Array,
            default: () => [],
        },
        groupedObjects: {
            type: Array,
            default: () => [],
        },
        config: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            TEMPLATE_CATEGORIES: {
                BEMATRIX: 0,
                SELF: 1,
                NONE: -1,
            },
            GROUPED_OBJECT_CATEGORIES: {
                BEMATRIX: 0,
                SELF: 1,
                NONE: -1,
            },
            currentTemplateCategory: -1,
            currentGroupObjectsCategory: -1,
            showTemplatesPanel: false,
            currentTemplates: null,
            currentGroupedObjects: null,
            bmTemplatesUrl: `${this.config.amazonS3Url}/`,
        };
    },
    mounted() {
        self.app.events.on("@selection-manager.select-entity", () => {
            this.closeTemplateTabs();
        });
    },
    methods: {
        /**
         * Show the templates of the given template category
         */
        toggleTemplates(templateCategory) {
            // overlapping on bottom menu
            if (templateCategory === this.currentTemplateCategory) {
                this.currentTemplateCategory = this.TEMPLATE_CATEGORIES.NONE;
                return;
            }
            if (this.currentGroupObjectsCategory !== this.TEMPLATE_CATEGORIES.NONE) {
                this.currentGroupObjectsCategory = this.TEMPLATE_CATEGORIES.NONE;
            }
            self.app.modules.selectionManager.unselectAll();// TODO : temporary to avoid left menu
            this.currentTemplateCategory = templateCategory;
            this.currentTemplates = this.templates[templateCategory].templates;
        },

        /**
         * Show the templates of the given template category
         */
        toggleGroupedObjects(groupedObjectCategory) {
            // overlapping on bottom menu
            if (groupedObjectCategory === this.currentGroupObjectsCategory) {
                this.currentGroupObjectsCategory = this.GROUPED_OBJECT_CATEGORIES.NONE;
                return;
            }
            if (this.currentTemplateCategory !== this.GROUPED_OBJECT_CATEGORIES.NONE) {
                this.currentTemplateCategory = this.GROUPED_OBJECT_CATEGORIES.NONE;
            }

            self.app.modules.selectionManager.unselectAll();// TODO : temporary to avoid left menu
            this.currentGroupObjectsCategory = groupedObjectCategory;
            this.currentGroupedObjects = this.groupedObjects[groupedObjectCategory].groupObjects;
        },

        /**
         * Close all template and group object tabs
         */
        closeTemplateTabs() {
            if (this.currentGroupObjectsCategory !== this.GROUPED_OBJECT_CATEGORIES.NONE) {
                this.toggleGroupedObjects(this.currentGroupObjectsCategory);
            } else if (this.currentTemplateCategory !== this.TEMPLATE_CATEGORIES.NONE) {
                this.toggleTemplates(this.currentTemplateCategory);
            }
        },

        /**
         * Load the given template at the given url
         */
        loadTemplate(url, infillsImg) {
            self.app.events.emit("open-popin", {
                title: this._("Load a template"),
                description: this._(
                    "All unsaved progress will be lost. Do you want to proceed and load the selected template ?"
                ),
                onValidate: () => {
                    self.app.modules.projectManager.controller.openTemplate(
                        url,
                        infillsImg
                    );
                },
            });
        },

        loadGroupProduct(url, name) {
            self.app.modules.projectManager.controller.openStoredProductFromUrl(url, name);
        },

        computeTemplateCategoryName(type) {
            if (type === this.TEMPLATE_CATEGORIES.BEMATRIX) {
                return this._("BeMatrix templates");
            }
            return this._("My templates");
        },

        computeGroupedObjectCategoryName(type) {
            if (type === this.GROUPED_OBJECT_CATEGORIES.BEMATRIX) {
                return this._("BeMatrix Group of Objects");
            }
            return this._("My Group of Objects");
        },

        canDisplayTemplateCategory(templateCategory) {
            return !RightsHelper.isModeDemo()
                    || (RightsHelper.isModeDemo() && templateCategory.category === 0);
        },
        canDisplayGroupedObjectsCategory(groupedObjectsCategory) {
            return !RightsHelper.isModeDemo()
                    || (RightsHelper.isModeDemo() && groupedObjectsCategory.category === 0);
        },

        _(...args) {
            return self.app.modules.stonejs.gettext(...args);
        },
    },
};
</script>

<style lang="scss">
@import "../../../../../style/bematrix";
</style>
