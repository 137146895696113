import {
    TransformNode,
    PointerDragBehavior,
    Quaternion,
    Vector3,
    SpriteManager,
    Space,
} from "@babylonjs/core";

import RightsHelper from "helpers/rights-helper";
import DragHelper from "helpers/drag-helper";
import VueHelper from "helpers/vue-helper";
import EntitiesData from "../../../../entities-data";

import DuplicateControl from "../models/duplicate-control";
import SelectionHelper from "../helpers/selection-helper";

import self from "../..";

const {
    events,
    modules: {
        optionManager: {
            optionController: OptionController,
        },
        obsidianEngine: {
            controller: engineController,
        },
        geometryUtility,
        gizmoManager: {
            controller: gizmoController,
        },
        gridManager,
        history,
        dataStore,
        entityManager: {
            Controller: EntityController,
            Helper: EntityHelper,
        },
        cameraManager: {
            controller: CameraController,
            ScreenshotController,
        },
        guiManager,
        meshManager: {
            meshUtility,
        },
        measurementManager: {
            Controller: measurementController,
        },
        highlightManager,
    },
    log,
} = self.app;

export default class SelectionController {

    constructor() {
        this.scene = null;
        this.currentEntity = null;
        this.selectMesh = null;
        this.nodeMesh = null;
        this.copying = false;
        this.multiSelectedMesh = [];
        this.spriteManagerDuplicate = null;

        if (engineController.ready) {
            this.scene = engineController.scene;
            this.initSelectionController();
        } else {
            events.on("@obsidian-engine.engine-ready", scene => {
                this.scene = scene;
                this.initSelectionController();
            });
        }

        // vue binding
        const that = this;
        this.vueData = {
            // yep you can put here some getter-setters to handle vue datas better
            get multiSelectedMesh() {
                return that.multiSelectedMesh;
            },

            /**
             * As this value is an integer vue don't keep a reference of it but a value of it
             * that's why i created the object below
             */
            currentEntityBaseplateType: {
                get data() {
                    if (that.currentEntity) {
                        return that.currentEntity.getBaseplateOption();
                    } return null;
                },
            },
            currentEntityGroundplateType: {
                get data() {
                    if (that.currentEntity) {
                        return that.currentEntity.getGroundplateOption();
                    } return null;
                },
            },
            currentEntityLightType: {
                get data() {
                    if (that.currentEntity) {
                        return that.currentEntity.getLightOption();
                    } return null;
                },
            },
        };

        VueHelper.AddVueProperty(this, "duplicationActive", false);
        this.meshToInitialize = null;

        this.initEvents();

        window.selectionController = this;
    }

    initSelectionController() {
        this.spriteManagerDuplicate = new SpriteManager(
            "duplicateManager",
            "assets/images/duplication.png",
            6,
            256,
            this.scene
        );
        this.spriteManagerDuplicate.isPickable = true;

        this.dragSizeViewer = new guiManager.DragSizeViewer(guiManager.GuiController, this.scene);
    }

    /**
     * Initialive all events related to the selection
     */
    initEvents() {
        events.on("@gizmo-manager.show-gizmo", () => {
            this.removeControlToCurrentEntity();
        });

        events.on("@entity-manager.recreated-mesh", entity => {
            if (this.currentEntity && this.currentEntity.id === entity.id) {
                this.selectEntity(this.currentEntity.mesh, false, false, false);
            }
        });

        events.on("duplicated", mesh => {
            this.unselectEntityOnScene();
            this.selectEntityOnScene(mesh, false);
            this.addControlToCurrentEntity(mesh);
        });

        // -- Snapping Events

        events.on("@snapping.snap", snappingParams => {
            this.onSnap(snappingParams.snappingEntity.mesh);
        });

        events.on("@snapping.ghost", () => {
            this.toggleSelectMeshVisibility(false, false);
        });

        events.on("@snapping.unghost", () => {
            this.toggleSelectMeshVisibility(true, false);
        });

        // -- Engine events : keyboard, mouse, drag, selection..

        events.on("@obsidian-engine.undo", () => {
            this.unselectAll();
            history.back();
        });

        events.on("@obsidian-engine.redo", () => {
            this.unselectAll();
            history.forward();
        });

        events.on("@obsidian-engine.copy", () => {
            this.copyCurrentSelection();
        });

        events.on("@obsidian-engine.copyAndMove", () => {
            const copies = this.copyCurrentSelection(false);
            if (copies.length) {
                this.selectEntities(copies);
                this.showSelectMeshPositionGizmo();
            }
        });

        events.on("@obsidian-engine.copyAndRotate", () => {
            const copies = this.copyCurrentSelection(false);
            if (copies.length) {
                this.selectEntities(copies);
                this.showSelectMeshRotationGizmo();
            }
        });

        events.on("@obsidian-engine.copyAndReplace", () => {
            if (!this.currentEntity) {
                return;
            }
            const copies = this.copyCurrentSelection(false);
            if (copies.length) {
                this.selectEntities(copies);
                this.showSelectMeshScaleGizmo();
            }
        });

        events.on("@obsidian-engine.paste", () => {
            this.pasteCurrentCopy();
        });
        events.on("@obsidian-engine.move", () => {
            this.showSelectMeshPositionGizmo();
        });
        events.on("@obsidian-engine.rotate", () => {
            this.showSelectMeshRotationGizmo();
        });
        events.on("@obsidian-engine.rotate90", () => {
            this.rotate90DegreesCurrentSelection();
        });
        events.on("@obsidian-engine.scale", () => {
            if (this.currentEntity
                && EntitiesData.entityTypeFromSubCategory(
                    this.currentEntity.subCategory,
                    this.currentEntity.ref
                )) {
                this.showSelectMeshScaleGizmo();
            }
        });

        events.on("@obsidian-engine.duplicate", () => {
            this.toggleDuplication();
        });

        events.on("@obsidian-engine.select-option", mesh => {
            this.selectOptionOnScene(mesh);
        });

        events.on("@obsidian-engine.drag-start-mesh", mesh => {
            if (this.meshToInitialize) {
                // We are initializing a mesh on the scene, we block the event
                return;
            }
            this.unselectAll();
            this.selectEntityOnScene(mesh);
        });

        events.on("@obsidian-engine.multiselect-mesh", mesh => {
            this.multiSelectEntityOnScene(mesh);
        });

        events.on("@obsidian-engine.multiunselect-mesh", selectMesh => {
            this.multiUnselectEntityOnScene(selectMesh);
        });

        events.on("@obsidian-engine.select-end-mesh", mesh => {
            this.stopDragMesh(mesh);
        });

        events.on("@obsidian-engine.drag-end-mesh", mesh => {
            this.stopDragMesh(mesh);
            history.snapshot();
        });

        events.on("@obsidian-engine.drag-start-select", mesh => {
            this.removeControlToCurrentEntity();
            this.deactivateGizmo();
            // In the case the mesh selected is not a selectMesh but an option we must start the
            // drag by hand
            if (!RightsHelper.isModePublic()
                && !RightsHelper.isModeBuildingPlan()
                && mesh.selectMesh
                && mesh.selectMesh === this.selectMesh) {
                const result = engineController.eventHandler.mouse.sceneMousePick();

                this.selectMesh.behaviors[0].startDrag(-2, result.ray, result.pickedPoint);
            }
        });

        events.on("@obsidian-engine.pointer-down-sprite", () => {
            this.stopDragMesh(this.selectMesh);
        });

        // -- Camera Events

        events.on("@camera-manager.start-screenshot-behavior", () => {
            this.toggleSelectionHighlight(false);
        });

        events.on("@camera-manager.end-screenshot-behavior", () => {
            this.toggleSelectionHighlight();
        });

        // -- Events provoking an unselection

        const unselectAllEvents = ["@project-manager.clear-scene", "@obsidian-engine.unselect",
            "@measurement-manager.measurement-selected", "@bematrix-ui.toggle-products"];

        unselectAllEvents.forEach(
            event => {
                events.on(event, () => {
                    this.unselectAll();
                });
            }
        );
    }

    /**
     * Select an entity and emit an event
     */
    selectEntityOnScene(mesh, dragging = true) {
        this.selectEntity(mesh, dragging);
        events.emit("select-entity-scene", this.currentEntity);
    }

    /**
     * Make the mesh the selected mesh of the scene, and create a selectMesh for it
     *
     * This doesn't use currentEntity has options are not entities
     *
     * @param{Mesh} mesh
     */
    selectOptionOnScene(mesh) {
        this.deactivateGizmo();
        if (this.selectMesh) {
            this.removeCurrentSelectMesh();
        }

        // Create the option select mesh
        this.selectMesh = SelectionHelper.createOptionSelectMesh(mesh);

        // The original mesh is returned in order to have a consistent id to refer to
        // as the select mesh is recreated every time
        events.emit("select-option-scene", mesh);
    }

    /**
     * Unselect an entity and emit an event
     */
    unselectEntityOnScene() {
        if (!this.currentEntity) {
            return;
        }
        events.emit("unselect-entity-scene", this.currentEntity);
        this.unselectEntity();
    }

    /**
     * Select an entity when ctrl+clic on a selectmesh
     * @param {Mesh} selectMesh
     */
    multiSelectEntityOnScene(mesh) {
        if (!this.multiSelectedMesh.length && !this.currentEntity) {
            this.selectEntityOnScene(mesh);
            return;
        }
        this.multiSelectedMesh.forEach(selectMesh => {
            selectMesh.computeWorldMatrix(true);
        });
        if (this.currentEntity) {
            if (this.currentEntity === mesh.entity) {
                // We should never get here but we disable the actions in this case
                log.error(`Error on selecting: ${mesh.name} You just selected a
                mesh that is already selected
                this should not happen because if this mesh is selected you can't interact
                with it but only with it's select mesh`);
                return;
            }
            const entity = this.currentEntity;
            this.unselectEntityOnScene();
            this.multiSelectMesh(entity);
            events.emit("select-entity-scene", entity);
        }
        this.multiSelectMesh(mesh.entity);
        events.emit("select-entity-scene", mesh.entity);
    }

    /**
     * Unselect an entity when ctrl+clic on a selectmesh
     * @param {Mesh} selectMesh
     */
    multiUnselectEntityOnScene(selectMesh) {
        // It means that there is only one mesh selected
        if (this.currentEntity) {
            this.unselectEntityOnScene();
            return;
        }
        if (selectMesh.behaviors && selectMesh.behaviors.length) {
            selectMesh.behaviors[0].releaseDrag();
        }
        this.deactivateGizmo();
        const entity = selectMesh.entity;
        this.removeOneMultiSelectMesh(entity);
        if (this.multiSelectedMesh.length === 1) {
            this.currentEntity = this.multiSelectedMesh[0].entity;
            this.selectMesh = this.multiSelectedMesh[0];
            this.setSelectMeshBehavior(this.selectMesh, false);
            this.multiSelectedMesh.splice(0, 1);
            events.emit("unselect-multiple-entities");
            events.emit("select-entity", this.currentEntity);
        }
        events.emit("unselect-entity-scene", entity);
    }

    /**
     * Called on selecting a mesh
     * The difference with selectEntityOnScene is that the other emit an event
     * that can be used for an UI module wich update itself in case of changing
     * when we select something
     * @param {*} mesh
     * @param {*} dragging
     * @param {Boolean} checkGizmo
     * @param {Boolean} emitEvent
     */
    selectEntity(mesh, dragging = true, checkGizmo = false, emitEvent = true) {
        const currentGizmo = gizmoController.currentGizmo;
        this.deactivateGizmo();
        if (this.selectMesh) {
            this.removeCurrentSelectMesh();
        }
        this.currentEntity = mesh.entity;
        this.currentEntity.unfreeze();
        OptionController.lightController.updateCanHaveLights();
        this.addCurrentSelectMesh(dragging);
        if (checkGizmo && currentGizmo) {
            gizmoController.showSpecificGizmo(currentGizmo, this.selectMesh);
        }
        if (emitEvent) {
            events.emit("select-entity", this.currentEntity);
        }
    }

    /**
     * Called on unselecting a mesh
     */
    unselectEntity() {
        this.deactivateGizmo();
        this.stopDragMesh(this.selectMesh);

        this.removeCurrentSelectMesh();
        this.removeControlToCurrentEntity();
        if (this.currentEntity) {
            this.currentEntity.mesh.setEnabled(true);
            this.currentEntity.freeze();
            this.currentEntity = null;
            events.emit("unselect-entity");
        }
    }

    selectEntities(entities) {
        this.unselectAll();
        if (entities.length === 1) {
            this.selectEntity(entities[0].mesh, false);
        } else {
            entities.forEach(entity => {
                this.multiSelectMesh(entity);
            });
        }
    }

    /**
     * Remove the current select mesh and stop dragging
     */
    unselectMesh() {
        this.deactivateGizmo();
        this.stopDragMesh(this.selectMesh);

        this.removeCurrentSelectMesh();
    }

    /** Unselect everything on scene */
    unselectAll() {
        this.destroyNodeMesh();

        while (this.multiSelectedMesh.length) {
            this.multiUnselectEntityOnScene(this.multiSelectedMesh[0]);
        }
        if (this.currentEntity) {
            this.unselectEntityOnScene();
        } else if (this.selectMesh) {
            this.unselectMesh();
        }

        events.emit("unselect-all");
    }

    /**
     * Delete all the products that are selected
     */
    deleteAllSelected() {
        if (this.currentEntity && !this.multiSelectedMesh.length) {
            this.removeCurrentEntity();
            this.removeControlToCurrentEntity();
            history.snapshot();
            return;
        }
        while (this.multiSelectedMesh.length) {
            const selectMesh = this.multiSelectedMesh.pop();
            const entityToRemove = selectMesh.entity;
            entityToRemove.mesh.selectMesh = null;
            selectMesh.dispose();
            dataStore.removeEntity(entityToRemove.id);
        }
        this.deactivateGizmo();
        history.snapshot();
        events.emit("delete-all-selected");
    }

    /**
     * Remove an entity by it's instance
     * Usefull when removing an entity from the UI
     * @param {*} entity
     */
    deleteEntityByRef(entity) {
        if (!this.currentEntity && entity.mesh.selectMesh) {
            this.multiUnselectEntityOnScene(entity.mesh.selectMesh);
        }
        dataStore.removeEntity(entity.id);
        history.snapshot();
    }

    /**
     * Call @createSelectMesh
     * And add behaviors to the mesh
     * @param {*} dragging
     */
    addCurrentSelectMesh(dragging = true) {
        this.selectMesh = SelectionHelper.createSelectMesh(this.currentEntity);
        this.setSelectMeshBehavior(this.selectMesh, dragging);
    }

    setSelectMeshBehavior(selectMesh, dragging = true) {
        const dragBehavior = new PointerDragBehavior({
            dragPlaneNormal: Vector3.Up(),
        });
        dragBehavior.useObjectOrientationForDragging = false;
        dragBehavior.updateDragPlane = true;
        dragBehavior.attachedMesh = selectMesh;
        selectMesh.checkCollisions = true;

        if (!RightsHelper.isModePublic() && !RightsHelper.isModeBuildingPlan()) {
            selectMesh.addBehavior(dragBehavior);
            dragBehavior.init();
            dragBehavior.attachedEntity = selectMesh.entity;

            // Filter clicks : if not left click, no drag
            const currentCallback = dragBehavior._pointerObserver.callback;
            dragBehavior._pointerObserver.callback = (pointerInfo, eventState) => {
                if (pointerInfo.event.button !== 0 && pointerInfo.event.type !== "pointermove") {
                    return;
                }
                currentCallback(pointerInfo, eventState);
            };

            EntityHelper.bindDragBehaviorToAttachedEntity(dragBehavior);
            dragBehavior.moveAttached = false;
            SelectionHelper.addVirtualGridBehavior(dragBehavior);
            if (selectMesh.entity.__name__ !== "connector") { // No collision with connectors
                SelectionHelper.addHighlightBehavior(dragBehavior, selectMesh);
            }
            // On start initialize gui, link mesh and store
            // selectMesh position in metadata
            dragBehavior.onDragStartObservable.add(() => {
                this.dragSizeViewer.initViewerBeforeDisplay(this.selectMesh);
            });

            // On drag update distances values, lines end points and end point position,
            // toggle gui elements visibility
            dragBehavior.onDragObservable.add(() => {
                this.dragSizeViewer.togglePlaneModeVisibility(true);
                const selectMeshCenter = this.selectMesh.entity.getBoundingBox()
                    .centerWorld.clone();
                const dragVector = selectMeshCenter.subtract(
                    this.selectMesh.metadata.dragOrigin
                );
                const projectionVectorLength = Vector3.Dot(
                    Vector3.Right(), dragVector
                );
                const projectedPoint = Vector3.Right().scale(projectionVectorLength);
                const rightAnglePoint = projectedPoint.add(
                    this.selectMesh.metadata.dragOrigin
                );

                // Set gui points position
                this.dragSizeViewer.setMiddlePointPosition(rightAnglePoint);
                this.dragSizeViewer.setEndPointPosition(
                    selectMeshCenter
                );
                this.dragSizeViewer.moveLineX(selectMeshCenter);
                this.dragSizeViewer.moveLineY(rightAnglePoint.clone());

                // Set vectors distanceLabel position and text
                this.dragSizeViewer.setLineXDisplay(rightAnglePoint, selectMeshCenter);
                this.dragSizeViewer.setLineYDisplay(
                    this.selectMesh.metadata.dragOrigin, rightAnglePoint
                );
            });

            // On end drag toggle gui elements visibility and remove drag start position from
            // metadata
            dragBehavior.onDragEndObservable.add(() => {
                this.dragSizeViewer.togglePlaneModeVisibility(false);
                this.dragSizeViewer.deinitViewerAfterDisplay(this.selectMesh);
            });

            if (dragging) {
            // -2 is the anyMouseID
                const result = engineController.eventHandler.mouse.sceneMousePick();
                dragBehavior.startDrag(-2, result.ray, result.pickedPoint);
            }
        }
    }

    /**
     * Called when we add a Frame on the scene. Enables the drag and drop from
     * the catalog
     * @param {*} entity the entity we add on the scene
     * @param {*} pointerInfo the pointerInfo of the mouseEvent (x,y)
     */
    initializingEntityOnScene(entity, pointerInfo, dragging = true) {
        // Init behavior
        this.unselectAll();

        if (!pointerInfo) {
            events.emit("end-initialization");
            throw new Error("Pointer info is null on initializing an Entity !");
        }
        this.deactivateGizmo();
        if (this.selectMesh) {
            this.removeCurrentSelectMesh();
        }

        // Set Entity main position
        entity.setPosition(new Vector3(0, entity.getBoundingBox().extendSizeWorld.y, 0));
        this.meshToInitialize = entity.mesh;
        this.meshToInitialize.entity = entity;

        const { mesh: temporaryPlane, pickResult } = DragHelper.createDragPlane(
            entity.mesh.position.y - (entity.height / 2),
            this.scene
        );

        if (pickResult.pickedPoint) {
            entity.position = new Vector3(
                pickResult.pickedPoint.x,
                pickResult.pickedPoint.y + entity.height / 2,
                pickResult.pickedPoint.z
            );
        } else {
            temporaryPlane.dispose();
            // Happens probably because the camera zooms to much and the plane is not pickable.
            // We reajust the camera so we can drag and drop
            CameraController.reajustCamera(() => {
                this.initializingEntityOnScene(entity, pointerInfo);
            });
            return;
        }
        if (!dragging) { // no drag: end initialization
            this.endMeshInitializing();
            temporaryPlane.dispose();
            return;
        }

        // Setup behavior
        const temporaryBehavior = new PointerDragBehavior({
            dragPlaneNormal: Vector3.Up(),
        });
        // Enables camera management when dragging the object
        temporaryBehavior.detachCameraControls = false;
        // Prevent default pointer behavior
        temporaryBehavior.startAndReleaseDragOnPointerEvents = false;

        temporaryBehavior.attachedEntity = entity;
        EntityHelper.bindDragBehaviorToAttachedEntity(temporaryBehavior);
        entity.mesh.checkCollisions = false;
        this.meshToInitialize.selectMesh = SelectionHelper.createSelectMesh(entity);
        temporaryBehavior.attach(this.meshToInitialize.selectMesh);
        temporaryBehavior.useObjectOrientationForDragging = false;

        // We add the dragging behavior with collisions
        SelectionHelper.addHighlightBehavior(temporaryBehavior, this.meshToInitialize.selectMesh);
        this.meshToInitialize.selectMesh.addBehavior(temporaryBehavior);
        this.meshToInitialize.selectMesh.checkCollisions = true;
        temporaryBehavior.temporaryPlane = temporaryPlane;
        temporaryBehavior.moveAttached = false;
        temporaryBehavior.onDragObservable.add(
            () => {
                // Translation part
                const pickPredicate = mesh => mesh.name === temporaryPlane.name;
                const result = this.scene.pick(
                    this.scene.pointerX,
                    this.scene.pointerY,
                    pickPredicate,
                    false,
                    this.scene.activeCamera
                );
                if (result.pickedPoint) {
                    const point = result.pickedPoint;
                    point.y += temporaryBehavior.attachedEntity.height / 2;
                    const diff = point.subtract(this.meshToInitialize.selectMesh.position);
                    // Directly use this function instead of "addVirtualGridBehavior" because we
                    // need to get the scene pointer position
                    SelectionHelper.translateByStep(this.meshToInitialize.selectMesh, diff);
                    this.meshToInitialize.selectMesh.computeWorldMatrix(true);
                }
            }
        );

        // We bind left click event and rrelease drag manually
        // This help camera use while dragging mesh
        const onceLeftClick = () => {
            temporaryBehavior.releaseDrag();
            events.removeListener("@obsidian-engine.pointer-left-click", onceLeftClick);
        };

        // Simulate a once event
        const removeInitializing = () => {
            temporaryBehavior.onDragEndObservable.clear();
            temporaryBehavior.releaseDrag();
            this.meshToInitialize.selectMesh.dispose();
            if (this.copying) {
                this.copying = false;
                this.meshToInitialize.selectMesh.entity.destroy();
            } else {
                dataStore.removeEntity(this.meshToInitialize.selectMesh.entity.id);
            }
            this.meshToInitialize = null;
            events.emit("end-initialization");
            events.removeListener("@obsidian-engine.pointer-left-click", onceLeftClick);
        };

        const onceCallback = () => {
            removeInitializing();
            events.removeListener("@obsidian-engine.unselect", onceCallback);
        };
        events.on("@obsidian-engine.unselect", onceCallback);

        // Clear observale so it's called once
        const onDragEndOnce = () => {
            temporaryBehavior.onDragEndObservable.clear();
            temporaryBehavior.detach();
            this.endMeshInitializing();
            events.removeListener("@obsidian-engine.unselect", onceCallback);
        };

        temporaryBehavior.onDragEndObservable.add(
            onDragEndOnce
        );

        events.on("@obsidian-engine.pointer-left-click", onceLeftClick);

        temporaryBehavior.startDrag();
    }

    /**
     * Called when click on the scene once the entity is positioned
     */
    endMeshInitializing() {
        const selectMesh = this.meshToInitialize.selectMesh;
        const entity = this.meshToInitialize.entity;

        if (this.copying) {
            const path = EntityHelper.getEntityPath(entity);
            dataStore.addEntity(entity, path);
            this.copying = false;
        }

        this.meshToInitialize.computeWorldMatrix(true);
        if (entity.updateRectsMatrixes) {
            entity.updateRectsMatrixes();
        }
        if (selectMesh) {
            selectMesh.behaviors[0].temporaryPlane.dispose();
            selectMesh.checkCollisions = false;
            selectMesh.dispose();
            this.meshToInitialize.selectMesh = null;
        }

        geometryUtility.toggleMeshVisibility(this.meshToInitialize, true, false);
        OptionController.toggleOptionsVisibility(
            true, {
                entity: this.meshToInitialize.entity,
                flag: OptionController.optionsFamilies.ALL,
            }
        );

        this.meshToInitialize.setEnabled(true);
        this.meshToInitialize.checkCollisions = true;
        this.meshToInitialize = null;
        entity.updatePosition();
        entity.updateRotation();
        entity.freeze();
        if (!gridManager.isBBInsideGrid(entity.mesh.getBoundingInfo().boundingBox)) {
            // If entity is outside grid we remove it and don't save it
            dataStore.removeEntity(entity);
        } else {
            history.snapshot();
        }
        events.emit("end-initialization", entity);
    }

    /**
     * Add this entity's mesh to the selectedMesh array
     * @param {*} entity
     */
    multiSelectMesh(entity) {
        this.deactivateGizmo();
        const selectMesh = SelectionHelper.createSelectMesh(entity);
        entity.unfreeze();
        this.multiSelectedMesh.push(selectMesh);
        events.emit("multi-select-entity", entity);
    }

    /**
     * Remove this entity's mesh from the selectedMesh array and dispose the selectMesh
     * @param {*} entity
     */
    removeOneMultiSelectMesh(entity) {
        this.deactivateGizmo();
        entity.freeze();
        const selectMesh = entity.mesh.selectMesh;
        const index = this.multiSelectedMesh.indexOf(selectMesh);
        if (index >= 0) {
            this.multiSelectedMesh.splice(index, 1);
            SelectionHelper.removeSelectMesh(entity);
        }
    }

    /**
     * Dispose the current entity
     */
    removeCurrentEntity() {
        if (this.currentEntity) {
            if (this.selectMesh) {
                this.selectMesh.checkCollisions = false;
            }
            this.currentEntity.mesh.checkCollisions = false;
            const entityToRemove = this.currentEntity;
            this.unselectEntity();
            return new Promise(
                resolve => {
                    const onDispose = entity => {
                        if (entityToRemove.id === entity.id) {
                            events.removeListener("@entity-manager.mesh-disposed",
                                onDispose);
                            resolve();
                        }
                    };
                    events.on("@entity-manager.mesh-disposed", onDispose);
                    dataStore.removeEntity(entityToRemove);
                }
            );
        }
        return new Promise(null);
    }

    /**
     * Dispose the copy of the original selected mesh
     */
    removeCurrentSelectMesh() {
        if (!this.selectMesh) {
            return;
        }

        if (this.selectMesh.originalMesh) {
            geometryUtility.toggleMeshVisibility(this.selectMesh.originalMesh, true, true);
        }

        if (this.selectMesh.metadata && this.selectMesh.metadata.isOption) {
            geometryUtility.toggleMeshVisibility(this.selectMesh, false, true);
        } else {
            geometryUtility.toggleMeshVisibility(this.selectMesh, false, false);
        }

        this.selectMesh.behaviors.forEach(behavior => behavior.onDragEndObservable.clear());
        this.selectMesh.dispose();
        this.selectMesh = null;

        if (this.currentEntity) {
            geometryUtility.toggleMeshVisibility(this.currentEntity.mesh, true, false);
            this.currentEntity.mesh.selectMesh = null;
            this.currentEntity.mesh.checkCollisions = true;
        }
    }

    /**
     * Show the + sprites allowing duplication of the current entity (see DuplicateControl class)
     * Remove gizmos if needed
     */
    addControlToCurrentEntity() {
        if (!this.currentEntity) {
            return;
        }
        this.removeControlToCurrentEntity();
        this.deactivateGizmo();

        this.duplicationActive = true;
        const leftPlus = new DuplicateControl("leftPlus", this.spriteManagerDuplicate, this.currentEntity, new Vector3(1, 0, 0), this.scene);
        const rightPlus = new DuplicateControl("rightPlus", this.spriteManagerDuplicate, this.currentEntity, new Vector3(-1, 0, 0), this.scene);
        const upPlus = new DuplicateControl("upPlus", this.spriteManagerDuplicate, this.currentEntity, new Vector3(0, 1, 0), this.scene);
        const downPlus = new DuplicateControl("downPlus", this.spriteManagerDuplicate, this.currentEntity, new Vector3(0, -1, 0), this.scene);
        const frontPlus = new DuplicateControl("frontPlus", this.spriteManagerDuplicate, this.currentEntity, new Vector3(0, 0, 1), this.scene);
        const backPlus = new DuplicateControl("backPlus", this.spriteManagerDuplicate, this.currentEntity, new Vector3(0, 0, -1), this.scene);

        this.currentEntity.controls = [leftPlus, rightPlus, upPlus, downPlus, frontPlus, backPlus];
    }

    /**
     * Set the infill image to all selected entities
     * @param {String} urlObject
     */
    addInfillImageToSelectedEntities(urlObject, infillType) {
        if (!this.currentEntity && !this.multiSelectedMesh.length) {
            return;
        }
        if (this.currentEntity) {
            OptionController.infillController.setInfillFromUrlObject(urlObject,
                [this.currentEntity],
                infillType,
                true);
        } else if (this.multiSelectMesh.length) {
            const selectedEntities = this.multiSelectedMesh
                .map(
                    selectedMesh => selectedMesh.entity
                );
            OptionController.infillController.setInfillFromUrlObject(urlObject,
                selectedEntities,
                infillType,
                true);
        }
    }

    displayImageOnEverySelectedEntities(image) {
        if (!this.multiSelectedMesh.length) {
            return;
        }
        events.emit("displaying-image-on-many-infills");
        const selectedEntities = this.multiSelectedMesh
            .map(
                selectedMesh => selectedMesh.entity
            );
        OptionController.infillController.displayOneImageOnManyInfills(
            selectedEntities, image
        );
    }

    /**
     * Set the infill color to all selected entities
     * @param {String} color
     */
    addInfillColorToSelectedEntities(color, infillType) {
        if (!this.currentEntity && !this.multiSelectedMesh.length) {
            return;
        }
        if (this.currentEntity) {
            OptionController.infillController.setInfill([this.currentEntity],
                { color },
                infillType);
        } else if (this.multiSelectMesh.length) {
            const selectedEntities = this.multiSelectedMesh
                .map(
                    selectedMesh => selectedMesh.entity
                );
            OptionController.infillController.setInfill(selectedEntities,
                { color },
                infillType);
        }
    }

    /**
     * Remove the + sprites allowing duplication of the current entity
     */
    removeControlToCurrentEntity() {
        if (!this.currentEntity
            || !this.currentEntity.controls) {
            return;
        }
        this.currentEntity.controls.forEach(control => {
            control.removeEvents();
            control.dispose();
        });
        this.currentEntity.controls = null;
        this.duplicationActive = false;
    }

    /**
     * Remove the drag behavior fron the selectMesh
     */
    stopDragMesh() {
        if (this.selectMesh) {
            if (this.currentEntity) {
                this.currentEntity.updatePosition();
            }
            if (this.selectMesh.entity && this.selectMesh.entity.updateRectsMatrixes) {
                this.selectMesh.entity.updateRectsMatrixes();
            }
            if (!RightsHelper.isModePublic()
                    && !RightsHelper.isModeBuildingPlan()
                    && this.selectMesh.behaviors
                    && this.selectMesh.behaviors.length) {
                this.selectMesh.behaviors[0].releaseDrag();
            }
        }
    }

    /**
     * Return the number of selected objects
     */
    getSelectedObjectsLength() {
        if (this.selectMesh && this.multiSelectedMesh
            .length === 0) {
            return 1;
        } if (!this.selectMesh && this.multiSelectedMesh.length > 0) {
            return this.multiSelectedMesh.length;
        }
        return 0;
    }

    /**
     * Change the selected mesh visibility state
     * @param {*} isVisible
     */
    toggleSelectMeshVisibility(isVisible = true, toggleOptions = true) {
        let selectMesh = null;
        if (this.meshToInitialize) {
            selectMesh = this.meshToInitialize.selectMesh;
        } else {
            selectMesh = this.selectMesh;
        }
        if (!selectMesh) {
            return;
        }
        geometryUtility.toggleMeshVisibility(selectMesh, isVisible, toggleOptions);
    }

    /**
     * Called on snap event
     * Select the meshes entity as the current entity
     * @param {AbstractMesh} meshToSelect
     */
    onSnap(meshToSelect) {
        if (!this.meshToInitialize) {
            this.selectEntity(meshToSelect,
                false,
                true);
        }
    }

    /**
     * Set the entityList position correctly, relative to the grid
     * @param {Array<Entity>} entityList
     */
    addGroupedObjectOnScene(entityList, snapshot = false) {
        this.unselectAll();
        const meshList = entityList.map(
            entity => {
                EntityController.fetchMesh(entity);
                return entity.mesh;
            }
        );
        let bb = meshUtility.ComputeMeshListBB(meshList);
        const nodeMesh = new TransformNode("nodeMesh");
        nodeMesh.position = bb.centerWorld.clone();
        nodeMesh.computeWorldMatrix(true);
        entityList.forEach(
            entity => {
                entity.mesh.computeWorldMatrix(true);
                entity.mesh.parent = nodeMesh;
            }
        );
        bb = meshUtility.ComputeMeshListBB(meshList);
        if (bb.minimumWorld.y < -0.001) {
            nodeMesh.position.y += -bb.minimumWorld.y;
            nodeMesh.computeWorldMatrix(true);
            meshList.forEach(
                mesh => {
                    mesh.computeWorldMatrix(true);
                }
            );
        }
        entityList.forEach(
            entity => {
                entity.mesh.computeWorldMatrix(true);
                entity.mesh.setParent(null);
                entity.updateDynamicParameters();
            }
        );
        nodeMesh.dispose();
        this.initGroupObject(entityList, { snapshot, copy: false, dragging: true });
    }

    /**
     *  Start the drag behavior of the given group of object
     * @param {Array<Entity>} entityList
     * @param {Boolean} copy - is it the initialization following a copy
     * @param {Boolean} dragging - launch the drag behavior after initialization
     */
    initGroupObject(entityList,
            options = {snapshot:true, copy: false, dragging: true}) { // eslint-disable-line
        const tmpSelectMeshes = [];

        // Create a selectMesh for each duplicated entities
        entityList.forEach(
            entity => {
                const selectMesh = (SelectionHelper.createSelectMesh(entity));
                tmpSelectMeshes.push(selectMesh);
            }
        );

        const bb = meshUtility.ComputeMeshListBB(tmpSelectMeshes);
        // Init a transformNode that will be used to translate all selectMeshes
        const copyTransformNode = new TransformNode("tn", this.scene);
        copyTransformNode.position = bb.centerWorld.clone();
        copyTransformNode.x = geometryUtility.adaptToStep(copyTransformNode.x, false, true);
        copyTransformNode.y = geometryUtility.adaptToStep(copyTransformNode.y, false, true);
        copyTransformNode.z = geometryUtility.adaptToStep(copyTransformNode.z, false, true);
        copyTransformNode.computeWorldMatrix(true);

        // Set the select meshes to correct position
        tmpSelectMeshes.forEach(
            selectMesh => {
                const originalPos = selectMesh.position.clone();
                selectMesh.parent = copyTransformNode;
                selectMesh.computeWorldMatrix(true);
                selectMesh.setAbsolutePosition(originalPos);
                const instance = selectMesh.entity.mesh;
                instance.parent = selectMesh;
                instance.position = Vector3.Zero();
                instance.rotationQuaternion = Quaternion.Zero();
                instance.rotationQuaternion.w = 1;
            }
        );

        // Create the transformNode drag behavior
        const temporaryBehavior = new PointerDragBehavior({
            dragPlaneNormal: Vector3.Up(),
        });
        SelectionHelper.addMultiselectMeshHighlightBehavior(temporaryBehavior,
            tmpSelectMeshes);
        temporaryBehavior.attach(copyTransformNode);
        temporaryBehavior.moveAttached = false;
        SelectionHelper.addVirtualGridBehavior(temporaryBehavior);
        temporaryBehavior.useObjectOrientationForDragging = false;

        // A callback called if the user press "ESC" during copy paste
        const unselectCallback = () => {
            temporaryBehavior.onDragEndObservable.clear();
            tmpSelectMeshes.forEach(
                selectMesh => {
                    const entity = selectMesh.entity;
                    SelectionHelper.removeSelectMesh(entity);
                    entity.destroy();
                }
            );
            temporaryBehavior.detach();
            temporaryBehavior.releaseDrag();
            copyTransformNode.dispose();
            events.emit("abort-group-init");
        };
        // Currently you can't remove a listener with a the once method
        // Here's a way to avoid it
        const onceListener = () => {
            unselectCallback();
            events.removeListener("@obsidian-engine.unselect", onceListener);
        };
        events.on("@obsidian-engine.unselect", onceListener);

        let releaseDragListener;
        if (options.copy) {
            releaseDragListener = () => {
                temporaryBehavior.releaseDrag();
                events.removeListener("@obsidian-engine.paste", releaseDragListener);
            };
            events.on("@obsidian-engine.paste", releaseDragListener);
        } else {

            const onceLeftClick = () => {
                temporaryBehavior.releaseDrag();
                events.removeListener("@obsidian-engine.pointer-left-click", onceLeftClick);
            };
            events.on("@obsidian-engine.pointer-left-click", onceLeftClick);
        }

        const onDragEnd = () => {
            events.removeListener("@obsidian-engine.unselect", onceListener);
            if (options.copy && typeof releaseDragListener === "function") {
                events.removeListener("@obsidian-engine.paste", releaseDragListener);
            }
            if (!options.copy) {
                const tmpBb = meshUtility.ComputeMeshListBB(tmpSelectMeshes);
                if (!gridManager.isBBInsideGrid(tmpBb)) {
                    tmpSelectMeshes.forEach(
                        selectMesh => {
                            SelectionHelper.removeSelectMesh(selectMesh.entity);
                            selectMesh.entity.destroy();
                        }
                    );
                    temporaryBehavior.onDragEndObservable.clear();
                    temporaryBehavior.detach();
                    copyTransformNode.dispose();
                    events.emit("abort-group-init");
                    return;
                }
            }
            tmpSelectMeshes.forEach(
                selectMesh => {
                    selectMesh.computeWorldMatrix(true);
                    selectMesh.setParent(null);
                    const instance = selectMesh.entity.mesh;
                    selectMesh.getWorldMatrix().decompose(
                        null,
                        instance.rotationQuaternion,
                        instance.position
                    );
                    instance.parent = null;
                    instance.computeWorldMatrix(true);
                    selectMesh.entity.updateDynamicParameters();
                    SelectionHelper.removeSelectMesh(selectMesh.entity);
                    const path = EntityHelper.getEntityPath(
                        selectMesh.entity
                    );
                    dataStore.addEntity(selectMesh.entity, path);
                }
            );

            temporaryBehavior.onDragEndObservable.clear();
            temporaryBehavior.detach();
            if (options.snapshot) {
                history.snapshot();
            }
            copyTransformNode.dispose();
        };
        // Paste behavior for multiselection
        temporaryBehavior.onDragEndObservable.addOnce(onDragEnd);

        temporaryBehavior.startDrag(-2);
        if (!options.dragging) {
            const height = bb.extendSizeWorld.y;
            const { mesh: temporaryPlane, pickResult } = DragHelper.createDragPlane(
                copyTransformNode.position.y - (height / 2),
                this.scene
            );
            if (pickResult.pickedPoint) {
                copyTransformNode.position = pickResult.pickedPoint;
                copyTransformNode.position.y += height / 2;
            }
            temporaryPlane.dispose();
            temporaryBehavior.releaseDrag();
        }
    }

    /**
     * Returns the current selection bounding box
     */
    getSelectionBb() {
        if (this.currentEntity) {
            return this.currentEntity.getBoundingBox();
        } if (this.multiSelectedMesh.length > 0) {
            return meshUtility.ComputeMeshListBB(this.multiSelectedMesh);
        }
        return null;
    }

    /**
     * Creates a TransformNode used for multiselected object interaction
     */
    initNodeMesh() {
        this.nodeMesh = new TransformNode("nodeMesh");
        const bb = meshUtility.ComputeMeshListBB(this.multiSelectedMesh);
        this.nodeMesh.position = bb.centerWorld.clone();
        this.nodeMesh.computeWorldMatrix(true);

        this.multiSelectedMesh.forEach(
            mesh => {
                const originalPos = mesh.position.clone();
                mesh.parent = this.nodeMesh;
                mesh.computeWorldMatrix(true);
                mesh.setAbsolutePosition(originalPos);
                const instance = mesh.entity.mesh;
                instance.parent = mesh;
                instance.position = Vector3.Zero();
                instance.rotationQuaternion = Quaternion.Zero();
                instance.rotationQuaternion.w = 1;
            }
        );
    }

    /**
     * Destroys the TransformNode used for multiselected object interaction
     */
    destroyNodeMesh() {
        this.multiSelectedMesh.forEach(
            mesh => {
                mesh.computeWorldMatrix(true);
                mesh.setParent(null);
                const instance = mesh.entity.mesh;
                instance.parent = null;
                mesh.getWorldMatrix().decompose(
                    null, instance.rotationQuaternion, instance.position
                );
            }
        );
        if (this.nodeMesh) {
            this.nodeMesh.dispose();
            this.nodeMesh = null;
        }

    }

    /**
     * Called on CTRL + C and check if current selection is a single selection or a multiselection
     * @param {Boolean} dragging - Is the drag behavior activated after the copy
     * @returns {Entity[]}
     */
    copyCurrentSelection(dragging = true) {
        this.deactivateGizmo();
        if (this.currentEntity) {
            const copy = this.copyCurrentEntity(dragging);
            return copy ? [copy] : [];
        }
        if (this.multiSelectedMesh.length > 0) {
            return this.copyMultiSelectedEntities(dragging);
        }
        return [];
    }

    rotate90DegreesCurrentSelection() {
        this.deactivateGizmo();
        if (this.multiSelectedMesh.length > 0) {
            this.initNodeMesh();
        }
        const meshToRotate = this.nodeMesh || this.selectMesh;
        if (!meshToRotate) {
            return;
        }
        meshToRotate.rotate(Vector3.Up(), Math.PI / 2, Space.WORLD);
        const updatePositionCallback = mesh => {
            mesh.computeWorldMatrix(true);
            mesh.entity.updateDynamicParameters();
        };
        if (this.nodeMesh) {
            this.multiSelectedMesh.forEach(
                updatePositionCallback
            );
            this.destroyNodeMesh();
            history.snapshot();
        } else if (this.selectMesh) {
            updatePositionCallback(this.selectMesh);
            history.snapshot();
        }
    }

    /**
     * Called when we copy a single entity
     * Set a mesh to copy below the cursor
     * @returns {Entity}
     */
    copyCurrentEntity(dragging = true) {
        if (!this.currentEntity) {
            return null;
        }
        this.copying = true;
        const newEntity = this.currentEntity.clone();
        newEntity.group = this.currentEntity.group;
        EntityController.fetchMesh(newEntity);
        this.initializingEntityOnScene(newEntity,
            { x: this.scene.pointerX, y: this.scene.pointerY },
            dragging);
        return newEntity;
    }

    /**
     * Called when we copy a multiple selection
     * @returns {Entity[]}
     */
    copyMultiSelectedEntities(dragging = true) {
        // Clone every entities selected
        const copiedEntities = this.multiSelectedMesh.map(
            mesh => {
                const clonedEntity = mesh.entity.clone();
                EntityController.fetchMesh(clonedEntity);
                return clonedEntity;
            }
        );
        this.unselectAll();
        this.initGroupObject(copiedEntities, { snapshot: true, copy: true, dragging });
        return copiedEntities;
    }

    /**
     * Called when we press CTRL+V and we have a single copy
     * Duplicates the mesh under the pointer
     */
    pasteCurrentCopy() {
        if (!this.copying || !this.meshToInitialize) {
            return;
        }
        const currentSelectMesh = this.meshToInitialize.selectMesh;
        let position;

        // In case of magnetism
        if (currentSelectMesh.entity.ghost && currentSelectMesh.entity.ghost.snapped) {
            // We force position to magnetism
            position = currentSelectMesh.entity.ghost.mesh.getAbsolutePosition().clone();
            currentSelectMesh.entity.unghost();
        } else {
            position = currentSelectMesh.getAbsolutePosition().clone();
        }
        const newEntity = currentSelectMesh.entity.clone(
            { position }
        );
        const path = EntityHelper.getEntityPath(newEntity);
        EntityController.fetchMesh(newEntity);
        dataStore.addEntity(newEntity, path);
        history.snapshot();
        events.emit("entity-pasted", newEntity);
    }

    /**
     * Show/Hide every entity except current selection
     * @param {Boolean} visibility - default true
     */
    toggleEveryEntityVisibilityExceptSelection(visibility = true) {
        const entitiesSelectedIds = this.multiSelectedMesh.map(
            selectMesh => selectMesh.entity.id
        );
        const entityList = dataStore.listEntities("/products/**")
            .concat(dataStore.listEntities("/connectors/**"))
            .filter(
                entity => (entitiesSelectedIds.indexOf(
                    entity.id
                ) === -1)
            );
        entityList.forEach(
            entity => {
                geometryUtility.toggleMeshVisibility(entity.mesh, visibility);
            }
        );
        return entityList;
    }

    toggleSelectionHighlight(visibility = true) {
        this.multiSelectedMesh.forEach(
            selectMesh => {
                highlightManager.toggleHighlightMesh(selectMesh, visibility);
            }
        );
    }

    /**
     * Return a screenshot of the current selection
     */
    async getCurrentSelectionScreenshot(
        download = false,
        resolution = null,
        callback = () => {}
    ) {
        const meshList = this.currentEntity
            ? [this.currentEntity.mesh] : this.multiSelectedMesh;
        const target = this.getSelectionBb().centerWorld;
        const otherEntities = this.toggleEveryEntityVisibilityExceptSelection(false);
        otherEntities.forEach(ent => { ent.mesh.isPickable = false; });
        // hide measurements and do the screenshot
        measurementController.hideAll();

        const screenshot = await new Promise(resolve => {
            setTimeout(() => {
                ScreenshotController.screenshotMeshList(
                    meshList,
                    target,
                    resolution,
                    callback
                ).then(image => {
                    this.toggleEveryEntityVisibilityExceptSelection();
                    otherEntities.forEach(ent => { ent.mesh.isPickable = true; });
                    measurementController.showAll();
                    if (download) {
                        const link = document.createElement("a");
                        link.download = "img.png";
                        link.href = URL.createObjectURL(image);
                        link.click();
                        URL.revokeObjectURL(link.href);
                    }
                    resolve(image);
                });
            }, 300);
        });

        return screenshot;
    }

    /*
    ////////////////////////////////////////////////////////////////
    ///////////////////// GIZMO LOGIC //////////////////////////////
    ////////////////////////////////////////////////////////////////
    */

    showSelectMeshPositionGizmo() {
        const isActive = Boolean(gizmoController.positionGizmo.attachedMesh);
        this.deactivateGizmo();
        if (!isActive) {
            this.removeControlToCurrentEntity();
            if (this.selectMesh) {
                gizmoController.showPositionGizmo(this.selectMesh);
                gizmoController.showPlaneGizmo(this.selectMesh);
            } else if (this.multiSelectedMesh.length > 0) {
                this.initNodeMesh();
                gizmoController.showPositionGizmo(this.nodeMesh);
                gizmoController.showPlaneGizmo(this.nodeMesh);
            }
        }
    }

    showSelectMeshPlaneGizmo() {
        gizmoController.showPlaneGizmo(this.selectMesh);
    }

    showSelectMeshScaleGizmo() {
        if (!this.selectMesh) {
            return;
        }
        const isActive = Boolean(gizmoController.scaleGizmo.attachedMesh);
        this.deactivateGizmo();
        if (!isActive) {
            gizmoController.showScaleGizmo(this.selectMesh);
        }
    }

    showSelectMeshRotationGizmo() {
        const isActive = Boolean(gizmoController.rotationGizmo.attachedMesh);
        this.deactivateGizmo();

        if (!isActive) {
            this.removeControlToCurrentEntity();
            if (this.selectMesh) {
                gizmoController.showRotationGizmo(this.selectMesh);
            } else if (this.multiSelectedMesh.length > 0) {
                this.initNodeMesh();
                gizmoController.showRotationGizmo(this.nodeMesh);
            }
        }
    }

    deactivateGizmo() {
        if (gizmoController.isGizmoActive()) {
            gizmoController.hideGizmos();
            if (this.nodeMesh) {
                this.destroyNodeMesh();
            }
        }
    }

    /**
     * @returns {Boolean}
     */
    hasSelection() {
        return Boolean(this.selectMesh || this.multiSelectedMesh.length);
    }

    toggleDuplication() {
        if (!this.currentEntity || this.multiSelectedMesh.length) {
            return;
        }
        this.deactivateGizmo();
        if (!this.duplicationActive) {
            this.addControlToCurrentEntity();
        } else {
            this.removeControlToCurrentEntity();
        }
    }

    /**
     * Select nearby connectors
     * Used to screenshot group of connectors
     */
    selectNearbyConnectors() {
        const connectors = dataStore.listEntities("/connectors/*");
        const minDist = 0.2;
        const sqrMinDist = minDist * minDist;
        const groupEnts = [this.currentEntity];
        connectors.forEach(entity => {
            if (entity === this.currentEntity) {
                return;
            }
            const selectEnt = groupEnts.map(
                gE => Vector3.DistanceSquared(entity.mesh.position, gE.mesh.position)
            ).find(dist => (dist <= sqrMinDist));
            if (selectEnt !== undefined) {
                groupEnts.push(entity);
            }
        });
        if (groupEnts.length > 1) {
            this.unselectEntityOnScene();
            groupEnts.forEach(entity => {
                this.multiSelectMesh(entity);
            });
        }
    }

}
