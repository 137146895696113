import { Vector3 } from "@babylonjs/core";
import config from "defaultConfig";

import self from "../index";
import AbstractOptionController from "./abstract-option-controller";

const CollisionHelper = self.app.modules.collisionManager.CollisionHelper;
const GeometryUtility = self.app.modules.geometryUtility;
const OptionableMixin = require("../model/optionable-mixin");

export default class LightOptionController extends AbstractOptionController {

    constructor(optionController) {
        super(optionController);

        this.lightsReferences = {
            SAM_LIGHT: "250 05 50 S",
            CELIGHT: "250 05 50 LED",
            BIG_SAM_LIGHT: "250 05 51 S",
        };

        super.initializeEvents(this.lightsReferences, () => {});
    }

    addLights(entity = null) {
        const realEntity = this.optionController.returnRealEntity(entity);
        if (realEntity) {
            realEntity.computeOptionsParameters();
            if (!realEntity.lightSingularity) {
                realEntity.lightSingularity = OptionableMixin.LIGHT_SINGULARITY_OPTION.SIMPLE;
            }
            if (!realEntity.lightNumber) {
                realEntity.lightNumber = 1;
            }

            return this.updateLightMeshes(realEntity);
        }
        return Promise.resolve(null);
    }

    updateLightMeshes(entity) {
        this.removeLightMeshes(entity);
        if (entity.lightOption) {
            entity.optionsMeshes.lights = this.placeLights(entity);

            // Swap side if needed
            if (entity.swappedOptions.LIGHT) {
                this.optionController.swapOptionsMeshesSide(
                    this.optionController.optionsFamilies.LIGHT,
                    entity
                );
            }

            this.toggleLightsVisibility(entity.visible, entity);
        }
        return Promise.resolve(null);
    }

    /**
     * Create lights put them where they should be and returns them
     */
    placeLights(entity) {
        const offset = GeometryUtility.adaptToStep(entity.orientedWidth / (entity.lightNumber + 1));
        const lights = [];
        for (let i = 0; i < entity.lightNumber; i += 1) {
            lights.push(this.instantiateLight(entity.lightOption));

            if (entity.lightSingularity === OptionableMixin.LIGHT_SINGULARITY_OPTION.DOUBLE) {
                lights.push(this.instantiateLight(entity.lightOption));
                const shift = (entity.lightOption === OptionableMixin.LIGHT_OPTION.BIG_SAM_LIGHT
                    ? 4 : 2);
                LightOptionController.positionLightUp(
                    entity,
                    lights[i * 2],
                    offset * (i + 1) - config.step * shift
                );
                LightOptionController.positionLightUp(
                    entity,
                    lights[i * 2 + 1],
                    offset * (i + 1) + config.step * shift
                );
                lights[i * 2 + 1].rotate(Vector3.Forward(), Math.PI);
            } else {
                LightOptionController.positionLightUp(entity, lights[i], offset * (i + 1));
            }
        }
        return lights;
    }

    static positionLightUp(frame, light, offset) {
        light.parent = frame.mesh;
        light.rotate(Vector3.Left(), -Math.PI / 2);
        light.rotate(Vector3.Up(), -frame.angleToUp);
        light.translate(
            Vector3.Forward(),
            -(frame.orientedHeight / 2
                + 0.0035) // Futur connector thickness
        );
        light.translate(
            Vector3.Left(),
            frame.orientedWidth / 2 - offset
        );
        light.computeWorldMatrix(true);
    }

    removeLightMeshes(entity = null) {
        const realEntity = this.optionController.returnRealEntity(entity);
        if (realEntity) {
            realEntity.optionsMeshes.lights.forEach(lightMesh => {
                lightMesh.dispose();
            });
            realEntity.optionsMeshes.lights = [];
        }
    }

    removeLight(entity = null) {
        const realEntity = this.optionController.returnRealEntity(entity);
        if (realEntity) {
            this.removeLightMeshes(realEntity);
            realEntity.lightOption = 0;
            realEntity.lightNumber = 0;
            realEntity.lightSingularity = 0;
            realEntity.swappedOptions.LIGHT = false;
        }
    }

    instantiateLight(lightType) {
        const ref = this.getLightRef(lightType);
        const lightMesh = this.meshManager.getMeshFromLoadedGeometry(
            ref,
            "eco",
            null,
            {
                category: "LIGHTING",
                subCategory: "LIGHTING",
            }
        );

        self.app.modules.meshManager.meshUtility.AddMetadataProperties(
            lightMesh,
            {
                isOption: true,
            }
        );

        lightMesh.name = "light";
        return lightMesh;
    }

    /**
     * Check for lights specific rules
     * @param {Entity} entity (Optional) the entity we need to check
     * @param {Array<Mesh>} potentialObstructors (Optional) the potential meshes that can
     * block the light instanciation
     * @param {Boolean} computeOptionsParams (Optional) if true, options parameters of the entity
     *                                       are recomputed
     */
    canHaveLights(entity = null, potentialObstructors = [], computeOptionsParams = true) {
        const realEntity = this.optionController.returnRealEntity(entity);
        if (realEntity) {
            // Creating fake lights to test collisions
            if (computeOptionsParams) {
                realEntity.computeOptionsParameters();
            }
            const lights = this.placeLights(realEntity);

            // Activate collisions
            CollisionHelper.toggleCollisions(potentialObstructors, true);

            // Is there a mesh upside this frame
            realEntity.canHaveLightsOption = true;
            for (let i = 0; i < lights.length; i += 1) {
                lights[i].checkCollisions = true;
                if (self.app.modules.collisionManager
                    .Controller.checkStaticCollisionsMesh(
                        lights[i],
                        this.getEntityLightName(realEntity.lightSingularity)
                    )) {
                    realEntity.canHaveLightsOption = false;
                }

                if (potentialObstructors.length > 0
                    && CollisionHelper.checkStaticCollisionsWithMesh(
                        lights[i],
                        this.getEntityLightName(realEntity.lightSingularity),
                        potentialObstructors
                    )) {
                    realEntity.canHaveLightsOption = false;
                }
                lights[i].dispose();
            }
            CollisionHelper.toggleCollisions(potentialObstructors, false);
        }
    }

    /**
     * Check if lights are still allowed for this entity
     * If not removes the lights of the entity
     * @param {*} entity
     * @param {*} potentialObstructors meshes that can prevent the lights to be allowed
     */
    updateCanHaveLights(entity, potentialObstructors = [], computeOptionsParams = true) {
        const realEntity = this.optionController.returnRealEntity(entity);
        if (realEntity) {
            // If we snapped on top a of the snapped frame
            this.canHaveLights(realEntity, potentialObstructors, computeOptionsParams);
            if (!realEntity.canHaveLightsOption && realEntity.optionsMeshes.lights.length) {
                this.optionController.removeOption(
                    this.optionController.optionsFamilies.LIGHT, realEntity
                );
            }
        }
    }

    /**
     * Update the maximum number of light that can be set on the given entity
     * @param {*} entity
     */
    updateMaxLightNumber(entity = null) {
        const realEntity = this.optionController.returnRealEntity(entity);

        realEntity.computeOptionsParameters();
        if (realEntity) {
            if (realEntity.orientedWidth < 1.488) {
                realEntity.maxLightNumber = 1;
            } else if (realEntity.orientedWidth >= 1.488 && realEntity.orientedWidth < 2.480) {
                realEntity.maxLightNumber = 2;
            } else {
                realEntity.maxLightNumber = 3;
            }

            if (realEntity.lightNumber > realEntity.maxLightNumber) {
                realEntity.lightNumber = realEntity.maxLightNumber;
            }
        }
    }

    /**
     * Toggle the visibilty of the lights if there are any
     * @param {*} isVisible
     * @param {*} entity
     */
    toggleLightsVisibility(isVisible, entity = null) {
        const realEntity = this.optionController.returnRealEntity(entity);

        if (realEntity
        && realEntity.optionsMeshes.lights.length > 0) {
            realEntity.optionsVisibility.lights = isVisible;
            self.app.modules.geometryUtility.toggleMeshesVisibility(
                realEntity.optionsMeshes.lights,
                isVisible && realEntity.visible
            );
        }
    }

    getEntityLightName(lightType) { // eslint-disable-line class-methods-use-this
        const lightName = Object.keys(OptionableMixin.LIGHT_OPTION).find(
            key => OptionableMixin.LIGHT_OPTION[key] === lightType
        );
        return lightName;
    }

    getLightRef(lightType) {
        const lightName = this.getEntityLightName(lightType);
        return this.lightsReferences[lightName];
    }

    isLightRef(ref) {
        return Object.values(this.lightsReferences).includes(ref);
    }

}
