import SerializableClass from "abitbol-serializable";
import url from "url";
import config from "defaultConfig";
import AWSLambda from "aws-sdk/clients/lambda";
import pako from "pako";
import ObsidianFile from "obsidian-file";
import Serializer from "abitbol-serializable/lib/serializer";

import self from "../..";
import QuoteHelper from "../helper/quote-helper";
import BecadHelper from "../helper/becad-helper";
import ObjHelper from "../helper/obj-helper";
import ConfigHelper from "../helper/config-helper";
import PatchHelper from "../helper/patch-helper";

const {
    modules: {
        selectionManager,
        assetsManager,
        entityManager: { Controller: entityController },
    },
    events,
} = self.app;

export default class ProjectManager {

    constructor() {
        this.initModules();
        this.lambdaClient = null;
        this.importedXML = null;
        this.importedConnectorsXML = null;
        this.importedCoversXML = null;
        this.initSaveDataFunction();
        this.initApiMethod();
        this.initDebugMethods();
        this.ephemeralDatas = {
            images: [],
            colors: [],
        };
        events.emit("project-manager-ready");
    }

    initModules() {
        this.dataStore = self.app.modules.dataStore;
        this.groupController = self.app.modules.groupManager.Controller;
        this.catalogManager = self.app.modules.catalogManager;
        this.entityManager = self.app.modules.entityManager;
        this.optionController = self.app.modules.optionManager.optionController;
        this.engine = self.app.modules.obsidianEngine.engineController;
        this.materialManager = self.app.modules.materialManager;
        // Setup option manager that way to avoid circular dependency
        this.optionController.catalogManager = this.catalogManager;
        this.meshUtility = self.app.modules.meshManager.meshUtility;
    }

    initDebugMethods() {
        window.DEBUG = {
            saveProjectAsData64: () => this.saveProjectAsData64(),
            openProjectFromData64: (data64) => this.openProjectFromData64(data64),
        };
    }

    /**
     * Initialize all the apiMethods use with the iframeApi module
     */
    initApiMethod() {
        self.app.modules.iframeApi.addApiMethod("saveProjectAsBlob", () => this.saveProjectAsBlob());
        self.app.modules.iframeApi.addApiMethod(
            "openProjectFromBlob",
            (blob) => {
                this.openProjectFromBlob(blob);
            }
        );
        self.app.modules.iframeApi.addApiMethod(
            "openProjectFromUrl",
            (projectUrl) => {
                this.openProjectFromURL(projectUrl);
            }
        );
        self.app.modules.iframeApi.addApiMethod("generateQuote", () => QuoteHelper.generateJSONQuote());
        self.app.modules.iframeApi.addApiMethod("importBecadXml", (blob) => {
            this.importBecadXml(blob);
        });
        self.app.modules.iframeApi.addApiMethod(
            "exportBecadXml",
            (
                addConnectors,
                blob,
                selectedEntities,
                coverTypeOption,
                exportType
            ) => this.exportBecadXml(
                addConnectors,
                blob,
                selectedEntities,
                coverTypeOption,
                exportType
            )
        );
        self.app.modules.iframeApi.addApiMethod("exportObj", () => this.exportObj());
        self.app.modules.iframeApi.addApiMethod("getDebugEntities", () => this.getDebugJson());
        self.app.modules.iframeApi.addApiMethod(
            "getVersionName",
            () => config.versionName
        );
        self.app.modules.iframeApi.addApiMethod(
            "getProjectName",
            () => config.projectName
        );
        self.app.modules.iframeApi.addApiMethod("getImportedXml", () => this.getImportedXml());
        self.app.modules.iframeApi.addApiMethod(
            "getImportedConnectorsXml",
            () => this.getImportedConnectorsXml()
        );
        self.app.modules.iframeApi.addApiMethod("getImportedCoversXml", () => this.getImportedCoversXml());
        self.app.modules.iframeApi.addApiMethod("exportImportedImages", () => assetsManager.controller.exportImportedImages());
        self.app.modules.iframeApi.addApiMethod("exportRemovedImages", () => assetsManager.controller.exportRemovedImages());
        self.app.modules.iframeApi.addApiMethod("resetRemovedImages", () => assetsManager.controller.resetRemovedImages());
        self.app.modules.iframeApi.addApiMethod(
            "reimportEveryInfillImages",
            () => this.reimportEveryInfillImages()
        );
        self.app.modules.iframeApi.addApiMethod("saveTemplate", () => this.saveTemplate());
        self.app.modules.iframeApi.addApiMethod("storeCurrentProduct", () => this.storeCurrentProduct());
        self.app.modules.iframeApi.addApiMethod("generatePanelplan", () => this.generatePanelplan());
        self.app.modules.iframeApi.addApiMethod(
            "openStoredProductFromUrl",
            (productUrl) => {
                this.openStoredProductFromUrl(productUrl);
            }
        );
    }

    initSaveDataFunction() {
        this.saveData = (function initSave() {
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style.display = "none";
            return (data, fileName) => {
                const objectUrl = window.URL.createObjectURL(data);
                a.href = objectUrl;
                a.download = fileName;
                a.click();
            };
        }());
    }

    /**
     * Load a project form a given JSON
     * @param {JSON} json
     */
    loadJson(json) {
        this.dataStore.unserializeEntities(json);
        this.loadDataStore();
    }

    /**
     * Load entities and groups from the data store
     * Then, initialize their dynamic parameters.
     */
    loadDataStore() {
        try {
            events.emit("loading-project");
            this.groupController.defaultGroup = this.dataStore.listEntities("/groups/default")[0];
            const entities = this.dataStore.listEntities("/products/default");
            // TODO : Handle other structures saves
            const promises = [];
            entities.forEach((entity) => {
                // TODO : position and rotation options doesn't have to be passed as options..
                // Abitbol serializers should be added to handle that
                // It would avoid recreating quaternion and vector by hands
                // Also rotation is useless if a rotationQuaternion is set
                const options = {
                    id: entity.id,
                    position: entity.position,
                    rotationQuaternion: entity.rotationQuaternion,
                };
                promises.push(
                    this.addToScene(
                        entity.ref,
                        entity.materialName,
                        true,
                        options
                    )
                );
            });

            const connectors = this.dataStore.listEntities(
                "/connectors/default"
            );
            connectors.forEach((connector) => {
                const options = {
                    id: connector.id,
                    position: connector.position,
                    rotationQuaternion: connector.rotationQuaternion,
                };
                promises.push(
                    this.addToScene(
                        connector.ref,
                        connector.materialName,
                        true,
                        options
                    )
                );
            });
            Promise.all(promises).then(() => {
                events.emit("project-loaded");
                self.app.modules.history.clear();
                self.app.modules.history.snapshot();
            });
        } catch (err) {
            self.app.log.error("Can't load datastore : ", err);
            throw err;
        }
    }

    getDebugJson() {
        return this.dataStore.serializeEntities();
    }

    /**
     * Creates the structure corresponding to the ref parameter if needed
     * and add its mesh to the scene
     * @param {String} ref
     * @param {String} materialName optional
     * @param {Boolean} fromOpeningProject optional
     * True if we calling the function when opening a project
     * @param {String} options.id optional => id of the structure
     * @param {Vector3} options.position optional
     * @param {Vector3} options.rotationQuaternion optional
     * @param {string} options.groupName optional
     * @returns {Promise(EntityStructure)}
     * */
    addToScene(
        ref,
        materialName = this.materialManager.defaultMaterial,
        fromOpeningProject = false,
        options = {}
    ) {
        const newMesh = (initialize = true) => {
            try {
                const serializedData = this.catalogManager.products[ref];
                if (!serializedData) {
                    self.app.log.error(
                        "Entity Library : reference ",
                        ref,
                        " not found"
                    );
                }
                const struct = SerializableClass.$unserialize(serializedData);
                // If the struct is colorable it's material is already set to the default color
                // Doing that would overwrite the default color
                if (struct.materialId === "default") {
                    struct.setMaterialId(materialName);
                } else if (struct.category === "-") {
                    struct.setMaterialId("error");
                }

                // If the frame implement options mixin
                const path = this.entityManager.Helper.getEntityPath(struct);

                // Make sure that mesh exists before setting position infos
                const sceneInfoCallback = () => {
                    if (options.position) {
                        const newPos = options.position.clone();
                        struct.setPosition(newPos);
                    }
                    if (options.rotationQuaternion) {
                        const newRotQuaternion = options.rotationQuaternion.clone();
                        struct.setRotationQuaternion(newRotQuaternion);
                    }
                };

                if (!struct.mesh) {
                    const fetchMeshCallback = (entity) => {
                        if (entity.id === struct.id) {
                            sceneInfoCallback();
                            events.removeListener(
                                "@entity-manager.mesh-fetched",
                                fetchMeshCallback
                            );
                        }
                    };
                    events.on(
                        "@entity-manager.mesh-fetched",
                        fetchMeshCallback
                    );
                } else {
                    sceneInfoCallback();
                }

                if (!initialize) {
                    this.dataStore.addEntity(struct, path);
                } else {
                    struct.initializing = true;
                    this.dataStore.addEntity(struct, path);
                }
                if (options.refKey) {
                    this.checkEphemeralData(struct, options.refKey);
                }

                if (options.groupName && options.groupName.length !== 0) {
                    let group = this.groupController.getGroupFromName(
                        options.groupName
                    );
                    if (!group) {
                        group = this.groupController.constructor.createGroup(
                            options.groupName,
                            true
                        );
                    }
                    this.groupController.addEntityToGroup(struct, group);
                } else {
                    this.groupController.addEntityToGroup(struct);
                }
                return Promise.resolve(struct);
            } catch (err) {
                self.app.log.error("Can't add new mesh ", ref, err);
                return Promise.resolve(null);
            }
        };

        // If it's a new project => creates a new structure and register it in the dataStore
        return (
            this.catalogManager
                .tryRegisterMeshGeometry(ref)
                // load corresponding geometry
                .then((meshInfos) => {
                    if (meshInfos) {
                        if (!fromOpeningProject) {
                            return newMesh();
                        }
                        return this.setupMeshFromDataStore(options);
                    }
                    return Promise.resolve(null);
                })
                .catch((err) => {
                    self.app.log.error(`Can't add ref ${ref} to scene :`, err);
                })
        );
    }

    /**
     * Creates a mesh from an entity stored in the dataStore
     * @param {Object} options
     * @param {String} options.id the id of the entity checked
     */
    setupMeshFromDataStore(options) {
        try {
            const struct = this.dataStore.getEntity(options.id);
            if (options.position) {
                const newPos = options.position.clone();
                struct.setPosition(newPos);
            }
            if (options.rotationQuaternion) {
                const newRotQuaternion = options.rotationQuaternion.clone();
                struct.setRotationQuaternion(newRotQuaternion);
            }
            entityController.fetchMesh(struct);
            return Promise.resolve(struct);
        } catch (err) {
            self.app.log.error("can't set mesh from dataStore :", err);
            return Promise.resolve(null);
        }
    }

    /**
     * Remove all the entities in paths present in the array
     * @param {string []} pathToClear
     */
    clearPath(pathToClear) {
        pathToClear.forEach((path) => this.dataStore
            .listEntities(path)
            .forEach((entity) => this.dataStore.removeEntity(entity)));
    }

    /**
     * Clear the scene from all the basic entities
     * Create a new default group
     */
    reinitializeScene() {
        events.emit("clear-scene");
        this.clearPath([
            "/connectors/*",
            "/groups",
            "/groups/default",
            "/products/*",
        ]);
        this.groupController.createDefaultGroup();
    }

    // eslint-disable-next-line class-methods-use-this
    saveProjectAsBlob() {
        return self.app.modules.dataExporter.exportAsBlob({
            version: config.version,
        });
    }

    // eslint-disable-next-line class-methods-use-this
    saveProjectAsData64() {
        return self.app.modules.dataExporter.exportAsData64({
            version: config.version,
        });
    }

    /**
     *  Export the current object selection as a blob
     * @returns {Blob}
     */
    // eslint-disable-next-line class-methods-use-this
    exportCurrentSelectionAsBlob() {
        selectionManager.deactivateGizmo();
        selectionManager.initNodeMesh(); // Place object in the node world
        const objectList = selectionManager.multiSelectedMesh.map((mesh) => {
            const newEntity = mesh.entity.clone({
                position: mesh.position.clone(),
                rotationQuaternion: mesh.rotationQuaternion.clone(),
            });
            newEntity.groupId = null;
            newEntity.infillImageId = null;
            newEntity.firstInfillImageId = null;
            newEntity.firstInfillColor = null;
            newEntity.secondInfillImageId = null;
            newEntity.secondInfillColor = null;
            return newEntity;
        });
        selectionManager.destroyNodeMesh();
        const resultObject = {
            objectList,
        };
        const json = Serializer.objectSerializer(resultObject);
        const project = new ObsidianFile();
        project.type = ObsidianFile.MIMETYPE;
        project.metadata = { version: config.version };
        project.project = json;
        return project.exportAsBlob();
    }

    /**
     * Save a stand as a template
     * @returns {thumbnail: blob, template: blob} The templateObject (png, bmx)
     */
    async saveTemplate() {
        const thumbnail = await self.app.modules.cameraManager.ScreenshotController.screenshotThumbnail();
        const templateObject = {
            thumbnail,
            template: this.saveProjectAsBlob(),
        };
        return templateObject;
    }

    /**
     * Save the current selection as a product inside the catalog by getting a .bge and a .png
     * for the thumbnail
     * @returns {thumbnail:blob, product: blob}
     */
    async storeCurrentProduct() {
        try {
            const productBuffer = this.exportCurrentSelectionAsBlob();
            const productBlob = new Blob([productBuffer], {
                type: ObsidianFile.MIMETYPE,
            });
            const thumbnail = await self.app.modules.selectionManager.getCurrentSelectionScreenshot();
            const currentProduct = {
                thumbnail,
                product: productBlob,
            };
            return currentProduct;
        } catch (err) {
            self.app.log.error("Cannot store product :", err);
            return null;
        }
    }

    /**
     * Open a project from a given .bmx Blob
     * @param {Blob} projectBlob - the project blob
     */
    openProjectFromBlob(projectBlob) {
        if (this.groupController.defaultGroup) {
            this.dataStore.removeEntity(this.groupController.defaultGroup.id);
        }

        // Read blob
        const reader = new FileReader();
        reader.onloadend = () => {
            if (reader.readyState !== 2) {
                // 2 -> DONE
                return;
            }

            const projectBuffer = Buffer.from(reader.result);
            const project = new ObsidianFile(projectBuffer);
            const patchedProject = PatchHelper.PatchProject(project);
            const exportedPatchedProject = patchedProject.exportAsBlob();
            self.app.modules.dataExporter.import(exportedPatchedProject);
            this.loadDataStore();
        };
        reader.readAsArrayBuffer(projectBlob);
    }

    /**
     * Open a project from a given data64
     * @param {Array} projectData64
     */
    openProjectFromData64(projectData64) {
        if (this.groupController.defaultGroup) {
            this.dataStore.removeEntity(this.groupController.defaultGroup.id);
        }

        const projectBuffer = Buffer.from(projectData64, "base64");
        const project = new ObsidianFile(projectBuffer);
        const patchedProject = PatchHelper.PatchProject(project);
        const exportedPatchedProject = patchedProject.exportAsBlob();
        self.app.modules.dataExporter.import(exportedPatchedProject);

        this.loadDataStore();
    }

    /**
     * Open a project by loading a blob from a given URL
     * @param {String} projectUrl - the url of the project blob
     */
    openProjectFromURL(projectUrl) {
        if (this.catalogManager.catalogLoaded) {
            this.openProjectAfterCatalogLoaded(projectUrl, false);
        } else {
            events.emit("loading-remote-project");
            events.on("@catalog-manager.catalog-initialized", () => {
                this.openProjectAfterCatalogLoaded(projectUrl, true);
            });
        }
    }

    /**
     * Open a template with a given blob URL
     * @param {String} projectUrl - the url of the Template
     * @param {Object} infillsImg - (Optional) list of infill images
     */
    openTemplate(projectUrl, infillsImg = []) {
        if (infillsImg.length) {
            ConfigHelper.updateConfigImages(infillsImg);
        }
        this.materialManager.initTextures().then(() => {
            this.reinitializeScene();
            this.openProjectFromURL(projectUrl);
        });
    }

    /**
     * Open a stored product with a given blob URL
     * @param {String} productUrl - the url of the product
     */
    openStoredProductFromUrl(productUrl, name) {
        if (config.isLocalMeshesUrl) {
            return self.app.modules.httpRequest
                .getRaw(`${config.meshesUrl}${productUrl}`)
                .then((productBuffer) => this.openStoredProductBlob(productBuffer, name))
                .catch((err) => {
                    self.app.log.error("Can't load product :", err);
                });
        }
        const productAbsoluteUrl = config.projectsUrl
            ? url.resolve(config.projectsUrl, productUrl)
            : productUrl;
        return self.app.modules.httpRequest
            .getRawProxy(productAbsoluteUrl)
            .then((productBuffer) => this.openStoredProductBlob(productBuffer, name))
            .catch((err) => {
                self.app.log.error("Can't load product :", err);
            });
    }

    openStoredProductBlob(blob, name) {
        // eslint-disable-line no-unused-vars
        const file = new ObsidianFile(blob);
        const patchedProduct = PatchHelper.PatchProject(file);
        const unserializedObject = Serializer.objectUnserializer(
            patchedProduct.project
        );
        const promiseList = [];
        const copiedEntities = unserializedObject.objectList.map((entity) => {
            const newEntity = entity.clone(); // avoid same id when importing product twice
            newEntity.forceBaseplate = true;
            promiseList.push(
                this.catalogManager.tryRegisterMeshGeometry(newEntity.ref)
            );
            return newEntity;
        });
        events.emit("init-group-object");

        // Wait for every object to be added on scene before adding them on the group
        return Promise.all(promiseList)
            .then(() => {
                const expectedPromise = new Promise((resolve) => {
                    let numberOfEntityInstanciated = 0;
                    let abortCallback;
                    const entityAddedCallback = (entity) => {
                        if (
                            copiedEntities.find(
                                (copiedEntity) => copiedEntity.id === entity.id
                            )
                        ) {
                            numberOfEntityInstanciated += 1;
                        }
                        if (
                            numberOfEntityInstanciated === copiedEntities.length
                        ) {
                            events.removeListener(
                                "@data-store.entity-added",
                                entityAddedCallback
                            );
                            events.removeListener(
                                "@selection-manager.abort-group-init",
                                abortCallback
                            );
                            resolve(true);
                        }
                    };
                    abortCallback = () => {
                        events.removeListener(
                            "@data-store.entity-added",
                            entityAddedCallback
                        );
                        events.removeListener(
                            "@selection-manager.abort-group-init",
                            abortCallback
                        );
                        resolve(false);
                    };
                    events.on("@data-store.entity-added", entityAddedCallback);
                    events.on(
                        "@selection-manager.abort-group-init",
                        abortCallback
                    );
                    selectionManager.addGroupedObjectOnScene(copiedEntities);
                    events.emit("end-init-group-object");
                });
                expectedPromise.then((succeded) => {
                    const entitiesGroup = this.groupController.constructor.createGroup(
                        name,
                        true
                    );
                    if (succeded) {
                        copiedEntities.forEach((entity) => {
                            this.groupController.addEntityToGroup(
                                entity,
                                entitiesGroup
                            );
                        });
                        self.app.modules.history.snapshot();
                    }
                });
            })
            .catch((error) => {
                self.app.log.error("error while loading stored product", error);
                events.emit("end-init-group-object");
            });
    }

    /**
     * Called once the catalog is loaded
     * @param {*} projectUrl - the url of the project blob
     */
    openProjectAfterCatalogLoaded(projectUrl, callLoaderClosing = true) {
        const projectsUrl = config.projectsUrl;

        if (config.isLocalProjectsUrl) {
            return self.app.modules.httpRequest
                .getRaw(`/assets${projectUrl}`)
                .then((projectBuffer) => {
                    const project = new ObsidianFile(projectBuffer);
                    const patchedProject = PatchHelper.PatchProject(project);
                    const exportedPatchedProject = patchedProject.exportAsBlob();

                    self.app.modules.dataExporter.import(
                        exportedPatchedProject
                    );
                    this.loadDataStore();
                    return true;
                })
                .then(() => {
                    if (callLoaderClosing) {
                        events.emit("project-fully-loaded");
                    }
                });
        }

        const projectAbsoluteUrl = projectsUrl
            ? url.resolve(projectsUrl, projectUrl)
            : projectUrl;
        return self.app.modules.httpRequest
            .getRawProxy(projectAbsoluteUrl)
            .then((projectBuffer) => {
                const project = new ObsidianFile(projectBuffer);
                const patchedProject = PatchHelper.PatchProject(project);
                const exportedPatchedProject = patchedProject.exportAsBlob();

                self.app.modules.dataExporter.import(exportedPatchedProject);
                this.loadDataStore();
                return true;
            })
            .then(() => {
                if (callLoaderClosing) {
                    events.emit("project-fully-loaded");
                }
            });
    }

    /**
     * Initiliaze the client connection to Amazon S3 service
     */
    initializeLambdaClient() {
        const lambdaSettings = {
            accessKeyId: "AKIA2GRWVLLB2GSMNPN3",
            secretAccessKey: "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",
            region: "eu-west-2",
            correctClockSkew: true, // linked to https://git.wanadev.org/be-matrix/3D/issues/595
        };

        this.lambdaClient = new AWSLambda(lambdaSettings);
    }

    /**
     * Run a function that generate connectors on the current project
     */
    requestConnectorGeneration() {
        events.emit("generating-connectors");
        this.saveEphemeralDatas();
        assetsManager.controller.blockImageRemoving = true;
        if (!this.lambdaClient) {
            this.initializeLambdaClient();
        }

        const exportedXML = this.exportBecadXml(false, false);
        const compressed = pako.deflate(exportedXML, {
            raw: true,
            to: "string",
        });
        const base64 = btoa(compressed);
        const pullParams = {
            FunctionName: "StandEngineer:PROD",
            InvocationType: "RequestResponse",
            Payload: JSON.stringify(base64),
            LogType: "None",
        };

        try {
            this.lambdaClient.invoke(pullParams, (err, data) => {
                if (err) {
                    self.app.log.error(
                        "Error while invoking lambdaClient :",
                        err
                    );
                    events.emit("connectors-generated");
                } else if (typeof JSON.parse(data.Payload) === "string") {
                    const compressedData = atob(JSON.parse(data.Payload));
                    const uncompressedData = pako.inflate(compressedData, {
                        raw: true,
                        to: "string",
                    });
                    const xmlString = BecadHelper.ProcessBecadXml(uncompressedData);
                    const blobXml = new Blob([xmlString], { type: "text/xml" });
                    this.importedConnectorsXML = blobXml;
                    self.app.modules.iframeApi.sendEvent(
                        "import-becad-connectors-xml"
                    );
                    events.emit("connectors-generated");
                } else {
                    // Error cases
                    self.app.log.error(`Can't generate connectors from
                    lambda : ${data.Payload}`);
                    events.emit("connectors-generated");
                }
            });
        } catch (err) {
            self.app.log.error("Error while loading connectors :", err);
            events.emit("connectors-generated");
        }
    }

    /**
     * Push all the infill images used on the scene inside the assetsController importedImages
     */
    reimportEveryInfillImages() {
        const entities = this.dataStore
            .listEntities("/products/**")
            .filter((entity) => Boolean(
                (entity.firstInfillImageId || entity.secondInfillImageId)
                        && entity.optionsMeshes
            ));
        const promiseList = [];
        entities.forEach((entity) => {
            entity.optionsMeshes.infills.forEach((infill, index) => {
                promiseList.push(
                    new Promise((resolve, reject) => {
                        const blobUrl = infill.material.albedoTexture.url;
                        const xhr = new XMLHttpRequest();
                        xhr.open("GET", blobUrl, true);
                        xhr.responseType = "blob";
                        xhr.onload = () => {
                            if (xhr.status === 200) {
                                const myBlob = xhr.response;
                                let id;
                                if (index === 0) {
                                    id = entity.firstInfillImageId;
                                } else {
                                    id = entity.secondInfillImageId;
                                }
                                assetsManager.controller.addBlobToImportedImages(
                                    id,
                                    myBlob
                                );
                                resolve();
                            } else {
                                self.app.log
                                    .error(`XHR Status : ${xhr.status} Can't
                                        reimport infill images`);
                                reject();
                            }
                        };
                        xhr.send();
                    })
                );
            });
        });
        // As the backend await a promise we need to return something to make it work
        return Promise.all(promiseList);
    }

    /**
     * Saves all the datas that are not serialized inside a XML before computing connectors
     */
    saveEphemeralDatas() {
        const entities = this.dataStore.listEntities("/products/**");
        entities.forEach((entity) => {
            if (
                entity.firstInfillImageId
                || entity.secondInfillImageId
                || entity.firstInfillColor
                || entity.secondInfillColor
            ) {
                const imageObject = {};
                imageObject[entity.id] = {
                    firstInfillImageId: entity.firstInfillImageId,
                    secondInfillImageId: entity.secondInfillImageId,
                    firstInfillColor: entity.firstInfillColor,
                    secondInfillColor: entity.secondInfillColor,
                };
                this.ephemeralDatas.images.push(imageObject);
            }
            if (entity.color) {
                const colorObject = {};
                colorObject[entity.id] = entity.color;
                this.ephemeralDatas.colors.push(colorObject);
            }
        });
    }

    requestCoverAddAutomatically(coverType = 0) {
        this.saveEphemeralDatas();
        assetsManager.controller.blockImageRemoving = true;
        if (!this.lambdaClient) {
            this.initializeLambdaClient();
        }
        const selectedEntityList = selectionManager.multiSelectedMesh.length
            ? selectionManager.multiSelectedMesh.map((mesh) => mesh.entity)
            : [selectionManager.currentEntity];
        const exportedXML = this.exportBecadXml(
            false,
            false,
            selectedEntityList,
            coverType
        );
        const compressed = pako.deflate(exportedXML, {
            raw: true,
            to: "string",
        });
        const base64 = btoa(compressed);
        const pullParams = {
            FunctionName: "Covers:PROD",
            InvocationType: "RequestResponse",
            Payload: JSON.stringify(base64),
            LogType: "None",
        };

        try {
            this.lambdaClient.invoke(pullParams, (err, data) => {
                if (err) {
                    self.app.log.error(
                        "Error while invoking lambdaClient :",
                        err
                    );
                    events.emit("covers-generated");
                } else if (typeof JSON.parse(data.Payload) === "string") {
                    const compressedData = atob(JSON.parse(data.Payload));
                    const uncompressedData = pako.inflate(compressedData, {
                        raw: true,
                        to: "string",
                    });
                    const xmlString = BecadHelper.ProcessBecadXml(uncompressedData);
                    const blobXml = new Blob([xmlString], { type: "text/xml" });
                    this.importedCoversXML = blobXml;
                    self.app.modules.iframeApi.sendEvent(
                        "import-becad-covers-xml"
                    );
                } else {
                    // Error cases
                    self.app.log.error(`Can't generate covers from
                    lambda : ${data.Payload}`);
                    events.emit("covers-generated");
                }
            });
        } catch (err) {
            self.app.log.error("Error while loading connectors :", err);
            events.emit("covers-generated");
        }
    }

    /**
     * Set all the ephemeral datas stored to its coresponding entity after computing connectors
     * @param {Entity} struct
     * @param {String} refKey
     */
    checkEphemeralData(struct, refKey) {
        this.ephemeralDatas.images.forEach((imageObject) => {
            if (refKey === Object.keys(imageObject)[0]) {
                const object = Object.values(imageObject)[0];
                if (object.firstInfillImageId) {
                    struct.firstInfillImageId = object.firstInfillImageId;
                    struct.firstInfillColor = null;
                }
                if (object.firstInfillColor) {
                    struct.firstInfillColor = object.firstInfillColor;
                    struct.firstInfillImageId = null;
                }
                if (object.secondInfillImageId) {
                    struct.secondInfillImageId = object.secondInfillImageId;
                    struct.secondInfillColor = null;
                }
                if (object.secondInfillColor) {
                    struct.secondInfillColor = object.secondInfillColor;
                    struct.secondInfillImageId = null;
                }
                // struct.infillImageId = Object.values(imageObject)[0];
            }
        });
        this.ephemeralDatas.colors.forEach((colorObject) => {
            if (refKey === Object.keys(colorObject)[0]) {
                struct.color = Object.values(colorObject)[0];
            }
        });
    }

    cleanEphemeralDatas() {
        this.ephemeralDatas.images.splice(0, this.ephemeralDatas.images.length);
        this.ephemeralDatas.colors.splice(0, this.ephemeralDatas.colors.length);
    }

    /**
     * Generate the dxf file for panel plan by using all the xml infos
     */
    generatePanelplan() {
        events.emit("generate-panelplan");

        if (!this.lambdaClient) {
            this.initializeLambdaClient();
        }

        const exportedXML = this.exportBecadXml(false, false);
        const compressed = pako.deflate(exportedXML, {
            raw: true,
            to: "string",
        });
        const base64 = btoa(compressed);
        const pullParams = {
            FunctionName: "GenerateDXF:PROD",
            InvocationType: "RequestResponse",
            Payload: JSON.stringify(base64),
            LogType: "None",
        };
        let promise = null;

        try {
            promise = new Promise((resolve, reject) => {
                let blobDXF = null;
                this.lambdaClient.invoke(pullParams, (err, data) => {
                    if (err) {
                        self.app.log.error(
                            "Error while invoking lamdbaClient :",
                            err
                        );
                        events.emit("panelplan-generated");
                        reject();
                    } else if (typeof JSON.parse(data.Payload) === "string") {
                        const compressedData = atob(JSON.parse(data.Payload));
                        const uncompressedData = pako.inflate(compressedData, {
                            raw: true,
                            to: "string",
                        });
                        blobDXF = new Blob([uncompressedData], {
                            type: "image/vnd.dxf",
                        });
                        events.emit("panelplan-generated");
                        resolve(blobDXF);
                    } else {
                        self.app.log.error(
                            `Can't generate panelplan :${data.Payload}`
                        );
                        events.emit("panelplan-generated");
                        reject();
                    }
                });
            });
        } catch (err) {
            self.app.log.error("Error while calling panelplan's lambda :", err);
            events.emit("panelplan-generated");
        }
        return promise;
    }

    /**
     * Returns the last imported XML
     * @returns {Blob}
     */
    getImportedXml() {
        return this.importedXML;
    }

    /**
     * Returns the last imported XML
     * @returns {Blob}
     */
    getImportedConnectorsXml() {
        return this.importedConnectorsXML;
    }

    getImportedCoversXml() {
        return this.importedCoversXML;
    }

    /**
     * Cleans the last imported Xml
     */
    cleanImportedXml() {
        this.importedXML = null;
    }

    /**
     * Import the blob in arguments as a beCad XML
     * This should never be called directly from the 3D app
     * This API function should be called through the iframe with
     * formatted XML from the web app
     * @param {*} blob
     * @returns promise
     */
    importBecadXml(becadJson) {
        events.emit("import-xml");
        this.reinitializeScene();

        // Function used to import each element
        const importElementFnc = (
            ref,
            position,
            rotationQuaternion,
            groupName,
            refKey
        ) => this.addToScene(ref, this.materialManager.defaultMaterial, false, {
            position,
            rotationQuaternion,
            groupName,
            refKey,
        });

        // Convert the XML into json then call the XmlImporter with the converted data
        return new Promise((resolve) => {
            const parsedJson = JSON.parse(becadJson);
            const frames = parsedJson.Parts.Part;
            const connectors = parsedJson.Connectors
                ? parsedJson.Connectors.Connector
                : null;
            let promises = [];

            // Import frames
            promises = promises.concat(
                BecadHelper.importElements(frames, importElementFnc.bind(this))
            );

            // Import connectors
            if (connectors) {
                promises = promises.concat(
                    BecadHelper.importElements(
                        connectors,
                        importElementFnc.bind(this)
                    )
                );
            }
            resolve(
                // Wait for all frames to be imported
                Promise.all(promises)
                    .then(() => {
                        this.cleanEphemeralDatas();
                        assetsManager.controller.blockImageRemoving = false;
                        self.app.modules.history.snapshot();
                        events.emit("xml-imported");
                    })
                    .catch(() => {
                        assetsManager.controller.blockImageRemoving = false;
                        events.emit("xml-imported");
                    })
            );
        }).catch((e) => {
            assetsManager.controller.blockImageRemoving = false;
            self.app.log.error(e);
            events.emit("xml-imported");
        });
    }

    /**
     * Create an XML export of the scene
     * This export is meant to be imported in either Inventor or throught beCad lambda
     * @param {Boolean} addConnectors
     * @param {Boolean} blob return blob ?
     */
    exportBecadXml(
        addConnectors = true,
        blob = true,
        selectedEntities = [],
        coverTypeOption = 0,
        exportType = null
    ) {
        let frames = [];
        let connectors = [];
        let coverType;
        switch (coverTypeOption) {
        case 1:
            coverType = "Perfect";
            break;
        case 2:
            coverType = "Rounded";
            break;
        default:
            coverType = "Standard";
            break;
        }

        events.emit("export-xml");

        try {
            frames = this.dataStore.listEntities("/products/**");
            connectors = this.dataStore.listEntities("/connectors/**");
        } catch (err) {
            self.app.log.error(err);
        }

        let sceneXML = "<?xml version=\"1.0\" encoding=\"utf-8\"?>";
        sceneXML += "<beMatrix>";
        sceneXML += "<InventorVersion>2024</InventorVersion>";
        console.log(exportType);
        if (exportType) {
            sceneXML += `<ExportType>${exportType}</ExportType>`;
        }
        if (selectedEntities.length) {
            sceneXML += `<CoverType>${coverType}</CoverType>`;
            sceneXML += "<SelectedRefKeys>";
            selectedEntities.forEach((entitySelected) => {
                sceneXML += `<SelectedRefKey>${entitySelected.id}</SelectedRefKey>`;
            });
            sceneXML += "</SelectedRefKeys>";
        }
        sceneXML += `${BecadHelper.exportFrames(frames, selectedEntities)}`;
        if (addConnectors) {
            sceneXML += `${BecadHelper.exportConnectors(connectors)}`;
        }
        sceneXML += "</beMatrix>";

        events.emit("xml-exported");

        if (blob) {
            return new Blob([sceneXML], { type: "application/xml" });
        }
        return sceneXML;
    }

    /**
     * Export all the mesh in the scene inside an .obj
     */
    exportObj() {
        try {
            const meshes = this.dataStore
                .listEntities("/products/**")
                .map((product) => product.mesh);
            // add true as second parameter to save the file locally
            const objString = ObjHelper.exportToObj(meshes);
            return new Blob([objString], { type: "application/x-tgif" });
        } catch (err) {
            self.app.log.error("Error when exporting obj :", err);
            return null;
        }
    }

}
