<template>
    <div v-if="isOverlayVisible">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div class="modal-body">
                            <slot name="body">
                                <loader v-if="nbLoaderCalling > 0" />
                                <dialogPopin
                                    v-if="isPopinVisible"
                                    :title="configuration.title"
                                    :description="configuration.description"
                                    :is-required="configuration.isRequired"
                                    :on-validate="configuration.onValidate"
                                    :on-cancel="configuration.onCancel"
                                    @hidePopin="hidePopin"
                                />
                            </slot>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import RightsHelper from "helpers/rights-helper";
import self from "../../../index";
import loader from "./loader.vue";
import dialogPopin from "./dialog-popin.vue";

const {
    events,
    modules: {
        iframeApi,
        selectionManager,
        stonejs,
    },
    log,
} = self.app;

export default {
    name: "Overlay",
    components: {
        loader, dialogPopin,
    },
    data() {
        return {
            isOverlayVisible: false,
            isPopinVisible: false,
            nbLoaderCalling: 0,
            configuration: {
                title: null,
                description: null,
                isRequired: null,
                onValidate: null,
                onCancel: null,
            },
        };
    },
    mounted() {
        iframeApi.addApiMethod("runLoader", () => {
            this.showLoader();
        });
        iframeApi.addApiMethod("stopLoader", () => {
            this.hideLoader();
        });

        // Init loader
        this.showLoader();

        events.on("showLoader", () => {
            this.showLoader();
        });
        events.on("hideLoader", () => {
            this.hideLoader();
        });

        events.on("open-popin", configuration => {
            this.loadPopin(configuration);
        });

        this.initLoaderEvents();
        this.initDeleteEvents();

    },
    methods: {
        /**
             * Initialize all the events that shows or hide the loader
             */
        initLoaderEvents() {
            // HIDE LOADER EVENTS
            ["hideLoader", "@catalog-manager.catalog-initialized",
                "@project-manager.project-loaded", "@swap-manager.end-swap-model",
                "@project-manager.connectors-generated", "@option-manager.screen-bracket-loaded",
                "@project-manager.xml-imported", "@project-manager.xml-exported",
                "@project-manager.panelplan-generated", "@option-manager.infill-image-loaded",
                "@project-manager.end-init-group-object", "@project-manager.project-fully-loaded",
                "@building-plan-manager.preview-created", "@building-plan-manager.page-content-written",
                "@option-manager.end-update-option", "@camera-manager.end-screenshot-behavior",
                "@measurement-manager.measurement-mode-loaded",
                "@option-manager.image-to-spread-loaded"]
                .forEach(
                    currentEventName => {
                        events.on(currentEventName, () => {
                            this.hideLoader();
                        });
                    }
                );

            // SHOW LOADER EVENTS
            ["showLoader", "@project-manager.loading-project",
                "@project-manager.generating-connectors",
                "@swap-manager.loading-swap-model", "@option-manager.loading-screen-bracket",
                "@project-manager.import-xml", "@project-manager.export-xml",
                "@project-manager.generate-panelplan",
                "@option-manager.load-infill-image",
                "@project-manager.init-group-object", "@project-manager.loading-remote-project",
                "@building-plan-manager.create-preview", "@building-plan-manager.write-page-content",
                "@option-manager.update-option", "@camera-manager.start-screenshot-behavior",
                "@measurement-manager.loading-measurement-mode",
                "@selection-manager.displaying-image-on-many-infills"]
                .forEach(
                    currentEventName => {
                        events.on(currentEventName, () => {
                            this.showLoader();
                        });
                    }
                );
        },

        /**
         * Event when the user delete entities
         */
        initDeleteEvents() {
            self.app.events.on("@obsidian-engine.delete", () => {
                if (RightsHelper.isModeBuildingPlan()) {
                    return;
                }
                selectionManager.deleteAllSelected();
            });
        },

        showLoader() {
            this.isOverlayVisible = true;
            this.nbLoaderCalling += 1;
        },

        hideLoader() {
            this.nbLoaderCalling -= 1;
            if (this.nbLoaderCalling === 0) {
                this.isOverlayVisible = false;
            } if (this.nbLoaderCalling < 0) {
                log.error(
                    "HideLoader is called to much times, it's current value goes under 0:",
                    this.nbLoaderCalling
                );
            }
        },

        showPopin() {
            this.isOverlayVisible = true;
            this.isPopinVisible = true;
            events.emit("popin-opened");
        },

        hidePopin() {
            this.isOverlayVisible = false;
            this.isPopinVisible = false;
        },

        loadPopin(configuration) {
            this.configuration = configuration;
            this.showPopin();
        },

        _(...args) {
            return stonejs.gettext(...args);
        },
    },
};
</script>
<style lang ="scss">

@import "../../../../../style/base/variables";

    .modal-mask {
        position: fixed;
        z-index: $z-index-overlay;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
    }
    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }
</style>
