const groundMeshes = [
    "main-ground",
    "offset-plane",
];

const ignoredMeshNames = groundMeshes.concat([
    "initDragPlane",
]);


export default {
    HasPickedIgnoredMesh(pickResult) {
        return ignoredMeshNames.includes(pickResult.pickedMesh.name);
    },

    HasPickedGround(pickResult) {
        return groundMeshes.includes(pickResult.pickedMesh.name);
    },
};
