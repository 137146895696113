import {
    TransformNode, MeshBuilder, Color3, Vector3, StandardMaterial, Quaternion,
} from "@babylonjs/core";
import { v4 as uuid } from "uuid";
import config from "defaultConfig"; // eslint-disable-line
import self from "../../index";

const DEBUG_SNAP_RECTANGLES = false;
let DebugMaterial = null;
let DebugMaterialSnapped = null;

export default class SnapRectangle extends TransformNode {

    /**
     * @class bematrix.SnapRectangle
     * @param {TransformNode} parentEntity
     * @param {Number} index
     * @param {Vector3} relativePosition
     * @param {Quaternion} rotationQuaternion
     * @param {Number} width
     * @param {Number} height
     */
    constructor(parentEntity, index, relativePosition, rotationQuaternion, width, height) {
        super(uuid(), parentEntity.mesh.getScene());
        this.parent = parentEntity.mesh;

        this.parentEntity = parentEntity;

        this.position = relativePosition;

        this.index = index;

        this.snapped = null;

        // local rotation
        this.rotationQuaternion = rotationQuaternion;

        // global rotation
        this.globalQuaternion = null;

        // Opposite transform
        this.oppositeTransform = new TransformNode(uuid(), this.getScene());
        this.oppositeTransform.parent = this.parent;
        this.oppositeTransform.rotationQuaternion = new Quaternion(
            rotationQuaternion.x, rotationQuaternion.y, rotationQuaternion.z, rotationQuaternion.w
        );
        this.oppositeTransform.rotationQuaternion.multiplyInPlace(
            Quaternion.RotationAxis(
                Vector3.Left(), Math.PI
            )
        );
        this.oppositeTransform.rotationQuaternion.normalize();

        // global opposite rotation
        this.oppositeGlobalQuaternion = null;

        this.normal = this.forward;

        this.width = Math.round(width / config.step) * config.step;
        this.height = Math.round(height / config.step) * config.step;

        if (height > width) {
            self.app.log.error(`Snap face corrupted height is bigger than width for reference
                ${this.parentEntity.ref}`);
        }

        if (this.parentEntity.subCategory !== "COVER PROFILES") {
            this.position.y = Math.round(this.position.y / config.step) * config.step;
        }

        // to investigate : snap bug when mesh not entity yet and we stap an other one to it
        // happens only when there is no compute world matrix aftertimeout
        // and no debug rectangles ...
        setTimeout(() => {
            this.computeWorldMatrix();
        }, 100);

        if (DEBUG_SNAP_RECTANGLES) {
            this.debugMesh();
        }

    }

    snap(snapRectangle) {
        this.snapped = snapRectangle;
        snapRectangle.snapped = this;

        if (DEBUG_SNAP_RECTANGLES) {
            if (!DebugMaterialSnapped) {
                DebugMaterialSnapped = new StandardMaterial(`debug Mat ${uuid()}`, this.getScene());
                DebugMaterialSnapped.diffuseColor = Color3.Blue();
                DebugMaterialSnapped.emissiveColor = Color3.Blue();
            }
            this.debugMesh.material = DebugMaterialSnapped;
            this.debugNormalMesh.material = DebugMaterialSnapped;

            snapRectangle.debugMesh.material = DebugMaterialSnapped;
            snapRectangle.debugNormalMesh.material = DebugMaterialSnapped;
        }
    }

    unsnap() {

        if (this.snapped) {
            if (DEBUG_SNAP_RECTANGLES) {
                this.snapped.debugMesh.material = DebugMaterial;
                this.snapped.debugNormalMesh.material = DebugMaterial;
                this.debugMesh.material = DebugMaterial;
                this.debugNormalMesh.material = DebugMaterial;
            }

            this.snapped.snapped = null;
            this.snapped = null;
        }

    }

    /* Not used yet..
    overlap(snapRectangle) {
        const projectedPos1 = Vector3.Dot(this.absolutePosition, this.right);
        const projectedPos2 = Vector3.Dot(snapRectangle.absolutePosition, this.right);

        const max1 = projectedPos1 + this.width / 2;
        const min1 = projectedPos1 - this.width / 2;

        const max2 = projectedPos2 + snapRectangle.width / 2;
        const min2 = projectedPos2 - snapRectangle.width / 2;

        let delta = 0;
        if (max1 > max2) {
            delta = max2 - min1;
        } else {
            delta = max1 - min2;
        }
        return Math.max(delta, 0);
    } */

    removeWidthComponent(vector, maxOffset = this.width) {
        let projOnUp = Vector3.Dot(vector, this.right);
        if (Math.abs(projOnUp) > maxOffset) {
            projOnUp = maxOffset * (Math.sign(projOnUp));
        }
        vector.subtractInPlace(this.right.scale(projOnUp));
    }

    debugMesh() {
        this.debugMesh = MeshBuilder.CreateBox(uuid(), {
            width: this.width,
            height: this.height,
            depth: 0.002,
        }, this.getScene());

        if (!DebugMaterial) {
            DebugMaterial = new StandardMaterial(`debug Mat ${uuid()}`, this.getScene());
            DebugMaterial.diffuseColor = Color3.Red();
            DebugMaterial.emissiveColor = Color3.Red();
        }

        const normalMesh = MeshBuilder.CreateCylinder(`normal${uuid()}`,
            {
                height: 0.200,
                diameter: 0.005,
                tessellation: 3,
            },
            this.getScene());
        normalMesh.parent = this;
        normalMesh.isPickable = false;

        //
        normalMesh.position.z += 0.100;
        // Debug : console.log("top normal :", this.forward);
        // Debug : console.log("top inverted normal :", this.oppositeTransform.forward);
        normalMesh.rotate(Vector3.Left(), Math.PI / 2);
        this.debugNormalMesh = normalMesh;
        this.debugNormalMesh.material = DebugMaterial;
        this.debugMesh.material = DebugMaterial;
        this.debugMesh.isPickable = false;
        this.debugMesh.parent = this;
        this.debugMesh.metadata = {
            isOption: true,
        };
        this.debugNormalMesh.metadata = {
            isOption: true,
        };
    }

}
