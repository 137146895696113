<template>
    <div class="environment-selector">
        <div class="selector-header">
            <h1> {{ _("Floor selector") }} </h1>
            <img
                src="/assets/icons/close.svg"
                class="close"
                @click="cancel()"
            >
        </div>
        <div class="image-list">
            <img
                v-for="(url, name) in floors"
                :key="name"
                :src="url"
                :class="{ selected: floorName === name}"
                @click="$emit('update:floorName', name)"
            >
        </div>
        <div class="selector-footer">
            <button
                :disabled="!canConfirm"
                class="confirm"
                @click="confirm()"
            >
                {{ _("Confirm") }}
            </button>
            <button
                class="cancel"
                @click="cancel()"
            >
                {{ _("Cancel") }}
            </button>
        </div>
    </div>
</template>
<script>
import self from "../../index";
import EventBus from "../eventBus";

export default {
    name: "EnvironmentSelectionDialog",
    props: {
        floorName: {
            type: String,
            default: () => "",
        },
    },
    data() {
        return {
            floors: {
                default: "/assets/textures/default_grid.png",
                white: "/assets/textures/white_floor.jpg",
                marble: "/assets/textures/marble_01_1k_jpg/marble_01_diff_1k.jpg",
                concrete: "/assets/textures/Concrete_011_SD/Concrete_011_COLOR.jpg",
                fabric_007: "/assets/textures/Fabric_007_SD/Fabric_007_COLOR.jpg",
                fabric_011: "/assets/textures/Fabric_011_SD/Fabric_011_COLOR.jpg",
                fabric_015: "/assets/textures/Fabric_015_SD/Fabric_015_COLOR.jpg",
                grass: "/assets/textures/Grass_001_SD/Grass_001_COLOR.jpg",
                rug: "/assets/textures/Rug_001/Rug_001_COLOR.jpg",
                tweed: "/assets/textures/Tweed_001_SD/Tweed_001_COLOR.jpg",
                wood_007: "/assets/textures/Wood_007_SD/Wood_007_COLOR.jpg",
                wood_020: "/assets/textures/Wood_020_SD/Wood_020_basecolor.jpg",
                wood_floor: "/assets/textures/Wood_Floor_007_SD/Wood_Floor_007_COLOR.jpg",
            },
        };
    },
    computed: {
        canConfirm() {
            return this.floorName;
        },
    },
    methods: {
        confirm() {
            self.app.modules.iframeApi.sendEvent("screenshot");
            EventBus.$emit("close-environment-selector");
        },
        cancel() {
            EventBus.$emit("close-environment-selector");
            this.resetSelection();
        },
        resetSelection() {
            this.$emit("update:floorName", null);
        },
        _(...args) {
            return self.app.modules.stonejs.gettext(...args);
        },
    },
};
</script>
<style lang="scss">

@import "../../../../style/bematrix.scss";

.environment-selector {
    width: 30%;
    max-height: 50%;
    background-color: #fff;
    transition: all 0.3s ease;
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 35%;
    top: 200px;

    .selector-header {
        display: flex;
        width: 100%;
        border-radius: 4px 4px 0 0;
        padding: 1rem;
        background: $green;

        h1 {
            font-family: $font-semibold;
            font-size: 1.2em;
            color: #fff;
            text-align: center;
        }

        .close {
            margin-left: auto;
            right: 15px;
            top: 15px;
            cursor: pointer;
        }
    }

    .image-list {
        margin: 1.5rem 0;
        overflow: auto;
        display: flex;
        flex-wrap: wrap;

        img {
            width: 25%;
            padding: 0.25rem;
            margin: auto;
            border: 2px solid transparent;
            border-radius: 5px;

            &.selected {
                border-color:$green;
            }
        }
    }

    .selector-footer {
        padding: 1rem;
        display: flex;
        flex-direction: row-reverse;
        border-top: solid $border-grey 1px;

        button {
            width: 20%;
            font-family: $font-medium;
            font-size: 0.85em;
            border: 0px transparent;
            outline: 0px transparent;
            border-radius: 3px;
            margin-left: 1rem;
            text-align: center;
            padding: 0.25rem;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            &.confirm {
                background: $green;
                color: #fff;

                &:hover {
                    background: $green-hover;
                }

                &:active {
                    background: $green-click;
                }

                &:disabled {
                    background: $pale-grey-click;
                }
            }

            &.cancel {
                background: $pale-grey;
                color: $dark;

                &:hover {
                    background: $pale-grey-hover;
                }

                &:active {
                    background: $pale-grey-click;
                }
            }
        }
    }
}
</style>
