export default {
    name: "project-manager",
    requires: [
        "data-store",
        "catalog-manager",
        "iframe-api",
        "http-request",
        "data-exporter",
        "group-manager",
        "grid-manager",
        "scene-manager",
        "camera-manager",
        "option-manager",
        "entity-manager",
        "obsidian-engine",
        "material-manager",
        "selection-manager",
        "mesh-manager",
        "assets-manager",
        "history",
    ],
    load() {
        const Controller = require("./src/controllers/project-controller").default; // eslint-disable-line
        return {
            controller: new Controller(),
        };
    },
    unload() {},
};
