import {
    PositionGizmo,
    Vector3,
    AbstractMesh,
    TransformNode,
    Mesh,
    Matrix,
    PointerDragBehavior,
} from "@babylonjs/core";

import GizmoHelper from "../helpers/gizmo-helper";
import {
    PLANES, NORMALS, COLORS, Plane, addHoverMaterial,
} from "../helpers/gizmo-meshes-helper";

import self from "../..";

const {
    collisionManager: {
        CollisionHelper,
    },
    guiManager,
    history,
} = self.app.modules;

/**
 * Extends Babylon base Gizmo
 * Ready to be modified to fit beMatrix demands
 */


export default class PlaneGizmo extends PositionGizmo {

    constructor(highlightManager, scene) {
        super();
        this.highlightManager = highlightManager;
        this.attachedEntity = null;
        this.highlightBehavior = null;

        this.dragSizeViewer = new guiManager.DragSizeViewer(guiManager.GuiController, scene);

        this.planes = [];

        Object.values(NORMALS).forEach((value, index) => {
            this.planes.push(
                new Plane(
                    value,
                    COLORS[index],
                )
            );
        });

        // Translation and rotaton to move the gizmo
        this.planes[PLANES.YZ].mesh.rotate(Vector3.Up(), -Math.PI / 2);
        this.planes[PLANES.XZ].mesh.rotate(Vector3.Right(), Math.PI / 2);
        this.planes.forEach((plane) => {
            plane.mesh.translate(Vector3.Up(), 0.025);
            plane.mesh.translate(Vector3.Right(), 0.025);
        });

        // Remove the old behavior
        [this.xGizmo, this.yGizmo, this.zGizmo].forEach((gizmo, index) => {
            gizmo._rootMesh.removeBehavior(gizmo._rootMesh.behaviors[0]);

            gizmo.setCustomMesh(this.planes[index].mesh);

            // Create a new behavior for each axis
            gizmo.dragBehavior = new PointerDragBehavior(
                {
                    dragPlaneNormal: NORMALS[index],
                }
            );
            gizmo.dragBehavior.moveAttached = false;
            PlaneGizmo.addVirtualGridMovementBehavior(gizmo);

            gizmo.dragBehavior.onDragObservable.add(
                () => {
                    if (gizmo.attachedMesh && gizmo.attachedMesh instanceof Mesh) {
                        CollisionHelper.isUnderGrid(
                            gizmo.attachedMesh.entity,
                            { isEntity: true, respond: true }
                        );
                    }
                    if (this.highlightBehavior) {
                        this.highlightBehavior.startHighlightBehavior();
                    }
                }
            );

            gizmo.dragBehavior.onDragEndObservable.add(
                () => {
                    if (gizmo.attachedMesh && gizmo.attachedMesh instanceof TransformNode
                        && !(gizmo.attachedMesh instanceof AbstractMesh)) {
                        GizmoHelper.checkNodeGridRepulsion(gizmo.attachedMesh);
                        GizmoHelper.updateGizmoAttachedMeshChildren(gizmo);
                    } else {
                        this.notificateEntity();
                    }
                    history.snapshot();
                    if (this.highlightBehavior) {
                        this.highlightBehavior.startHighlightBehavior();
                    }
                }
            );

            this.bindGuiBehavior(gizmo, index);

            addHoverMaterial(gizmo, COLORS[index], this.planes[index].material);

            // Add the new behavior
            gizmo._rootMesh.addBehavior(gizmo.dragBehavior);
            gizmo.dragBehavior.attach(gizmo._rootMesh);
        });
    }

    notificateEntity() {
        if (this.attachedEntity) {
            this.attachedEntity.updatePosition();
        }
    }

    /**
     * Add the behavior that makes the mesh move on the virual grid
     * @param {*} gizmo
     */
    static addVirtualGridMovementBehavior(gizmo) {
        const tmpVector = new Vector3();
        const localDelta = new Vector3();
        const tmpMatrix = new Matrix();
        let currentSnapDragDistanceX = 0;
        let currentSnapDragDistanceY = 0;
        let currentSnapDragDistanceZ = 0;
        gizmo.dragBehavior.onDragObservable.add((event) => {
            if (gizmo.attachedMesh) {

                // Convert delta to local translation if it has a parent
                if (gizmo.attachedMesh.parent) {
                    gizmo.attachedMesh.parent.computeWorldMatrix().invertToRef(tmpMatrix);
                    tmpMatrix.setTranslationFromFloats(0, 0, 0);
                    Vector3.TransformCoordinatesToRef(
                        event.delta,
                        tmpMatrix,
                        localDelta
                    );
                } else {
                    gizmo.attachedMesh.computeWorldMatrix().invertToRef(tmpMatrix);
                    tmpMatrix.setTranslationFromFloats(0, 0, 0);
                    Vector3.TransformCoordinatesToRef(
                        event.delta,
                        tmpMatrix,
                        localDelta
                    );
                }
                // Snapping logic
                if (gizmo.snapDistance === 0) {
                    gizmo.attachedMesh.position.addInPlace(localDelta);
                } else {
                    const tmpSnapEvent = { snapDistance: 0 };

                    // Code is almost the same for the three axis
                    currentSnapDragDistanceX += localDelta.x;
                    if (Math.abs(currentSnapDragDistanceX) > gizmo.snapDistance) {
                        const dragSteps = Math.floor(
                            Math.abs(currentSnapDragDistanceX) / gizmo.snapDistance
                        );
                        const xAxisDelta = gizmo.attachedMesh.right
                            .scale(Math.sign(currentSnapDragDistanceX));
                        currentSnapDragDistanceX %= gizmo.snapDistance;
                        xAxisDelta.normalizeToRef(tmpVector);
                        tmpVector.scaleInPlace(gizmo.snapDistance * dragSteps);
                        gizmo.attachedMesh.position.addInPlace(tmpVector);
                        tmpSnapEvent.snapDistance = gizmo.snapDistance * dragSteps;
                    }

                    currentSnapDragDistanceY += localDelta.y;
                    if (Math.abs(currentSnapDragDistanceY) > gizmo.snapDistance) {
                        const dragSteps = Math.floor(
                            Math.abs(currentSnapDragDistanceY) / gizmo.snapDistance
                        );
                        const yAxisDelta = gizmo.attachedMesh.up
                            .scale(Math.sign(currentSnapDragDistanceY));
                        currentSnapDragDistanceY %= gizmo.snapDistance;
                        yAxisDelta.normalizeToRef(tmpVector);
                        tmpVector.scaleInPlace(gizmo.snapDistance * dragSteps);
                        gizmo.attachedMesh.position.addInPlace(tmpVector);
                        tmpSnapEvent.snapDistance = gizmo.snapDistance * dragSteps;
                    }

                    currentSnapDragDistanceZ += localDelta.z;
                    if (Math.abs(currentSnapDragDistanceZ) > gizmo.snapDistance) {
                        const dragSteps = Math.floor(
                            Math.abs(currentSnapDragDistanceZ) / gizmo.snapDistance
                        );
                        const zAxisDelta = gizmo.attachedMesh.forward
                            .scale(Math.sign(currentSnapDragDistanceZ));
                        currentSnapDragDistanceZ %= gizmo.snapDistance;
                        zAxisDelta.normalizeToRef(tmpVector);
                        tmpVector.scaleInPlace(gizmo.snapDistance * dragSteps);
                        gizmo.attachedMesh.position.addInPlace(tmpVector);
                        tmpSnapEvent.snapDistance = gizmo.snapDistance * dragSteps;
                    }

                    if (tmpSnapEvent.snapDistance) {
                        gizmo.onSnapObservable.notifyObservers(tmpSnapEvent);
                    }
                }
                gizmo.attachedMesh.computeWorldMatrix(true);
                gizmo.attachedMesh.getChildMeshes(true).forEach(
                    (mesh) => { mesh.computeWorldMatrix(true); }
                );
                if (gizmo.attachedMesh
                    && gizmo.attachedMesh instanceof TransformNode) {
                    GizmoHelper.checkNodeGridRepulsion(gizmo.attachedMesh);
                }
            }
        });
    }

    set attachedMesh(mesh) {
        super.attachedMesh = mesh;
        GizmoHelper.attachMeshToMovableGizmo(this, mesh);
    }

    /** Bind functions on dragStart, drag and dragEnd in order to display lines and distances
     * of the translation on each axis and display it on labels
     * @param {Gizmo} gizmo
     * @param {Number} planeIndex a constant associated with a gizmo, a translation plane, etc
     * see PLANES in gizmo-meshes-helper.js
     */
    bindGuiBehavior(gizmo, planeIndex) {
        // On start initialize gui, link mesh, toggle gui elements visibility and store
        // selectMesh position in metadata
        gizmo.dragBehavior.onDragStartObservable.add(() => {
            this.dragSizeViewer.initViewerBeforeDisplay(this._meshAttached);
        });

        // On drag update distances values, lines end points and end point position
        gizmo.dragBehavior.onDragObservable.add(() => {
            this.dragSizeViewer.togglePlaneModeVisibility(true);

            let meshCenter = null;
            if (this._meshAttached instanceof AbstractMesh) {
                meshCenter = this._meshAttached.entity.getBoundingBox().centerWorld.clone();
            } else if (this._meshAttached instanceof TransformNode) {
                meshCenter = this._meshAttached.getAbsolutePosition().clone();
            } else {
                throw new Error("Can't drag plane because the given object is not a mesh or a node");
            }

            const dragVector = meshCenter.subtract(
                this._meshAttached.metadata.dragOrigin
            );

            // Find projection point of the vector on each axis of the basis, order matters
            const yAxisProjectLength = Vector3.Dot(this._meshAttached.up, dragVector);
            const zAxisProjectLength = Vector3.Dot(this._meshAttached.forward, dragVector);
            const xAxisProjectLength = Vector3.Dot(this._meshAttached.right, dragVector);
            const projectedPoints = [this._meshAttached.up.scale(yAxisProjectLength),
                this._meshAttached.forward.scale(zAxisProjectLength),
                this._meshAttached.right.scale(xAxisProjectLength)];

            // Put the points in the translation space
            projectedPoints.map(
                vector => vector.addInPlace(this._meshAttached.metadata.dragOrigin)
            );

            const rightAnglePoint = projectedPoints[planeIndex];

            // Set gui points position
            this.dragSizeViewer.setMiddlePointPosition(rightAnglePoint);
            this.dragSizeViewer.setEndPointPosition(
                meshCenter
            );
            this.dragSizeViewer.moveLineX(meshCenter);
            this.dragSizeViewer.moveLineY(rightAnglePoint);

            // Set vectors distanceLabel postion and text
            this.dragSizeViewer.setLineXDisplay(rightAnglePoint, this._meshAttached.position);
            this.dragSizeViewer.setLineYDisplay(
                rightAnglePoint, this._meshAttached.metadata.dragOrigin
            );
        });

        // On end drag toggle gui elements visibility and remove drag start position from
        // metadata
        gizmo.dragBehavior.onDragEndObservable.add(() => {
            this.dragSizeViewer.togglePlaneModeVisibility(false);
            this.dragSizeViewer.deinitViewerAfterDisplay(this._meshAttached);
        });
    }

}
