var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"group-product",class:{
        'hidden': !_vm.product.visible || !_vm.groupIsDisplayed,
    },on:{"click":function($event){return _vm.toggleProductSelection()}}},[_c('label',{staticClass:"product-infos",class:{

            selected: _vm.groupSelected || _vm.isSelected,
        }},[_c('img',{attrs:{"src":_vm.isSelected ? '/assets/icons/frame-select.svg' : '/assets/icons/normal.svg'}}),_vm._v(" "),_c('span',{staticClass:"product-text"},[_vm._v(_vm._s(_vm.product.ref))]),_vm._v(" "),(_vm.canInteractWithProduct)?[(_vm.product.visible && _vm.groupIsDisplayed)?_c('img',{staticClass:"interaction-icon ml-auto",attrs:{"src":_vm.isSelected ? '/assets/icons/eye.svg' : '/assets/icons/eye-black.svg'},on:{"click":function($event){$event.stopPropagation();return _vm.setProductVisibility(false)}}}):_c('img',{staticClass:"interaction-icon ml-auto",attrs:{"src":_vm.isSelected
                    ? '/assets/icons/eye-slash.svg'
                    : '/assets/icons/eye-slash-black.svg'},on:{"click":function($event){$event.stopPropagation();return _vm.setProductVisibility(true)}}}),_vm._v(" "),(_vm.canRemoveProduct)?_c('img',{staticClass:"interaction-icon",attrs:{"src":_vm.isSelected ? '/assets/icons/close.svg' : '/assets/icons/close-black.svg'},on:{"click":function($event){$event.stopPropagation();return _vm.removeProduct()}}}):_vm._e()]:_vm._e()],2),_vm._v(" "),(_vm.showOptions)?_c('ul',{staticClass:"options"},_vm._l((_vm.options),function(value,key){return _c('productOption',{key:key,attrs:{"option-key":key,"option-family":value,"product-selected":_vm.isSelected,"product":_vm.product,"options-number":_vm.optionsNumber}})}),1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }