import {
    Quaternion,
    Vector3,
    UtilityLayerRenderer,
    Mesh,
    MeshBuilder,
    AbstractMesh,
    TransformNode,
    RotationGizmo,
} from "@babylonjs/core";
import config from "defaultConfig";

import MathHelper from "helpers/math-helper";
import GizmoHelper from "../helpers/gizmo-helper";
import RotationGizmoGui from "../gui/rotation-gizmo-gui";
import {
    Torus, AXIS, NORMALS, COLORS, addHoverMaterial,
} from "../helpers/gizmo-meshes-helper";

import self from "../../index";

const {
    guiManager: {
        GuiController,
    },
    collisionManager: {
        CollisionHelper,
    },
    meshManager: {
        meshUtility,
    },
} = self.app.modules;

const angleEpsilon = 0.001;

/**
 * Extends Babylon base Gizmo
 * Ready to be modified to fit beMatrix demands
 * */
export default class CustomRotationGizmo extends RotationGizmo {

    constructor(highlightManager) {
        super();
        this.highlightManager = highlightManager;
        this.attachedEntity = null;
        this.highlightBehavior = null;
        // Arc reprensenting the current rotation
        this.arc = null;

        this.initEvents();
        this.initCustomMeshes();
        this.initGuiElements();

        [this.xGizmo, this.yGizmo, this.zGizmo].forEach((gizmo, index) => {
            // Change gizmo mesh
            gizmo.setCustomMesh(this.toruses[index].mesh);

            gizmo.dragBehavior.onDragObservable.add(() => {
                if (this.highlightBehavior) {
                    this.highlightBehavior.startHighlightBehavior();
                }
            });

            gizmo.dragBehavior.onDragObservable.add(() => {
                const normal = gizmo.dragBehavior._options.dragPlaneNormal;
                let angleKey = "x";
                angleKey = normal.y > (1.0 - angleEpsilon) ? "y" : angleKey;
                angleKey = normal.z > (1.0 - angleEpsilon) ? "z" : angleKey;
                this.stepRotation(gizmo, angleKey);
            });

            gizmo.dragBehavior.onDragObservable.add(() => { this.notificateEntity(); });

            // Collisions with ground
            gizmo.dragBehavior.onDragEndObservable.add(() => {
                if (gizmo.attachedMesh) {
                    if (gizmo.attachedMesh instanceof AbstractMesh) {
                        CollisionHelper.isUnderGrid(
                            gizmo.attachedMesh.entity,
                            { isEntity: true, respond: true }
                        );
                    } else if (gizmo.attachedMesh instanceof TransformNode) {
                        GizmoHelper.checkNodeGridRepulsion(gizmo.attachedMesh);
                        GizmoHelper.updateGizmoAttachedMeshChildren(gizmo);
                    }
                }
                self.app.modules.history.snapshot();
                if (this.highlightBehavior) {
                    this.highlightBehavior.startHighlightBehavior();
                }
            });

            addHoverMaterial(gizmo, COLORS[index], this.toruses[index].material);
            this.bindGuiBehavior(gizmo, index);
        });
    }

    initEvents() {
        self.app.events.on("@obsidian-engine.modifierPressed", () => {
            this.snapDistance = config.angleStep5;
        });
        self.app.events.on("@obsidian-engine.modifierReleased", () => {
            this.snapDistance = config.angleStep45;
        });
    }

    initCustomMeshes() {
        this.toruses = [];
        Object.values(COLORS).forEach((value, index) => {
            this.toruses.push(
                new Torus(
                    NORMALS[index],
                    value,
                )
            );
        });

        this.toruses[AXIS.X].mesh.rotate(Vector3.Forward(), -Math.PI / 2);
        this.toruses[AXIS.Z].mesh.rotate(Vector3.Right(), Math.PI / 2);
    }

    initGuiElements() {
        if (GuiController.isReady) {
            this.rotationGizmoGui = new RotationGizmoGui();
        } else {
            self.app.events.on("@gui-manager.gui-ready", () => {
                this.rotationGizmoGui = new RotationGizmoGui();
            });
        }
    }

    notificateEntity() {
        if (this.attachedEntity) {
            this.attachedEntity.updateRotation();
        }
        self.app.events.emit("mesh-rotate");
    }

    /**
     * This makes the 45 degrees rotation always aligned with axis
     * @param {Gizmo} gizmo
     * @param {string} angleKey name of the key that need to be aligned with axis
     */
    stepRotation(gizmo, angleKey) {
        const angles = gizmo.attachedMesh.rotationQuaternion.toEulerAngles();
        if (this.snapDistance === config.angleStep45) {
            const isAligned = Boolean(
                Math.round((angles[angleKey] * 180) / Math.PI) % 45 === 0
            );
            const remainder = angles[angleKey] % config.angleStep45;

            if (Math.abs(remainder) < config.angleStep45 - 0.001) {
                angles[angleKey] -= remainder;
            }

            // Force the snap event as we snapped during a snapDistance change
            if (!isAligned) {
                gizmo.onSnapObservable.notifyObservers({ snapDistance: remainder });
            }
        }
        CustomRotationGizmo.updateAttachedMeshQuaternion(
            gizmo,
            angles,
        );
    }

    get attachedMesh() {
        // A Babylon patch, used because in v3 attachedMesh property is private in prototype
        // and can't be read, but we need it outside of the gizmos
        return this._meshAttached;
    }

    set attachedMesh(mesh) {
        super.attachedMesh = mesh; // Don't remove super or it won't work!
        // Must set an accessible property, else the property attachedMesh won't be accessible even
        // with a getter and we will be doomed (attachedMesh will return undefined)
        GizmoHelper.attachMeshToMovableGizmo(this, mesh);
    }

    /**
     * Create a quaternion from the passed angles
     * Apply this quaterino to the meshes attached to the gizmo
     * @param {Gizmo} gizmo
     * @param {NumberConstructor} angles
     */
    static updateAttachedMeshQuaternion(gizmo, angles) {
        const quat = Quaternion.RotationYawPitchRoll(
            angles.y,
            angles.x,
            angles.z
        );

        // We copy the quaterion as the seletMesh quaternion
        // is just a copy of the original mesh quaternion
        // If we just set the selectMesh quaternion to the new quaternion,
        // the original mesh quaternion is overriden and the orginal mesh rotation
        // is not updated anymore
        gizmo.attachedMesh.rotationQuaternion.copyFrom(quat);
        gizmo.attachedMesh.computeWorldMatrix(true);
    }

    /** Bind functions on dragStart, drag and dragEnd in order to track current angle of rotation
     * and display it on a label
     * Also add a disc to display the current angle with a visual hint
     * @param {Gizmo} gizmo
     * @param {Number} rotationAxis a constant associated with a gizmo, rotationPlane, etc
     * see AXIS in gizmo-meshes-helper.js
     */
    bindGuiBehavior(gizmo, rotationAxis) {
        let initialRotation = null;
        gizmo.dragBehavior.onDragStartObservable.add(() => {
            meshUtility.AddMetadataProperties(
                this._meshAttached, { currentAngle: 0 },
            );

            initialRotation = gizmo._rootMesh.rotationQuaternion.clone();

            this.rotationGizmoGui.linkGuiWithMesh(gizmo._rootMesh);
            this.rotationGizmoGui.toggleGuiVisibility(true);
            this.rotationGizmoGui.setAngleText(0);
        });

        gizmo.onSnapObservable.add((event) => {
            if (this.arc) {
                this.arc.dispose();
            }

            this._meshAttached.metadata.currentAngle += Math.round(
                MathHelper.ToDegrees(event.snapDistance)
            );
            this._meshAttached.metadata.currentAngle %= 360;

            if (this._meshAttached.metadata.currentAngle !== 0) {
                const sign = -Math.sign(this._meshAttached.metadata.currentAngle);

                // Create the new arc
                this.createArcGizmo(gizmo, this._meshAttached.metadata.currentAngle);

                // Align with the initial gizmo rotation
                this.arc.rotationQuaternion.multiplyInPlace(initialRotation);

                // Rotate to match gizmo rotation
                // These rotations are almost arbitrary
                if (rotationAxis === AXIS.X) {
                    if (sign === 1) {
                        this.arc.rotate(Vector3.Right(), Math.PI);
                        this.arc.rotate(Vector3.Up(), Math.PI / 2);
                    } else {
                        this.arc.rotate(Vector3.Up(), -Math.PI / 2);
                    }
                }
                if (rotationAxis === AXIS.Y) {
                    this.arc.rotate(Vector3.Right(), sign * Math.PI / 2);
                }
                if (rotationAxis === AXIS.Z) {
                    if (sign === -1) {
                        this.arc.rotate(Vector3.Up(), sign * Math.PI);
                        this.arc.rotate(Vector3.Forward(), sign * Math.PI);
                    }
                }
            }

            this.rotationGizmoGui.setAngleText(
                Math.abs(this._meshAttached.metadata.currentAngle)
            );
        });

        gizmo.dragBehavior.onDragEndObservable.add(() => {
            if (this.arc) {
                this.arc.dispose();
            }

            meshUtility.AddMetadataProperties(
                this._meshAttached, { currentAngle: 0 },
            );

            this.rotationGizmoGui.toggleGuiVisibility(false);
        });
    }

    /**
     * Create an arc that is not parented to the gizmo
     * But inherit almost all of his properties
     * position, scaling and material
     * @param {Gizmo} gizmo
     * @param {Number} angle an angle btw 0 / 360 degrees
     */
    createArcGizmo(gizmo, angle) {
        this.arc = MeshBuilder.CreateDisc(
            "rotation-gizmo-disc", {
                arc: Math.abs(angle) / 360,
                radius: 0.05,
                sideOrientation: Mesh.DOUBLESIDE,
            }, UtilityLayerRenderer.DefaultUtilityLayer.utilityLayerScene
        );

        // Can't parent it so do the stuff manualy
        this.arc.rotationQuaternion = Quaternion.Identity();
        this.arc.position = gizmo._rootMesh.position;
        this.arc.scaling = gizmo._rootMesh.scaling;
        this.arc.material = gizmo._rootMesh.getChildMeshes()[0].material.clone();
    }

}
