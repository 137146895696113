import { AbstractMesh, UtilityLayerRenderer } from "@babylonjs/core";
import RightsHelper from "helpers/rights-helper";

import { EventHandlerRulesManager } from "../helpers/event-handler-helper";
import EngineHelper from "../helpers/engine-helper";


import self from "../..";

const {
    app: {
        events,
    },
} = self;

const BUTTON = {
    LEFT_CLICK: 0,
};

export const PICKING_TYPE = {
    mesh: Symbol("mesh"),
    gizmo: Symbol("gizmo"),
    sprite: Symbol("sprite"),
};

export default class MouseEventHandler {

    constructor(scene, canvas) {
        this.scene = scene;
        this.canvas = canvas;
        this.dragging = false;
        this.draggingObject = null;
        this.selectedObject = null;
        this.modifierPressed = false;
        this.selectionController = null;

        // Rules manager that handles picking, mouseMove and selection
        this.pickingRulesManager = new EventHandlerRulesManager();
        this.mouseMoveRulesManager = new EventHandlerRulesManager();
        this.selectionRulesManager = new EventHandlerRulesManager();

        this.initListening();
    }

    initListening() {
        events.on("modifierPressed", () => {
            this.modifierPressed = true;
        });
        events.on("modifierReleased", () => {
            this.modifierPressed = false;
        });
        events.on("unselect", () => {
            this.draggingObject = null;
        });
        events.on("@building-plan-manager.toggle-connector-selection", (isEnabled) => {
            this.connectorSelectionOnly = isEnabled;
        });
        this.canvas.addEventListener("pointermove", () => { this.onPointerMove(); });
        this.canvas.addEventListener("pointerdown", (event) => { this.onPointerDown(event); });
        this.canvas.addEventListener("pointerup", (event) => { this.onPointerUp(event); });

        this.canvas.addEventListener("dblclick",
            (eventInfo) => {
                events.emit("double-click", eventInfo);
            });
    }

    onPointerMove() {
        if (this.mouseMoveRulesManager.rulesList.length) {
            // If we don't have mouse rules there's no point to do a picking here
            const pickResult = this.scene.pick(this.scene.pointerX,
                this.scene.pointerY);
            this.mouseMoveRulesManager.checkRules(pickResult);
        }

        if (!this.draggingObject) {
            return;
        }
        if (this.draggingObject === PICKING_TYPE.mesh) {
            this.dragging = true;
            events.emit("dragging-mesh");
        }
    }

    onPointerDown(event) {
        if (event.button !== BUTTON.LEFT_CLICK) { // We handle only left click
            return;
        }
        const pickingResult = this.checkPicking(true);
        if (pickingResult.type === PICKING_TYPE.gizmo) {
            // We picked a gizmo
            this.draggingObject = pickingResult.type;
            return;
        }
        if (pickingResult.type === PICKING_TYPE.mesh) {
            // We picked a mesh

            if (this.selectionRulesManager.checkRules(pickingResult.pickinfo)) {
                return; // If a selection rule is valid, we block any interaction with object
            }

            this.draggingObject = pickingResult.type;
            this.selectedObject = pickingResult.pickinfo.pickedMesh;

            // Must check if the picked object is a simple mesh or a selectMesh
            if (this.selectedObject.name === "selectMesh") {
                if (this.selectedObject.metadata && this.selectedObject.metadata.isOption) {
                    return;
                }

                if (this.modifierPressed) {
                    // If we pick a select mesh and modifier is pressed
                    events.emit("multiunselect-mesh", this.selectedObject);
                    return;
                }

                events.emit("drag-start-select", this.selectedObject);
            } else {
                if (this.selectedObject.metadata && this.selectedObject.metadata.isOption) {
                    events.emit("select-option", this.selectedObject);
                    return;
                }

                // A simple mesh is picked
                if (this.modifierPressed) {
                    // We check the last parent mesh in case we picked an option

                    if (this.selectedObject.selectMesh) {
                        // If we pick an option and the object is already selected
                        // Same case as we pick a selectMesh
                        events.emit("multiunselect-mesh", this.selectedObject.selectMesh);
                        return;
                    }
                    // if we multi select an object (modifier is pressed)
                    events.emit("multiselect-mesh", this.selectedObject);
                    return;
                }
                if (this.selectedObject.selectMesh) {
                    // Select a select mesh by an option
                    events.emit("drag-start-select", this.selectedObject);
                    return;
                }
                // Simple selection of an object
                events.emit("drag-start-mesh", this.selectedObject);
            }
        }
        if (pickingResult.type === PICKING_TYPE.sprite) {
            // We picked a sprite
            events.emit("pointer-down-sprite");
        }
    }

    onPointerUp(event) {
        if (event.button !== BUTTON.LEFT_CLICK) { // We handle only left click
            return;
        }

        events.emit("pointer-left-click");
        // If we pick a child, pick the parent instead
        const pickingResult = this.checkPicking();
        if (!this.draggingObject) {
            switch (pickingResult.type) {
            case PICKING_TYPE.mesh:
                break;
            case PICKING_TYPE.gizmo:
                break;
            case PICKING_TYPE.sprite: { // If sprite exists run it's click function
                const sprite = pickingResult.pickinfo.pickedSprite;
                if (typeof sprite.onSpriteClicked === "function") {
                    sprite.onSpriteClicked();
                }
                break;
            }
            default: // We are clicking the void or the ground.
                break;
            }
        } else if (this.draggingObject === PICKING_TYPE.mesh) {
            if (!(this.selectedObject.metadata && this.selectedObject.metadata.isOption)) {
                if (this.dragging) {
                    events.emit("drag-end-mesh", this.selectedObject);
                    this.dragging = false;
                } else {
                    events.emit("select-end-mesh", this.selectedObject);
                }
            }
            this.draggingObject = null;
        } else if (this.draggingObject === PICKING_TYPE.gizmo) {
            this.draggingObject = null;
        }
    }

    /**
     * Check what object is picked in the scene
     * @return {Object} the type of the object picked (PICKING_TYPE) and the pickinfos
     */
    checkPicking(checkRules = false) {
        const pickResult = this.sceneMousePick(checkRules);
        const gizmoResult = MouseEventHandler.gizmoLayerMousePick(
            this.scene.pointerX, this.scene.pointerY
        );
        const spriteResult = this.scene.pickSprite(this.scene.pointerX, this.scene.pointerY);

        if (gizmoResult.hit) {
            return { type: PICKING_TYPE.gizmo, pickinfo: gizmoResult };
        }

        if (spriteResult.hit && pickResult.hit) {
            if (spriteResult.distance < pickResult.distance) {
                return { type: PICKING_TYPE.sprite, pickinfo: spriteResult };
            }
            return { type: PICKING_TYPE.mesh, pickinfo: pickResult };
        }

        if (spriteResult.hit) {
            return { type: PICKING_TYPE.sprite, pickinfo: spriteResult };
        } if (pickResult.hit) {
            return { type: PICKING_TYPE.mesh, pickinfo: pickResult };
        }

        return { type: -1, pickinfo: null };
    }

    /**
     * Scene picking selecting the parent if a child is picked or return an option if clicked on one
     * @param {Scene} scene
     * @param {*} pick
     */
    sceneMousePick(checkRules = false) {
        const predicateFunction = this.connectorSelectionOnly
            ? MouseEventHandler.connectorsOnly : null;
        const pickResult = this.scene.pick(
            this.scene.pointerX,
            this.scene.pointerY,
            predicateFunction
        );

        if (pickResult.hit && pickResult.pickedMesh) {
            if (checkRules && this.pickingRulesManager.checkRules(pickResult)) {
                return { distance: 0.0 };
            }
            if (EngineHelper.HasPickedIgnoredMesh(pickResult)) {
                return { distance: 0.0 };
            }

            while ((pickResult.pickedMesh.parent
                    && pickResult.pickedMesh.parent instanceof AbstractMesh)
                && ((!pickResult.pickedMesh.metadata
                    || !pickResult.pickedMesh.metadata.isOption)
                    || !RightsHelper.isModeBuildingPlan())) {
                pickResult.pickedMesh = pickResult.pickedMesh.parent;
            }
        }

        return pickResult;
    }

    /**
     * Returns true if the mesh in argument entity is a connector
     * @param{Mesh} mesh
     * @returns{boolean}
     */
    static connectorsOnly(mesh) {
        let rootMesh = mesh;
        while (rootMesh.parent) {
            rootMesh = rootMesh.parent;
        }

        if (rootMesh && rootMesh.entity) {
            return rootMesh.entity.isConnector && rootMesh.isVisible;
        }
        return false;
    }

    static gizmoLayerMousePick(x, y) {
        return UtilityLayerRenderer.DefaultUtilityLayer.utilityLayerScene.pick(x, y);
    }

}
