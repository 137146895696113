/**
 * Patch to version 1.46.7
 *
 * The purpose of this patch is to fix old frames that got infills image in a single id that isn't
 * stored into two ids property
 */

import self from "../../index";

const {
    OptionableMixin,
} = self.app.modules.optionManager;

export default {
    /**
     * Main patch function
     * Set the old door frames structures entity to new door frames structures
     *
     * Broken data structure example
     *  {
     *      __name__: "frame",
     *      category : "FRAMES",
     *      infillImageId: "XXXXXXX"
     *  },
     *
     * Post fix data structure example
     *
     * {
     *      __name__: "frame",
     *      category : "FRAMES",
     *      firstInfillImageId : "XXXXXX",
     *      secondInfillImageId : "XXXXXX"
     * },
     * @param {ObsidianFile} project
     * @returns {ObsidianFile}
     */
    patch(project) {
        const projectSerializedData = project.project;

        Object.values(projectSerializedData).forEach(
            (path) => {
                path.forEach(
                    (item) => {
                        if (item.infillImageId
                            && (!item.firstInfillImageId || !item.secondInfillImageId)) {
                            item.firstInfillImageId = item.infillImageId;
                            if (item.infillOption === OptionableMixin.INFILL_OPTION.BOTH_FACES) {
                                item.secondInfillImageId = item.infillImageId;
                            }
                        }
                    }
                );
            }
        );

        return project;
    },

};
