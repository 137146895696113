<template>
    <div
        class="right-menu"
        :class="{ open: isOpen }"
    >
        <!-- <b-button
            id="toggle-right-menu"
            v-b-tooltip.hover
            :title="toggleButtonText"
            variant="light"
            @click="toggleGroups()"
        >
            <img src="/assets/icons/collaps-panel-element.svg">
        </b-button> -->
        <div
            v-if="isOpen"
            id="groupsMenu"
        >
            <div
                class="group-list"
                :class="{ expanded: !isMultiSelectedProducts }"
            >
                <ul class="groups">
                    <group
                        v-for="group in updatedGroups"
                        :key="group.id"
                        :group="group"
                        :show-options="showOptions"
                    />
                </ul>
            </div>
            <div>
                <div class="displayMenu">
                    <b-button
                        v-if="canStoreProducts"
                        pill
                        @click="storeProducts()"
                    >
                        <template>
                            <img src="/assets/icons/save-product.svg">
                        &nbsp;&nbsp;
                            <span>{{ _("Store") }}</span>
                        </template>
                    </b-button>
                    <div class="border-top">
                        <b-button
                            pill
                            @click="toggleOptions()"
                        >
                            <template v-if="showOptions">
                                <img src="/assets/icons/hide-option-green.svg">
                        &nbsp;&nbsp;
                                <span>{{ _("Hide options") }}</span>
                            </template>
                            <template v-else>
                                <img src="/assets/icons/view-option-green.svg">
                        &nbsp;&nbsp;
                                <span>{{ _("Show options") }}</span>
                            </template>
                        </b-button>
                        <b-button
                            pill
                            @click="collapseGroups()"
                        >
                            <img src="/assets/icons/hide-option-green.svg">
                    &nbsp;&nbsp;
                            {{ _("Collapse groups") }}
                        </b-button>
                    </div>
                </div>
                <div
                    v-if="canGroupProducts"
                    class="groupButton"
                    :disabled="groupActionStatus.id === 5"
                    @click="groupActionStatus.callback"
                >
                    <img
                        v-if="groupActionStatus.icon != ''"
                        :src="`/assets/icons/${groupActionStatus.icon}.svg`"
                    >
                    &nbsp;&nbsp;
                    <span>{{ groupActionStatus.label }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RightsHelper from "helpers/rights-helper";
import self from "../../index";
import EventBus from "../eventBus";
import group from "./group/group.vue";

const {
    events,
    modules: {
        groupManager,
        iframeApi,
        stonejs,
    },
} = self.app;

export default {
    name: "RightMenu",
    components: { group },
    props: {
        groups: {
            type: Array,
            default: () => [],
        },
        engineData: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            isOpen: true, // Check if the menu is open or not
            showOptions: true, // Check if we show the options on the UI
            updatedGroups: this.groups, // The group set as data for binding
            multiSelectedMesh: this.engineData.multiSelectedMesh,
        };
    },
    computed: {
        canStoreProducts() {
            return RightsHelper.isMode3dAuthenticated() && this.isMultiSelectedProducts;
        },
        canGroupProducts() {
            return !RightsHelper.isModeBuildingPlan() && !RightsHelper.isModePublic();
        },

        // Enable to know wich action to set on the buttons (group/ungroup)
        groupActionStatus() {
            return this._(
                self.app.modules.groupManager.GroupSelection.getGroupActionStatus()
            );
        },

        isMultiSelectedProducts() {
            return (this.multiSelectedMesh.length > 0);
        },

        toggleButtonText() {
            if (this.isOpen) {
                return this._("Hide");
            }
            return this._("Show");

        },
    },
    mounted() {

        // CLOSE RIGHT MEMU EVENT
        ["@project-manager.loading-project"].forEach(
            (event) => {
                self.app.events.on(event, () => {
                    this.isOpen = false;
                });
            }
        );

        // OPEN RIGHT MEMU EVENT
        ["@project-manager.project-loaded"].forEach(
            (event) => {
                self.app.events.on(event, () => {
                    this.isOpen = true;
                });
            }
        );

        groupManager.Controller.updateVueData();
    },
    methods: {
        /**
         * Show/hide the group UI
         */
        toggleGroups() {
            this.isOpen = !this.isOpen;
            // We expand/reduce the top menu when opening right menu
            EventBus.$emit("toggle-groups", this.isOpen);
        },

        toggleOptions() {
            this.showOptions = !this.showOptions;
        },

        // When we click on collapse all group
        collapseGroups() {
            EventBus.$emit("collapseGroups");
        },

        storeProducts() {
            const popinDescription = this._("Please insert a name for your product:");
            const popinWarning = this._("Warning : Please give a valid product name"
                + ", if you write any special characters like (!,_-^%)"
                + " or give an empty version name,the submission will be blocked.");

            events.emit("open-popin", {
                title: this._("Save a group product"),
                description: `${popinDescription}
                    <br /><input type="text" id="product-name" required pattern= "^([a-zA-Z0-9.]|\\s)+$">
                <br/>
                        <span class="warning-info">
                            ${popinWarning}
                        </span>`,
                onValidate: () => {
                    const productName = document.getElementById("product-name").value;
                    iframeApi.sendEvent("store-product", productName);
                },
            });
        },
        _(...args) {
            return stonejs.gettext(...args);
        },
    },
};
</script>
