<template>
    <li
        class="group-product"
        :class="{
            'hidden': !product.visible || !groupIsDisplayed,
        }"
        @click="toggleProductSelection()"
    >
        <label
            :class="{

                selected: groupSelected || isSelected,
            }"
            class="product-infos"
        >
            <img :src="isSelected ? '/assets/icons/frame-select.svg' : '/assets/icons/normal.svg'">
            <span class="product-text">{{ product.ref }}</span>
            <template v-if="canInteractWithProduct">
                <img
                    v-if="product.visible && groupIsDisplayed"
                    :src="isSelected ? '/assets/icons/eye.svg' : '/assets/icons/eye-black.svg'"
                    class="interaction-icon ml-auto"
                    @click.stop="setProductVisibility(false)"
                >
                <img
                    v-else
                    :src="isSelected
                        ? '/assets/icons/eye-slash.svg'
                        : '/assets/icons/eye-slash-black.svg'
                    "
                    class="interaction-icon ml-auto"
                    @click.stop="setProductVisibility(true)"
                >
                <img
                    v-if="canRemoveProduct"
                    :src="isSelected ? '/assets/icons/close.svg' : '/assets/icons/close-black.svg'"
                    class="interaction-icon"
                    @click.stop="removeProduct()"
                >
            </template>
        </label>
        <ul
            v-if="showOptions"
            class="options"
        >
            <productOption
                v-for="(value, key) in options"
                :key="key"
                :option-key="key"
                :option-family="value"
                :product-selected="isSelected"
                :product="product"
                :options-number="optionsNumber"
            />
        </ul>
    </li>
</template>
<script>
import RightsHelper from "helpers/rights-helper";
import self from "../../../index";
import productOption from "./product-option.vue";

const {
    app: {
        modules: {
            optionManager: { optionController },
            groupManager: {
                DisplayHelper: displayHelper,
                GroupSelection: groupSelection,
            },
            selectionManager,
            history,
            stonejs,
            buildingPlanManager: {
                buildingPlanController: bpController,
                PAGE_TYPES,
            },
            measurementManager: {
                Controller: measurementController,
            },
        },
        events,
    },
} = self;

export default {
    name: "GroupProduct",
    components: { productOption },
    props: {
        product: {
            type: Object,
            default: () => ({}),
        },
        showOptions: {
            type: Boolean,
            default: true,
        },
        groupSelected: {
            type: Boolean,
            default: true,
        },
        groupIsDisplayed: {
            type: Boolean,
            default: () => ({}),
        },
    },
    data() {
        return {
            options: optionController.getEntityOptions(this.product),
            optionsNumber: optionController.getEntityOptionsNumber(this.product),
            buildingPlanData: bpController.vueData,
            measurementController,
        };
    },
    computed: {
        // Check if the product is selected
        isSelected() {
            return groupSelection.isSelectedProduct(this.product);
        },
        canInteractWithProduct() {
            return !RightsHelper.isModePublic() && !this.buildingPlanData.isCameraFixed;
        },
        canRemoveProduct() {
            return !RightsHelper.isModeBuildingPlan();
        },
    },
    mounted() {
        this.initUpdateOptionsEvent();

        events.on("@building-plan-manager.change-product-visibility",
            () => {
                this.$forceUpdate();
            });
    },
    methods: {
        /**
         * Update option UI on layers UI when the options are updated
         */
        initUpdateOptionsEvent() {
            [
                "@option-manager.update-baseplate-option",
                "@option-manager.update-groundplate-option",
                "@option-manager.update-light-option",
                "@option-manager.update-shelf-option",
                "@option-manager.update-screen-option",
                "@option-manager.update-shelf-number",
                "@option-manager.update-screen-number",
                "@option-manager.update-light-number",
                "@option-manager.update-infill-option",
            ].forEach((event) => {
                events.on(event, (id) => {
                    if (id === this.product.id || id === this.product) {
                        this.options = optionController.getEntityOptions(
                            this.product
                        );
                        this.optionsNumber = optionController.getEntityOptionsNumber(
                            this.product
                        );
                    }
                });
            });
        },

        // Unselect or select a product on the UI
        toggleProductSelection() {
            if (!this.buildingPlanData.isCameraFixed
                && !this.measurementController.measureModeActivated
                && this.buildingPlanData.currentPage.type !== PAGE_TYPES.HTML) {
                if (this.isSelected) {
                    groupSelection.unselectProduct(this.product);
                } else {
                    groupSelection.selectProduct(this.product);
                }
            }
        },

        // We emit events so the parent (group) will update it's UI in case of
        // hiding or displaying a frame of a group
        setProductVisibility(visibility) {
            if (!visibility && this.isSelected) {
                this.toggleProductSelection();
            }
            displayHelper.setProductVisibility(this.product, visibility);
            if (!visibility) {
                this.$emit("productHidden");
            } else {
                this.$emit("productDisplayed");
            }

            this.$forceUpdate();
        },

        removeProduct() {
            if (
                selectionManager.currentEntity
                && selectionManager.currentEntity.id === this.product.id
            ) {
                selectionManager.removeCurrentEntity();
                history.snapshot();
            } else {
                selectionManager.deleteEntityByRef(this.product);
            }
        },

        _(...args) {
            return stonejs.gettext(...args);
        },
    },
};
</script>

<style lang="scss">
@import "../../../../../style/bematrix";
.group-product {
    .product-infos {
        display: flex;
        align-items: center;
        font-family: $font-regular;
        font-size: 0.75rem;
        padding: 2% 0;
        padding-left: $group-padding-left;
        margin: 0 $group-product-margin-left;
        color: #6a696c;
        border-radius: 3px;

        .product-text {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        .interaction-icon {
            display: none;
            max-height: 13px;
        }

        &.selected {
            background: $green;
            color: #fff;
        }

        img {
            padding: 0 0.35rem;
            max-height: 20px;
        }
    }

    &.hidden {
        opacity: 0.3;
    }
}

.group:not(.hidden) .product-infos:not(.selected):hover {
    background-color: white;
    cursor: pointer;

    .interaction-icon {
        display: inline;
    }
}
</style>
