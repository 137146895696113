import {
    PBRMaterial, StandardMaterial, Texture, CubeTexture, HDRCubeTexture, Color3,
} from "@babylonjs/core";

import VueHelper from "helpers/vue-helper";
import config from "defaultConfig";
import self from "../..";
import { filterCollectionByUniqueFirstKey } from "../../../../helpers/js-helper";

const DEBUG = false;

const BABYLON = {
    PBRMaterial, StandardMaterial, Texture, CubeTexture, HDRCubeTexture, Color3,
};

export default class MaterialManager {

    /**
     * @class bematrix.MaterialManager
     * */
    constructor() {

        this.path = "/assets/textures/";

        this.loadedMaterials = [];

        this.loadedLocalTextures = [];

        this.loadedAwsTextures = {};

        this.defaultMaterial = "frames-ano";

        this.textureLoaded = false;

        this.materialLibraryLoaded = false;

        this.initVueData();

        this.holeLightMap = null;

        self.app.events.on("@obsidian-engine.engine-ready", scene => {
            /** @type Scene  */
            this.scene = scene;
            this.initTextures();
            this.initEnvironment();
        });
        self.app.events.on("@project-manager.update-config-images", () => {
            this.initTextures(true);
        });
        if (DEBUG) {
            window.materialManager = this;
        }
    }

    initVueData() {
        VueHelper.AddVueProperty(this, "areFramesTransparent", false);
    }

    /**
     * Environment for all PBR Materials
     */
    initEnvironment() {
        this.scene.environmentTexture = this.loadTexture("envmaps/autoshop.env");
        this.scene.environmentTexture.level = 100;
    }

    /**
     * Initialize all the texture needed for the project given in the config
     */
    initTextures(reimport = false) {
        const promiseArray = [];

        let infillsImg = filterCollectionByUniqueFirstKey(JSON.parse(config.infillsImg));

        // TODO : Remove patch //
        // This is patch to fix doctrine bug at https://github.com/symfony/symfony/issues/37041
        // The problem is that empty collections become empty objects and not arrays
        if (!Array.isArray(infillsImg)) {
            infillsImg = [];
        }

        // End of patch
        infillsImg.forEach(
            imgObject => {
                promiseArray.push(this.loadAwsTexture(imgObject)
                    .then(
                        texture => {
                            if (reimport) {
                                self.app.events.emit("reimport-texture", {
                                    id: Object.keys(imgObject)[0],
                                    texture,
                                });
                            }
                            return texture;
                        }
                    ).catch(
                        err => {
                            self.app.log.error("Can't load texture :", err);
                            throw err;
                        }
                    ));
            }
        );
        return Promise.all(promiseArray).finally(
            () => {
                this.imagesLoaded = true;
                // TODO : no one is listening to this event
                self.app.events.emit("images-loaded");
            }
        ).catch(
            err => {
                self.app.log.error("Can't initialize project textures :", err);
                throw err;
            }
        );
    }

    /**
     * Setup the material library
     * It creates materials from the passed JSON
     * Then creates procedural materials
     * @param {Material[]} materialsParams
     */
    initializeMaterialLibrary(materialsParams) {
        // material.json materials
        Object.keys(materialsParams).forEach(name => {
            this.loadMaterial(name, materialsParams[name]);
        });

        // procedural materials
        this.createHoleMaterial(this.loadedMaterials["frames-ano"]);

        this.holeLightMap = this.loadedMaterials["frames-ano-hole"].lightmapTexture;
        this.materialLibraryLoaded = true;
    }

    /**
     * Creates a material named after the name parameter
     * Parameters in the param Object will be assigned to the material :
     * - type : type of material, e.g. StandardMaterial, PBRMaterial, PBRMetallicRoughnessMaterial
     * - Primitives (string, number, boolean, object) will be applied directly
     * - Textures must be objects, with an url parameter and classic Babylon Texture parameters
     *   example : { url : "leather.jpg", uScale : 3, vSCale :3 }
     * - Colors can be an hex string (ex : "#FFFFFF")
     *   Or an rgb object, like { r:1, g:1, b:1} for white
     * /!\ If a material with the same name as already been loaded, returns it instead
     *   @param {String} name
     *   @param {Object} params
     *   @returns {StandardMaterial}
     */
    loadMaterial(name, params = {}) {
        if (!this.loadedMaterials[name]) {
            // type represent material type,
            // it can be PBRMaterial, StandardMaterial, PBRMetallicRoughnessMaterial etc.
            // by default it's StandardMaterial
            let type = "StandardMaterial";
            if (params.type) {
                if (typeof BABYLON[params.type] === "function") {
                    ({ type } = params); // go to hell eslint
                }
                delete params.type;
            }
            const mat = new BABYLON[type](name, this.scene);
            Object.keys(params).forEach(pK => {
                this.handleBabylonParam(mat, pK, params[pK]);
            });

            if (config.fileType === ".glb") {
                mat.backFaceCulling = false;

            }
            this.loadedMaterials[name] = mat;
            mat.freeze();
            self.app.events.emit("load-material", name);
        }
        return this.loadedMaterials[name];
    }

    async handleBabylonParam(material, key, value) {
        const lowerKey = key.toLowerCase();
        if (lowerKey.includes("texture")) {
            const texParams = value;
            const texUrl = texParams.url;

            // Handle infills textures stuff
            if (texParams.remote && texParams.id) {
                const imageObject = {};
                imageObject[texParams.id] = texUrl;
                try {
                    material[key] = await this.loadAwsTexture(imageObject,
                        true,
                        texParams.letYReverted);
                } catch (err) {
                    self.app.log.error("Can't load remote texture :", err);
                    material[key] = new Texture("/assets/textures/errorTexture.png", this.scene);
                    throw err;
                }
            } else {
                delete texParams.url;
                material[key] = this.loadTexture(texUrl, texParams);
            }
        } else if (lowerKey.includes("color")) {
            const colorParams = value;
            let color;
            if (typeof colorParams === "string") {
                color = Color3.FromHexString(colorParams);
            } else {
                color = new Color3(
                    colorParams.r,
                    colorParams.g,
                    colorParams.b,
                );
            }
            material[key] = color;
        } else if (typeof (value) === "object" && material[key] && key !== "metadata") {
            const obj = material[key];
            Object.keys(value).forEach(subKey => {
                this.handleBabylonParam(obj, subKey, value[subKey]);
            });
        } else {
            material[key] = value;
        }
    }

    /**
     * Loads a texture from a file located at the url parameter
     * Assign the parameters of the param object to it
     * If the file has already been loaded, clones the texture instead of loading a new one
     * @param {String} url
     * @param {Object} params
     * */
    loadTexture(url, params = {}) {
        if (!url) {
            throw new Error(`Can't load texture with url : ${url}`);
        }
        if (!this.loadedLocalTextures[url]) {
            // dds & hdr handling for env map
            const urlSplit = url.split(".");
            const extension = urlSplit[urlSplit.length - 1].toLowerCase();
            const urlToLoad = this.path + url;
            if (extension === "hdr") {
                this.loadedLocalTextures[url] = new HDRCubeTexture(
                    urlToLoad,
                    this.scene,
                    params.size ? params.size : 256
                );
            } else if (extension === "env") {
                this.loadedLocalTextures[
                    url
                ] = new CubeTexture(
                    urlToLoad,
                    this.scene
                );
            } else if (extension === "dds") {
                this.loadedLocalTextures[
                    url
                ] = CubeTexture.CreateFromPrefilteredData(
                    urlToLoad,
                    this.scene
                );
            } else {
                // Other textures type
                this.loadedLocalTextures[url] = new Texture(
                    urlToLoad,
                    this.scene
                );
            }
        }
        const tex = this.loadedLocalTextures[url].clone();
        Object.keys(params).forEach(param => {
            tex[param] = params[param];
        });
        return tex;
    }

    /**
     * Load a texture stored in AWS S3 by giving an imageObject
     * @param {Object} imageObject - the imageObject that contains the infos of the image
     * @param {String} imageObject.keys[0] - the id of the image
     * @param {String} imageObject[id] - the url of the given id
     * @param {Boolean} isBlobUrl - true if the url is for a local cached blob,
     *                              false if its a remote url
     */
    loadAwsTexture(imageObject, isBlobUrl = false, letYReverted = true) {
        const id = Object.keys(imageObject)[0];
        const url = imageObject[id];
        if (!id) {
            return Promise.reject(new Error("No id specified for the texture"));
        }
        if (!this.loadedLocalTextures[id]) {
            if (!url) {
                return Promise.reject(new Error(`Can't load texture with wrong url ${url}`));
            }
            if (isBlobUrl) {
                const texture = new Texture(
                    url,
                    this.scene,
                );
                texture._invertY = letYReverted;
                this.loadedLocalTextures[id] = texture;
                return Promise.resolve(texture.clone());
            }

            // Means the texture must be loaded with the S3 URL
            const texture = self.app.modules.httpRequest.getRawProxy(url)
                .then(
                    imageBuffer => {
                        const imageBlob = new Blob([imageBuffer], { type: "image/jpeg" });
                        const imageUrl = URL.createObjectURL(imageBlob);
                        const text = new Texture(
                            imageUrl,
                            this.scene
                        );
                        text._invertY = letYReverted;
                        this.loadedLocalTextures[id] = text;
                        return text.clone();
                    }
                ).catch(error => {
                    self.app.log.error("Error while loading aws texture ", error);
                    throw error;
                });
            return texture;
        }
        this.loadedLocalTextures[id]._invertY = letYReverted;
        return Promise.resolve(this.loadedLocalTextures[id].clone());
    }

    createHoleMaterial(mat) {
        const materialName = `${mat.name}-hole`;
        if (!this.loadedMaterials[materialName]) {
            const texMaterial = this.loadTexture("holes.png", { uScale: 1, vScale: 1, uOffset: 0 });
            /**
             * @type PBRMaterial
             */
            const holeMaterial = mat.clone();
            holeMaterial.lightmapTexture = texMaterial;
            holeMaterial.useLightmapAsShadowmap = true;
            window.holeMaterial = holeMaterial;
            this.loadedMaterials[materialName] = holeMaterial;
        }
        return this.loadedMaterials[materialName];
    }

    createInfillMaterial(entity, options = { id: null, url: null, color: null }) {
        const isImage = !options.color;
        const materialName = `infill-material-${isImage ? options.id : options.color}`;
        const isR430 = entity.ref.includes("R430");
        if (isImage && !options.url && this.loadedMaterials[materialName]) {
            const reloadedUrl = this.loadedMaterials[materialName].albedoTexture.url;
            self.app.events.emit("check-reloaded-material", options.id, reloadedUrl);
        }
        try {
            const letYReverted = (entity.subCategory.toUpperCase() === "STRAIGHT FRAMES"
                && !isR430) || (config.fileType !== ".glb")
            || entity.subCategory.toUpperCase().includes("LIGHTBOX")
            || entity.subCategory.toUpperCase().includes("MOTIONSKIN");
            if (isImage) {
                this.loadMaterial(
                    materialName,
                    {
                        type: "PBRMaterial",
                        metallic: 0,
                        roughness: 0.3,
                        environmentIntensity: 0.3,
                        albedoTexture: {
                            id: options.id,
                            url: options.url,
                            remote: true,
                            letYReverted,
                        },
                    }
                );
            } else {
                this.loadMaterial(
                    materialName,
                    {
                        type: "StandardMaterial",
                        metallic: 0,
                        roughness: 0.3,
                        environmentIntensity: 0.3,
                        diffuseColor: options.color,
                    }
                );
            }
        } catch (err) {
            self.app.log.error("Can't load infill material ", err);
            throw new Error(err);
        }
        return materialName;
    }

    addCustomMaterial(name, material) {
        this.loadedMaterials[name] = material;
    }

    /**
     * Register a new ral material to the library
     * Name and color are used to create a unique identifier for the material
     * @param {String} name material name prefix
     * @param {String} color hex formated color
     * @returns {String} created material identifier
     */
    createRalMaterial(name, color) {
        const materialName = `RAL-${name}-${color}`;
        const material = this.loadMaterial(
            materialName,
            {
                type: "PBRMaterial",
                metallic: 1,
                roughness: 0.85,
                environmentIntensity: 0.4,
                albedoColor: color,
            }
        );

        MaterialManager.setMaterialTransparency(material, this.areFramesTransparent);
        return materialName;
    }

    setFramesTransparency(transparencyModeEnabled) {
        this.setFramesMaterialsTransparency(transparencyModeEnabled);
        this.areFramesTransparent = transparencyModeEnabled;
    }

    /**
     * Create and return the ledskin material
     */
    getLedskinMaterial() {
        if (!this.ledskinMaterial) {
            const texMaterial = this.loadTexture("carbon.png", { uScale: 5, vScale: 5, uOffset: 0 });
            this.ledskinMaterial = new StandardMaterial("ledskin-mat", this.scene);
            if (config.fileType === ".glb") {
                this.ledskinMaterial.backFaceCulling = false;
            }
            this.ledskinMaterial.ambientTexture = texMaterial;
        }
        return this.ledskinMaterial;
    }

    getRalMaterials() {
        return Object.keys(this.loadedMaterials).filter(name => name.startsWith("RAL-")).map(
            name => this.loadedMaterials[name]
        );
    }

    /**
     * Set the frames materials transparency to 0.25
     * As of the frames are instances and / or use the same materials
     * Changing the materials here will apply the modifications on all frames
     * @param {Boolean} transparencyModeEnabled
     */
    setFramesMaterialsTransparency(transparencyModeEnabled) {
        if (!this.loadedMaterials["frames-ano-hole"]) {
            this.createHoleMaterial(this.loadedMaterials["frames-ano"]);
        }
        const frameMaterials = this.getRalMaterials();

        frameMaterials.push(
            this.loadedMaterials["frames-ano"],
            this.loadedMaterials["frames-ano-hole"],
            this.getLedskinMaterial(),
        );

        frameMaterials.forEach(
            material => {
                if (transparencyModeEnabled) {
                    material.unfreeze();
                }
                MaterialManager.setMaterialTransparency(material, transparencyModeEnabled);
                if (!transparencyModeEnabled) {
                    this.scene.render();
                    material.freeze();
                }
            }
        );

        if (transparencyModeEnabled) {
            this.loadedMaterials["frames-ano-hole"].lightmapTexture = null;
        } else {
            this.loadedMaterials["frames-ano-hole"].unfreeze();
            this.loadedMaterials["frames-ano-hole"].lightmapTexture = this.holeLightMap;
            this.scene.render();
            this.loadedMaterials["frames-ano-hole"].freeze();
        }
    }

    static setMaterialTransparency(material, transparencyModeEnabled) {
        if (transparencyModeEnabled) {
            material.alpha = 0.25;
            material.disableLighting = true;
            material.needDepthPrePass = true;
        } else {
            material.alpha = 1;
            material.needDepthPrePass = false;
            material.disableLighting = false;
        }

    }

}
