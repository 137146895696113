const Structure = require("@obsidianjs/data-store/src/entity");

const EntityStructure = require("./entity-structure").default;

const StructuralStructure = EntityStructure.$extend({

    __name__: "structural",

    __init__(params) {
        this.$super(params);
    },

});
Structure.$register(StructuralStructure);
export default StructuralStructure;
