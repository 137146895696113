<!-- eslint-disable vue/no-mutating-props -->
<template>
    <b-navbar
        class="options-menu"
        :class="{
            noRightMenu: isExpanded,
        }"
    >
        <template v-if="selectedEntity">
            <!-- Base plate options -->
            <template v-if="optionFamily === optionsFamilies.BASEPLATE">
                <!-- Options -->

                <OptionBaseplate
                    :config="config"
                    :current-baseplate-type="currentBaseplateType"
                    :baseplate-options="baseplateOptions"
                    :show-edit="selectedEntity.optionsMeshes.baseplates.length !== 0"
                    :show-swap="currentBaseplateType.data === 4"
                    @addOption="addOption"
                    @swapOption="swapOption"
                    @removeOption="removeOption"
                />
            </template>

            <!-- Groundplate -->
            <template v-else-if="optionFamily === optionsFamilies.GROUNDPLATE">
                <!-- Options -->
                <b-navbar-nav>
                    <b-nav-item
                        v-for="option in groundplateOptions"
                        :key="option.ref"
                        :disabled="currentGroundplateType.data === option.type"
                    >
                        <b-button
                            class="option-type-button"
                            :disabled="currentGroundplateType.data === option.type"
                            @click="addOption(option.type)"
                        >
                            {{ option.ref }}
                        </b-button>
                    </b-nav-item>
                </b-navbar-nav>
                <!-- Modifiers -->
                <b-navbar-nav
                    v-show="selectedEntity.optionsMeshes.groundplates.length !== 0"
                    class="top-menu-div"
                >
                    <b-nav-item>
                        <remove-button @click="removeOption()" />
                    </b-nav-item>
                </b-navbar-nav>
            </template>

            <!-- Shelves options -->
            <template
                v-else-if="optionFamily === optionsFamilies.SHELF
                    && selectedEntity.canHaveShelfOption"
            >
                <!-- Options -->
                <b-navbar-nav>
                    <b-nav-item>
                        <b-button
                            class="option-type-button"
                            :disabled="selectedEntity.shelfOption === shelvesOptions.SIXTYDEG"
                            @click="addOption(shelvesOptions.SIXTYDEG)"
                        >
                            {{ _("60 Degrees") }}
                        </b-button>
                    </b-nav-item>
                    <b-nav-item>
                        <b-button
                            class="option-type-button"
                            :disabled="selectedEntity.shelfOption === shelvesOptions.LARGE"
                            @click="addOption(shelvesOptions.LARGE)"
                        >
                            {{ _("Large") }}
                        </b-button>
                    </b-nav-item>
                    <b-nav-item>
                        <b-button
                            class="option-type-button"
                            :disabled="selectedEntity.shelfOption === shelvesOptions.SMALL"
                            @click="addOption(shelvesOptions.SMALL)"
                        >
                            {{ _("Small") }}
                        </b-button>
                    </b-nav-item>
                    <b-nav-item>
                        <b-button
                            class="option-type-button"
                            :disabled="selectedEntity.shelfOption === shelvesOptions.TUBE"
                            @click="addOption(shelvesOptions.TUBE)"
                        >
                            {{ _("Tube") }}
                        </b-button>
                    </b-nav-item>
                    <b-nav-item>
                        <select
                            v-show="selectedEntity.optionsMeshes.shelves.length !== 0"
                            v-model="selectedEntity.shelfNumber"
                            @change="onOptionNumberChange($event)"
                        >
                            <option
                                disabled
                                value
                            >
                                {{ _("Choose") }}
                            </option>
                            <option
                                v-for="(value, index) in shelfNumberValues"
                                :key="index"
                                :value="value"
                            >
                                {{ value }}
                            </option>
                        </select>
                    </b-nav-item>
                </b-navbar-nav>
                <!-- Modifiers -->
                <b-navbar-nav
                    v-show="selectedEntity.optionsMeshes.shelves.length !== 0"
                    class="top-menu-div"
                >
                    <b-nav-item>
                        <swap-button @click="swapOption()" />
                    </b-nav-item>
                    <b-nav-item>
                        <remove-button @click="removeOption()" />
                    </b-nav-item>
                </b-navbar-nav>
            </template>
            <!-- Light options -->
            <template v-else-if="optionFamily === optionsFamilies.LIGHT">
                <b-navbar-nav>
                    <b-nav-item>
                        <select
                            v-model="selectedEntity.lightOption"
                            @change="onOptionLightChange($event)"
                        >
                            <option
                                v-for="(value, index) in lightOptions"
                                :key="index"
                                :value="value"
                            >
                                {{ getLightName(value) }}
                            </option>
                        </select>
                    </b-nav-item>
                    <b-nav-item>
                        <b-button
                            v-if="selectedEntity.lightOption !== lightOptions.NONE"
                            class="option-type-button"
                            :disabled="selectedEntity.lightSingularity
                                === lightSingularityOptions.SIMPLE"
                            @click="updateLightSingularity(lightSingularityOptions.SIMPLE)"
                        >
                            {{ _("Simple") }}
                        </b-button>
                    </b-nav-item>
                    <b-nav-item>
                        <b-button
                            v-if="selectedEntity.lightOption !== lightOptions.NONE"
                            class="option-type-button"
                            :disabled="selectedEntity.lightSingularity
                                === lightSingularityOptions.DOUBLE"
                            @click="updateLightSingularity(lightSingularityOptions.DOUBLE)"
                        >
                            {{ _("Double") }}
                        </b-button>
                    </b-nav-item>
                    <b-nav-item>
                        <select
                            v-show="selectedEntity.optionsMeshes.lights.length !== 0"
                            v-model="selectedEntity.lightNumber"
                            @change="onLightNumberChange($event)"
                        >
                            <option
                                v-for="(value, index) in lightNumberValues"
                                :key="index"
                                :value="value"
                            >
                                {{ value }}
                            </option>
                        </select>
                    </b-nav-item>
                </b-navbar-nav>
                <!-- Modifiers -->
                <b-navbar-nav
                    class="top-menu-div"
                >
                    <b-nav-item>
                        <swap-button
                            v-show="selectedEntity.optionsMeshes.lights.length > 0"
                            @click="swapOption()"
                        />
                    </b-nav-item>
                    <b-nav-item>
                        <remove-button
                            v-show="selectedEntity.optionsMeshes.lights.length !== 0"
                            @click="removeOption()"
                        />
                    </b-nav-item>
                </b-navbar-nav>
            </template>
            <!-- Screen options -->
            <template
                v-else-if="
                    optionFamily === optionsFamilies.SCREEN && selectedEntity.canHaveScreenOption
                "
            >
                <b-navbar-nav>
                    <b-nav-item>
                        <b-button
                            class="option-type-button"
                            :disabled="selectedEntity.screenOption === screenOptions.UNIVERSAL"
                            @click="addOption(screenOptions.UNIVERSAL)"
                        >
                            {{ _("B62 Universal Bracket") }}
                        </b-button>
                    </b-nav-item>
                    <b-nav-item>
                        <b-button
                            v-show="isStandardWidth"
                            class="option-type-button"
                            :disabled="selectedEntity.screenOption === screenOptions.FLATSCREEN"
                            @click="addOption(screenOptions.FLATSCREEN)"
                        >
                            {{ _("Flatscreen support 860MM") }}
                        </b-button>
                    </b-nav-item>
                    <b-nav-item>
                        <select
                            v-show="
                                selectedEntity.screenOption === screenOptions.UNIVERSAL &&
                                    selectedEntity.optionsMeshes.screens.length !== 0
                            "
                            v-model="selectedEntity.screenNumber"
                            @change="onOptionNumberChange($event)"
                        >
                            <option
                                v-for="(value, index) in screenNumberValues"
                                :key="index"
                                :value="value"
                            >
                                {{ value }}
                            </option>
                        </select>
                    </b-nav-item>
                </b-navbar-nav>
                <!-- Modifiers -->
                <b-navbar-nav
                    v-show="selectedEntity.optionsMeshes.screens.length !== 0"
                    class="top-menu-div"
                >
                    <b-nav-item>
                        <swap-button @click="swapOption()" />
                    </b-nav-item>
                    <b-nav-item>
                        <remove-button @click="removeOption()" />
                    </b-nav-item>
                </b-navbar-nav>
            </template>
            <!-- Infill option -->
            <template v-else-if="optionFamily === optionsFamilies.INFILL">
                <!-- Options -->
                <b-navbar-nav>
                    <b-nav-item>
                        <b-button
                            class="option-type-button"
                            :disabled="selectedEntity.infillOption === infillsOptions.ONE_FACE"
                            @click="addOption(infillsOptions.ONE_FACE)"
                        >
                            {{ _("One face") }}
                        </b-button>
                    </b-nav-item>
                    <b-nav-item>
                        <b-button
                            v-if="selectedEntity.hasInfillsBothSide"
                            class="option-type-button"
                            :disabled="selectedEntity.infillOption === infillsOptions.BOTH_FACES"
                            @click="addOption(infillsOptions.BOTH_FACES)"
                        >
                            {{ _("Both faces") }}
                        </b-button>
                    </b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav
                    v-show="selectedEntity.optionsMeshes.infills.length !== 0"
                    class="top-menu-div"
                >
                    <b-navbar-nav v-if="selectedEntity.infillOption === infillsOptions.BOTH_FACES">
                        <b-nav-item>
                            <ul
                                v-if="infillImageMenuVisible"
                                v-click-outside="closeInfillImageMenu"
                                class="infill-image-menu"
                                tabindex="0"
                                @blur="closeInfillImageMenu"
                                @focusout="closeInfillImageMenu"
                            >
                                <li @click.stop="loadImage">
                                    {{ _("Add image to both infills") }}
                                </li>
                                <li @click.stop="(event) => loadImage(event, 1)">
                                    {{ _("Add image to first infill") }}
                                </li>
                                <li @click.stop="(event) => loadImage(event, 2)">
                                    {{ _("Add image to second infill") }}
                                </li>
                            </ul>
                            <b-nav-item>
                                <b-button
                                    class="option-type-button"
                                    pill
                                    @click.stop="toggleInfillImageMenu"
                                >
                                    <input
                                        ref="fileInput"
                                        type="file"
                                        accept="image/*"
                                        @change="importInfillImage"
                                    >
                                    {{ _("Add image") }}
                                </b-button>
                            </b-nav-item>
                        </b-nav-item>
                        <b-nav-item>
                            <ul
                                v-if="infillColorMenuVisible"
                                v-click-outside="closeInfillColorMenu"
                                class="infill-color-menu"
                                tabindex="1"
                                @blur="closeInfillColorMenu"
                            >
                                <li
                                    @click.stop="(event) => openColorPicker(0)"
                                >
                                    {{ _("Add color to both infills") }}
                                </li>
                                <li
                                    @click.stop="(event) => openColorPicker(1)"
                                >
                                    {{ _("Add color to first infill") }}
                                </li>
                                <li
                                    @click.stop="(event) => openColorPicker(2)"
                                >
                                    {{ _("Add color to second infill") }}
                                </li>
                            </ul>
                            <b-nav-item>
                                <b-button
                                    class="option-type-button"
                                    @click.stop="toggleInfillColorMenu"
                                >
                                    {{ _("Add color") }}
                                </b-button>
                            </b-nav-item>
                        </b-nav-item>
                    </b-navbar-nav>
                    <!-- One face infill -->
                    <b-navbar-nav v-else>
                        <b-nav-item>
                            <b-button
                                class="option-type-button"
                                pill
                                @click.stop="(event) => loadImage(event, 1)"
                            >
                                {{ _("Add image") }}
                                <input
                                    ref="fileInput"
                                    type="file"
                                    accept="image/*"
                                    @change="importInfillImage"
                                >
                            </b-button>
                        </b-nav-item>
                        <b-nav-item>
                            <b-button
                                class="option-type-button"
                                pill
                                @click.stop="(event) => openColorPicker(1)"
                            >
                                {{ _("Add color") }}
                            </b-button>
                        </b-nav-item>
                    </b-navbar-nav>
                    <b-nav-item>
                        <b-button
                            v-show="infillsHasImage()"
                            class="option-type-button"
                            @click="removeInfillImage"
                        >
                            {{ _("Remove image") }}
                        </b-button>
                        <b-button
                            v-show="infillsHasColor()"
                            class="option-type-button"
                            @click="removeInfillImage"
                        >
                            {{ _("Remove color") }}
                        </b-button>
                    </b-nav-item>
                    <b-nav-item>
                        <swap-button
                            v-show="selectedEntity.hasInfillsBothSide"
                            @click="swapOption()"
                        />
                    </b-nav-item>
                    <b-nav-item>
                        <remove-button @click="removeOption()" />
                    </b-nav-item>
                </b-navbar-nav>
            </template>
        </template>
        <template v-else-if="selectedMeshes">
            <!-- Multiselect options -->
            <!-- Infill -->
            <template v-if="optionFamily === optionsFamilies.INFILL">
                <b-navbar-nav>
                    <b-nav-item>
                        <b-button
                            class="option-type-button"
                            :disabled="sameInfillType(infillsOptions.ONE_FACE)"
                            @click="addOption(infillsOptions.ONE_FACE)"
                        >
                            {{ _("One face") }}
                        </b-button>
                    </b-nav-item>
                    <b-nav-item>
                        <b-button
                            class="option-type-button"
                            :disabled="sameInfillType(infillsOptions.BOTH_FACES)"
                            @click="addOption(infillsOptions.BOTH_FACES)"
                        >
                            {{ _("Both faces") }}
                        </b-button>
                    </b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav
                    class="top-menu-div"
                >
                    <ul
                        v-if="spreadImageMenuVisible"
                        v-click-outside="closeSpreadImageMenu"
                        class="infill-image-menu"
                        tabindex="0"
                        @blur="closeSpreadImageMenu"
                        @focusout="closeSpreadImageMenu"
                    >
                        <li @click.stop="loadImage">
                            {{ _("Add image on each infill") }}
                        </li>
                        <li @click.stop="e => loadImage(e, 0, true)">
                            {{ _("Spread image on every infill") }}
                        </li>
                    </ul>
                    <b-nav-item>
                        <span v-if="areStraightAndHaveInfills()">
                            <b-button
                                v-show="!sameInfillType(infillsOptions.NONE)"
                                class="option-type-button"
                                pill
                                @click.stop="openSpreadImageMenu"
                            >
                                {{ _("Add image") }}
                                <input
                                    ref="fileInputForMultiInfill"
                                    type="file"
                                    accept="image/*"
                                    @change="spreadInfillImage"
                                >
                                <input
                                    ref="fileInput"
                                    type="file"
                                    accept="image/*"
                                    @change="importInfillImage"
                                >
                            </b-button>
                        </span>
                        <span v-else>
                            <b-button
                                v-show="!sameInfillType(infillsOptions.NONE)"
                                class="option-type-button"
                                pill
                                @click.stop="loadImage"
                            >
                                {{ _("Add image") }}
                                <input
                                    ref="fileInput"
                                    type="file"
                                    accept="image/*"
                                    @change="importInfillImage"
                                >
                            </b-button>
                        </span>
                    </b-nav-item>
                    <b-nav-item class="meshes">
                        <b-button
                            v-show="!sameInfillType(infillsOptions.NONE)"
                            pill
                            class="option-type-button"
                            @click="openColorPicker(0)"
                        >
                            {{ _("Add color") }}
                        </b-button>
                    </b-nav-item>
                    <b-nav-item>
                        <b-button
                            v-show="infillsHasImage()"
                            class="option-type-button"
                            @click="removeInfillImage"
                        >
                            {{ _("Remove image") }}
                        </b-button>
                        <b-button
                            v-show="infillsHasColor()"
                            class="option-type-button"
                            @click="removeInfillImage"
                        >
                            {{ _("Remove color") }}
                        </b-button>
                    </b-nav-item>
                    <b-nav-item>
                        <swap-button
                            v-show="meshesHaveInfills()"
                            @click="swapOption()"
                        />
                    </b-nav-item>
                    <b-nav-item>
                        <remove-button
                            v-show="meshesHaveInfills()"
                            @click="removeOption()"
                        />
                    </b-nav-item>
                </b-navbar-nav>
            </template>
            <!-- Light -->
            <template v-else-if="optionFamily === optionsFamilies.LIGHT">
                <b-navbar-nav>
                    <b-nav-item>
                        <select
                            @change="onOptionLightChange($event)"
                        >
                            <option
                                v-for="(value, index) in lightOptions"
                                :key="index"
                                :value="value"
                            >
                                {{ getLightName(value) }}
                            </option>
                        </select>
                    </b-nav-item>
                    <b-nav-item>
                        <b-button
                            class="option-type-button"
                            @click="updateLightSingularity(lightSingularityOptions.SIMPLE)"
                        >
                            {{ _("Simple") }}
                        </b-button>
                    </b-nav-item>
                    <b-nav-item>
                        <b-button
                            class="option-type-button"
                            @click="updateLightSingularity(lightSingularityOptions.DOUBLE)"
                        >
                            {{ _("Double") }}
                        </b-button>
                    </b-nav-item>
                    <b-nav-item>
                        <select
                            @change="onLightNumberChange($event)"
                        >
                            <option
                                v-for="(value, index) in lightNumberValues"
                                :key="index"
                                :value="value"
                            >
                                {{ value }}
                            </option>
                        </select>
                    </b-nav-item>
                </b-navbar-nav>
                <!-- Modifiers -->
                <b-navbar-nav
                    v-show="meshesHaveLights()"
                    class="top-menu-div"
                >
                    <b-nav-item>
                        <swap-button
                            v-show="canAllMeshSwapLights()"
                            @click="swapOption()"
                        />
                    </b-nav-item>
                    <b-nav-item>
                        <remove-button
                            @click="removeOption()"
                        />
                    </b-nav-item>
                </b-navbar-nav>
            </template>
            <!-- Baseplate -->
            <template v-else-if="optionFamily === optionsFamilies.BASEPLATE">
                <OptionBaseplate
                    :config="config"
                    :current-baseplate-type="currentBaseplateType"
                    :baseplate-options="baseplateOptions"
                    :show-edit="meshesHaveBaseplates()"
                    :show-swap="true"
                    @addOption="addOption"
                    @swapOption="swapOption"
                    @removeOption="removeOption"
                />
            </template>
            <!-- Groundplate -->
            <template v-else-if="optionFamily === optionsFamilies.GROUNDPLATE">
                <!-- Options -->
                <b-navbar-nav>
                    <b-nav-item
                        v-for="option in groundplateOptions"
                        :key="option.ref"
                    >
                        <b-button
                            class="option-type-button"
                            @click="addOption(option.type)"
                        >
                            {{ option.ref }}
                        </b-button>
                    </b-nav-item>
                </b-navbar-nav>
                <!-- Modifiers -->
                <b-navbar-nav
                    v-show="meshesHaveGroundplates()"
                    class="top-menu-div"
                >
                    <b-nav-item>
                        <remove-button @click="removeOption()" />
                    </b-nav-item>
                </b-navbar-nav>
            </template>
            <!-- Screen -->
            <template v-else-if="optionFamily === optionsFamilies.SCREEN">
                <b-navbar-nav>
                    <b-nav-item>
                        <b-button
                            class="option-type-button"
                            @click="addOption(screenOptions.UNIVERSAL)"
                        >
                            {{ _("B62 Universal Bracket") }}
                        </b-button>
                    </b-nav-item>
                    <b-nav-item>
                        <b-button
                            v-show="allStandardWidth"
                            class="option-type-button"
                            @click="addOption(screenOptions.FLATSCREEN)"
                        >
                            {{ _("Flatscreen support 860MM") }}
                        </b-button>
                    </b-nav-item>
                </b-navbar-nav>
                <!-- Modifiers -->
                <b-navbar-nav
                    v-show="meshesHaveScreens()"
                    class="top-menu-div"
                >
                    <b-nav-item>
                        <swap-button
                            @click="swapOption()"
                        />
                    </b-nav-item>
                    <b-nav-item>
                        <remove-button @click="removeOption()" />
                    </b-nav-item>
                </b-navbar-nav>
            </template>
            <!-- Shelf -->
            <template v-else-if="optionFamily === optionsFamilies.SHELF">
                <b-navbar-nav>
                    <b-nav-item>
                        <b-button
                            class="option-type-button"
                            @click="addOption(shelvesOptions.SIXTYDEG)"
                        >
                            {{ _("60 Degrees") }}
                        </b-button>
                    </b-nav-item>
                    <b-nav-item>
                        <b-button
                            class="option-type-button"
                            @click="addOption(shelvesOptions.LARGE)"
                        >
                            {{ _("Large") }}
                        </b-button>
                    </b-nav-item>
                    <b-nav-item>
                        <b-button
                            class="option-type-button"
                            @click="addOption(shelvesOptions.SMALL)"
                        >
                            {{ _("Small") }}
                        </b-button>
                    </b-nav-item>
                    <b-nav-item>
                        <b-button
                            class="option-type-button"
                            @click="addOption(shelvesOptions.TUBE)"
                        >
                            {{ _("Tube") }}
                        </b-button>
                    </b-nav-item>
                </b-navbar-nav>
                <!-- Modifiers -->
                <b-navbar-nav
                    v-show="meshesHaveShelves()"
                    class="top-menu-div"
                >
                    <b-nav-item>
                        <swap-button @click="swapOption()" />
                    </b-nav-item>
                    <b-nav-item>
                        <remove-button @click="removeOption()" />
                    </b-nav-item>
                </b-navbar-nav>
            </template>
        </template>
    </b-navbar>
</template>

<script>
import config from "defaultConfig";
import EventBus from "../../eventBus";
import RemoveButton from "../buttons/remove-button.vue";
import SwapButton from "../buttons/swap-button.vue";
import OptionBaseplate from "./option-baseplate.vue";
import self from "../../../index";
import { debounce } from "../../../../../helpers/js-helper";

const {
    app: {
        modules: {
            optionManager: {
                optionController,
                OptionableMixin: optionableMixin,
            },
            stonejs,
            selectionManager,
            assetsManager,
            history,
        },
        events,
    },
} = self;

const debounceSnapshot = debounce(() => {
    history.snapshot();
}, 1000);

export default {
    name: "EntityToolboxView",
    components: {
        RemoveButton,
        SwapButton,
        OptionBaseplate,
    },
    props: {
        selectedEntity: {
            type: Object,
            default: () => ({}),
        },
        optionFamily: {
            type: Number,
            default: () => 0,
        },
        currentBaseplateType: {
            type: Object,
            default: () => ({}),
        },
        currentGroundplateType: {
            type: Object,
            default: () => ({}),
        },
        currentLightType: {
            type: Object,
            default: () => ({}),
        },
        optionsFamilies: {
            type: Object,
            default: () => ({}),
        },
        selectedMeshes: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            baseplateOptions: null,
            groundplateOptions: null,
            shelvesOptions: optionableMixin.SHELF_OPTION,
            screenOptions: optionableMixin.SCREEN_OPTION,
            lightSingularityOptions: optionableMixin.LIGHT_SINGULARITY_OPTION,
            lightOptions: optionableMixin.LIGHT_OPTION,
            infillsOptions: optionableMixin.INFILL_OPTION,
            infillTypeImage: 0,
            isExpanded: false,
            infillImageMenuVisible: false,
            infillColorMenuVisible: false,
            spreadImageMenuVisible: false,
            currentColor: {
                value: "#ffffff",
            },
            config,
        };
    },
    computed: {
        shelfNumberValues() {
            optionController.shelfController.updateMaxShelfNumber();
            const values = [];
            for (let i = 1; i <= this.selectedEntity.maxShelfNumber; i += 1) {
                values.push(i);
            }
            return values;
        },
        screenNumberValues() {
            optionController.screenBracketController.updateMaxScreenNumber();
            const values = [];
            for (let i = 1; i <= this.selectedEntity.maxScreenNumber; i += 1) {
                values.push(i);
            }
            return values;
        },
        lightNumberValues() {
            let maxLight = 0;
            if (this.selectedMeshes.length > 0) {
                const maxLightArray = this.selectedMeshes.map(
                    mesh => {
                        optionController.lightController.updateMaxLightNumber(mesh.entity);
                        return mesh.entity.maxLightNumber;
                    }
                );
                maxLight = Math.min(...maxLightArray);
            } else {
                optionController.lightController.updateMaxLightNumber();
                maxLight = this.selectedEntity.maxLightNumber;
            }
            const values = [];
            for (let i = 1; i <= maxLight; i += 1) {
                values.push(i);
            }
            return values;
        },
        lights() {
            return Object.keys(optionController.lightController.lightsReferences);
        },
        isStandardWidth() {
            return this.selectedEntity.orientedWidth === 0.992;
        },
        allStandardWidth() {
            return this.selectedMeshes.every(
                mesh => mesh.entity.orientedWidth === 0.992
            );
        },
    },
    watch: {
        "currentColor.value": function () {
            this.onColorPickerChange();
        },
    },
    mounted() {
        EventBus.$on("enabledBaseplatesOptions", () => {
            this.computeBaseplatesRules();
        });
        events.on("@selection-manager.select-entity-scene", () => {
            this.computeBaseplatesRules();
        });
        events.on("@selection-manager.unselect-entity-scene", () => {
            this.computeBaseplatesRules();
        });
        EventBus.$on("enabledGroundplatesOptions", () => {
            this.computeGroundplatesRules();
        });
        events.on("@option-manager.infill-image-loaded", () => {
            this.$forceUpdate();
        });
        events.on("@option-manager.infill-image-removed", () => {
            this.$forceUpdate();
        });
        EventBus.$on("toggle-groups", open => {
            this.isExpanded = !open;
        });
    },
    methods: {
        /**
         * Set the given option type of the current option family to the current entity
         */
        addOption(type) {
            if (this.selectedEntity) {
                optionController.addOption(
                    this.optionFamily,
                    type,
                    null,
                    true,
                    true
                );
            } else if (this.selectedMeshes) {
                const entitiesList = this.selectedMeshes.map(
                    mesh => mesh.entity
                );
                optionController.multiEntityAddOption(
                    this.optionFamily,
                    type,
                    entitiesList
                );
                // Force the update otherwise we can't disable the button of the applied option
                this.$forceUpdate();
            }
        },

        /**
         * Remove the current option family of the current entity
         */
        removeOption() {
            if (this.selectedEntity) {
                optionController.removeOption(this.optionFamily, null, true);
            } else if (this.selectedMeshes) {
                const entitiesList = this.selectedMeshes.map(
                    mesh => mesh.entity
                );
                optionController.multiEntityRemoveOption(
                    this.optionFamily,
                    entitiesList
                );
                this.$forceUpdate();
            }
        },

        /**
         * Swap the current optionfamily side
         */
        swapOption() {
            if (this.selectedEntity) optionController.swapOptionsSide(this.optionFamily);
            else if (this.selectedMeshes) {
                const entitiesList = this.selectedMeshes.map(
                    mesh => mesh.entity
                );
                optionController.multiEntitySwapOptionsSide(
                    this.optionFamily,
                    entitiesList
                );
                this.$forceUpdate();
            }
        },

        /**
         * Check the current option number to display according to current option family
         */
        checkOptionNumber() {
            /**
             * Carefull, this don't work for infill/multiselection because options with number
             * are not meant to be used for multiselection
             */
            if (this.selectedEntity && this.optionFamily) {
                if (this.optionFamily === this.optionsFamilies.SHELF) {
                    return this.selectedEntity.shelfNumber;
                }
                if (this.optionFamily === this.optionsFamilies.LIGHT) {
                    return this.selectedEntity.lightNumber;
                }
                if (this.optionFamily === this.optionsFamilies.SCREEN) {
                    return this.selectedEntity.screenNumber;
                }
                return 1;
            }
            return 0;
        },

        /**
         * Check baseplaterules
         */
        computeBaseplatesRules() {
            if (this.selectedEntity) {
                this.baseplateOptions = optionController.baseplateController.availableBaseplates();
            } else if (this.selectedMeshes && this.selectedMeshes.length > 0) {
                // Keep only common values of all meshes
                let commonOptions = optionController.baseplateController
                    .availableBaseplates(this.selectedMeshes[0].entity);
                for (let i = 1; i < this.selectedMeshes.length; i += 1) {
                    const opts = optionController.baseplateController
                        .availableBaseplates(this.selectedMeshes[i].entity);
                    if (!commonOptions || !opts) break;
                    commonOptions = commonOptions.filter(
                        v => opts.findIndex(elt => elt.type === v.type) !== -1
                    );
                }
                this.baseplateOptions = commonOptions;
            }
        },
        computeGroundplatesRules() {
            if (this.selectedEntity) {
                this.groundplateOptions = optionController.groundplateController
                    .availableGroundplates();
            } else if (this.selectedMeshes && this.selectedMeshes.length > 0) {

                // Keep only common values of all meshes
                let commonOptions = optionController.groundplateController
                    .availableGroundplates(this.selectedMeshes[0].entity);
                for (let i = 1; i < this.selectedMeshes.length; i += 1) {
                    const opts = optionController.groundplateController
                        .availableGroundplates(this.selectedMeshes[i].entity);
                    commonOptions = commonOptions.filter(
                        v => opts.findIndex(elt => elt.type === v.type) !== -1
                    );
                }
                this.groundplateOptions = commonOptions;
            }

        },
        onUpdateOptionNumber() {
            optionController.onUpdateOptionNumberFromUi(this.optionFamily);
        },

        /**
         * Check if selected meshes are the same infill type as the one given
         * @param {Number} type - the infill type to check
         * @param {Boolean} every - (Optional, true by default) true if the condition must be valid
         * for every selected meshes, false if the condition need to be valid only once
         */
        sameInfillType(type, every = true) {
            if (this.selectedMeshes) {
                if (every) {
                    return this.selectedMeshes.every(
                        mesh => mesh.entity.infillOption === type
                    );
                }
                return this.selectedMeshes.some(
                    mesh => mesh.entity.infillOption === type
                );
            }
            return null;
        },

        areStraightAndHaveInfills() {
            if (this.selectedMeshes) {
                return this.selectedMeshes.every(
                    mesh => mesh.entity.subCategory === "STRAIGHT FRAMES"
                    && mesh.entity.infillOption === this.infillsOptions.ONE_FACE
                );
            }
            return false;
        },

        /**
         * Open the import image dialog
         */
        loadImage(event, type = 0, spread = false) {
            if (!spread) {
                this.$refs.fileInput.click();
            } else {
                this.$refs.fileInputForMultiInfill.click();
            }
            this.infillTypeImage = type;
        },

        /**
         * Add the imported image to the selected entites
         * enumTypeInfillImages = {
                allFaces: 0,
                firstFace: 1,
                secondFace: 2,
            };
         */
        importInfillImage(event) {
            const urlObjet = assetsManager.controller.addNewImportedImage(
                event.target.files[0]
            );
            event.target.value = "";
            selectionManager.addInfillImageToSelectedEntities(urlObjet, this.infillTypeImage);
        },

        openColorPicker(infillType = 0) {
            this.infillTypeImage = infillType;
            this.updateCurrentColorFromSelection();
            this.closeInfillColorMenu();
            setTimeout(() => {
                EventBus.$emit("open-color-picker", this.currentColor);
            }, 0);
        },

        onColorPickerChange() {
            this.importInfillColor(this.currentColor.value, this.infillTypeImage);
            debounceSnapshot();
        },

        /**
         * Add the imported color to the selected entities
         * @param {Color} color The color to set
         * @param {int} type The type of infill (0, 1, 2)
         */
        importInfillColor(color, type = 0) {
            this.infillTypeImage = type;
            selectionManager.addInfillColorToSelectedEntities(color.toString(),
                this.infillTypeImage);
        },

        spreadInfillImage(event) {
            const imageFile = event.target.files[0];
            event.target.value = "";
            selectionManager.displayImageOnEverySelectedEntities(imageFile);
        },

        onOptionNumberChange(event) {
            if (parseInt(event.target.value, 10) === 0) {
                this.removeOption();
            }
            history.snapshot();
        },

        onOptionLightChange(event) {
            if (this.selectedEntity) {
                this.selectedEntity.lightOption = parseInt(event.target.value, 10);
                optionController.lightController.addLights(this.selectedEntity);
            } else if (this.selectedMeshes.length) {
                this.selectedMeshes.forEach(
                    mesh => {
                        mesh.entity.lightOption = parseInt(event.target.value, 10);
                        optionController.lightController.addLights(mesh.entity);
                    }
                );
            }
            history.snapshot();
        },

        updateLightSingularity(lightSingularityOption) {
            if (this.selectedEntity) {
                this.selectedEntity.lightSingularity = lightSingularityOption;
                optionController.lightController.updateLightMeshes(this.selectedEntity);
            } else if (this.selectedMeshes) {
                this.selectedMeshes.forEach(mesh => {
                    mesh.entity.lightSingularity = lightSingularityOption;
                    optionController.lightController.updateLightMeshes(mesh.entity);
                });
            }
            history.snapshot();
        },

        onLightNumberChange(event) {
            if (this.selectedEntity) {
                this.selectedEntity.lightNumber = parseInt(event.target.value, 10);
                optionController.lightController.updateLightMeshes(this.selectedEntity);
            } else if (this.selectedMeshes.length) {
                this.selectedMeshes.forEach(
                    mesh => {
                        mesh.entity.lightNumber = parseInt(event.target.value, 10);
                        optionController.lightController.updateLightMeshes(mesh.entity);
                    }
                );
            }
            history.snapshot();
        },

        getLightName(lightOption) {
            const lightNameEntry = Object.entries(this.lightOptions).find(
                entry => entry[1] === lightOption
            );
            if (!lightNameEntry) {
                return "";
            }
            return lightNameEntry[0].replace(/_/g, " ", " ");
        },

        /**
         * Remove the selected infill images
         */
        removeInfillImage() {
            if (this.selectedEntity) {
                optionController.infillController.removeInfillImage(
                    this.selectedEntity,
                    true,
                    true
                );
            } else if (this.selectedMeshes) {
                const selectedEntities = this.selectedMeshes.map(
                    mesh => mesh.entity
                );
                optionController.infillController.removeEntitiesListInfillsImages(
                    selectedEntities
                );
            }
        },

        /**
         * Check if all selected meshes has infill
         */
        meshesHaveInfills() {
            return this.selectedMeshes.some(
                mesh => mesh.entity.infillOption !== this.infillsOptions.NONE
            );
        },

        /**
         * Check if any selected meshes has lights
         */
        meshesHaveLights() {
            return this.selectedMeshes.some(
                mesh => mesh.entity.optionsMeshes.lights.length !== 0
            );
        },

        /**
         * Check if all selected meshes can swap lights
         */
        canAllMeshSwapLights() {
            return this.selectedMeshes.find(
                mesh => mesh.entity.optionsMeshes.lights.length > 0
            );
        },

        /**
         * Check if any selected meshes has shelves
         */
        meshesHaveShelves() {
            return this.selectedMeshes.some(
                mesh => mesh.entity.optionsMeshes.shelves.length !== 0
            );
        },

        /**
         * Check if any selected meshes has screens
         */
        meshesHaveScreens() {
            return this.selectedMeshes.some(
                mesh => mesh.entity.optionsMeshes.screens.length !== 0
            );
        },

        /**
         * Check if any selected meshes has baseplates
         */
        meshesHaveBaseplates() {
            return this.selectedMeshes.some(
                mesh => mesh.entity.optionsMeshes.baseplates.length !== 0
            );
        },

        /**
         * Check if any selected meshes has groundplates
         */
        meshesHaveGroundplates() {
            return this.selectedMeshes.some(
                mesh => mesh.entity.optionsMeshes.groundplates.length !== 0
            );
        },

        /**
         * Check if all selected meshes can swap baseplates
         */
        canAllMeshSwapBaseplates() {
            return this.selectedMeshes.every(
                mesh => mesh.entity.optionsMeshes.baseplates.length === 0
                    || mesh.entity.getBaseplateOption()
                    === optionableMixin.BASEPLATE_OPTION.ONE_SIDE
            );
        },

        /**
         * Check if all selected meshes has infill and images
         * Check if all selected meshes has infill images
         */
        infillsHasImage() {
            if (this.selectedEntity) {
                return Boolean(this.selectedEntity.firstInfillImageId
                || this.selectedEntity.secondInfillImageId);
            }
            if (this.selectedMeshes) {
                return this.selectedMeshes.some(
                    mesh => mesh.entity.firstInfillImageId || mesh.entity.secondInfillImageId
                );
            }
            return false;
        },
        /**
         * Check if all selected meshes has infill colors
         */
        infillsHasColor() {
            if (this.selectedEntity) {
                return Boolean(this.selectedEntity.firstInfillColor
                || this.selectedEntity.secondInfillColor);
            }
            if (this.selectedMeshes) {
                return this.selectedMeshes.some(
                    mesh => mesh.entity.firstInfillColor || mesh.entity.secondInfillColor
                );
            }
            return false;
        },
        updateCurrentColorFromSelection() {
            let foundColor;
            if (this.selectedEntity) {
                foundColor = null
                    // eslint-disable-next-line max-len
                    || (this.infillTypeImage === 0 ? this.selectedEntity.firstInfillColor || this.selectedEntity.secondInfillColor : null)
                    || (this.infillTypeImage === 1 ? this.selectedEntity.firstInfillColor : null)
                    || (this.infillTypeImage === 2 ? this.selectedEntity.secondInfillColor : null);
            } else if (this.selectedMeshes) {
                for (let i = 0; i < this.selectedMeshes.length; i++) {
                    const mesh = this.selectedMeshes[i];
                    foundColor = null
                        || (this.infillTypeImage === 0 ? mesh.entity.firstInfillColor || mesh.entity.secondInfillColor : null)
                        || (this.infillTypeImage === 1 ? mesh.entity.firstInfillColor : null)
                        || (this.infillTypeImage === 2 ? mesh.entity.secondInfillColor : null);
                    if (foundColor) {
                        break;
                    }
                }
            }
            if (foundColor) {
                this.currentColor.value = foundColor;
            }
        },
        toggleInfillImageMenu() {
            this.infillImageMenuVisible = !this.infillImageMenuVisible;
            this.closeInfillColorMenu();
        },
        toggleInfillColorMenu() {
            this.infillColorMenuVisible = !this.infillColorMenuVisible;
            this.infillImageMenuVisible = false;
            if (this.infillColorMenuVisible) return;
            this.closeInfillColorMenu();
        },

        showInfillImageMenu() {
            this.infillImageMenuVisible = true;
        },

        closeInfillImageMenu() {
            this.infillImageMenuVisible = false;
        },

        showInfillColorMenu() {
            this.infillColorMenuVisible = true;
        },

        closeInfillColorMenu() {
            this.infillColorMenuVisible = false;
        },

        openSpreadImageMenu() {
            this.spreadImageMenuVisible = true;
        },
        closeSpreadImageMenu() {
            this.spreadImageMenuVisible = false;
        },
        _(...args) {
            return stonejs.gettext(...args);
        },
    },
};
</script>
