import {
    Mesh, TransformNode, AbstractMesh, Matrix, Vector3, PointerDragBehavior, PositionGizmo,
} from "@babylonjs/core";
import config from "defaultConfig";

import GizmoHelper from "../helpers/gizmo-helper";
import {
    Arrow, AXIS, NORMALS, COLORS, addHoverMaterial,
} from "../helpers/gizmo-meshes-helper";
import self from "../..";

const {
    collisionManager: {
        CollisionHelper,
    },
    guiManager,
    history,
} = self.app.modules;

/**
 * Extends Babylon base Gizmo
 * Ready to be modified to fit beMatrix demands
 * */
export default class CustomPositionGizmo extends PositionGizmo {

    constructor(highlightManager, scene) {
        super();
        this.attachedEntity = null;
        this.highlightManager = highlightManager;
        this.highlightBehavior = null;

        this.dragSizeViewer = new guiManager.DragSizeViewer(guiManager.GuiController, scene);

        this.arrows = [];
        Object.values(COLORS).forEach((value, index) => {
            this.arrows.push(
                new Arrow(
                    NORMALS[index],
                    value,
                )
            );
        });

        this.arrows[AXIS.X].mesh.rotate(Vector3.Forward(), -Math.PI / 2);
        this.arrows[AXIS.Z].mesh.rotate(Vector3.Right(), Math.PI / 2);
        this.arrows.forEach(arrow => arrow.mesh.translate(Vector3.Up(), 0.05));

        // Remove the old behavior
        [this.xGizmo, this.yGizmo, this.zGizmo].forEach((gizmo, index) => {
            const axis = gizmo.dragBehavior._options.dragAxis;
            gizmo._rootMesh.removeBehavior(gizmo._rootMesh.behaviors[0]);

            // Change gizmo mesh
            gizmo.setCustomMesh(this.arrows[index].mesh);

            // Create a new behavior for each axis
            gizmo.dragBehavior = new PointerDragBehavior(
                {
                    dragAxis: axis,
                }
            );
            gizmo.dragBehavior.moveAttached = false;
            gizmo.dragBehavior.dragged = false;
            CustomPositionGizmo.addVirtualGridBehavior(gizmo);

            this.bindGuiBehavior(gizmo);

            gizmo.dragBehavior.onDragObservable.add(
                () => {
                    if (this.highlightBehavior) {
                        this.highlightBehavior.startHighlightBehavior();
                    }
                }
            );
            gizmo.dragBehavior.onDragObservable.add(
                () => {
                    if (gizmo.attachedMesh && gizmo.attachedMesh instanceof Mesh) {
                        CollisionHelper.isUnderGrid(
                            gizmo.attachedMesh.entity,
                            { isEntity: true, respond: true }
                        );
                    } else if (gizmo.attachedMesh
                        && gizmo.attachedMesh instanceof TransformNode) {
                        GizmoHelper.checkNodeGridRepulsion(gizmo.attachedMesh);
                    }
                }
            );

            gizmo.dragBehavior.onDragEndObservable.add(() => {
                if (gizmo.dragBehavior.dragged) {
                    gizmo.dragBehavior.dragged = false;
                } else {
                    CustomPositionGizmo.translate62FromAxis(gizmo);
                }
                if (gizmo.attachedMesh && gizmo.attachedMesh instanceof TransformNode
                    && !(gizmo.attachedMesh instanceof AbstractMesh)) {
                    GizmoHelper.checkNodeGridRepulsion(gizmo.attachedMesh);
                    GizmoHelper.updateGizmoAttachedMeshChildren(gizmo);
                } else {
                    this.notificateEntity();
                }
                if (this.highlightBehavior) {
                    this.highlightBehavior.startHighlightBehavior();
                }
                history.snapshot();

            });

            // Add the new behavior
            gizmo._rootMesh.addBehavior(gizmo.dragBehavior);
            gizmo.dragBehavior.attach(gizmo._rootMesh);

            addHoverMaterial(gizmo, COLORS[index], this.arrows[index].material);
        });
    }

    static translate62FromAxis(gizmo) {
        const localDelta = gizmo.dragBehavior._options.dragAxis.scale(config.step * 2);
        const newPos = Vector3.TransformCoordinates(localDelta, gizmo.attachedMesh
            .getWorldMatrix());
        // We need to do this to avoid setting attachedMesh.position with another vec3, else the
        // entitie's mesh will lose its ref to the selectMesh and won't move with it
        const worldDelta = newPos.subtract(gizmo.attachedMesh.position);
        if (GizmoHelper.isGoingUnderGrid(gizmo.attachedMesh, worldDelta)) {
            return;
        }
        gizmo.attachedMesh.position.addInPlace(worldDelta);
        gizmo.attachedMesh.computeWorldMatrix(true);
        if (gizmo.attachedMesh.entity) {
            gizmo.attachedMesh.entity.updatePosition();
        }
    }

    /**
     * Makes the meshes moves 62mm by 62mm
     * @param {*} gizmo
     */
    static addVirtualGridBehavior(gizmo) {
        let currentSnapDragDistance = 0;
        const tmpVector = new Vector3();
        const tmpSnapEvent = { snapDistance: 0 };
        const localDelta = new Vector3();
        const tmpMatrix = new Matrix();
        gizmo.dragBehavior.onDragObservable.add((event) => {
            if (gizmo.attachedMesh) {
                gizmo.dragBehavior.dragged = true;
                // Convert delta to local translation if it has a parent
                if (gizmo.attachedMesh.parent) {
                    gizmo.attachedMesh.parent.computeWorldMatrix().invertToRef(tmpMatrix);
                    tmpMatrix.setTranslationFromFloats(0, 0, 0);
                    Vector3.TransformCoordinatesToRef(
                        event.delta,
                        tmpMatrix,
                        localDelta
                    );
                } else {
                    localDelta.copyFrom(event.delta);
                }

                // Snapping logic
                if (gizmo.snapDistance === 0) {
                    gizmo.attachedMesh.position.addInPlace(localDelta);
                } else {
                    currentSnapDragDistance += event.dragDistance;
                    if (Math.abs(currentSnapDragDistance) > gizmo.snapDistance) {
                        const dragSteps = Math.floor(
                            Math.abs(currentSnapDragDistance) / gizmo.snapDistance
                        );
                        currentSnapDragDistance %= gizmo.snapDistance;
                        localDelta.normalizeToRef(tmpVector);
                        tmpVector.scaleInPlace(gizmo.snapDistance * dragSteps);
                        gizmo.attachedMesh.position.addInPlace(tmpVector);
                        tmpSnapEvent.snapDistance = gizmo.snapDistance * dragSteps;
                        gizmo.onSnapObservable.notifyObservers(tmpSnapEvent);
                    }
                }
            }
        });
    }

    get attachedMesh() {
        // A Babylon patch, used because in v3 attachedMesh property is private in prototype
        // and can't be read, but we need it outside of the gizmos
        return this._meshAttached;
    }

    set attachedMesh(mesh) {
        super.attachedMesh = mesh; // Don't remove super or it won't work!
        // Must set an accessible property, else the property attachedMesh won't be accessible even
        // with a getter and we will be doomed
        GizmoHelper.attachMeshToMovableGizmo(this, mesh);
    }

    notificateEntity() {
        if (this.attachedEntity) {
            this.attachedEntity.updatePosition();
        }
    }

    bindGuiBehavior(gizmo) {
        // On start initialize gui, toggle line gui elements visibility and store
        // _meshAttached position
        gizmo.dragBehavior.onDragStartObservable.add(() => {
            this.dragSizeViewer.initViewerBeforeDisplay(this._meshAttached);
            this.dragSizeViewer.linkLineWithMesh(null);
        });

        // On drag update end points positions and label distances text
        gizmo.dragBehavior.onDragObservable.add(() => {

            this.dragSizeViewer.toggleLineModeVisibility(true);
            let meshCenter = null;
            if (this._meshAttached instanceof AbstractMesh) {
                meshCenter = this._meshAttached.entity.getBoundingBox().centerWorld.clone();
            } else if (this._meshAttached instanceof TransformNode) {
                meshCenter = this._meshAttached.getAbsolutePosition().clone();
            } else {
                throw new Error(`Can't drag arrow gizmo because the given object is not
                 a mesh or a node`);
            }
            this.dragSizeViewer.moveLine(meshCenter);
            this.dragSizeViewer.setEndPointPosition(
                meshCenter
            );
            this.dragSizeViewer.setLineDisplay(
                this._meshAttached.position,
                this._meshAttached.metadata.dragOrigin
            );
        });

        // On end toggle line gui elements visibility and remove drag start position from
        // metadata
        gizmo.dragBehavior.onDragEndObservable.add(() => {
            this.dragSizeViewer.toggleLineModeVisibility(false);
            this.dragSizeViewer.deinitViewerAfterDisplay(this._meshAttached);
        });
    }

}
