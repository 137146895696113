import KeyboardEventHandler from "./handlers/keyboard-event-handler";
import MouseEventHandler from "./handlers/mouse-event-handler";

export default class EventHandler {

    constructor(scene, canvas) {
        this.mouse = new MouseEventHandler(scene, canvas);
        this.keyboard = new KeyboardEventHandler();
    }

}
