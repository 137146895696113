export default {
    name: "catalog-manager",
    requires: ["http-request", "mesh-manager", "material-manager", "iframe-api"],
    load() {
        const Catalog = require("./src/catalog-controller").default;
        const Controller = new Catalog();
        return Controller;
    },
    unload() { },
};
