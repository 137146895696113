<template>
    <b-button
        id="remove"
        pill
        @click="$emit('click')"
    >
        <img src="/assets/icons/remove.svg">
        {{ _("Remove") }}
    </b-button>
</template>
<script>
import self from "../../../index";

const {
    app: {
        modules: { stonejs },
    },
} = self;

export default {
    methods: {
        _(...args) {
            return stonejs.gettext(...args);
        },
    },
};
</script>
<style lang="scss">
@import "../../../../../style/bematrix";
#remove {
    font-family: $font-regular;
    font-size: 0.75em;
    box-shadow: 0 3px 3px -2px rgba(0, 63, 79, 0.29);
    background-color: $green;
    color: white;
    border-color: transparent;

    &:hover {
        background-color: $green-hover;
    }

    &:active {
        background-color: $green-click;
    }
}
</style>
