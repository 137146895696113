import self from "../..";

const Structure = require("@obsidianjs/data-store/src/entity");

const EntityStructure = require("./entity-structure").default;


const PopinStructure = EntityStructure.$extend({

    __name__: "popin",

    __init__(params) {
        this.$super(params);
        this.initSnapping();
    },

    __include__: [
        self.app.modules.snapping.SnappableMixin.prototype,
    ],

});
Structure.$register(PopinStructure);
export default PopinStructure;
