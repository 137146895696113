import self from "../..";

const {
    modules: {
        dataStore,
        optionManager,
        catalogManager: catalogController,
        optionManager: {
            optionController,
        },
    },

} = self.app;

export default class QuoteHelper {

    /**
     * Returns an object with the content of the scene rearranged
     * to have an array of groups, containing frames grouped by frame
     * category
     * @returns {Object}
     */
    static generateJSONQuote() {
        let groups = dataStore.listEntities("/groups");
        groups = groups.concat(dataStore.listEntities("/groups/default"));
        const rootObject = {
            groups: groups.map(group => QuoteHelper.generateJSONGroupQuote(group)),
        };
        return JSON.stringify(rootObject);
    }

    /**
     * Return the XML quote of the group
     * @param {[]} group the we want to generate the quote from
     * @returns {string} xml quote of the group
     */
    static generateJSONGroupQuote(group) {
        const groupObj = {
            name: group.getName(),
            families: [],
            hideInQuote: false,
        };

        group.getProductIDs().forEach((id) => {
            const entity = dataStore.getEntity(id);
            const productFamily = QuoteHelper.getProductFamily(groupObj, entity.$data);
            QuoteHelper.addProductToFamily(productFamily, entity);

            // Manage product options
            if (optionController.haveOptions(entity)) {
                QuoteHelper.addProductOptions(groupObj, entity);
            }

        });

        // Update families hideInQuote property
        groupObj.families.forEach(
            (family) => {
                family.hideInQuote = family.products.every(prod => prod.hideInQuote);
            }
        );

        // Set hideInQuote so the group know it contains stuff hidden in the quote
        groupObj.hideInQuote = groupObj.families.every(family => family.hideInQuote);

        return groupObj;
    }

    /**
     * Add a product to a family
     * @param {*} family
     * @param {*} productEntity
     */
    static addProductToFamily(family, productEntity, quantity = 1) {
        let productIndex = QuoteHelper.productExists(family.products, productEntity);
        let currentProduct = family.products[productIndex];

        if (productIndex === -1) {
            const catalogItem = self.app.modules.catalogManager.products[productEntity.ref];
            const hideInQuote = QuoteHelper.mustHideInQuote(catalogItem.ref);

            // Creates a copy, otherwise we modify the original object
            productIndex = family.products.push(
                {
                    qty: 0,
                    hideInQuote,
                    ...catalogItem,
                }
            ) - 1;
            currentProduct = family.products[productIndex];

            if (productEntity.color) {
                // If color is defined then we force RAL
                currentProduct.finishing = "ral";
                currentProduct.color = optionManager.RalColors
                    .getRalFromHex(productEntity.color);
            } else if (currentProduct.finishingEco) {
                // Default option if it don't have color and can be eco
                currentProduct.finishing = "eco";
            } else if (currentProduct.finishingAno) {
                currentProduct.finishing = "ano";
            } else if (currentProduct.finishingRal) {
                // Only if ral is only option but don't have color
                currentProduct.finishing = "ral";
                currentProduct.color = optionManager.RalColors.getRalFromHex(
                    optionManager.RalColors.getDefaultColor()
                );
            } else {
                // It means the object has no finition info so we set to eco by default
                currentProduct.finishing = "eco";
            }
        }

        // Increment quantity
        currentProduct.qty += quantity;
    }

    /**
     * Add a product options to the options family
     * Options family is just PARTS family
     * @param {*} groupObj
     * @param {*} productEntity
     */
    static addProductOptions(groupObj, productEntity) {
        //  Loop through all options types
        Object.keys(optionController.optionsFamilies).forEach((optionFamilyKey) => {
            const optionFamilyValue = optionController.optionsFamilies[optionFamilyKey];

            if (optionFamilyValue !== optionController.optionsFamilies.ALL
                && optionFamilyValue !== optionController.optionsFamilies.NONE) {

                let optionRef = optionController
                    .getOptionReference(optionFamilyValue, productEntity);

                let currentOptionQuantity = 1;
                // We check if the option quantity is different than 0
                if (optionFamilyValue === optionController.optionsFamilies.SCREEN) {
                    currentOptionQuantity = optionController
                        .getEntityOptionsNumber(productEntity).screenNumber;
                } else if (optionFamilyValue === optionController.optionsFamilies.SHELF) {
                    currentOptionQuantity = optionController
                        .getEntityOptionsNumber(productEntity).shelfNumber;
                } else if (optionFamilyValue === optionController.optionsFamilies.LIGHT) {
                    const multiplier = productEntity.lightSingularity;
                    currentOptionQuantity = optionController
                        .getEntityOptionsNumber(productEntity).lightNumber * multiplier;
                } else if (optionFamilyValue === optionController.optionsFamilies.INFILL) {
                    currentOptionQuantity = productEntity.infillOption;
                }

                if (optionRef && currentOptionQuantity) {
                    const catalogItem = catalogController.products[optionRef];

                    // We cannot find an item that match this prefix
                    if (!catalogItem) {
                        if (optionFamilyValue === optionController.optionsFamilies.INFILL) {
                            const references = Object.keys(catalogController.products).filter(
                                element => element.includes(optionRef)
                            );
                            const addInfillRef = (ref) => {
                                const product = catalogController.products[ref];

                                const optionFamily = QuoteHelper.getProductFamily(
                                    groupObj,
                                    product
                                );
                                QuoteHelper.addProductToFamily(
                                    optionFamily,
                                    product
                                );
                            };

                            // Both face
                            if (currentOptionQuantity === 2) {
                                references.forEach((ref) => {
                                    addInfillRef(ref);
                                });
                            } else if (currentOptionQuantity === 1) { // One face
                                if (productEntity.swappedOptions.INFILL) {
                                    optionRef = references.find(
                                        ref => ref.includes("Rear") || ref.includes("IN")
                                    );
                                } else {
                                    optionRef = references.find(
                                        ref => ref.includes("Front") || ref.includes("OUT")
                                    );
                                }

                                addInfillRef(optionRef);
                            }
                        }
                    } else {
                        const optionFamily = QuoteHelper.getProductFamily(groupObj, catalogItem);
                        QuoteHelper.addProductToFamily(
                            optionFamily,
                            catalogItem,
                            currentOptionQuantity
                        );
                    }
                }
            }
        });
    }

    /**
     * Return the index of the family or -1 if it doesn't exists
     * @param {[]} familyArray array of families
     * @param {String} familyName name of the family we're looking for
     * @returns {Number} index of the family or -1
     */
    static familyExists(familyArray, familyName) {
        for (let i = 0; i < familyArray.length; i += 1) {
            if (familyArray[i].name === familyName) {
                return i;
            }
        }
        return -1;
    }

    /**
     * Return the object family
     * If the family doesn't exist we create one and return it
     * @param {*} groupObj the object containing all the families present in the parsed group
     * @param {*} product the object we need to find a family for
     */
    static getProductFamily(groupObj, product) {
        let familyIndex = QuoteHelper
            .familyExists(groupObj.families, product.category);
        let family = groupObj.families[familyIndex];

        // Is the family registered
        if (familyIndex === -1) {
            familyIndex = groupObj.families.push(
                {
                    name: product.category,
                    products: [],
                    hideInQuote: false,
                }
            ) - 1;
            family = groupObj.families[familyIndex];
        }

        return family;
    }

    /**
     * Return the index of the product or -1 if it doesn't exists
     * @param {[]} productArray array of products
     * @param {String} productRef reference of the product we're looking for
     * @returns {Number} index of the product or -1
     */
    static productExists(productArray, product) {
        const productRal = optionManager.RalColors.getRalFromHex(product.color);
        for (let i = 0; i < productArray.length; i += 1) {
            if (productArray[i].ref === product.ref
                && productArray[i].color === productRal) {
                return i;
            }
        }
        return -1;
    }

    /**
     * Return a string with the content of all the groups
     * using XML architecture
     * @returns {string} xml quote of the scene
     */
    static generateXMLQuote() {
        let groups = dataStore.listEntities("/groups");
        groups = groups.concat(dataStore.listEntities("/groups/default"));
        let xmlQuote = "";

        groups.forEach((group) => {
            xmlQuote += QuoteHelper.generateXMLGroupQuote(group);
        });
        return xmlQuote;
    }

    /**
     * Return the XML quote of the group
     * @param {[]} group the we want to generate the quote from
     * @returns {string} xml quote of the group
     */
    static generateXMLGroupQuote(group) {
        const productsCount = QuoteHelper.countGroupProducts(group);
        let groupProducts = "";
        Object.keys(productsCount).forEach((ref) => {
            groupProducts += "<x20:Lines>";
            groupProducts += `<x20:Descr>${group.getName()}</x20:Descr>`;
            groupProducts += `<x20:ItemNo>${ref}</x20:ItemNo>`;
            groupProducts += `<x20:Qty>${productsCount[ref]}</x20:Qty>`;
            groupProducts += "</x20:Lines>";
        });

        return groupProducts;
    }

    /**
     * Count the number of each reference in the group
     * @param {*} group
     * @returns {number[]} array of each reference of the group
     * and the number of each of them in the group
     */
    static countGroupProducts(group) {
        const productsCount = [];
        group.getProductIDs().forEach((id) => {
            const entity = dataStore.getEntity(id);
            const ref = entity.getRef();
            if (productsCount[ref]) {
                productsCount[ref] += 1;
            } else {
                productsCount[ref] = 1;
            }
        });

        return productsCount;
    }

    /**
 * Check if the object must be added to the quote here
 * @param {string} ref
 * @return {boolean} true if the product must be hidden in the quote false otherwise
 */
    static mustHideInQuote(ref) {
        return [
            /ErrorBlock/,
            /PANEL FOR \.*/,
        ].some(regex => regex.test(ref));
    }

}
