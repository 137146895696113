import {
    Plane,
    Mesh,
    MeshBuilder,
} from "@babylonjs/core";

import GeometryUtility from "../modules/geometry-utility/src/geometry-utility";

const DragHelper = {


    createDragPlane(y, scene, doPicking = true) {
        // Create a plane of drag
        const plane = new Plane(0, 180, 0, 0);
        plane.normalize();
        const temporaryPlane = MeshBuilder.CreatePlane("initDragPlane", {
            size: 1000,
            sourcePlane: plane,
            sideOrientation: Mesh.DOUBLESIDE,
        }, scene);
        temporaryPlane.position.y = y;
        temporaryPlane.computeWorldMatrix(true);
        temporaryPlane.isVisible = false;

        let pickResult = null;
        if (doPicking) {
            const predicate = mesh => mesh.name === temporaryPlane.name;

            // The picked position plus the entity height/2 (upside the grid)
            pickResult = scene.pick(scene.pointerX, scene.pointerY,
                predicate, false, scene.activeCamera);
            const pickPosition = pickResult.pickedPoint;
            if (pickPosition) {
                pickPosition.x = GeometryUtility.adaptToStep(pickPosition.x, false, true);
                pickPosition.z = GeometryUtility.adaptToStep(pickPosition.z, false, true);
            }
        }

        return { mesh: temporaryPlane, plane, pickResult };


    },


};
export default DragHelper;
