import { Scene } from "@babylonjs/core";
import { AdvancedDynamicTexture } from "@babylonjs/gui";

import self from "../../index";

const {
    obsidianEngine: {
        controller: engineController,
    },
} = self.app.modules;

export default class GuiController {

    constructor() {
        this.isReady = false;
        this.scene = null;

        if (engineController.scene && engineController.scene.activeCamera) {
            this.initGuiScene();
        } else {
            self.app.events.on("@camera-manager.camera-ready", () => {
                this.initGuiScene();
            });
        }
    }

    /**
     * Init a gui scene to render the gui in
     * We cannot render the gui into the main scene cause it gets occluded by gizmos
     * So we create this scene and render it last on top of everything
     */
    initGuiScene() {
        this.scene = new Scene(
            engineController.engine,
            { virtual: true }
        );
        this.scene.detachControl();
        this.scene.activeCamera = engineController.scene.activeCamera;
        this.scene.autoClear = false;
        this.scene.autoClearDepthAndStencil = true;

        this.guiTexture = AdvancedDynamicTexture.CreateFullscreenUI(
            "ui",
            true,
            this.scene
        );

        self.app.events.on("@bematrix-ui.canvas-resized", (size) => {
            this.guiTexture.scaleTo(size.width, size.height);
        });

        self.app.events.on(
            "@obsidian-engine.main-scene-rendered",
            () => { this.render(); }
        );

        this.isReady = true;
        self.app.events.emit("gui-ready", this.scene);
    }

    addControl(control) {
        this.guiTexture.addControl(control);
    }

    render() {
        const activeCameraPreviousScene = this.scene.activeCamera.getScene();
        this.scene.activeCamera._scene = this.scene;

        this.scene.render(false);

        this.scene.activeCamera._scene = activeCameraPreviousScene;
    }

}
