import config from "defaultConfig";
import self from "../../index";

import { PAGE_TYPES } from "../model/page-structure";

const {
    app: {
        modules: {
            dataStore,
            materialManager,
            optionManager: {
                optionController,
                OptionableMixin: optionableMixin,
            },
            catalogManager: catalogController,
            geometryUtility,
            selectionManager: selectionController,
        },
        events: { emit },
        log,
    },
} = self;

export default {
    /**
     * Return the provided filename or the config versionName
     * @param {String || null} filename
     * @returns {String} the filename to use when exporting a building plan pdf
     */
    getPdfFilename(filename) {
        return filename || config.versionName;
    },

    /**
     * Must be in another modules that manages the scene frames states
     * Return all the visible mesh of the scene refs in an array
     * @returns {Array[string]}
     */
    getActiveProductsReferences() {
        const visibleFrames = dataStore.listEntities("/products/*")
            .filter(frame => frame.visible);
        const visibleFramesRefs = visibleFrames.map(frame => frame.ref);
        const visibleConnectorsRefs = dataStore.listEntities("/connectors/*")
            .filter(connector => connector.visible)
            .map(connector => connector.ref);

        const visibleProductsRefs = [
            ...visibleFramesRefs,
            ...visibleConnectorsRefs,
        ];

        visibleFrames.forEach((frame) => {
            if (frame.isOptionable) {
                const visibleOptionsRefs = this.getActiveOptionsReferences(frame);

                visibleProductsRefs.push(...visibleOptionsRefs);
            }
        });

        return visibleProductsRefs;
    },

    getActiveOptionsReferences(product) {
        const visibleOptionsReferences = [];

        if (product.optionsVisibility.screens) {
            const screenRef = optionController.getOptionReference(
                optionController.optionsFamilies.SCREEN,
                product
            );

            if (screenRef) {
                const optionNumber = optionController.getEntityOptionsNumber(product).screenNumber;

                visibleOptionsReferences.push(...new Array(optionNumber).fill(screenRef));
            }
        }

        if (product.optionsVisibility.baseplates) {
            const baseplateRef = optionController.getOptionReference(
                optionController.optionsFamilies.BASEPLATE,
                product
            );

            if (baseplateRef) {
                visibleOptionsReferences.push(baseplateRef);
            }
        }

        if (product.optionsVisibility.lights) {
            const lightRef = optionController.getOptionReference(
                optionController.optionsFamilies.LIGHT,
                product
            );

            if (lightRef) {
                const multiplier = product.lightOption;
                const optionNumber = optionController.getEntityOptionsNumber(product).lightNumber;

                visibleOptionsReferences.push(
                    ...new Array(optionNumber * multiplier).fill(lightRef)
                );
            }
        }

        if (product.optionsVisibility.shelves) {
            const shelfRef = optionController.getOptionReference(
                optionController.optionsFamilies.SHELF,
                product
            );

            if (shelfRef) {
                const optionNumber = optionController.getEntityOptionsNumber(product).shelfNumber;

                visibleOptionsReferences.push(...new Array(optionNumber).fill(shelfRef));
            }
        }

        if (product.optionsVisibility.infills) {
            const referencePrefix = optionController.getOptionReference(
                optionController.optionsFamilies.INFILL,
                product
            );

            if (referencePrefix) {
                // Some infills references are only prefixes of a real reference
                // so we look for all the references including the supposed prefix
                const references = Object.keys(catalogController.products)
                    .filter(ref => ref.includes(referencePrefix));

                // Infill have the same reference for both sides
                if (references.length === 1) {
                    visibleOptionsReferences.push(
                        ...new Array(product.infillOption).fill(references[0])
                    );
                } else if (references.length === 2) { // Different ref for each side
                    // Find the ref corresponding the infill side
                    if (product.infillOption === optionableMixin.INFILL_OPTION.ONE_FACE) {
                        let infillRef = null;

                        if (product.swappedOptions.INFILL) {
                            infillRef = references.find(
                                ref => ref.includes("Rear") || ref.includes("IN")
                            );
                        } else {
                            infillRef = references.find(
                                ref => ref.includes("Front") || ref.includes("OUT")
                            );
                        }

                        visibleOptionsReferences.push(infillRef);
                    } else { // Both refs are used
                        visibleOptionsReferences.push(...references);
                    }
                } else {
                    log.error(`Found ${references.length}
                        infills references for the : ${referencePrefix},
                        reference prefix. An infill reference prefix should match at least 1
                        reference and no more that 2 references !`);
                }
            }
        }

        return visibleOptionsReferences;
    },

    /**
     * Must be in another modules that manages the scene frames states
     * Count occurences of the same products in the product array
     * @param {Array[string]} productsRefs
     * @returns {Object} key: product ref, value: product quantity
     */
    getProductsQuantities(productsRefs) {
        const productsQuantities = {};

        productsRefs.forEach((ref) => {
            productsQuantities[ref] = productsQuantities[ref] + 1 || 1;
        });

        return productsQuantities;
    },

    /**
     * Get all products visibility infos into an array of objects
     *
     * Each object contains an entity id and it's visibility infos
     * @returns {Array[Object]}
     */
    getProductVisibility() {
        const entities = dataStore.listEntities("/products/*");
        entities.push(...dataStore.listEntities("/connectors/*"));

        return entities.map((entity) => {
            const visbilityInfos = {
                id: entity.id,
                visible: entity.visible,
            };

            if (entity.isOptionable) {
                visbilityInfos.optionsVisibility = {
                    lights: entity.optionsVisibility.lights,
                    baseplates: entity.optionsVisibility.baseplates,
                    shelves: entity.optionsVisibility.shelves,
                    screens: entity.optionsVisibility.screens,
                    infills: entity.optionsVisibility.infills,
                };
            }

            return visbilityInfos;
        },);
    },


    /**
     * Loop through every entry of the page productsVisibility metadata, to apply the visibility
     * infos of each products into the scene ones
     *
     * @param {Page} page the page we get the visibility infos from
     */
    restoreProductsVisibility(page) {
        if (page.scenePageMetadata.productsVisibility.length) {
            page.scenePageMetadata.productsVisibility.forEach((entityVisibilityInfos) => {
                const entity = dataStore.getEntity(entityVisibilityInfos.id);
                entity.visible = entityVisibilityInfos.visible;

                geometryUtility.toggleMeshVisibility(entity.mesh, entity.visible, false);

                if (entity.isOptionable) {
                    const optionsVisibility = entityVisibilityInfos.optionsVisibility;
                    if (optionsVisibility.infills && !optionController.showInfills) {
                        optionController.showInfills = true;
                        optionController.infillsVisibilityLocked = false;
                    }
                    Object.keys(entity.optionsVisibility).forEach((key) => {

                        if (entity.optionsMeshes[key].length) {
                            entity.optionsVisibility[key] = optionsVisibility[key];
                            if (key === "infills" && page.scenePageMetadata
                                .connectorSelectionModeEnabled) {
                                // Don't show infills if we are in connectorSelectionMode
                                geometryUtility.toggleMeshesVisibility(
                                    entity.optionsMeshes[key],
                                    false,
                                    true
                                );
                            } else {
                                geometryUtility.toggleMeshesVisibility(
                                    entity.optionsMeshes[key],
                                    entity.optionsVisibility[key],
                                    true
                                );
                            }
                        }
                    });
                }
            });
        } else {
            const entities = dataStore.listEntities("/products/*");
            entities.push(...dataStore.listEntities("/connectors/*"));

            entities.forEach((entity) => {
                entity.visible = true;
                geometryUtility.toggleMeshVisibility(entity.mesh, true, false);

                if (entity.isOptionable) {
                    if (entity.optionsVisibility.infills && !optionController.showInfills) {
                        optionController.showInfills = true;
                        optionController.infillsVisibilityLocked = false;
                    }
                    Object.keys(entity.optionsVisibility).forEach((key) => {
                        entity.optionsVisibility[key] = true;

                        if (entity.optionsMeshes[key].length) {
                            geometryUtility.toggleMeshesVisibility(
                                entity.optionsMeshes[key],
                                true,
                                true
                            );
                        }
                    });
                }
            });
        }
        emit("restore-page-products-visibility");
    },

    /**
     * Displays the measurements of a page
     * @param {Page} page the page we want the measurements from
     */
    restoreMeasurements(page) {
        if (page.type === PAGE_TYPES.SCENE && page.scenePageMetadata.measurements.length) {
            page.scenePageMetadata.measurements.forEach(
                (measurementEntity) => {
                    // We use only dataStore to avoid snapshot
                    // Also we don't send a measurement-created event to avoid
                    // an infinite event loop
                    dataStore.addEntity(measurementEntity, "/measurements");
                }
            );
        }
    },

    /**
     * Remove the measurements of the given page;
     * @param {Page} page
     */
    removePageMeasurements(page) {
        if (page.type === PAGE_TYPES.SCENE) {
            this.removeMeasurementList(
                page.scenePageMetadata.measurements
            );
        }
    },

    /**
     * Remove the measurement from dataStore of a givenList
     * @param {Array<MeasurementEntity>} measurementList the list we want to remove from dataStore
     */
    removeMeasurementList(measurementList) {
        measurementList.forEach(
            (measurementEntity) => {
                dataStore.removeEntity(measurementEntity);
            }
        );
    },

    /**
     * Set the connector mode of the page
     * Set the frames and infills visibility states
     * @param {Page} page
     * @param {boolean} connectorSelectionModeEnabled
     */
    setConnectorSelectionMode(page, connectorSelectionModeEnabled) {
        selectionController.unselectAll();

        page.scenePageMetadata.connectorSelectionModeEnabled = connectorSelectionModeEnabled;

        emit("toggle-connector-selection", connectorSelectionModeEnabled);

        materialManager.setFramesTransparency(connectorSelectionModeEnabled);

        // When enabling transparency mode, hide infills, same when disabling

        if (!connectorSelectionModeEnabled) {
            this.restoreProductsVisibility(page);
        } else {
            optionController.setAllOptionsVisibility(
                !connectorSelectionModeEnabled,
                optionController.optionsFamilies.INFILL
            );
        }
    },

    /**
     * Return an annotation id for the selecion
     * if it's a single entity => returns its id
     * is it's a multi selection => returns the stringified array of ids
     * @returns {String}
     */
    selectionAnnotationId() {
        if (selectionController.selectMesh) {
            const selectedProduct = selectionController.selectMesh.entity
                || selectionController.selectMesh.originalMesh;
            return selectedProduct.id;
        }
        const ids = selectionController.multiSelectedMesh.map(mesh => mesh.entity.id);
        ids.sort();
        return JSON.stringify(ids);
    },
};
