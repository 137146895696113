/* eslint-disable  import/no-import-module-exports */

import classUtils from "vendors/class-utils";
// eslint-disable-next-line import/no-import-module-exports
import self from "../index";

const Class = require("abitbol");

const OptionUtility = require("../utility/option-utility");

const OptionableMixin = Class.$extend({

    __classvars__: {
        LIGHT_SINGULARITY_OPTION: {
            NONE: 0,
            SIMPLE: 1,
            DOUBLE: 2,
        },
        LIGHT_OPTION: {
            NONE: 0,
            SAM_LIGHT: 1,
            CELIGHT: 2,
            BIG_SAM_LIGHT: 3,
        },
        BASEPLATE_OPTION: {
            NONE: 0,
            SQUARED: 1,
            BOTH_SIDE: 2,
            DOUBLE: 3,
            ONE_SIDE: 4,
        },
        GROUNDPLATE_OPTION: {
            NONE: 0,
            TYPE1: 1,
            TYPE2: 2,
        },
        SHELF_OPTION: {
            NONE: 0,
            SIXTYDEG: 1,
            LARGE: 2,
            SMALL: 3,
            TUBE: 4,
        },
        SCREEN_OPTION: {
            NONE: 0,
            UNIVERSAL: 1,
            FLATSCREEN: 2,
        },
        INFILL_OPTION: {
            NONE: 0,
            ONE_FACE: 1,
            BOTH_FACES: 2,
        },
    },

    initOptions() {
        this.optionsMeshes = {
            lights: [],
            baseplates: [],
            groundplates: [],
            shelves: [],
            screens: [],
            infills: [],
        };
        //  oriented size
        this.orientedWidth = 0;
        this.orientedHeight = 0;

        // oriented orientation vectors
        this.orientedUp = null;
        this.orientedLeft = null;

        this.isUp = true;
        this.angleToUp = 0;

        this.canHaveOptions = true;
        this.canHaveBaseplatesOption = true;
        this.canHaveGroundplatesOption = true;
        this.canHaveLightsOption = true;
        this.canHaveScreenOption = true;
        this.canHaveShelfOption = true;
        this.hasInfillsBothSide = true;
        this.forceBaseplate = false; // Set to true if you want to avoid update of baseplate rules
        this.maxShelfNumber = 1;
        this.maxScreenNumber = 1;
        this.maxLightNumber = 1;

        // serialized options
        this.$data.lightOption = OptionableMixin.LIGHT_OPTION.NONE;
        this.$data.baseplateOption = OptionableMixin.BASEPLATE_OPTION.NONE;
        this.$data.groundplateOption = OptionableMixin.GROUNDPLATE_OPTION.NONE;
        this.$data.shelfOption = OptionableMixin.SHELF_OPTION.NONE;
        this.$data.screenOption = OptionableMixin.SCREEN_OPTION.NONE;
        this.$data.infillOption = OptionableMixin.INFILL_OPTION.NONE;

        this.$data.infillImageId = null;

        this.$data.firstInfillImageId = null;
        this.$data.secondInfillImageId = null;
        this.$data.firstInfillColor = null;
        this.$data.secondInfillColor = null;

        this.$data.shelfNumber = 0;
        this.$data.screenNumber = 0;
        this.$data.lightNumber = 0;
        this.$data.lightOption = 0;
        this.$data.lightSingularity = 0;

        this.$data.swappedOptions = {
            LIGHT: false,
            BASEPLATE: false,
            SHELF: false,
            SCREEN: false,
            INFILL: false,
        };

        this.$data.optionsVisibility = {
            lights: true,
            baseplates: true,
            shelves: true,
            screens: true,
            infills: true,
        };

        this.isOptionable = true;
    },

    computeOptionsParameters() {
        const angleInfo = OptionUtility.angleToUpward(this.mesh);
        this.isUp = angleInfo.isUp;
        this.orientedUp = angleInfo.upVector;
        this.orientedRight = angleInfo.rightVector;
        this.angleToUp = angleInfo.angle;
        this.mesh.computeWorldMatrix();
        this.mesh.refreshBoundingInfo();
        const bb = this.mesh.getBoundingInfo().boundingBox;
        const dx = Math.round((bb.maximum.x - bb.minimum.x) * 1000) / 1000;
        const dy = Math.round((bb.maximum.y - bb.minimum.y) * 1000) / 1000;
        if (this.isUp) {
            this.orientedHeight = dy;
            this.orientedWidth = dx;
        } else {
            this.orientedHeight = dx;
            this.orientedWidth = dy;
        }
    },

    getLightOption: classUtils.getter,
    setLightOption: classUtils.setter,

    getLightNumber: classUtils.getter,
    setLightNumber: classUtils.setter,

    getLightSingularity: classUtils.getter,
    setLightSingularity: classUtils.setter,

    getBaseplateOption: classUtils.getter,
    setBaseplateOption(bo) {
        this.$data.baseplateOption = bo;
        self.app.events.emit(
            "update-baseplate-option", this.id
        );
    },

    getGroundplateOption: classUtils.getter,
    setGroundplateOption(go) {
        this.$data.groundplateOption = go;
        self.app.events.emit(
            "update-groundplate-option", this.id
        );
    },

    getShelfOption: classUtils.getter,
    setShelfOption(so) {
        this.$data.shelfOption = so;
        self.app.events.emit(
            "update-shelf-option", this.id
        );
    },

    getScreenOption: classUtils.getter,
    setScreenOption(so) {
        this.$data.screenOption = so;
        self.app.events.emit(
            "update-screen-option", this.id
        );
    },
    getInfillOption: classUtils.getter,
    setInfillOption(so) {
        this.$data.infillOption = so;
        self.app.events.emit(
            "update-infill-option", this.id
        );
    },
    getSwappedOptions: classUtils.getter,
    setSwappedOptions: classUtils.setter,

    getOptionsVisibility: classUtils.getter,
    setOptionsVisibility: classUtils.setter,

    getShelfNumber: classUtils.getter,
    setShelfNumber(sn) {
        this.$data.shelfNumber = sn;
        self.app.events.emit(
            "update-shelf-number", this
        );
    },

    getScreenNumber: classUtils.getter,
    setScreenNumber(sn) {
        this.$data.screenNumber = sn;
        self.app.events.emit(
            "update-screen-number", this
        );
    },

    getInfillImageId: classUtils.getter,
    setInfillImageId(newVal) {
        const oldVal = this.$data.infillImageId;
        this.$data.infillImageId = newVal;
        self.app.events.emit(
            "update-infill-image-id", newVal, oldVal
        );
    },

    getFirstInfillImageId: classUtils.getter,
    setFirstInfillImageId(newVal) {
        const oldVal = this.$data.firstInfillImageId;
        this.$data.firstInfillImageId = newVal;
        self.app.events.emit(
            "update-infill-image-id", newVal, oldVal
        );
    },

    getSecondInfillImageId: classUtils.getter,
    setSecondInfillImageId(newVal) {
        const oldVal = this.$data.secondInfillImageId;
        this.$data.secondInfillImageId = newVal;
        self.app.events.emit(
            "update-infill-image-id", newVal, oldVal
        );
    },

    getFirstInfillColor: classUtils.getter,
    setFirstInfillColor(newVal) {
        const oldVal = this.$data.firstInfillColor;
        this.$data.firstInfillColor = newVal;
        self.app.events.emit(
            "update-infill-image-id", newVal, oldVal
        );
    },

    getSecondInfillColor: classUtils.getter,
    setSecondInfillColor(newVal) {
        const oldVal = this.$data.secondInfillColor;
        this.$data.secondInfillColor = newVal;
        self.app.events.emit(
            "update-infill-image-id", newVal, oldVal
        );
    },

});

module.exports = OptionableMixin;
