<template>
    <div class="option-baseplate d-flex">
        <b-navbar-nav>
            <b-nav-item
                v-for="option in baseplateOptions"
                :key="option.ref"
                :disabled="currentBaseplateType.data === option.type"
            >
                <button
                    class="d-flex flex-column baseplate-option-button justify-content-end"
                    :disabled="currentBaseplateType.data === option.type"
                    @click="addOption(option.type)"
                >
                    <img
                        :src="`${config.thumbnailsUrl}/${option.thumbnail}`"
                        alt=""
                    >
                    <div class="ref w-100">
                        {{ option.ref }}
                    </div>
                </button>
            </b-nav-item>
        </b-navbar-nav>
        <!-- Modifiers -->
        <b-navbar-nav
            v-show="showEdit"
            class="top-menu-div edit-baseplate"
        >
            <b-nav-item>
                <swap-button
                    v-show="showSwap"
                    @click="swapOption()"
                />
            </b-nav-item>
            <b-nav-item>
                <remove-button @click="removeOption()" />
            </b-nav-item>
        </b-navbar-nav>
    </div>
</template>
<script>
import RemoveButton from "../buttons/remove-button.vue";
import SwapButton from "../buttons/swap-button.vue";

export default {
    name: "OptionBaseplate",
    components: {
        RemoveButton,
        SwapButton,
    },
    props: {
        config: {
            type: Object,
            default: () => ({}),
        },
        currentBaseplateType: {
            type: Object,
            default: () => ({}),
        },
        baseplateOptions: {
            type: Array,
            default: () => [],
        },
        showEdit: {
            type: Boolean,
            default: false,
        },
        showSwap: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        addOption(type) {
            this.$emit("addOption", type);
            this.$forceUpdate();
        },
        removeOption() {
            this.$emit("removeOption");
            this.$forceUpdate();
        },
        swapOption() {
            this.$emit("swapOption");
            this.$forceUpdate();
        },
    },
};
</script>
