<template>
    <div class="history-menu">
        <b-nav-item
            :class="{ 'disabled': isLast }"
            class="h-100 item-interactions"
            :link-classes="'p-4'"
            @click="back()"
        >
            <img
                v-if="isLast"
                class="icons"
                src="/assets/icons/undo-disabled.svg"
            >
            <img
                v-else
                class="icons"
                src="/assets/icons/undo.svg"
            >
        </b-nav-item>
        <b-nav-item
            :class="{ 'disabled': isFirst }"
            class="h-100 item-interactions"
            :link-classes="'p-4'"
            @click="forward()"
        >
            <img
                v-if="isFirst"
                class="icons"
                src="/assets/icons/redo-disabled.svg"
            >
            <img
                v-else
                class="icons"
                src="/assets/icons/redo.svg"
            >
        </b-nav-item>
    </div>
</template>

<script>
import self from "../../index";

const {
    history,
    selectionManager,
    entityManager: {
        Controller: entityController,
    },
} = self.app.modules;

export default {
    name: "History",
    data() {
        return {
            isFirst: history.isFirst(),
            isLast: history.isLast(),
        };
    },
    mounted() {
        [
            "@history.history-snapshot",
            "@history.history-go",
            "@history.history-clear",
        ].forEach((event) => {
            self.app.events.on(event, () => {
                this.updateButtons();
            });
        });
    },
    methods:
        {
            back() {
                entityController.freezeAll(false, false);
                selectionManager.unselectAll();
                history.back();
            },
            forward() {
                entityController.freezeAll(false, false);
                selectionManager.unselectAll();
                history.forward();
            },
            updateButtons() {
                // isFirst and isLast are only getters, we can't set vueData so we need to
                // update informations on snapshot or on "go"
                this.isFirst = history.isFirst();
                this.isLast = history.isLast();
            },
        },
};
</script>

<style lang="scss">
@import "../../../../style/bematrix";
.history-menu {
    @extend .top-menu-div;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .icons {
        width: 13px;
        height: 13px;
        cursor: pointer;
    }

    a {
        display: flex;
        align-items: center;
        height: 100%;
    }
}
</style>
