/* eslint-disable max-len */
const config = {
    language: "fr_FR",
    version: process.env.VERSION,
    step: 0.031, // Global grid step
    angleStep45: Math.PI / 4, // 45° in  radian
    angleStep5: 0.08726646, // 5° in radian
    websiteUrl: "",
    amazonS3Url: "",
    projectsUrl: "",
    catalogUrl: "",
    templatesUrl: "",
    groupsObjectsUrl: "",
    projectName: "Mon super projet",
    fileType: ".obj",
    infillsImg: "[]",
    unitMode: "eu",
    versionName: "",
    token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImJjaGFyYm9ubmllckB3YW5hZGV2LmZyIiwicm9sZXMiOlsiZGVzaWduZXIiLCJmaW5hbmNlIiwiYWRtaW5fYmVtYXRyaXgiXX0.u4I1yrfG69F12w2Zvlj6r5l-opxLAk9RxKi_lMbXMvU",
};

export default config;
