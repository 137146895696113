<template>
    <b-nav-item-dropdown
        right
        no-caret
        class="camera-menu"
        @click="toggleDropdown()"
    >
        <!-- Change button content to an image -->
        <template slot="button-content">
            <img src="/assets/icons/camera.svg">
            <span class="custom-text">{{ _("Camera") }}</span>
            <img
                :class="{ open: isOpen }"
                src="/assets/icons/dropdown-black.svg"
            >
        </template>
        <b-dropdown-item @click="emitDefaultView()">
            {{ _("View 3D") }}
        </b-dropdown-item>
        <b-dropdown-item @click="emitTopView()">
            {{ _("View 2D") }}
        </b-dropdown-item>
        <b-dropdown-item @click="emitSideView()">
            {{ _("Side View") }}
        </b-dropdown-item>
        <b-dropdown-item @click="orbit()">
            {{ _("Orbit View") }}
        </b-dropdown-item>
        <b-dropdown-item @click="changeMode()">
            <template v-if="cameraData.orthographic">
                {{ _("Perspective") }}
            </template>
            <template v-else>
                {{ _("Orthographic") }}
            </template>
        </b-dropdown-item>
        <template v-if="debugCamera">
            <b-dropdown-divider />
            <b-dropdown-item @click="debugCurrentCamera()">
                DEBUG: Print camera infos
            </b-dropdown-item>
            <b-dropdown-item @click="setDebugView()">
                DEBUG: Set debug view
            </b-dropdown-item>
        </template>
    </b-nav-item-dropdown>
</template>

<script>
import self from "../../index";

export default {
    name: "Camera",
    props: {
        cameraData: {
            type: Object,
            default: () => ({
                orthographic: false,
            }),
        },
    },
    data() {
        return {
            isOpen: false,
            cameraName: "Free camera",
            debugCamera: false,
        };
    },
    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },

        // Functions to change the camera view
        emitDefaultView() {
            self.app.modules.cameraManager.controller.changeView(
                self.app.modules.cameraManager.CameraViews.DEFAULT_VIEW
            );
        },
        emitTopView() {
            self.app.modules.cameraManager.controller.changeView(
                self.app.modules.cameraManager.CameraViews.TOP_VIEW
            );
        },
        emitFrontView() {
            self.app.modules.cameraManager.controller.changeView(
                self.app.modules.cameraManager.CameraViews.FRONT_VIEW
            );
        },
        emitSideView() {
            self.app.modules.cameraManager.controller.changeView(
                self.app.modules.cameraManager.CameraViews.SIDE_VIEW
            );
        },

        /**
         * Makes the camera orbiting
         */
        orbit() {
            self.app.modules.cameraManager.controller.updateOrbiting();
        },
        changeMode() {
            self.app.modules.cameraManager.controller.changeMode();
        },
        changeCam() {
            self.app.modules.cameraManager.controller.changeCamera();
            this.cameraName = this._(
                self.app.modules.cameraManager.controller.getCameraName()
            );
        },

        // DEBUG FUNCTIONS
        debugCurrentCamera() {
            self.app.modules.cameraManager.controller.debugCurrentCamera();
        },
        setDebugView() {
            self.app.modules.cameraManager.controller.setDebugView();
        },
        _(...args) {
            return self.app.modules.stonejs.gettext(...args);
        },
    },
};
</script>

<style lang="scss">
@import "../../../../style/bematrix";

.camera-menu {
    @extend .top-menu-div;

    height: 100%;
    font-family: $font-medium;

    .custom-text {
        font-size: 0.85em;
        color: #66676a;
    }

    // sry
    > a {
        padding: 0 1.5rem !important;
    }

    span {
        padding: 0 0.5rem;
    }

    img {
        &.open {
            transform: rotate(180deg);
        }
    }

    .dropdown-menu {
        border-radius: 0;
        margin: 0;
    }

    .dropdown-item {
        font-family: $font-regular;
        font-size: 0.8em;

        &:hover {
            background-color: $pale-grey-hover;
        }

        &:active {
            background-color: $pale-grey-click;
        }
    }
}
</style>
