export default {
    name: "swap-manager",
    requires: [
        "collision-manager",
        "geometry-utility",
        "project-manager",
        "group-manager",
        "selection-manager",
        "mesh-manager",
        "history",
        "entity-manager",
        "option-manager",
        "gui-manager",
        "gizmo-manager",
        "obsidian-engine",
        "catalog-manager",
    ],
    load() {
        const SwapManager = require("./src/controller/swap-manager").default;
        return new SwapManager();
    },
    unload() { },
};
