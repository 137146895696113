<template>
    <div
        id="tools-menu"
        class="menu"
    >
        <div
            v-if="canGenerateCoverPlacement"
            class="rounded-button"
            :disabled="!hasSelection"
            @click.stop="openCoversMenu"
        >
            <span class="icon">
                <img src="/assets/icons/calculate.svg">
            </span>
            <span class="tools-button-text">{{ _("Generate covers") }}</span>
        </div>

        <!-- Hidden select menu when adding covers -->
        <ul
            v-if="coversMenuOpened"
            v-click-outside="closeCoversMenu"
            class="covers-select-menu"
            tabindex="0"
            @blur="closeCoversMenu"
            @focusout="closeCoversMenu"
        >
            <li @click.stop="generateCovers()">
                {{ _("Standard Covers") }}
            </li>
            <li @click.stop="generateCovers(1)">
                {{ _("Perfect Covers") }}
            </li>
            <li @click.stop="generateCovers(2)">
                {{ _("Rounded Covers") }}
            </li>
        </ul>
        <!-- end of menu -->

        <div
            v-if="canCalculateConnectors"
            class="rounded-button"
            @click="calculateConnectors()"
        >
            <span class="icon">
                <img src="/assets/icons/calculate.svg">
            </span>
            <span class="tools-button-text">{{ _("Calculate connectors") }}</span>
        </div>
        <div
            v-if="canGeneratePanelPlan"
            class="rounded-button"
            @click="generatePanelPlan()"
        >
            <span class="icon">
                <img src="/assets/icons/generate-3-d.svg">
            </span>
            <span class="tools-button-text">{{ _("Generate panel plan") }}</span>
        </div>
        <div
            v-if="canCreateBuildingPlan"
            class="rounded-button"
            @click="pdfMode()"
        >
            <span class="icon">
                <img src="/assets/icons/combined-shape.svg">
            </span>
            <span class="tools-button-text">{{ _("Create building plan") }}</span>
        </div>
        <div
            v-if="canRequestQuote"
            class="rounded-button quote-button"
            @click="requestQuote()"
        >
            <span class="icon">
                <img src="/assets/icons/request-quote.svg">
            </span>
            <span class="tools-button-text">{{ _("Request quote") }}</span>
        </div>
    </div>
</template>

<script>
import RightsHelper from "helpers/rights-helper";
import self from "../../index";
import EventBus from "../eventBus";

const {
    events,
    modules: {
        projectManager: {
            controller: projectController,
        },
        selectionManager: selectionController,
        buildingPlanManager: {
            buildingPlanController: bpController,
        },
        stonejs,

    },
} = self.app;

export default {
    name: "Tools",
    data() {
        return {
            coversMenuOpened: false,
            selectionController,
        };
    },
    computed: {
        canGenerateCoverPlacement() {
            return true;
        },
        canCalculateConnectors() {
            return RightsHelper.isRoleDesigner() && RightsHelper.isPlanEssential()
                && RightsHelper.isMode3dAuthenticated();
        },
        canGeneratePanelPlan() {
            return RightsHelper.isRoleDesigner() && RightsHelper.isPlanEssential()
                && RightsHelper.isMode3dAuthenticated();
        },
        canCreateBuildingPlan() {
            return RightsHelper.isRoleDesigner() && RightsHelper.isPlanEssential()
                && RightsHelper.isMode3dAuthenticated();
        },
        canRequestQuote() {
            return RightsHelper.isRoleFinance() && RightsHelper.isMode3dAuthenticated();
        },
        hasSelection() {
            return this.selectionController.hasSelection();
        },
    },
    methods: {
        openCoversMenu() {
            if (!this.hasSelection) {
                return;
            }
            this.coversMenuOpened = true;
        },
        closeCoversMenu() {
            this.coversMenuOpened = false;
        },
        requestQuote() {
            events.emit("lockUnload");
            events.emit("checkVersion", "quotation-request");
        },
        pdfMode() {
            bpController.togglePdfMode();
            // Avoid UI shift if we go in pdf mode while catalog is open
            EventBus.$emit("hide-products");
            selectionController.unselectAll();
        },
        calculateConnectors() {
            projectController.requestConnectorGeneration();
        },
        generatePanelPlan() {
            events.emit("checkVersion", "generate-panelplan");
        },
        generateCovers(coverType = 0) {
            projectController.requestCoverAddAutomatically(coverType);
            this.closeCoversMenu();
        },
        print() {
            window.print();
        },
        _(...args) {
            return stonejs.gettext(...args);
        },
    },
};
</script>
