import { Quaternion } from "@babylonjs/core";

const SerializableClass = require("abitbol-serializable");

SerializableClass.$addSerializer("babylon-quaternion", {

    class: Quaternion,

    serialize(quaternion) {
        return {
            x: quaternion.x,
            y: quaternion.y,
            z: quaternion.z,
            w: quaternion.w,
        };
    },

    unserialize(data) {
        return new Quaternion(data.x, data.y, data.z, data.w);
    },

});
