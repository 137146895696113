export const RESIZABLE_CONSTRAINTS_TEMPLATES = {
    BACKLED: {
        MIN_HEIGHT: Infinity,
        MAX_HEIGHT: 0,
        ONE_AXIS: true,
    },
    CORNER: {
        MIN_DEPTH: Infinity,
        MAX_DEPTH: 0,
        ONE_AXIS: true,
    },
    COVER: {
        MIN_DEPTH: Infinity,
        MAX_DEPTH: 0,
        ONE_AXIS: true,
    },
    CURVED: {
        MIN_HEIGHT: Infinity,
        MAX_HEIGHT: 0,
        ONE_AXIS: true,
    },
    STRUCTURAL: {
        MIN_DEPTH: Infinity,
        MAX_DEPTH: 0,
        ONE_AXIS: true,
    },
    PERFECT: {
        MIN_HEIGHT: Infinity,
        MAX_HEIGHT: 0,
        ONE_AXIS: true,
    },
    GLASS: {
        MIN_HEIGHT: Infinity,
        MIN_WIDTH: Infinity,
        MAX_HEIGHT: 0,
        MAX_WIDTH: 0,
    },
    SIDELED: {
        MIN_HEIGHT: Infinity,
        MIN_WIDTH: Infinity,
        MAX_HEIGHT: 0,
        MAX_WIDTH: 0,
        ONE_AXIS: true,
    },
    STRAIGHT: {
        MIN_HEIGHT: Infinity,
        MIN_WIDTH: Infinity,
        MAX_HEIGHT: 0,
        MAX_WIDTH: 0,
    },
};
