import { Quaternion, Vector3 } from "@babylonjs/core";

import self from "../..";

// Serializers for babylon object
require("../model/serializers/babylon-quaternion");
require("../model/serializers/babylon-vector3");

const { PRODUCT_ENTITIES_NAME } = require("../helpers/entity-helper");

const {
    app: {
        modules: {
            dataStore,
            catalogManager,
            meshManager: {
                meshController,
            },
            geometryUtility,
        },
        events: {
            on,
            emit,
        },
        log,
    },
} = self;

if (process.env.NODE_ENV === "development") window.dataStore = dataStore;

const EntityController = {

    /**
     * Instanciate the mesh of the added entity if this one is a
     * "meshable" entity (frame, connector, parts ...)
     * @param {*} entity
     */
    onEntityAdded(entity) {
        if (PRODUCT_ENTITIES_NAME.includes(entity.__name__)) {
            try {
                this.checkMeshAvailability(entity).catch(
                    error => {
                        log.error("Error while instancing mesh ", entity.ref, ": ", error);
                    }
                );
            } catch (err) {
                log.error("Error while instancing mesh ", entity.ref, ": ", err);
            }
        }

        if (entity.isEntity) {
            this.handleDynamicRalFinishing(entity);
        }
    },

    /**
     * Called when we go back or go forward in the history
     * Handle if the some serialized value in entity changed but not in its mesh
     */
    onHistoryGo() {
        const products = dataStore.listEntities("/products/default");
        products.forEach(
            product => {
                if (!product.mesh) {
                    return;
                }
                if (product.visible === product.mesh.isVisible) {
                    return;
                }
                // Update the entity's mesh visibility after history go
                geometryUtility
                    .toggleMeshVisibility(product.mesh, product.visible);
                emit("history-change-visibility", product.mesh);
            }
        );

        this.freezeAll();
    },

    /**
     * Called when we instanciate the mesh of a given entity
     * @param {*} entity
     */
    fetchMesh(entity) {
        if (entity.mesh) {
            return;
        }
        // QUICKFIX !!!!! This must be changed it's only a temporary fix for retrocompatibility
        if (entity.materialId === "ano") {
            if (entity.__name__ === "frame" || entity.__name__ === "door") {
                entity.setMaterialId("frames-ano");
            } else if (entity.__name__ === "connector") {
                entity.setMaterialId("connectors-ano");
            }
        }
        // ENDOF QUICKFIX !!!

        entity.mesh = meshController.getMeshFromLoadedGeometry(
            entity.ref,
            entity.materialId,
            null,
            {
                category: entity.getCategory(),
                subCategory: entity.getSubCategory(),
                ref: entity.ref,
            }
        );
        if (!entity.initializing) {
            const position = new Vector3(entity.position.x,
                entity.position.y,
                entity.position.z);
            const rotationQuaternion = new Quaternion(
                entity.rotationQuaternion.x,
                entity.rotationQuaternion.y,
                entity.rotationQuaternion.z,
                entity.rotationQuaternion.w
            );
            entity.mesh.position = position;
            entity.mesh.rotationQuaternion = rotationQuaternion;
        } else {
            entity.setPosition(new Vector3(
                0,
                entity.getBoundingBox().extendSizeWorld.y,
                0
            ));
            entity.setRotationQuaternion(Quaternion.Identity());
            entity.handleInitialRotation();
            entity.mesh.computeWorldMatrix(true);
            entity.initializing = false;
        }
        const boundingInfo = entity.getBoundingBox();
        entity.height = Math.abs(boundingInfo.maximumWorld.y - boundingInfo.minimumWorld.y);
        entity.width = Math.abs(boundingInfo.maximumWorld.x - boundingInfo.minimumWorld.x);
        entity.depth = Math.abs(boundingInfo.maximumWorld.z - boundingInfo.minimumWorld.z);
        entity.mesh.entity = entity;
        entity.mesh.computeWorldMatrix(true);
        entity.mesh.checkCollisions = true;
        geometryUtility
            .toggleMeshVisibility(entity.mesh, entity.visible);
        emit("mesh-fetched", entity);
    },

    /**
     * Check if an entity needs to register its mesh geometry first before
     * fetching it
     * @param {*} entity
     */
    checkMeshAvailability(entity) {
        return catalogManager.tryRegisterMeshGeometry(entity.ref).then(
            meshInfos => {
                if (meshInfos) {
                    this.fetchMesh(entity);
                }
            }
        );
    },

    /**
     * Modify the passed entity material depending on the product ral finish value
     * @param {*} entity
     */
    handleDynamicRalFinishing(entity) {
        const currentProduct = catalogManager.products[entity.ref];
        const notRalFinishes = Object.keys(currentProduct).filter(
            finishingKey => finishingKey.includes("finishing") && !finishingKey.includes("Ral")
        );
        const ralOnly = notRalFinishes.every(finishingKey => !currentProduct[finishingKey])
            && currentProduct.finishingRal;
        entity.isColorable = currentProduct.finishingRal;

        if (entity.isColorable) {
            entity.initColor(ralOnly);
        }

        // Not colorable anymore
        if (entity.color && !entity.isColorable) {
            entity.setEcoMaterial();
        }
    },

    /**
     * freeze world matrixes of all entities and connectors
     * @param {Boolean} freezeEntities=true
     * @param {Boolean} freezeConnectors=true
     */
    freezeAll(freezeEntities = true, freezeConnectors = true) {
        if (freezeEntities) {
            const entities = dataStore.listEntities("/products/default");
            entities.forEach(e => {
                e.freeze();
            });
        }
        if (freezeConnectors) {
            const connectors = dataStore.listEntities("/connectors/default");
            connectors.forEach(c => {
                c.freeze();
            });
        }
    },
};

const initEvents = () => {
    on("need-mesh-refresh",
        entity => {
            EntityController.fetchMesh(entity);
        });

    on("@data-store.entity-added",
        entity => {
            EntityController.onEntityAdded(entity);
        });
    on("@data-store.entity-removed",
        entity => {
            if (PRODUCT_ENTITIES_NAME.includes(entity.__name__)) {
                entity.destroy();
            }
        });
    on("@project-manager.project-loaded",
        () => {
            EntityController.freezeAll();
        });
    on("@project-manager.connectors-generated",
        () => {
            EntityController.freezeAll(false);
        });
    on("@history.history-go",
        () => {
            EntityController.onHistoryGo();
        });
};

// Initialise the pseudo controller
initEvents();

export default EntityController;
