import {
    Vector3, TransformNode, Quaternion, Matrix,
} from "@babylonjs/core";

import self from "../..";

const {
    events, log, modules: {
        obsidianEngine: {
            controller: engineController,
        },
    },
} = self.app;

/**
 * Class that represent a measurement point
 */
class MeasurementPoint {

    constructor(node, mesh) {
        // The transform node with the correct point position
        this.transformNode = node;
        // The mesh attached to the point
        this.attachedMesh = mesh;
        // The point mesh (wich is a sphere), created only when we activate the measurement mode
        this.pointMesh = null;
    }

}


const MeasurableEntityHelper = {

    /**
     * Check entity's type (ref, category, subcategory) and compute it's measurement point
     * with the most adapted rule
     * @param {*} entity
     */
    ComputeObjectMeasurementPoints(entity) {
        if (!entity.isMeasurable) {
            const err = new Error(`You are trying to compute measurement points from unmeasurable
                entity of ref ${entity.ref} and name ${entity.__name__}`);
            log.error(err);
            throw err;
        }

        // STRAIGHT OBJECTS
        if (entity.subCategory === "STRAIGHT FRAMES" || entity.category === "LIGHTBOXES"
            || entity.category === "DOOR FRAMES" || (entity.category === "LEDSKIN"
                && entity.ref.startsWith("200"))) {
            MeasurableEntityHelper._ComputeStraightObjectsPointsFromRef(entity);

        } else if (entity.category === "LEDSKIN" && entity.ref.startsWith("288")) {
            // CURVED OBJECT CENTERED
            MeasurableEntityHelper._ComputeCurvedObjectPointsFromRefAndCenter(entity);

        } else if (entity.subCategory === "CURVED FRAMES") {
            // CURVED OBJECT THAT HAS GOOD SNAPPY FACES
            MeasurableEntityHelper._ComputeCurvedObjectPointsFromSnappyRectangles(entity);

        } else if (entity.subCategory === "PERFECT CORNERS"
            && (entity.ref.startsWith("691") || entity.ref.startsWith("671"))
        ) {
            // PERFECT U OBJECTS
            MeasurableEntityHelper._ComputeUCornersFramesPointsFromRef(entity);

        } else if ((entity.subCategory === "PERFECT CORNERS"
                && (entity.ref.startsWith("690") || entity.ref.startsWith("670"))
        ) || (entity.subCategory === "LEDSKIN" && entity.ref.startsWith("290"))) {
            // PERFECT L OBJECTS
            MeasurableEntityHelper._ComputeLCornersFramesPointsFromRef(entity);

        } else {
            // DEFAULT FUNCTION
            MeasurableEntityHelper._ComputeDefaultObjectPoints(entity);
        }
    },

    /**
     * Compute measurement points for straight object based on BoundingBox size
     * WARNING : The width and height don't take account of the frame orientation
     * if the frame is rotated by 90° the height and width will be reversed cause it
     * depends of the width and height of the BB
     * @param {*} entity
     */
    _ComputeStraightObjectsPointsFromBB(entity) {
        const pointA = new TransformNode("measurementPointA", engineController.scene);
        pointA.position = new Vector3(entity.width / 2, entity.height / 2, entity.depth / 2);
        pointA.parent = entity.mesh;

        const pointB = new TransformNode("measurementPointB", engineController.scene);
        pointB.position = new Vector3(entity.width / 2, entity.height / 2, -entity.depth / 2);
        pointB.parent = entity.mesh;


        const pointC = new TransformNode("measurementPointC", engineController.scene);
        pointC.position = new Vector3(-entity.width / 2, entity.height / 2, entity.depth / 2);
        pointC.parent = entity.mesh;


        const pointD = new TransformNode("measurementPointD", engineController.scene);
        pointD.position = new Vector3(-entity.width / 2, entity.height / 2, -entity.depth / 2);
        pointD.parent = entity.mesh;


        const pointE = new TransformNode("measurementPointE", engineController.scene);
        pointE.position = new Vector3(entity.width / 2, -entity.height / 2, entity.depth / 2);
        pointE.parent = entity.mesh;


        const pointF = new TransformNode("measurementPointF", engineController.scene);
        pointF.position = new Vector3(entity.width / 2, -entity.height / 2, -entity.depth / 2);
        pointF.parent = entity.mesh;

        const pointG = new TransformNode("measurementPointG", engineController.scene);
        pointG.position = new Vector3(-entity.width / 2, -entity.height / 2, -entity.depth / 2);
        pointG.parent = entity.mesh;

        const pointH = new TransformNode("measurementPointH", engineController.scene);
        pointH.position = new Vector3(-entity.width / 2, -entity.height / 2, entity.depth / 2);
        pointH.parent = entity.mesh;


        const points = [pointA, pointB, pointC, pointD, pointE, pointF, pointG, pointH];
        entity.measurementPoints = points.map(
            point => new MeasurementPoint(point, entity.mesh)
        );
    },

    /**
     * Compute measurement points for straight object based on ref size
     * @param {*} entity
     */
    _ComputeStraightObjectsPointsFromRef(entity) {
        const splittedRef = entity.ref.split(" ");
        const width = Number.parseInt(splittedRef[1], 10) * 0.001;
        const height = Number.parseInt(splittedRef[2], 10) * 0.001;
        const depth = 0.062;

        const pointA = new TransformNode("measurementPointA", engineController.scene);
        pointA.parent = entity.mesh;
        pointA.position = new Vector3(width / 2, height / 2, depth / 2);


        const pointB = new TransformNode("measurementPointB", engineController.scene);
        pointB.parent = entity.mesh;
        pointB.position = new Vector3(width / 2, height / 2, -depth / 2);


        const pointC = new TransformNode("measurementPointC", engineController.scene);
        pointC.parent = entity.mesh;
        pointC.position = new Vector3(-width / 2, height / 2, depth / 2);


        const pointD = new TransformNode("measurementPointD", engineController.scene);
        pointD.parent = entity.mesh;
        pointD.position = new Vector3(-width / 2, height / 2, -depth / 2);


        const pointE = new TransformNode("measurementPointE", engineController.scene);
        pointE.parent = entity.mesh;
        pointE.position = new Vector3(width / 2, -height / 2, depth / 2);


        const pointF = new TransformNode("measurementPointF", engineController.scene);
        pointF.parent = entity.mesh;
        pointF.position = new Vector3(width / 2, -height / 2, -depth / 2);

        const pointG = new TransformNode("measurementPointG", engineController.scene);
        pointG.parent = entity.mesh;
        pointG.position = new Vector3(-width / 2, -height / 2, -depth / 2);

        const pointH = new TransformNode("measurementPointH", engineController.scene);
        pointH.parent = entity.mesh;
        pointH.position = new Vector3(-width / 2, -height / 2, depth / 2);


        const points = [pointA, pointB, pointC, pointD, pointE, pointF, pointG, pointH];
        entity.measurementPoints = points.map(
            point => new MeasurementPoint(point, entity.mesh)
        );
    },

    /**
     * Compute measurement points for curved object based on center and ref info
     * WARNING : This methods don't work if the curved object is not centered by the center
     * of the circle it's part of.
     * @param {*} entity
     */
    _ComputeCurvedObjectPointsFromRefAndCenter(entity) {
        const splittedRef = entity.ref.split(" ");
        const centerDistance = Number.parseInt(splittedRef[1], 10) * 0.001;
        const height = Number.parseInt(splittedRef[2], 10) * 0.001;
        const depth = 0.062;
        const pointA = new TransformNode("measurementPointA", engineController.scene);
        pointA.parent = entity.mesh;
        pointA.position = new Vector3(centerDistance, height / 2, 0);


        const pointB = new TransformNode("measurementPointB", engineController.scene);
        pointB.parent = entity.mesh;
        pointB.position = new Vector3(centerDistance - depth, height / 2, 0);


        const pointC = new TransformNode("measurementPointC", engineController.scene);
        pointC.parent = entity.mesh;
        pointC.position = new Vector3(0, height / 2, centerDistance - depth);


        const pointD = new TransformNode("measurementPointD", engineController.scene);
        pointD.parent = entity.mesh;
        pointD.position = new Vector3(0, height / 2, centerDistance);

        const pointE = new TransformNode("measurementPointE", engineController.scene);
        pointE.parent = entity.mesh;
        pointE.position = new Vector3(0, -height / 2, centerDistance - depth);

        const pointF = new TransformNode("measurementPointF", engineController.scene);
        pointF.parent = entity.mesh;
        pointF.position = new Vector3(0, -height / 2, centerDistance);

        const pointG = new TransformNode("measurementPointG", engineController.scene);
        pointG.parent = entity.mesh;
        pointG.position = new Vector3(centerDistance, -height / 2, 0);

        const pointH = new TransformNode("measurementPointH", engineController.scene);
        pointH.parent = entity.mesh;
        pointH.position = new Vector3(centerDistance - depth, -height / 2, 0);


        const points = [pointA, pointB, pointC, pointD, pointE, pointF, pointG, pointH];
        entity.measurementPoints = points.map(
            point => new MeasurementPoint(point, entity.mesh)
        );
    },

    /**
     * Compute measurement points for curved object based on snappy faces
     * Using this type of function with other object can be tricky if it has more snappy faces
     * than needed.
     * @param {*} entity
     */
    _ComputeCurvedObjectPointsFromSnappyRectangles(entity) {
        let measurementPoints = [];
        // Create a callback cause snappy rect can be created after mesh fetched so we need to wait
        // for the event
        const measurementPointGenerationCallback = () => {
            entity.snappyRects.forEach(
                (snapRectangle) => {
                    const width = snapRectangle.width;
                    const height = snapRectangle.height;
                    snapRectangle.computeWorldMatrix(true);

                    // Compute the transform to go from snappy rect space to mesh space
                    entity.mesh.computeWorldMatrix(true);
                    const meshLocalMatrix = new Matrix();
                    entity.mesh.getWorldMatrix().invertToRef(meshLocalMatrix);
                    snapRectangle.computeWorldMatrix(true);
                    const snapRectangleWorldMatrix = snapRectangle.getWorldMatrix();

                    // The transform we need
                    const snapToMesh = snapRectangleWorldMatrix.multiply(meshLocalMatrix);

                    const pointA = new TransformNode("measurementPointA", engineController.scene);
                    const pointB = new TransformNode("measurementPointB", engineController.scene);
                    const pointC = new TransformNode("measurementPointC", engineController.scene);
                    const pointD = new TransformNode("measurementPointD", engineController.scene);

                    const tmpPoints = [pointA, pointB, pointC, pointD];
                    tmpPoints.forEach(
                        (point) => {
                            point.parent = snapRectangle;
                        }
                    );

                    // Set our points position in snappy rectangle space
                    pointA.position = new Vector3(width / 2, height / 2, 0);

                    pointB.position = new Vector3(-width / 2, height / 2, 0);

                    pointC.position = new Vector3(-width / 2, -height / 2, 0);

                    pointD.position = new Vector3(width / 2, -height / 2, 0);

                    measurementPoints = measurementPoints.concat(tmpPoints.map(
                        (point) => {
                            point.computeWorldMatrix(true);
                            const quat = point.rotationQuaternion
                                || Quaternion.FromEulerAngles(
                                    point.rotation.x,
                                    point.rotation.y,
                                    point.rotation.z
                                );
                            // Create the point local matrix;
                            const pointLocalMatrix = Matrix.Compose(point.scaling,
                                quat,
                                point.position);
                            // Transform point from snappy rect space to mesh space
                            const newTransform = pointLocalMatrix.multiply(snapToMesh);
                            const newScale = new Vector3();
                            const newRot = new Vector3();
                            const newPos = new Vector3();
                            newTransform.decompose(newScale, newRot, newPos);
                            point.parent = entity.mesh;
                            point.position = newPos;
                            point.rotationQuaternion = newRot;
                            point.scale = newScale;
                            point.computeWorldMatrix(true);
                            return new MeasurementPoint(point, entity.mesh);
                        }
                    ));
                }
            );
            entity.measurementPoints = measurementPoints;
        };
        if (!entity.snappyRects.length) {
            // The snappy rectangles are not generated yet, we need to wait for the event emitted
            // once they are generated
            const snappyRectCallback = (eventEntity) => {
                if (eventEntity.id === entity.id) {
                    measurementPointGenerationCallback(eventEntity);
                    events.removeListener("@snapping.snappy-rect-generated", snappyRectCallback);
                }
            };
            events.on("@snapping.snappy-rect-generated", snappyRectCallback);
        } else {
            measurementPointGenerationCallback(entity);
        }
    },

    /**
     * Compute U corners object measurement points based on ref size
     * @param {*} entity
     */
    _ComputeUCornersFramesPointsFromRef(entity) {
        const splittedRef = entity.ref.split(" ");

        const height = Number.parseInt(splittedRef[2], 10) * 0.001;
        const depth = Number.parseInt(splittedRef[1], 10) * 0.001;
        const width = 0.496;

        const pointA = new TransformNode("measurementPointA", engineController.scene);
        pointA.parent = entity.mesh;
        pointA.position = new Vector3(width / 2, height / 2, -depth);

        const pointB = new TransformNode("measurementPointB", engineController.scene);
        pointB.parent = entity.mesh;
        pointB.position = new Vector3(width / 2 - 0.062, height / 2, -depth);


        const pointC = new TransformNode("measurementPointC", engineController.scene);
        pointC.parent = entity.mesh;
        pointC.position = new Vector3(width / 2, -height / 2, -depth);

        const pointD = new TransformNode("measurementPointD", engineController.scene);
        pointD.parent = entity.mesh;
        pointD.position = new Vector3(width / 2 - 0.062, -height / 2, -depth);


        const pointE = new TransformNode("measurementPointE", engineController.scene);
        pointE.parent = entity.mesh;
        pointE.position = new Vector3(-width / 2, height / 2, -depth);

        const pointF = new TransformNode("measurementPointF", engineController.scene);
        pointF.parent = entity.mesh;
        pointF.position = new Vector3(-width / 2 + 0.062, height / 2, -depth);


        const pointG = new TransformNode("measurementPointG", engineController.scene);
        pointG.parent = entity.mesh;
        pointG.position = new Vector3(-width / 2, -height / 2, -depth);

        const pointH = new TransformNode("measurementPointH", engineController.scene);
        pointH.parent = entity.mesh;
        pointH.position = new Vector3(-width / 2 + 0.062, -height / 2, -depth);

        const pointI = new TransformNode("measurementPointI", engineController.scene);
        pointI.parent = entity.mesh;
        pointI.position = new Vector3(-width / 2, height / 2, 0);

        const pointJ = new TransformNode("measurementPointJ", engineController.scene);
        pointJ.parent = entity.mesh;
        pointJ.position = new Vector3(width / 2, height / 2, 0);

        const pointK = new TransformNode("measurementPointK", engineController.scene);
        pointK.parent = entity.mesh;
        pointK.position = new Vector3(-width / 2, -height / 2, 0);

        const pointL = new TransformNode("measurementPointL", engineController.scene);
        pointL.parent = entity.mesh;
        pointL.position = new Vector3(width / 2, -height / 2, 0);

        const points = [pointA, pointB, pointC, pointD, pointE, pointF, pointG, pointH, pointI,
            pointJ, pointK, pointL];

        entity.measurementPoints = points.map(
            point => new MeasurementPoint(point, entity.mesh)
        );
    },

    /**
     * Compute L corners object measurement points based on ref size
     * @param {*} entity
     */
    _ComputeLCornersFramesPointsFromRef(entity) {
        const splittedRef = entity.ref.split(" ");

        const height = Number.parseInt(splittedRef[2], 10) * 0.001;
        const depth = Number.parseInt(splittedRef[1], 10) * 0.001;

        const pointA = new TransformNode("measurementPointA", engineController.scene);
        pointA.parent = entity.mesh;
        pointA.position = new Vector3(depth, height / 2, 0);

        const pointB = new TransformNode("measurementPointB", engineController.scene);
        pointB.parent = entity.mesh;
        pointB.position = new Vector3(depth - 0.062, height / 2, 0);


        const pointC = new TransformNode("measurementPointC", engineController.scene);
        pointC.parent = entity.mesh;
        pointC.position = new Vector3(depth, -height / 2, 0);

        const pointD = new TransformNode("measurementPointD", engineController.scene);
        pointD.parent = entity.mesh;
        pointD.position = new Vector3(depth - 0.062, -height / 2, 0);

        const pointE = new TransformNode("measurementPointE", engineController.scene);
        pointE.parent = entity.mesh;
        pointE.position = new Vector3(0, height / 2, depth);

        const pointF = new TransformNode("measurementPointF", engineController.scene);
        pointF.parent = entity.mesh;
        pointF.position = new Vector3(0, height / 2, depth - 0.062);

        const pointG = new TransformNode("measurementPointG", engineController.scene);
        pointG.parent = entity.mesh;
        pointG.position = new Vector3(0, -height / 2, depth);

        const pointH = new TransformNode("measurementPointH", engineController.scene);
        pointH.parent = entity.mesh;
        pointH.position = new Vector3(0, -height / 2, depth - 0.062);

        const pointI = new TransformNode("measurementPointI", engineController.scene);
        pointI.parent = entity.mesh;
        pointI.position = new Vector3(depth, height / 2, depth);

        const pointJ = new TransformNode("measurementPointJ", engineController.scene);
        pointJ.parent = entity.mesh;
        pointJ.position = new Vector3(depth, -height / 2, depth);

        const points = [pointA, pointB, pointC, pointD, pointE, pointF, pointG, pointH, pointI,
            pointJ];

        entity.measurementPoints = points.map(
            point => new MeasurementPoint(point, entity.mesh)
        );
    },

    /**
     * Default function if the entity given don't match with specific rule
     * @param {*} entity
     */
    _ComputeDefaultObjectPoints(entity) {
        entity.measurementPoints = [];
    },
};

export default MeasurableEntityHelper;
