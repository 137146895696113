<template>
    <div class="popin-container">
        <div class="popin-header">
            <slot name="header">
                <h1>{{ title }}</h1>
            </slot>
            <img
                v-if="!isRequired"
                src="/assets/icons/close.svg"
                class="close"
                @click="close()"
            >
        </div>
        <form @submit="validate">
            <div class="popin-body">
                <slot name="body">
                    <span v-html="description" />
                </slot>
            </div>
            <div class="popin-footer">
                <slot name="footer">
                    <button
                        class="confirm"
                        type="submit"
                    >
                        {{ _("Confirm") }}
                    </button>
                    <button
                        v-if="!isRequired"
                        class="cancel"
                        @click="cancel($event)"
                    >
                        {{ _("Cancel") }}
                    </button>
                    <div style="clear: both;" />
                </slot>
            </div>
        </form>
    </div>
</template>
<script>
import self from "../../../index";

const {
    modules,
    events,
} = self.app;

export default {
    name: "DialogPopin",
    props: {
        title: {
            type: String,
            default: () => "",
        },
        description: {
            type: String,
            default: () => "",
        },
        onValidate: {
            type: Function,
            default: () => () => {},
        },
        onCancel: {
            type: Function,
            default: () => () => {},
        },
        isRequired: {
            type: Boolean,
            default: () => false,
        },
    },
    methods: {
        validate(e) {
            if (typeof this.onValidate === "function") {
                this.onValidate();
                this.$emit("hidePopin");
            }
            e.preventDefault();
            events.emit("popin-closed");
        },
        cancel(e) {
            e.preventDefault();
            this.$emit("hidePopin");
            if (typeof this.onCancel === "function") {
                this.onCancel();
            }
            events.emit("popin-closed");
        },
        close() {
            this.$emit("hidePopin");
            events.emit();
            events.emit("popin-closed");
        },
        _(...args) {
            return modules.stonejs.gettext(...args);
        },
    },
};
</script>
<style lang="scss">
@import "../../../../../style/bematrix";
.popin-container {
    width: 25%;
    margin: 0px auto;
    background-color: #fff;
    border-radius: 4px;
    transition: all 0.3s ease;
    position: relative;

    .popin-header {
        display: flex;
        width: 100%;
        border-radius: 4px 4px 0 0;
        padding: 1rem;
        background: $green;

        h1 {
            font-family: $font-semibold;
            font-size: 1.2em;
            color: #fff;
        }

        .close {
            margin-left: auto;
            right: 15px;
            top: 15px;
            cursor: pointer;
        }

    }

    .popin-body {
        font-family: $font-medium;
        font-size: 0.9em;
        padding: 1.5rem;
        line-height: 1.5em;

        .warning-info {
            color: $orange;
            line-height: 1.5em;
        }

        input {
            font-family: $font-medium;
            font-size: 0.9em;
            margin: 0 1em;
            margin-top: 1em;
            margin-bottom: 3em;
            height: 15%;
            &[type="checkbox"] {
                height:auto;
            }
        }

    }

    .popin-footer {
        padding: 1rem;

        button {
            width: 27%;
            font-family: $font-regular;
            font-size: 0.85em;
            border: 0px transparent;
            outline: 0px transparent;
            border-radius: 3px;
            float: right;
            margin-left: 1rem;
            padding: 0.5rem 0.75rem;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            &.confirm {
                background: $dark;
                color: white;

                &:hover {
                    background: $dark-hover;
                }

                &:active {
                    background: $dark-click;
                }
            }

            &.cancel {
                background: $pale-grey;
                color: $dark;

                &:hover {
                    background: $pale-grey-hover;
                }

                &:active {
                    background: $pale-grey-click;
                }
            }
        }
    }
}
</style>
