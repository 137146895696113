import {
    Tools, Mesh, Geometry, VertexData, BoundingInfo,
} from "@babylonjs/core";

import { saveAs } from "file-saver";
import { v4 as uuid } from "uuid";

const ObjHelper = {

    tempMeshes: [],

    scale: 1000,

    exportToObj(meshes, save = false, exportDescendants = true) {
        meshes.forEach((mesh) => {
            this.handleMesh(mesh);
            if (exportDescendants) {
                const children = mesh.getChildMeshes(false);
                children.forEach((childMesh) => {
                    this.handleMesh(childMesh);
                });
            }
        });

        const obj = ObjHelper.meshToObj(this.tempMeshes);

        if (save) {
            const blob = new Blob([obj], { type: "text/plain" });
            saveAs(blob, "scene.obj");
        }

        this.tempMeshes.forEach((tempMesh) => {
            tempMesh.dispose();
        });
        return obj;
    },


    /**
     * Copy of the instance geometry with transformations bakes into vertices
     * @param  {InstancedMesh} instance
     */
    handleMesh(instance) {
        const mesh = new Mesh(instance.name);
        const sourceGeom = instance.geometry ? instance.geometry : instance.sourceMesh.geometry;
        const geom = ObjHelper.copyGeometry(sourceGeom);

        geom.applyToMesh(mesh);

        // Apply transformations to the copied mesh
        mesh.parent = instance.parent;
        if (instance.rotationQuaternion) {
            mesh.rotationQuaternion = instance.rotationQuaternion.clone();
        } else {
            mesh.rotation = instance.rotation.clone();
        }
        mesh.scaling = instance.scaling.clone();
        mesh.setAbsolutePosition(instance.absolutePosition.clone());
        mesh.computeWorldMatrix(true);

        mesh.bakeCurrentTransformIntoVertices();
        mesh.parent = null;

        this.tempMeshes.push(mesh);
    },


    /* eslint-disable */

    /**
     * Export a mesh to Obj
     * The transform of the mesh must have been baked into its vertex before
     * @param  {Mesh} mesh [description]
     * @return {[type]}      [description]
     */
    meshToObj(mesh) {
        const output = [];
        let v = 1;
        for (let j = 0; j < mesh.length; j += 1) {
            output.push(`g ${mesh[j].name}`);
            output.push(`o ${mesh[j].name}`);
            const g = mesh[j].geometry;
            if (!g) {
                Tools.Warn("No geometry is present on the mesh");
                continue;
            }
            const trunkVerts = g.getVerticesData("position");
            const trunkNormals = g.getVerticesData("normal");
            const trunkUV = g.getVerticesData("uv");
            const trunkFaces = g.getIndices();
            let curV = 0;
            if (!trunkVerts || !trunkFaces) {
                Tools.Warn("There are no position vertices or indices on the mesh!");
                continue;
            }

            for (let i = 0; i < trunkVerts.length; i += 3) {
                output.push(`v ${-trunkVerts[i] * ObjHelper.scale} ${trunkVerts[i + 1] * ObjHelper.scale} ${trunkVerts[i + 2] * ObjHelper.scale}`);
                curV += 1;
            }
            if (trunkNormals != null) {
                for (let i = 0; i < trunkNormals.length; i += 3) {
                    output.push(`vn ${-trunkNormals[i]} ${trunkNormals[i + 1]} ${trunkNormals[i + 2]}`);
                }
            }
            if (trunkUV != null) {
                for (let i = 0; i < trunkUV.length; i += 2) {
                    output.push(`vt ${trunkUV[i]} ${trunkUV[i + 1]}`);
                }
            }
            for (let i = 0; i < trunkFaces.length; i += 3) {
                const indices = [String(trunkFaces[i + 2] + v),
                    String(trunkFaces[i + 1] + v),
                    String(trunkFaces[i] + v),
                ];
                const blanks = ["", "", ""];
                const facePositions = indices;
                const faceUVs = trunkUV != null ? indices : blanks;
                const faceNormals = trunkNormals != null ? indices : blanks;
                output.push(`f ${facePositions[0]}/${faceUVs[0]}/${faceNormals[0]
                } ${facePositions[1]}/${faceUVs[1]}/${faceNormals[1]
                } ${facePositions[2]}/${faceUVs[2]}/${faceNormals[2]}`);
            }
            v += curV;
        }
        const text = output.join("\n");
        return (text);
    },


    /**
   * Clone the current geometry into a new geometry
   * It's the same as the clone function of Geometry
   * but it includes this fix : https://github.com/BabylonJS/Babylon.js/pull/5643
   *
   * @param id defines the unique ID of the new geometry
   * @returns a new geometry object
   */
    copyGeometry(baseGeom) {
        const vertexData = new VertexData();
        vertexData.indices = [];
        const indices = baseGeom.getIndices();
        if (indices) {
            for (let index = 0; index < indices.length; index++) {
                vertexData.indices.push(indices[index]);
            }
        }
        let updatable = false;
        let stopChecking = false;
        let kind;
        Object.keys(baseGeom._vertexBuffers).forEach((kind) => {
            // using slice() to make a copy of the array and not just reference it
            const data = baseGeom.getVerticesData(kind);
            if (data) {
                if (data instanceof Float32Array) {
                    vertexData.set(new Float32Array(data), kind);
                } else {
                    vertexData.set((data).slice(0), kind);
                }
                if (!stopChecking) {
                    const vb = baseGeom.getVertexBuffer(kind);

                    if (vb) {
                        updatable = vb.isUpdatable();
                        stopChecking = !updatable;
                    }
                }
            }
        });
        const geometry = new Geometry(uuid(), baseGeom._scene, vertexData, updatable);
        geometry.delayLoadState = baseGeom.delayLoadState;
        geometry.delayLoadingFile = baseGeom.delayLoadingFile;
        geometry._delayLoadingFunction = baseGeom._delayLoadingFunction;
        for (kind in baseGeom._delayInfo) {
            geometry._delayInfo = geometry._delayInfo || [];
            geometry._delayInfo.push(kind);
        }
        // Bounding info
        geometry._boundingInfo = new BoundingInfo(baseGeom._extend.minimum, baseGeom._extend.maximum);
        return geometry;
    },


};

export default ObjHelper;
