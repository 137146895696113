<template>
    <div id="app">
        <overlay />
        <div id="container-part">
            <div
                id="part-left"
            >
                <leftMenu
                    v-if="canDisplayLeftMenu && isCanvasReady && isWebGL2Supported"
                    :catalog="catalogData.catalog"
                    :config="config"
                    :templates="catalogData.templates"
                    :grouped-objects="catalogData.groupedObjects"
                />
                <pdfEditorLeftMenu
                    v-if="canDisplayPdfLeftMenu && isCanvasReady && isWebGL2Supported"
                    :building-plan-data="buildingPlanData"
                />
            </div>
            <!-- <div style="width: 100px;height: 100px;" /> -->
            <div id="part-center">
                <topMenu
                    v-if="isCanvasReady && isWebGL2Supported"
                    :config="config"
                    :camera-data="cameraData"
                    :options-data="optionsData"
                    :grid-data="gridData"
                    :material-data="materialData"
                    :measurement-data="measurementData"
                    :building-plan-data="buildingPlanData"
                    @onSaveProject="unsaved = false"
                />
                <div class="h-100 overflow-hidden">
                    <obsidianCanvas
                        v-show="isWebGL2Supported"
                        :is-canvas-ready="isCanvasReady"
                    />
                </div>
                <bottomMenu
                    v-if="canDisplayBottomMenu && isCanvasReady && isWebGL2Supported"
                    :selection-data="selectionData"
                    :options-data="optionsData"
                    :gizmo-data="gizmoData"
                />
                <pdfBottomMenu
                    v-if="canDisplayPdfBottomMenu && isCanvasReady && isWebGL2Supported"
                    :building-plan-data="buildingPlanData"
                />
            </div>
            <div
                v-if="isCanvasReady && isWebGL2Supported"
                id="part-right"
            >
                <rightMenu
                    :groups="layersData.groups"
                    :engine-data="selectionData"
                />
            </div>
            <environment-selector
                v-if="isCanvasReady && isWebGL2Supported"
                v-show="isEnvSelectorOpen"
                :environment-name.sync="screenshotEnvironment.environmentName"
                :floor-name.sync="screenshotEnvironment.floorName"
            />
        </div>
        <div v-if="!isCanvasReady && !isWebGL2Supported">
            <textPopup
                :title="_('WebGL 2 not supported by your browser')"
                :content="_('Please update you browser or use one that support WebGL 2')"
            />
        </div>
        <ColorPickerIro />
    </div>
</template>

<script>
import config from "defaultConfig";
import RightsHelper from "helpers/rights-helper";
import overlay from "./components/overlay/overlay.vue";
import obsidianCanvas from "./components/obsidian-canvas.vue";
import topMenu from "./components/top-menu.vue";
import bottomMenu from "./components/bottom-menu/bottom-menu.vue";
import pdfBottomMenu from "./components/pdf-mode/bottom-menu.vue";
import rightMenu from "./components/right-menu.vue";
import leftMenu from "./components/left-menu.vue";
import pdfEditorLeftMenu from "./components/pdf-mode/left-menu.vue";
import environmentSelector from "./components/environment-selector.vue";
import textPopup from "./components/overlay/text-popup.vue";
import ColorPickerIro from "./components/color-picker-iro.vue";
import EventBus from "./eventBus";
import self from "../index";

const {
    app: {
        modules: {
            gizmoManager: { controller: gizmoController },
            cameraManager: {
                controller: cameraController,
                ScreenshotController: screenshotController,
            },
            buildingPlanManager: { buildingPlanController },
            groupManager: {
                Controller: groupController,
                GroupSelection: groupSelection,
            },
            selectionManager,
            optionManager: { optionController },
            gridManager,
            catalogManager,
            // Uncomment when a webgl2 feature is absolutly needed
            // obsidianEngine: { controller: engineController },
            initUtility,
            stonejs,
            materialManager,
            measurementManager: { Controller: measurementController },
        },
        events,
    },
} = self;

export default {
    name: "App",
    components: {
        overlay,
        topMenu,
        bottomMenu,
        rightMenu,
        leftMenu,
        obsidianCanvas,
        environmentSelector,
        textPopup,
        pdfEditorLeftMenu,
        pdfBottomMenu,
        ColorPickerIro,
    },
    data() {
        return {
            isCanvasReady: false,
            isWebGL2Supported: true,
            isExpanded: false,
            gridData: null,
            cameraData: null,
            catalogData: {
                catalog: [],
                templates: [],
                groupedObjects: [],
            },
            layersData: null,
            gizmoData: null,
            measurementData: null,
            config,

            // We must set this data to make the UI update when we change properties
            groupSelectionData: null,
            selectionData: null,
            unsaved: true,
            unloadLocked: RightsHelper.isModePublic(),
            isEnvSelectorOpen: false,
            screenshotEnvironment: null,
            buildingPlanData: null,
        };
    },
    computed: {
        canDisplayLeftMenu() {
            return !RightsHelper.isModePublic() && !RightsHelper.isModeBuildingPlan();
        },
        canDisplayPdfLeftMenu() {
            return RightsHelper.isModeBuildingPlan() && RightsHelper.isPlanEssential();
        },
        canDisplayBottomMenu() {
            return !RightsHelper.isModePublic() && !RightsHelper.isModeBuildingPlan();
        },
        canDisplayPdfBottomMenu() {
            return RightsHelper.isModeBuildingPlan() && RightsHelper.isPlanEssential();
        },
    },
    mounted() {
        EventBus.$on("toggle-groups", (open) => {
            this.isExpanded = !open;
        });

        if (initUtility.isConfigurationLoaded) {
            this.canvasReady();
        } else {
            events.on("@init-utility.configuration-loaded", () => {
                this.canvasReady();
            });
        }
        events.on("lockUnload", () => {
            this.unloadLocked = true; // Avoid to open the onUnload popup when we don't want
            // confirmation
        });

        window.addEventListener("beforeunload", (e) => {
            const confirmationMessage = `${this._(
                "Are you sure to leave the application ?"
                    + " All the unsaved changes will be lost."
            )}`;
            if (this.unsaved && !this.unloadLocked) {
                (e || window.event).returnValue = confirmationMessage; // Gecko + IE
                return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
            }
            return null;
        });

        events.on("@catalog-manager.catalog-initialized", () => {
            this.catalogData = catalogManager.vueData;
        });

        EventBus.$on("open-environment-selector", () => {
            this.isEnvSelectorOpen = true;
        });

        EventBus.$on("close-environment-selector", () => {
            this.isEnvSelectorOpen = false;
        });
    },
    methods: {
        canvasReady() {
            events.emit("canvas-ready");

            this.gridData = gridManager.vueData;
            this.layersData = groupController.vueData;
            // We must set this data to make the UI update when we change properties
            this.cameraData = cameraController.vueData;
            this.groupSelectionData = groupSelection.vueData;
            this.selectionData = selectionManager.vueData;
            this.optionsData = optionController.vueData;
            this.screenshotEnvironment = screenshotController.vueData;
            this.gizmoData = gizmoController.vueData;
            this.materialData = materialManager.vueData;
            this.measurementData = measurementController.vueData;
            this.isCanvasReady = true;
            // Uncomment when a webgl2 feature is absolutely necessary
            // this.isWebGL2Supported = Boolean(
            //     engineController.engine.webGLVersion >= 2
            // );
            //
            // Remove if a webgl2 feature is absolutely necessary
            this.isWebGL2Supported = true;
            this.buildingPlanData = buildingPlanController.vueData;
        },

        _(...args) {
            return stonejs.gettext(...args);
        },
    },
};
</script>
