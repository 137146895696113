export default {
    name: "building-plan-manager",
    requires: [
        "obsidian-engine",
        "data-store",
        "camera-manager",
        "group-manager",
        "selection-manager",
        "material-manager",
        "option-manager",
        "catalog-manager",
        "iframe-api",
        "geometry-utility",
        "measurement-manager",
        "stonejs",
    ],
    load() {
        const BuildingPlanController = require("./src/controller/building-plan-controller").default;
        const BuildingPlanImageController = require("./src/controller/building-plan-image-controller");
        const BuildingPlanHelper = require("./src/helper/building-plan-helper").default;
        const BuildingPlanPageStructure = require("./src/model/page-structure");
        const TEXT_SIZES = require("./src/controller/building-plan-controller").TEXT_SIZES;

        // Inject method to jspdf
        const JsPDFExtension = require("./src/vendors/jspdf-api-extension"); // eslint-disable-line

        return {
            buildingPlanController: new BuildingPlanController(),
            buildingPlanImageController: BuildingPlanImageController,
            buildingPlanHelper: BuildingPlanHelper,
            PAGE_TYPES: BuildingPlanPageStructure.PAGE_TYPES,
            TEXT_SIZES,
        };
    },
    unload() {},
};
