<template>
    <b-navbar
        v-if="showContainer"
        class="navbar-bottom"
        :class="{
            noRightMenu: isExpanded,
        }"
    >
        <div class="d-flex">
            <!-- Nav draw menu -->
            <b-navbar-nav
                v-if="validMovableObject || isNotConnector"
                class="bottom-menu-div"
            >
                <b-nav-text class="{ d-flex align-items-center }">
                    {{ _("DRAW") }}
                </b-nav-text>
                <template v-if="validMovableObject">
                    <b-nav-item
                        v-b-tooltip.hover
                        :title="_('Move')"
                        :class="{ 'active': gizmoData.currentGizmo === gizmoStates.POSITION }"
                        @click="toggleMoveGizmo()"
                    >
                        <b-img
                            class="icon"
                            src="/assets/icons/move.svg"
                        />
                    </b-nav-item>
                    <b-nav-item
                        v-b-tooltip.hover
                        :title="_('Rotate')"
                        :class="{ 'active': gizmoData.currentGizmo === gizmoStates.ROTATION }"
                        @click="toggleRotateGizmo()"
                    >
                        <b-img
                            class="icon"
                            src="/assets/icons/rotate.svg"
                        />
                    </b-nav-item>
                </template>
                <template v-if="isNotConnector">
                    <b-nav-item
                        v-b-tooltip.hover
                        :title="_('Duplicate')"
                        :class="{ 'active': selectionData.duplicationActive }"
                        @click="toggleDuplicateGizmo()"
                    >
                        <b-img
                            class="icon"
                            src="/assets/icons/duplicate.svg"
                        />
                    </b-nav-item>
                    <b-nav-item
                        v-if="validSwapObject"
                        v-b-tooltip.hover
                        :title="_('Replace')"
                        :class="{ 'active': gizmoData.currentGizmo === gizmoStates.SCALE }"
                        @click="toggleReplaceGizmo()"
                    >
                        <b-img
                            class="icon"
                            src="/assets/icons/scale.svg"
                        />
                    </b-nav-item>
                    <b-nav-item
                        v-if="areColorable"
                        v-b-tooltip.hover
                        :title="_('Change color')"
                        :class="{ 'active': showColorPicker === true }"
                        @click="toggleColorPicker()"
                    >
                        <b-img
                            class="icon"
                            src="/assets/icons/color.svg"
                        />
                    </b-nav-item>
                    <colorPicker
                        v-if="showColorPicker"
                        :palette="coverPalette"
                        :colorable-entity="currentEntity"
                        :config="config"
                    />
                </template>
            </b-navbar-nav>
            <!-- !Nav draw menu -->
            <!-- Nav options menu -->
            <!--
            Only displayed if selection is infillable
            as all conditions test for subsets of infillable
        -->
            <b-navbar-nav
                v-if="isOptionable || areOptionable"
                class="bottom-menu-div"
            >
                <b-nav-text class="{ d-flex align-items-center }">
                    {{ _("OPTIONS") }}
                </b-nav-text>
                <template v-if="isStraightFrame || areStraightFrame">
                    <b-nav-item
                        v-if="canAllHaveLightsOption"
                        v-b-tooltip.hover
                        :title="ligthTooltipContent"
                        :class="{ 'active': optionFamily === optionsData.optionsFamilies.LIGHT }"
                        @click="addLight()"
                    >
                        <b-img
                            class="icon"
                            src="/assets/icons/light.svg"
                        />
                    </b-nav-item>
                    <b-nav-item
                        v-if="canAllHaveShelfOption"
                        v-b-tooltip.hover
                        :title="shelfTooltipContent"
                        :class="{ 'active': optionFamily === optionsData.optionsFamilies.SHELF }"
                        @click="addShelf()"
                    >
                        <b-img
                            class="icon"
                            src="/assets/icons/shelf.svg"
                        />
                    </b-nav-item>
                    <b-nav-item
                        v-if="canAllHaveScreenOption"
                        v-b-tooltip.hover
                        :title="screenTooltipContent"
                        :class="{ 'active': optionFamily === optionsData.optionsFamilies.SCREEN }"
                        @click="addScreen()"
                    >
                        <b-img
                            class="icon"
                            src="/assets/icons/tv.svg"
                        />
                    </b-nav-item>
                    <b-nav-item
                        v-if="canAllHaveBaseplatesOption"
                        v-b-tooltip.hover
                        :title="baseplateTooltipContent"
                        :class="{
                            'active': optionFamily === optionsData.optionsFamilies.BASEPLATE
                        }"
                        @click="addBaseplate()"
                    >
                        <b-img
                            class="icon"
                            src="/assets/icons/baseplate.svg"
                        />
                    </b-nav-item>
                </template>
                <template
                    v-if="isBeTrussSquare || areBeTrussSquare"
                >
                    <b-nav-item
                        v-if="canAllHaveGroundplatesOption"
                        v-b-tooltip.hover
                        :title="groundplateTooltipContent"
                        :class="{
                            'active': optionFamily === optionsData.optionsFamilies.GROUNDPLATE
                        }"
                        @click="addGroundplate()"
                    >
                        <b-img
                            class="icon"
                            src="/assets/icons/baseplate.svg"
                        />
                    </b-nav-item>
                </template>
                <!-- Selected frames infills -->
                <template
                    v-if="isInfillable || areInfillable"
                >
                    <b-nav-item
                        v-b-tooltip.hover
                        :title="infillTooltipContent"
                        :class="{ 'active': optionFamily === optionsData.optionsFamilies.INFILL }"
                        @click="addInfill()"
                    >
                        <b-img
                            class="icon"
                            src="/assets/icons/infill.svg"
                        />
                    </b-nav-item>
                </template>
            </b-navbar-nav>
        </div>
        <!-- !Nav options menu -->
        <!-- Entity options menu -->
        <entityOption
            v-show="optionFamily"
            class="options-menu"
            :option-family="optionFamily"
            :config="config"
            :current-baseplate-type="selectionData.currentEntityBaseplateType"
            :current-groundplate-type="selectionData.currentEntityGroundplateType"
            :current-light-type="selectionData.currentEntityLightType"
            :selected-entity="currentEntity"
            :selected-meshes="multiSelectedMesh"
            :options-families="optionsData.optionsFamilies"
        />
        <!-- !Entity options menu -->
    </b-navbar>
</template>

<script>
import EntitiesData from "../../../../../entities-data";
import entityOption from "./entity-option.vue";
import colorPicker from "../color-picker.vue";
import EventBus from "../../eventBus";
import self from "../../../index";

const {
    app: {
        modules: {
            gizmoManager: { gizmoStates },
            optionManager: { optionController, RalColors: ralColors },
            selectionManager,
            stonejs,
        },
        events,
    },
} = self;

export default {
    name: "BottomMenu",

    components: {
        entityOption,
        colorPicker,
    },
    props: {
        selectionData: {
            type: Object,
            default: () => ({}),
        },
        optionsData: {
            type: Object,
            default: () => ({}),
        },
        gizmoData: {
            type: Object,
            default: () => ({}),
        },
        config: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            optionFamily: null,
            showColorPicker: false,
            baseplateController: optionController.baseplateController,
            screenController: optionController.screenBracketController,
            shelfController: optionController.shelfController,
            currentEntity: null,
            multiSelectedMesh: this.selectionData.multiSelectedMesh,
            coverPalette: ralColors.getColorCodes(),
            isExpanded: false,
            gizmoStates,
        };
    },
    computed: {
        isStraightFrame() {
            return this.currentEntity
                && this.currentEntity.subCategory.toUpperCase() === "STRAIGHT FRAMES";
        },
        areStraightFrame() {
            return this.multiSelectedMesh && this.multiSelectedMesh.length > 0
                && this.multiSelectedMesh.every(elt => elt.entity.subCategory.toUpperCase() === "STRAIGHT FRAMES");
        },
        isOptionable() {
            return this.currentEntity
                && this.currentEntity.isOptionable
                && this.currentEntity.ref !== "618 0992 0992 MDF"
                && this.currentEntity.subCategory !== "GLASS FRAMES";
        },
        isInfillable() {
            return this.isOptionable
                && this.currentEntity.subCategory !== "BETRUSS SQUARE"; // Temporary disabled
        },
        isBeTrussSquare() {
            return this.currentEntity
                && this.currentEntity.isOptionable
                && this.currentEntity.subCategory.includes("BETRUSS SQUARE");
        },
        areBeTrussSquare() {
            return this.multiSelectedMesh && this.multiSelectedMesh.length > 0
                && this.multiSelectedMesh.every(elt => elt.entity.isOptionable
                    && elt.entity.subCategory.includes("BETRUSS SQUARE"));
        },
        areOptionable() {
            if (this.multiSelectedMesh) {
                return (this.multiSelectedMesh.length > 0) && this.multiSelectedMesh.every(
                    (element) => {
                        const { subCategory } = element.entity;
                        const result = element.entity.isOptionable
                            && element.entity.ref !== "618 0992 0992 MDF"
                            && subCategory !== "GLASS FRAMES";
                        return result;
                    }
                );
            }
            return false;
        },
        areColorable() {
            if (this.multiSelectedMesh && this.multiSelectedMesh.length > 0) {
                return this.multiSelectedMesh.every(
                    element => element.entity.isColorable
                );
            }
            return this.currentEntity.isColorable;
        },
        areInfillable() {
            if (this.multiSelectedMesh) {
                return (this.multiSelectedMesh.length > 0) && this.multiSelectedMesh.every(
                    element => element.entity.isOptionable && element.entity.ref !== "618 0992 0992 MDF"
                    && !element.entity.subCategory.includes("BETRUSS SQUARE")
                );
            }
            return false;
        },
        isNotConnector() {
            return (
                this.currentEntity
                && this.currentEntity.__name__ !== "connector"
            );
        },
        noMultiSelectedConnectors() {
            return (
                this.multiSelectedMesh.length > 0
                && !this.multiSelectedMesh.some(
                    element => element.entity.__name__ === "connector"
                )
            );
        },
        showContainer() {
            const noMultiSelectedConnectors = this.multiSelectedMesh.length > 0
                && !this.multiSelectedMesh.some(
                    element => element.entity.__name__ === "connector"
                );
            return this.isNotConnector || noMultiSelectedConnectors;
        },
        canHaveOptions() {
            return this.currentEntity.canHaveOptions;
        },
        canAllHaveLightsOption() {
            if (this.currentEntity) return this.currentEntity.canHaveLightsOption;
            return this.multiSelectedMesh && this.multiSelectedMesh.length > 0
                    && this.multiSelectedMesh.every(elt => elt.entity.canHaveLightsOption);
        },
        canAllHaveShelfOption() {
            if (this.currentEntity) return this.currentEntity.canHaveShelfOption;
            return this.multiSelectedMesh && this.multiSelectedMesh.length > 0
                    && this.multiSelectedMesh.every(elt => elt.entity.canHaveShelfOption);
        },
        canAllHaveScreenOption() {
            if (this.currentEntity) return this.currentEntity.canHaveScreenOption;
            return this.multiSelectedMesh && this.multiSelectedMesh.length > 0
                    && this.multiSelectedMesh.every(elt => elt.entity.canHaveScreenOption);
        },
        canAllHaveBaseplatesOption() {
            if (this.currentEntity) return this.currentEntity.canHaveBaseplatesOption;
            return this.multiSelectedMesh && this.multiSelectedMesh.length > 0
                    && this.multiSelectedMesh.every(elt => elt.entity.canHaveBaseplatesOption);
        },
        canAllHaveGroundplatesOption() {
            if (this.currentEntity) return this.currentEntity.canHaveGroundplatesOption;
            return this.multiSelectedMesh && this.multiSelectedMesh.length > 0
                    && this.multiSelectedMesh.every(elt => elt.entity.canHaveGroundplatesOption);
        },
        canHaveScreens() {
            return this.currentEntity.canHaveScreenOption;
        },
        validSwapObject() {
            return (
                EntitiesData.entityTypeFromSubCategory(
                    this.currentEntity.subCategory,
                    this.currentEntity.ref
                )
            );
        },
        validMovableObject() {
            return this.isNotConnector || this.noMultiSelectedConnectors;
        },
        groundplateTooltipContent() {
            if (!this.currentEntity || this.currentEntity.optionsMeshes.groundplates.length === 0) {
                return this._("Add groundplate");
            }
            return this._("Edit groundplate");
        },
        baseplateTooltipContent() {
            if (!this.currentEntity || this.currentEntity.optionsMeshes.baseplates.length === 0) {
                return this._("Add baseplate");
            }
            return this._("Edit baseplate");
        },
        ligthTooltipContent() {
            if (!this.currentEntity || this.currentEntity.optionsMeshes.lights.length === 0) {
                return this._("Add light");
            }
            return this._("Edit light");
        },
        shelfTooltipContent() {
            if (!this.currentEntity || this.currentEntity.optionsMeshes.shelves.length === 0) {
                return this._("Add shelf");
            }
            return this._("Edit shelf");
        },
        screenTooltipContent() {
            if (!this.currentEntity || this.currentEntity.optionsMeshes.screens.length === 0) {
                return this._("Add TV brackets");
            }
            return this._("Edit TV brackets");
        },
        infillTooltipContent() {
            if (!this.currentEntity || this.currentEntity.optionsMeshes.infills.length === 0) {
                return this._("Add infill");
            }
            return this._("Edit infill");
        },
    },
    watch: {
        optionFamily(val) {
            const nbSelectObject = selectionManager.getSelectedObjectsLength();
            if (val) {
                if (
                    val !== this.optionsData.optionsFamilies.INFILL
                    && nbSelectObject > 1
                ) {
                    return;
                }
                EventBus.$emit("option-menu-open", val);
            } else {
                EventBus.$emit("option-menu-close");
            }
        },
    },

    mounted() {
        events.on("selectOption", type => {
            this.addOption(type);
        });
        EventBus.$on("toggle-groups", open => {
            this.isExpanded = !open;
        });
        events.on("@selection-manager.select-entity", entity => {
            this.onEntitySelected(entity);
        });
        events.on("@selection-manager.unselect-entity", () => {
            this.onEntitySelected();
        });
    },

    methods: {
        /**
         * Initialize entity data on entity selected
         */
        onEntitySelected(entity) {
            this.optionFamily = null;
            this.showColorPicker = false;
            this.baseplateController.availableBaseplates();
            this.screenController.updateMaxScreenNumber();
            this.shelfController.updateMaxShelfNumber();

            if (entity && entity.__name__ !== "connector") {
                this.currentEntity = entity;
            } else {
                this.currentEntity = null;
            }
        },

        /**
         * Shows the position gizmo
         */
        toggleMoveGizmo() {
            selectionManager.showSelectMeshPositionGizmo();
        },

        /**
         * Shows the rotation gizmo
         */
        toggleRotateGizmo() {
            selectionManager.showSelectMeshRotationGizmo();
        },

        /**
         * Shows the duplication sprite
         */
        toggleDuplicateGizmo() {
            selectionManager.toggleDuplication();
        },

        /**
         * Shows the swapping gizmo
         */
        toggleReplaceGizmo() {
            selectionManager.deactivateGizmo();
            selectionManager.removeControlToCurrentEntity();

            if (this.currentGizmo !== gizmoStates.SCALE) {
                selectionManager.showSelectMeshScaleGizmo();
            }
        },

        /**
         * Show the option menu according to the given option to the current entity
         * @param {Number} type - The type of option
         */
        addOption(type) {
            switch (type) {
            case this.optionsData.optionsFamilies.LIGHT:
                this.addLight();
                break;
            case this.optionsData.optionsFamilies.BASEPLATE:
                this.addBaseplate();
                break;
            case this.optionsData.optionsFamilies.GROUNDPLATE:
                this.addGroundplate();
                break;
            case this.optionsData.optionsFamilies.SHELF:
                this.addShelf();
                break;
            case this.optionsData.optionsFamilies.SCREEN:
                this.addScreen();
                break;
            case this.optionsData.optionsFamilies.INFILL:
                this.addInfill();
                break;
            default:
            }
        },

        /**
         * Set the current optionFamily as infill
         */
        addInfill() {
            this.showColorPicker = false;
            if (
                !this.optionFamily
                || this.optionFamily !== this.optionsData.optionsFamilies.INFILL
            ) {
                this.optionFamily = this.optionsData.optionsFamilies.INFILL;
            } else {
                this.optionFamily = null;
            }
        },

        /**
         * Set the current optionFamily as light
         */
        addLight() {
            this.showColorPicker = false;

            if (
                !this.optionFamily
                || this.optionFamily !== this.optionsData.optionsFamilies.LIGHT
            ) {
                this.optionFamily = this.optionsData.optionsFamilies.LIGHT;
            } else {
                this.optionFamily = null;
            }
        },

        /**
         * Set the current optionFamily as shelf
         */
        addShelf() {
            this.showColorPicker = false;

            if (
                !this.optionFamily
                || this.optionFamily !== this.optionsData.optionsFamilies.SHELF
            ) {
                this.optionFamily = this.optionsData.optionsFamilies.SHELF;
            } else {
                this.optionFamily = null;
            }
        },

        /**
         * Set the current optionFamily as baseplate
         */
        addBaseplate() {
            this.showColorPicker = false;

            if (
                !this.optionFamily
                || this.optionFamily !== this.optionsData.optionsFamilies.BASEPLATE
            ) {
                this.optionFamily = this.optionsData.optionsFamilies.BASEPLATE;
                EventBus.$emit("enabledBaseplatesOptions");
            } else {
                this.optionFamily = null;
            }
        },

        addGroundplate() {
            this.showColorPicker = false;
            if (
                !this.optionFamily
                || this.optionFamily !== this.optionsData.optionsFamilies.GROUNDPLATE
            ) {
                this.optionFamily = this.optionsData.optionsFamilies.GROUNDPLATE;
                EventBus.$emit("enabledGroundplatesOptions");
            } else {
                this.optionFamily = null;
            }
        },

        /**
         * Set the current optionFamily as screen
         */
        addScreen() {
            this.showColorPicker = false;

            if (
                !this.optionFamily
                || this.optionFamily !== this.optionsData.optionsFamilies.SCREEN
            ) {
                this.optionFamily = this.optionsData.optionsFamilies.SCREEN;
            } else {
                this.optionFamily = null;
            }
        },

        /**
         * Show the colorPicker
         */
        toggleColorPicker() {
            this.showColorPicker = !this.showColorPicker;
            this.optionFamily = null;
        },
        _(...args) {
            return stonejs.gettext(...args);
        },
    },
};
</script>
