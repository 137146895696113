import classUtils from "vendors/class-utils";
import self from "../index";


const Class = require("abitbol");

const RalColors = require("../utility/ral-colors");

const {
    app: {
        modules: {
            materialManager,
            history,
        },
    },
} = self;

/**
 * @class
 * Mixin for entities able to have their color changed
 * Allow to change between ral and eco
 */
const ColorableMixin = Class.$extend({

    isColorable: true,

    /**
     * Init the colorable mixin
     * @param {Boolean} ralOnly does the object support eco too
     */
    initColor(ralOnly = true) {
        this.ralOnly = ralOnly;

        if (!this.color && this.ralOnly) {
            this.color = RalColors.getDefaultColor();
        }
    },

    setRalMaterial() {
        if (this.color) {
            this.materialId = materialManager.createRalMaterial(this.__name__, this.color);
        }
    },

    setEcoMaterial() {
        this.materialId = materialManager.defaultMaterial;
        this.color = null;
    },

    /**
     * Change the entity's color, and register it in history
     * @param  {String} newColor color hex string
     */
    changeColor(newColor) {
        this.color = newColor;
        history.snapshot();
    },

    getColor: classUtils.getter,
    setColor(color) {
        this.$data.color = color;
        this.setRalMaterial();
    },

});

module.exports = ColorableMixin;
